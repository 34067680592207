<template>
  <div class="view-topbar">
    <div class="grid-container">
      <div class="grid align-items-center">
        <div class="col-7 pl-4">
          <div class="flex align-items-center">
            <span class="xl:hidden flex-shrink-0 flex">
              <ButtonUI size="lg" feather="menu" class="p-button-icon-only p-button-rounded" @click="openSidebar" />
            </span>
            <span class="pl-4 flex-grow-1 flex-shrink-1 flex breadcrumbs">
              <BreadCrumb />
            </span>
          </div>
        </div>
        <div class="col-5 text-right">
          <NavProfile />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import NavProfile from "@/components/nav/NavProfile.vue";
import ButtonUI from "@/components/ButtonUI.vue";
import { useNavStore } from "@/stores";
import BreadCrumb from "@/components/BreadCrumb.vue";

export default {
  components: {
    BreadCrumb,
    NavProfile,
    ButtonUI,
  },
  methods: {
    openSidebar() {
      useNavStore().sidebarOpen();
    },
  },
  mounted() {
    useNavStore().setTopNavState(true);
  },
  unmounted() {
    useNavStore().setTopNavState(false);
  },
};
</script>
<style lang="scss" scoped>
.breadcrumbs {
  width: 90%;
  overflow: hidden;
  white-space: nowrap;
  position: relative;
}
</style>
