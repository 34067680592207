<template>
  <PDialog
    v-model:visible="state"
    :header="title"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :style="{ width: '540px' }"
    :draggable="false"
    :modal="true"
    :dismissableMask="true"
  >
    <div class="confirmation-content line-height-3">
      <slot v-if="$slots.body" name="body" />
      <span v-else v-html="body" />
    </div>
    <template #footer>
      <div v-if="$slots.footer">
        <slot name="footer" />
      </div>
      <div v-else>
        <ButtonUI
          size="lg"
          :label="no ? no : $t('button.no')"
          @click="cancel"
          :underline="cancelTextLink"
          :variant="cancelTextLink ? 'text' : 'default'"
          :color="cancelTextLink ? 'text-default' : 'primary'"
          :icon="cancelTextLink ? null : 'pi pi-times'"
        />
        <ButtonUI size="lg" :label="yes ? yes : $t('button.yes')" icon="pi pi-check" @click="ok" autofocus />
      </div>
    </template>
  </PDialog>
</template>
<script>
import Dialog from "primevue/dialog";
import ButtonUI from "@/components/ButtonUI.vue";

export default {
  components: {
    ButtonUI,
    PDialog: Dialog,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    cancelTextLink: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: "Confirmation",
    },
    message: String,
    yes: {
      type: String,
      default: null,
    },
    no: {
      type: String,
      default: null,
    },
  },
  emits: ["onCancel", "onDiscard", "onConfirm"],
  methods: {
    cancel() {
      this.$emit("onCancel");
      this.$emit("onDiscard");
    },
    ok() {
      this.$emit("onConfirm");
    },
  },
  computed: {
    state: {
      get() {
        return this.visible;
      },
      set(state) {
        if (!state) {
          this.$emit("onCancel");
        }
      },
    },
    body() {
      return this.message ? this.message : this.$t("message.confirmation_proceed");
    },
  },
};
</script>
