import LeftSidebar from "@/components/LeftSidebar.vue";
import UsersIndexView from "@/views/_platform/users/IndexView.vue";
import UsersCreateView from "@/views/_platform/users/CreateView.vue";
import UsersEditView from "@/views/_platform/users/EditView.vue";

export const usersRoutes = [
  // Users
  {
    name: "users",
    path: "/users",
    components: {
      LeftSidebar,
      default: UsersIndexView,
    },
    meta: {
      permission: "show users",
      supportPageID: "012",
    },
  },
  {
    name: "users-create",
    path: "/users/create",
    components: {
      LeftSidebar,
      default: UsersCreateView,
    },
    meta: {
      permission: "create users",
      supportPageID: "053",
    },
  },
  {
    name: "users-edit",
    path: "/users/:user_id/edit",
    components: {
      LeftSidebar,
      default: UsersEditView,
    },
    meta: {
      permission: "edit users",
      supportPageID: "013",
    },
  },
];
