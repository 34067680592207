import ForgotPasswordView from "@/views/_platform/forgot-password/ForgotView.vue";
import ResetPasswordView from "@/views/_platform/forgot-password/ResetView.vue";

export const forgotPasswordRoutes = [
  // Forgot Password
  {
    name: "forgot-password",
    path: "/forgot-password",
    component: ForgotPasswordView,
    meta: {
      permission: "public",
    },
  },
  {
    name: "forgot-password-reset",
    path: "/forgot-password/reset",
    component: ResetPasswordView,
    meta: {
      permission: "public",
    },
  },
];
