import { useAuthStore, useAdminAuthStore, useNavStore } from "@/stores";

export function getPermissions(permissions) {
  const isAdmin = useNavStore().isAdminPage;
  return isAdmin ? getAdminPermissions(permissions) : getCustomerPermissions(permissions);
}

export function getCustomerPermissions(permissions) {
  return useAuthStore().customerHasPermissions(permissions);
}

export function getAdminPermissions(permissions) {
  return useAdminAuthStore().adminHasPermissions(permissions);
}
