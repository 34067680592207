import LeftSidebar from "@/components/LeftSidebar.vue";
import DashboardView from "@/views/_platform/DashboardView.vue";

export const dashboardRoutes = [
  {
    name: "dashboard",
    path: "/dashboard",
    components: {
      LeftSidebar,
      default: DashboardView,
    },
    meta: {
      supportPageID: "003",
    },
  },
];
