<template>
  <div class="view-body">
    <div class="content-wrapper">
      <PageTitle :title="$t('title.users_overview')" />

      <div class="tabview">
        <TabNav :items="tabNavItems">
          <template #controls>
            <div v-if="getPermissions('show users')">
              <ButtonUI
                :label="$t('button.add_user')"
                color="primary"
                feather="plus"
                :to="{ name: 'users-create', query: { ...this.$route.query } }"
                v-if="getPermissions('create users')"
              />
            </div>
          </template>
          <template #panels>
            <div v-if="!loading">
              <TableUI v-if="users.length > 0" name="users" :value="users">
                <template #columns>
                  <PColumn field="name" :header="$t('label.name')" :style="getCellWidth('name')" />
                  <PColumn field="roles" :header="$t('label.roles')" :style="getCellWidth('roles')">
                    <template #body="slotProps">
                      <span v-for="(role, index) in slotProps.data.roles" :key="role.id">
                        <span v-if="index !== 0">, </span>
                        {{ $t(`roles.${role.name}`) }}
                      </span>
                    </template>
                  </PColumn>
                  <PColumn field="email" :header="$t('label.email')" :style="getCellWidth('email')" />

                  <!-- Render description column -->
                  <PColumn :style="getCellWidth('description')">
                    <template #body="slotProps">
                      <ToolTip :content="slotProps.data.description" v-if="slotProps.data.description" type="click" />
                    </template>
                  </PColumn>

                  <!-- Render controls column -->
                  <PColumn
                    headerStyle="text-align: right"
                    bodyStyle="text-align: right; overflow: visible"
                    :style="getCellWidth('controls')"
                  >
                    <template #body="slotProps">
                      <div class="controls">
                        <ButtonUI
                          :label="$t('button.settings')"
                          variant="text"
                          color="text-palette-1"
                          feather="settings"
                          class="p-button-text-inline"
                          :to="{
                            name: 'users-edit',
                            params: { user_id: slotProps.data.id },
                            query: { ...this.$route.query },
                          }"
                          v-if="getPermissions('edit users')"
                        />
                      </div>
                    </template>
                  </PColumn>
                </template>
              </TableUI>
              <NotificationMessage type="default" v-else>{{ $t("message.no_data_found") }}</NotificationMessage>
            </div>
          </template>
        </TabNav>
      </div>
    </div>
  </div>
</template>

<script>
import TableUI from "@/components/TableUI.vue";
import Column from "primevue/column";
import PageTitle from "@/components/PageTitle.vue";
import TabNav from "@/components/nav/TabNav.vue";
import ButtonUI from "@/components/ButtonUI.vue";
import NotificationMessage from "@/components/NotificationMessage.vue";
import ToolTip from "@/components/ToolTip.vue";
import { storeToRefs } from "pinia";
import { useAuthStore, useUsersStore } from "@/stores";
import { customError, getPermissions, getTableCellWidth, i18n, setBreadcrumbs } from "@/helpers";

export default {
  setup() {
    setBreadcrumbs();
    const authStore = useAuthStore();
    authStore.validateMe();

    const usersStore = useUsersStore();
    usersStore.getUsers();

    const { users, loading } = storeToRefs(usersStore);

    const tabNavItems = [
      {
        title: i18n.global.t("label.users"),
        route: "users",
        permissions: "show users",
      },
    ];

    return {
      loading,
      users,
      tabNavItems,
    };
  },
  components: {
    TableUI,
    PColumn: Column,
    PageTitle,
    ButtonUI,
    NotificationMessage,
    TabNav,
    ToolTip,
  },
  methods: {
    getPermissions,
    getCellWidth(cell) {
      return getTableCellWidth("users", cell);
    },
  },
};
</script>
