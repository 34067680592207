<template>
  <form @submit.prevent="onSubmit">
    <PageTitle :title="appName" />
    <h2 class="text-xl">{{ mode }}</h2>
    <hr />
    <NotificationMessage :error="error" />
    <FormRow>
      <FormLabel for="username">Username:</FormLabel>
      <FormText
        id="username"
        autocomplete="username"
        type="text"
        size="lg"
        v-model="form.username"
        data-lpignore="true"
      />
    </FormRow>

    <FormRow>
      <FormLabel for="current-password">Password:</FormLabel>
      <FormText
        inputId="current-password"
        :inputProps="{
          autocomplete: 'current-password',
        }"
        type="password"
        size="lg"
        v-model="form.password"
      />
    </FormRow>

    <div class="field mb-0">
      <ButtonUI type="submit" size="lg" class="w-full justify-content-center" label="Login" :disabled="isSubmitting" />
    </div>
  </form>
</template>
<script>
import FormRow from "@/components/form/FormRow.vue";
import FormText from "@/components/form/FormText.vue";
import ButtonUI from "@/components/ButtonUI.vue";
import PageTitle from "@/components/PageTitle.vue";
import NotificationMessage from "@/components/NotificationMessage.vue";
import FormLabel from "@/components/form/FormLabel.vue";
import { useBasicAuthStore } from "@/stores/basicAuth.store";
import { useAppStore } from "@/stores";

export default {
  setup() {
    let { mode, appName } = useAppStore().getAppConfig;
    mode = mode.charAt(0).toUpperCase() + mode.slice(1);
    return {
      mode,
      appName,
    };
  },
  components: {
    FormRow,
    ButtonUI,
    FormText,
    NotificationMessage,
    FormLabel,
    PageTitle,
  },
  data() {
    return {
      form: {
        username: null,
        password: null,
      },
      error: {},
      isSubmitting: false,
    };
  },
  methods: {
    async onSubmit() {
      const basicAuthStore = useBasicAuthStore();
      const { username, password } = this.form;
      return basicAuthStore.login(username, password).catch((error) => {
        this.error = error;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
form {
  margin: 60px;
  max-width: 320px;
}
</style>
