<template>
  <div class="content-wrapper">
    <div class="notfound-view">
      <SurfaceCard>
        <PageTitle :title="$t('404.title')" />
        <IntroText :text="$t('404.text')" class="mb-0" />
      </SurfaceCard>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/components/PageTitle.vue";
import IntroText from "@/components/IntroText.vue";
import SurfaceCard from "@/components/SurfaceCard.vue";
import { setBreadcrumbs } from "@/helpers";

export default {
  setup() {
    setBreadcrumbs();
  },
  components: {
    PageTitle,
    IntroText,
    SurfaceCard,
  },
};
</script>
