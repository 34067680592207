<template>
  <div v-if="resourceDataSource.length > 0">
    <div v-if="conflicting?.state">
      <NoteAlert
        :text="$t('message.schedule_systems_conflicting')"
        v-if="conflicting.type.toLowerCase() === 'systems'"
      />
      <NoteAlert :text="$t('message.schedule_groups_conflicting')" v-if="conflicting.type.toLowerCase() === 'groups'" />
    </div>
    <p class="mt-0" v-html="$t('message.info_schedule_timeslot_event')" />
    <SurfaceCard class="p-0 mt-7">
      <div class="card-body">
        <!-- eslint-disable -->
        <kendo-localization language="en-EN">
          <kendo-intl locale="en">
            <!-- https://www.telerik.com/forums/success-callback-on-datasource-transport-read -->
            <!-- https://stackoverflow.com/questions/23172685/how-to-disable-events-in-kendo-ui-scheduleri-just-to-make-it-read-only -->
            <kendo-schedulerdatasource
              ref="remoteDataSource"
              :batch="true"
              :transport-read="{
                url: apiGetEvents('read'),
                type: 'GET',
                dataType: 'json',
                beforeSend: onBeforeSend,
              }"
              :transport-update="{
              url: function (options) {
                return apiGetEvents('update', options);
              },
              type: 'PUT',
              dataType: 'json',
              beforeSend: onBeforeSend,
            }"
              :transport-create="{
              url: function (options) {
                return apiGetEvents('create', options);
              },
              type: 'POST',
              dataType: 'json',
              beforeSend: onBeforeSend,
            }"
              :transport-destroy="{
              url: function (options) {
                return apiGetEvents('destroy', options);
              },
              type: 'DELETE',
              dataType: 'json',
              beforeSend: onBeforeSend,
            }"
              :transport-parameter-map="parameterMap"
              :schema-timezone="'Etc/UTC'"
              schema-model-id="id"
              :schema-model-fields="fields"
            >
            </kendo-schedulerdatasource>

            <kendo-scheduler
              data-source-ref="remoteDataSource"
              ref="scheduler"
              :date="startDayDate"
              :min="minDate"
              :group="group"
              :work-day-start="startDayDate"
              :work-day-end="endDayDate"
              :footer="false"
              :show-work-hours="false"
              @databound="onInit"
              @navigate="onNavigate"
              @save="onSave"
              @remove="onRemove"
              @resize="onResize"
              @move="onMove"
              @moveend="onMoveEnd"
              :eventTemplate="eventTemplate"
              timezone="Etc/UTC"
              :editable="{
              move: moveValidated,
              template: editableTemplate,
            }"
            >
              <kendo-scheduler-view :type="'timeline'" :event-height="30"></kendo-scheduler-view>
              <kendo-scheduler-resource
                :field="'type'"
                :name="'Type'"
                :title="'Type'"
                :data-source="resourceDataSource"
              ></kendo-scheduler-resource>
            </kendo-scheduler>
          </kendo-intl>
        </kendo-localization>
        <!-- eslint-disable -->
      </div>
    </SurfaceCard>
    <DialogSchedulerBrowsePlaylist :type="playlistDialogType" v-model:visible="showPlaylistDialog" @onSelected="playlistSelectedFromDialog = $event"  />
  </div>
  <TextUI v-else>{{ $t("message.no_data_found") }}</TextUI>
</template>
<script>
import "@progress/kendo-ui/js/kendo.scheduler";
import "../../../kendo-ui-license.js";

import { Scheduler, SchedulerView, SchedulerResource } from "@progress/kendo-scheduler-vue-wrapper";
import { KendoSchedulerDataSource } from "@progress/kendo-datasource-vue-wrapper";

import "@progress/kendo-theme-default/dist/all.css";

import SurfaceCard from "@/components/SurfaceCard.vue";
import { getApiHeaders, getPermissions } from "@/helpers";
import { useNotificationStore, useNotificationStore as notificationStore, useScheduleStore } from "@/stores";

import { IntlProvider, LocalizationProvider } from "@progress/kendo-vue-intl";

// import likelySubtags from "cldr-core/supplemental/likelySubtags.json";
// import currencyData from "cldr-core/supplemental/currencyData.json";
// import weekData from "cldr-core/supplemental/weekData.json";
// import numbers from "cldr-numbers-full/main/es/numbers.json";
// import currencies from "cldr-numbers-full/main/es/currencies.json";
// import caGregorian from "cldr-dates-full/main/es/ca-gregorian.json";
// import dateFields from "cldr-dates-full/main/es/dateFields.json";
// load(likelySubtags, currencyData, weekData, numbers, currencies, caGregorian, dateFields);
// import esMessages from "./es.json";
// loadMessages(esMessages, "es-ES");

import autoComplete from "@tarekraafat/autocomplete.js";
import TextUI from "@/components/TextUI.vue";
import NoteAlert from "@/components/NoteAlert.vue";
import DialogSchedulerBrowsePlaylist from "@/components/_platform/dialog/DialogSchedulerBrowsePlaylist.vue";
import { useAppStore } from "@/stores";
import { storeToRefs } from "pinia";

export default {
  setup() {
    const scheduleStore = useScheduleStore();
    scheduleStore.getScheduleConflicting();
    const { conflicting } = storeToRefs(scheduleStore);
    return {
      conflicting,
    };
  },
  components: {
    NoteAlert,
    DialogSchedulerBrowsePlaylist,
    TextUI,
    "kendo-localization": LocalizationProvider,
    "kendo-intl": IntlProvider,
    "kendo-scheduler": Scheduler,
    "kendo-scheduler-view": SchedulerView,
    "kendo-scheduler-resource": SchedulerResource,
    "kendo-schedulerdatasource": KendoSchedulerDataSource,
    SurfaceCard,
  },
  props: {
    playlists: {
      type: Array,
    },
  },
  data() {
    return {
      showPlaylistDialog: false,
      playlistDialogType: "music",
      titleHasChanged: false,
      moveTempType: false,
      showAlert: true,
      scrollToTimeout: false,
      editTemplateTimeout: false,
      scrollLeftPos: 0,
      startDayDate: new Date(),
      minDate: new Date(new Date().setMonth(new Date().getMonth() - 1)),
      endDayDate: new Date(new Date().setHours(24, 0, 0, 0)),
      tempAutoCompleteResult: [],
      playlistSelectedFromDialog: {},
      fields: {
        id: { from: "id", type: "number" },
        playlist_type: { from: "playlist_type", type: "string" },
        title: {
          from: "title",
          validation: { required: true },
        },
        start: {
          type: "date",
          from: "start",
          validation: { required: true },
        },
        end: {
          type: "date",
          from: "end",
          validation: {
            required: true,
            dateCompare: { value: this.dateCompareValidator },
          },
        },
        description: { from: "description" },
        recurrenceId: { from: "recurrence_id" },
        recurrenceRule: { from: "recurrence_rule" },
        recurrenceException: { from: "recurrence_exception" },
        type: { from: "type", nullable: true, validation: { required: true } },
        playlist: { from: "playlist_id", validation: { required: true } },
        isAllDay: { type: "boolean", from: "is_all_day", defaultValue: false },
        isShuffled: { type: "boolean", from: "is_shuffled", defaultValue: true },
        isAdultOnly: { type: "boolean", from: "is_adult_only", defaultValue: false },
        slideInterval: { from: "slide_interval", defaultValue: 7 },
        slideShuffle: { from: "slide_shuffle", defaultValue: false },
        slideOverrideSystemAdvertising: { from: "slide_override_system_advertising", defaultValue: false },
        slideAdvertisingDisplayDuration: { from: "slide_advertising_display_duration", defaultValue: 30 },
        slideAdvertisingInterval: { from: "slide_advertising_interval", defaultValue: 1 },
        frameInterval: { from: "frame_interval", defaultValue: 5 },
        tickerBar: { from: "ticker_bar", defaultValue: 1 },
      },
      types: ["music", "slides", "frames", "tickers"], // also add type in _schedule.scss file!
      group: {
        resources: ["Type", "Playlists"],
        orientation: "vertical",
      },
      filteredPlaylists: [],
      filterPlaylistTypes: [],
      schedulerType: null,
      autoCompleteValue: "",
    };
  },
  mounted() {
    this.addPermissionClasses();
    window.showBrowsePlaylist = this.showBrowsePlaylist;
  },
  unmounted() {
    this.addPermissionClasses(false);
  },
  computed: {
    resourceDataSource() {
      let dataSource = [];

      let color = 600;
      this.types.forEach((type) => {
        if (this.showPermission(type)) {
          dataSource = [
            ...dataSource,
            {
              text: this.$t("label." + type), // Text of the resource instance
              value: type, // The identifier of the resource instance. Use that value to assign an event to this instance.
              color: this.getGroupColor(color), // Used as the background of events assigned to this resource.
            },
          ];
          color = color - 200;
          if (color < 100) {
            color = 50;
          }
        }
      });
      return dataSource;
    },
  },
  methods: {
    timezone() {
      return "Etc/UTC";
    },
    getPermissions,
    dateCompareValidator(input) {
      if (input.filter("[name=end]").length) {
        const container = input.closest(".k-scheduler-edit-form");
        const startInput = container.find("[name=start]:visible");
        const endInput = container.find("[name=end]:visible");

        if (startInput.val() != "" && endInput.val() != "") {
          if (Date.parse(startInput.val()) < Date.parse(endInput.val())) {
            return true;
          }
        }
        return false;
      }
      return true;
    },
    apiGetEvents(type, options = null) {
      const { apiUrl } = useAppStore().getAppConfig;
      const { system_id, group_id } = this.$route.params;

      const id = options?.models[0]?.id;

      let endpoint;
      switch (type) {
        case "read":
        case "create":
          if (system_id) {
            endpoint = `systems/${system_id}/schedule`;
          } else if (group_id) {
            endpoint = `groups/${group_id}/schedule`;
          }
          return `${apiUrl}/${endpoint}`;
        case "update":
        case "destroy":
          if (system_id) {
            endpoint = `systems/${system_id}/schedule`;
          } else if (group_id) {
            endpoint = `groups/${group_id}/schedule`;
          }
          return `${apiUrl}/${endpoint}/${id}`;
      }
    },
    onBeforeSend(xhr) {
      const apiHeaders = getApiHeaders();
      Object.keys(apiHeaders).forEach((key, index) => {
        xhr.setRequestHeader(key, apiHeaders[key]);
      });
    },
    parameterMap: function (options, operation) {
      if (operation !== "read" && options.models) {
        return { models: JSON.stringify(options.models) };
      }
    },
    getGroupColor(color = "600") {
      return getComputedStyle(document.documentElement).getPropertyValue(`--primary-${color}`);
    },
    getScrollPos() {
      const _self = this;
      const scheduler = _self.$refs.scheduler;
      const contentDiv = scheduler.$el.querySelector(".k-scheduler-content");

      contentDiv.addEventListener("scroll", function () {
        // Get the current horizontal scroll position of the element
        _self.scrollLeftPos = contentDiv.scrollLeft;
      });
    },
    scrollToHour(e = null) {
      const _self = this;

      if (_self.scrollToTimeout) {
        clearTimeout(_self.scrollToTimeout);
      }

      _self.scrollToTimeout = setTimeout(() => {
        const scheduler = _self.$refs.scheduler;
        const contentDiv = scheduler.$el.querySelector(".k-scheduler-content");
        const columns = contentDiv.querySelectorAll("tr:first-child td");

        let currentTime = new Date();

        if (e && e?.date) {
          const oldTime = currentTime;
          currentTime = e.date;
          currentTime.setHours(oldTime.getHours());
          currentTime.setMinutes(oldTime.getMinutes());
        }

        let startTime = new Date(new Date(currentTime).setHours(0, 0, 0, 0));
        let endTime = new Date(new Date(currentTime).setHours(0, 0, 0, 0));
        endTime = new Date(endTime.setMinutes(endTime.getMinutes() + 30));

        let minutes = 0;
        [...columns].forEach((el) => {
          startTime = new Date(startTime.setMinutes(startTime.getMinutes() + minutes));
          endTime = new Date(endTime.setMinutes(endTime.getMinutes() + minutes));

          if (currentTime.getTime() <= endTime.getTime() && currentTime.getTime() >= startTime.getTime()) {
            contentDiv.scroll({
              left: 0,
            });

            const elClientRect = el.getBoundingClientRect();
            const contentDivClientRect = contentDiv.getBoundingClientRect();

            const elementLeft = elClientRect.left;
            const containerLeft = contentDivClientRect.left;

            contentDiv.scroll({
              left: elementLeft - containerLeft - 10,
            });
          }
          minutes = 30;
        });
      }, 100);
    },
    scrollToPos() {
      const _self = this;
      _self.scrollToTimeout = setTimeout(() => {
        const scheduler = _self.$refs.scheduler;
        const contentDiv = scheduler.$el.querySelector(".k-scheduler-content");
        contentDiv.scrollLeft = _self.scrollLeftPos;
      }, 100);
    },
    onInit(e) {
      this.getScrollPos();
      if (!this.init) {
        this.scrollToHour();
        this.init = true;
      }
    },
    permission(permission, type) {
      let validated = false;
      // const permissionType = type === "music" ? "playlists" : type;
      const permissionType = type;
      if (this.getPermissions(`${permission} ${permissionType}`)) {
        validated = true;
      }
      return validated;
    },
    editPermission(type) {
      return this.permission("search", type);
    },
    removePermission(type) {
      return this.permission("search", type);
    },
    createPermission(type) {
      return this.permission("search", type);
    },
    showPermission(type) {
      return this.permission("search", type);
    },
    onMove(event) {
      const type = event.resources.type;
      if (!this.moveTempType) {
        this.moveTempType = type;
      }
      if (type != this.moveTempType) {
        this.showAlert = false;
      }
      event.preventDefault();
      return false;
    },
    onMoveEnd(event) {
      this.moveTempType = event.event.type;
    },
    onResize(e) {
      if (!this.editPermission(e.event.type)) {
        e.preventDefault();
      }
    },
    onNavigate(e) {
      // this.scrollToHour(e);
      this.scrollToPos();
      return e;
    },
    onSave(e) {
      if (this.showAlert) {
        notificationStore().saveAlert(this.$t("message.schedule_is_updated"));
      }
      setTimeout(() => {
        this.showAlert = true;
      }, 100);
    },
    moveValidated() {
      // check if any permission exists AND type is visible --> disable move completely
      let validated = true;
      this.types.forEach((type) => {
        if (this.showPermission(type)) {
          if (!this.createPermission(type)) {
            validated = false;
          }

          if (!this.editPermission(type)) {
            validated = false;
          }

          if (!this.removePermission(type)) {
            validated = false;
          }
        }
      });
      return validated;
    },
    onRemove(e) {
      if (this.removePermission(e.event.type)) {
        notificationStore().saveAlert(this.$t("message.schedule_is_updated"));
      } else {
        e.preventDefault();
      }
    },
    setPlaylistAutocompleteTitle(title) {
      const container = document.querySelector(".k-scheduler-edit-form");
      const autocompleteInputEl = container.querySelector("#playlistAutoComplete");
      autocompleteInputEl.value = title;

      if (!this.titleHasChanged) {
        const inputTitleEl = document.querySelector("input[name='title']");
        inputTitleEl.value = title;
        inputTitleEl.dispatchEvent(new Event("change"));
      }
    },
    setPlayListID(id) {
      const container = document.querySelector(".k-scheduler-edit-form");
      const playlistInputEl = container.querySelector("#playlistId");
      playlistInputEl.value = id;
    },
    setPlayListType(type) {
      const container = document.querySelector(".k-scheduler-edit-form");
      const playlistTypeInputEl = container.querySelector("#playlistType");
      playlistTypeInputEl.value = type;
    },
    triggerUpdateClick() {
      const container = document.querySelector(".k-scheduler-edit-form");
      const playlistInputEl = container.querySelector("#playlistId");
      const playlistTypeInputEl = container.querySelector("#playlistType");

      const autoCompleteInputEl = container.querySelector("#playlistAutoComplete");

      if (autoCompleteInputEl.value === "") {
        this.tempAutoCompleteResult = [];
      }

      // if (this.tempAutoCompleteResult.length > 0 && this.tempAutoCompleteResult[0]?.value?.id) {
      //   playlistInputEl.value = this.tempAutoCompleteResult[0].value.id;
      //   playlistTypeInputEl.value = this.tempAutoCompleteResult[0].value.playlist_type;
      // }

      playlistInputEl.dispatchEvent(new Event("change"));
      playlistTypeInputEl.dispatchEvent(new Event("change"));
    },
    eventTemplate(event) {
      return `<div class="k-event-template k-event-type--${event.type}">${event.title}</div>`;
    },
    editableTemplate(event) {
      const createEvent = event.id === 0;

      if (createEvent) {
        if (this.createPermission(event.type)) {
          return this.editTemplate(event);
        }
        return this.$t("message.schedule_no_permission");
      } else {
        if (this.editPermission(event.type)) {
          return this.editTemplate(event);
        }
        if (this.showPermission(event.type)) {
          return this.readOnlyTemplate(event);
        }
        return this.$t("message.schedule_no_permission");
      }
    },
    addPermissionClasses(add = true) {
      const _self = this;

      const { providerID } = useAppStore().getAppConfig;
      document.body.classList.add(`k-scheduler-provider--${providerID}`);

      this.types.forEach((type) => {
        if (add) {
          if (_self.createPermission(type)) {
            document.body.classList.add(`k-scheduler-${type}--create`);
          }
          if (_self.editPermission(type)) {
            document.body.classList.add(`k-scheduler-${type}--edit`);
          }
          if (_self.removePermission(type)) {
            document.body.classList.add(`k-scheduler-${type}--remove`);
          }
        } else {
          document.body.classList.remove(`k-scheduler-${type}--create`);
          document.body.classList.remove(`k-scheduler-${type}--edit`);
          document.body.classList.remove(`k-scheduler-${type}--remove`);
        }
      });
    },
    readOnlyTemplate(event) {
      const playlistId = event.playlist;
      let currentPlaylist = this.playlists.find((playlist) => playlist.id === playlistId);
      const playlistTitle = currentPlaylist?.title ? currentPlaylist.title : "";
      return `
    <div class="k-edit-label"><label for="title">${this.$t("label.title")}</label></div>
    <div data-container-for="title" class="k-edit-field">
        <span data-bind="text:title"></span>
    </div>
    <div class="k-edit-label">
        <label for="start">${this.$t("label.start")}</label>
      </div>
    <div data-container-for="start" class="k-edit-field">
        <span data-bind="text:start" data-format="dd/mm/yyyy h:mmtt"></span>
    </div>
    <div class="k-edit-label"><label for="end">${this.$t("label.end")}</label></div>
    <div data-container-for="end" class="k-edit-field">
        <span data-bind="text:end" data-format="dd/mm/yyyy h:mmtt"></span>
    </div>
    <div class="k-edit-label"><label for="description">${this.$t("label.description")}</label></div>
    <div data-container-for="description" class="k-edit-field">
         <span data-bind="text:description"></span>
    </div>
    <div id="playlists">
        <div class="k-edit-label"><label for="playlistId">${this.$t("label.playlist")}</label></div>
        <div data-container-for="playlist_id" class="k-edit-field">
            <span>${playlistTitle}</span>
        </div>
    </div>`;
    },
    disableRangeAdvertisingInterval(value) {
      const intervalID = "slideAdvertisingInterval";
      if (value == 0 || value > 60 || value === "") {
        this.syncRangeValue(document.getElementById(intervalID), 1, intervalID);
        this.syncRangeValue(document.getElementById(`${intervalID}Range`), 1, intervalID);
        document.getElementById(`${intervalID}Range`).setAttribute("disabled", "true");
        document.getElementById(`${intervalID}Text`).classList.add("is-disabled");
      } else {
        document.getElementById(`${intervalID}Range`).removeAttribute("disabled");
        document.getElementById(`${intervalID}Text`).classList.remove("is-disabled");
      }
    },
    initRangeSlider(id) {
      const _self = this;
      const inputEl = document.getElementById(id);
      const rangeEl = document.getElementById(`${id}Range`);

      if (inputEl && rangeEl) {
        _self.syncRangeValue(rangeEl, inputEl.value, id);

        // on input
        rangeEl.oninput = () => {
          _self.syncRangeValue(inputEl, rangeEl.value, id);
        };

        if (id === "slideAdvertisingDisplayDuration") {
          if (isNaN(parseInt(inputEl.value))) {
            _self.syncRangeValue(rangeEl, 65, id);
          }
          _self.disableRangeAdvertisingInterval(inputEl.value);
          rangeEl.onchange = () => {
            _self.disableRangeAdvertisingInterval(inputEl.value);
          };
        }
      }
    },
    syncRangeValue(el, value, id) {
      el.value = value;
      this.setRangeText(id, value);
      el.dispatchEvent(new Event("change"));
    },
    setRangeText(id, value) {
      const textEl = document.getElementById(`${id}Text`);
      value = parseInt(value);
      switch (id) {
        case "slideAdvertisingDisplayDuration":
          if (isNaN(value) || value == 65) {
            textEl.innerHTML = this.$t("label.always");
            const inputEl = document.getElementById(id);
            inputEl.value = "";
          } else {
            if (value > 5 && value < 65) {
              textEl.innerHTML = this.$t("label.show_x_seconds", { value });
            } else {
              textEl.innerHTML = this.$t("label.never");
            }
            if (value <= 5) {
              const inputEl = document.getElementById(id);
              inputEl.value = 0;
            }
          }
          break;
        case "slideAdvertisingInterval":
          if (value == 1) {
            textEl.innerHTML = this.$t("label.show_between_every_video");
          } else {
            textEl.innerHTML = this.$t("label.show_between_every_x_videos", { value });
          }
          break;
      }
    },
    getPlaylist() {
      const _self = this;
      if (!_self.schedulerType) {
        _self.filteredPlaylists = [];
      }
      _self.filteredPlaylists = _self.playlists.filter((item) => {
        if (item.type === _self.schedulerType && _self.filterPlaylistTypes.includes(item.playlist_type)) {
          return item;
        }
      });
    },
    filterPlaylistType() {
      const filterPlaylistType = document.querySelectorAll(".filter-playlist-type");
      this.filterPlaylistTypes = [];
      filterPlaylistType.forEach((checkbox) => {
        if (checkbox.checked) {
          this.filterPlaylistTypes.push(checkbox.value);
        }
      });
    },
    initInterval(event) {
      const _self = this;
      const playlistId = event.playlist;
      const containerEl = document.querySelector(".k-scheduler-edit-form");
      const autoCompleteInputEl = containerEl.querySelector("#playlistAutoComplete");

      if (autoCompleteInputEl) {
        // if container is found, stop search interval
        if (_self.editTemplateTimeout) {
          clearInterval(_self.editTemplateTimeout);
        }

        _self.initRangeSlider("slideAdvertisingDisplayDuration");
        _self.initRangeSlider("slideAdvertisingInterval");

        // reset values
        if (!_self.autoCompleteValue && playlistId !== "") {
          _self.setPlayListID("");
          _self.setPlayListType("");
        }

        // titleHasChanged start value
        const titleVal = document.querySelector("input[name='title']").value;
        _self.titleHasChanged = titleVal.length > 0;

        // init autoComplate
        const autoCompleteJS = new autoComplete({
          selector: "#playlistAutoComplete",
          placeHolder: _self.$t("placeholder.enter_name"),
          submit: true,
          data: {
            src: () => {
              return new Promise((resolve) => {
                setTimeout(() => {
                  _self.getPlaylist();
                  resolve(_self.filteredPlaylists);
                }, 100);
              });
            },
            keys: ["title"],
          },
          resultsList: {
            element: (list, data) => {
              if (!data.results.length) {
                // Create "No Results" message element
                const message = document.createElement("div");
                // Add class to the created element
                message.setAttribute("class", "no_result");
                // Add message text content
                message.innerHTML = `<span>${_self.$t("message.no_results_found")}</span>`;
                // Append message element to the results list
                list.prepend(message);
              }
            },
            noResults: true,
          },
          resultItem: {
            element: (item, data) => {
              let media = "";
              if (data.value?.contains) {
                data.value.contains.forEach((badge) => {
                  media += `
                  <span class="badge badge-md badge-media">
                    <span class="badge-label">${badge}</span>
                  </span>`;
                });
              }

              let category = "";
              if (data.value?.category) {
                data.value.category.forEach((badge) => {
                  category += `
                  <span class="badge badge-md badge-category">
                    <span class="badge-label">${badge}</span>
                  </span>`;
                });
              }

              let owner = "";
              if (data.value?.owner) {
                owner += `
                  <span class="badge badge-md badge-owner">
                    <span class="badge-label">${data.value.owner}</span>
                  </span>`;
              }

              let render = "";
              render += `<div class="autocomplete-item">`;
              render += `<div class="autocomplete-title">${data.match}</div>`;
              if (category || media || owner) {
                render += `<div class="autocomplete-badges">
                    ${category ? "<span class='badges-category'>" + category + "</span>" : ""}
                    ${media ? "<span class='badges-media'>" + media + "</span>" : ""}
                    ${owner ? "<span class='badges-owner'>" + owner + "</span>" : ""}
                  </div>`;
              }
              render += `</div>`;

              item.innerHTML = render;
            },
            highlight: true,
          },
          events: {
            input: {
              results(event) {
                const results = event.detail.results;
                _self.tempAutoCompleteResult = results ? results : [];
                _self.setPlayListID("");
                _self.setPlayListType("");
              },
              focus() {
                if (autoCompleteJS.input.value.length) autoCompleteJS.start();
              },
              selection(autoCompleteEvent) {
                const feedback = autoCompleteEvent.detail;
                autoCompleteJS.input.blur();
                // Prepare User's Selected Value
                const selection = feedback.selection.value[feedback.selection.key];
                // Render selected choice to selection div
                // document.querySelector(".selection").innerHTML = selection;
                // Replace Input value with the selected value
                autoCompleteJS.input.value = selection;
                // Console log autoComplete data feedback
                if (feedback?.selection?.value) {
                  _self.setPlayListID(feedback.selection.value.id);
                  _self.setPlayListType(feedback.selection.value.playlist_type);
                  if (!_self.titleHasChanged) {
                    const inputTitleEl = document.querySelector("input[name='title']");
                    inputTitleEl.value = feedback.selection.value.title;
                    inputTitleEl.dispatchEvent(new Event("change"));
                  }
                }
                _self.tempAutoCompleteResult = [];
              },
            },
          },
        });

        const schedulerUpdateEl = document.querySelector(".k-scheduler-update");
        if (schedulerUpdateEl) {
          schedulerUpdateEl.addEventListener("click", () => {
            _self.triggerUpdateClick();
          });
        }

        const inputTitleEl = document.querySelector("input[name='title']");
        if (inputTitleEl) {
          inputTitleEl.addEventListener("keyup", () => {
            const value = document.querySelector("input[name='title']").value;
            _self.titleHasChanged = value.length > 0;
          });
        }

        // filter autocomplete list by playlist_type
        const filterPlaylistType = document.querySelectorAll(".filter-playlist-type");
        filterPlaylistType.forEach((checkbox) => {
          checkbox.addEventListener("change", function () {
            _self.filterPlaylistType();
          });
        });
      }

      document.querySelector("input#slideInterval").removeAttribute("required");
      document.querySelector("input#frameInterval").removeAttribute("required");

      if (event.type === "slides") {
        document.querySelector("input#slideInterval").setAttribute("required", "required");
      }

      if (event.type === "frames") {
        document.querySelector("input#frameInterval").setAttribute("required", "required");
      }

      // set load state
      document.querySelector(".k-window").classList.add("is-loaded");
    },
    editTemplate(event) {
      const _self = this;
      const playlistId = event.playlist;

      _self.tempAutoCompleteResult = [];
      _self.filterPlaylistTypes = ["custom", "bcm"];

      this.schedulerType = event.type;
      this.getPlaylist();

      let currentPlaylist = _self.filteredPlaylists.find((playlist) => playlist.id === playlistId);
      _self.autoCompleteValue = currentPlaylist?.title ? currentPlaylist.title : "";
      const autoCompleteValue = _self.autoCompleteValue;

      // render javascript for template
      _self.editTemplateTimeout = setInterval(() => {
        _self.initInterval(event);
      }, 1000);

      return `
      <div class="k-form-type--${event.type}">

        <input type="hidden" name="isAllDay" data-bind="value:isAllDay">
        <div class="k-edit-label"><label for="title">${this.$t("label.title")}</label></div>
        <div data-container-for="title" class="k-edit-field">
            <input type="text" class="k-textbox" name="title" required="required" data-bind="value:title">
          </div>
        <div class="k-edit-label">
            <label for="start">${this.$t("label.start")}</label>
          </div>
        <div data-container-for="start" class="k-edit-field">
            <input type="text"
                   data-role="datetimepicker"
                   data-interval="15"
                   data-type="date"
                   data-bind="value:start,invisible:isAllDay"
                   name="start"/>
            <input type="text" data-type="date" data-role="datepicker" data-bind="value:start,visible:isAllDay" name="start" />
            <span data-for="start" class="k-invalid-msg" style="display: none;"></span>
          </div>
        <div class="k-edit-label"><label for="end">${this.$t("label.end")}</label></div>
        <div data-container-for="end" class="k-edit-field">
            <input type="text" data-type="date" data-role="datetimepicker" data-bind="value:end,invisible:isAllDay" name="end"
            data-datecompare-msg="End date should be greater than or equal to the start date" />
            <input type="text" data-type="date" data-role="datepicker" data-bind="value:end,visible:isAllDay" name="end"
            data-datecompare-msg="End date should be greater than or equal to the start date" />
            <span data-for="end" class="k-invalid-msg" style="display: none;"></span>
          </div>
        <div class="k-edit-label"><label for="recurrenceRule">${this.$t("label.repeat")}</label></div>
        <div data-container-for="recurrenceRule" class="k-edit-field repeat-field">
            <div data-bind="value:recurrenceRule" name="recurrenceRule" data-role="recurrenceeditor"></div>
        </div>
        <div class="k-edit-label"><label for="description">${this.$t("label.description")}</label></div>
        <div data-container-for="description" class="k-edit-field description-field">
          <textarea name="description" class="k-textbox" data-bind="value:description"></textarea>
        </div>
        <div id="playlists">
          <div class="k-edit-label"><label for="playlistId">${this.$t("label.playlist")}</label></div>
          <div data-container-for="playlist_id" class="k-edit-field">
              <div class="k-type-visible--music">
                <div class="autocomplete_filter">
                  <span class="filter-title">${this.$t("label.filter")}:</span>
                  <span class="filter-items">
                    <label class="p-custom-checkbox p-custom-checkbox-palette-1">
                      <input type="checkbox" value="custom" checked class="filter-playlist-type"><span class="checkmark"></span>${this.$t(
                        "label.user_playlists"
                      )}
                    </label>
                    <label class="p-custom-checkbox p-custom-checkbox-palette-1">
                      <input type="checkbox" value="bcm" checked class="filter-playlist-type"><span class="checkmark"></span>${this.$t(
                        "label.playlists"
                      )}
                    </label>
                  </span>
               </div>
              </div>
              <div class="autoComplete_wrapper">
                  <input id="playlistAutoComplete" class="k-textbox" type="text" dir="ltr" spellcheck=false autocorrect="off" autocomplete="off" autocapitalize="off" value="${autoCompleteValue}">
              </div>

              <button type="button" onclick="showBrowsePlaylist('${
                event.type
              }');" class="k-button-solid-primary k-scheduler-browse k-button k-button-md k-rounded-md k-button-solid k-button-solid-base">Browse all playlists</button>
              <input name="playlist" id="playlistId" type="hidden" data-bind="value:playlist" required="required">
              <input name="playlist_type" id="playlistType" type="hidden" data-bind="value:playlist_type">
            </div>
        </div>

        <!-- music -->
        <div class="k-type-visible--music">
          <div class="k-edit-label"><label for="description">${this.$t("label.shuffle_playlist")}</label></div>
          <div data-container-for="isShuffled" class="k-edit-field pt pt--switch">
              <div class="p-switch-container">
                <span class="p-switch-label">${this.$t("button.no")}</span>
                <label class="p-switch">
                  <input type="checkbox" name="isShuffled" data-type="boolean" data-bind="checked:isShuffled">
                  <span class="switch"></span>
                </label>
                <span class="p-switch-label">${this.$t("button.yes")}</span>
              </div>
          </div>
          <div class="k-type-visible--provider-od">
            <div class="k-edit-label"><label for="description">${this.$t("label.is_adult_only")}</label></div>
            <div data-container-for="isAdultOnly" class="k-edit-field pt pt--switch flex align-items-start">
                <div class="p-switch-container">
                  <span class="p-switch-label">${this.$t("button.no")}</span>
                  <label class="p-switch">
                    <input type="checkbox" name="isAdultOnly" data-type="boolean" data-bind="checked:isAdultOnly">
                    <span class="switch"></span>
                  </label>
                  <span class="p-switch-label">${this.$t("button.yes")}</span>
                </div>
                <span class="ml-6" style="color: var(--primary-500)" data-bind="visible:isAdultOnly">${this.$t(
                  "message.explicit_tracks_can_be_played"
                )}</span>
            </div>
          </div>
        </div>

        <!-- slides -->
        <div class="k-type-visible--slides">
            <div class="k-edit-label"><label for="slideInterval">${this.$t("label.slide_interval")}</label></div>
            <div data-container-for="slideInterval" class="k-edit-field">
                <input id="slideInterval" type="number" name="number" min="5" max="60" step="1" class="k-textbox k-textbox--number" data-bind="value:slideInterval">
                <span class="k-edit-suffix">${this.$t("label.seconds")}</span>
            </div>

            <div class="k-edit-label"><label for="slideShuffle">${this.$t("label.shuffle_playlist")}</label></div>
            <div data-container-for="slideShuffle" class="k-edit-field pt pt--switch">
                <div class="p-switch-container">
                  <span class="p-switch-label">${this.$t("button.no")}</span>
                  <label class="p-switch">
                    <input type="checkbox" name="slideShuffle" data-type="boolean" data-bind="checked:slideShuffle">
                    <span class="switch"></span>
                  </label>
                  <span class="p-switch-label">${this.$t("button.yes")}</span>
                </div>
            </div>

            <div class="k-edit-label"><label for="slideOverrideSystemAdvertising">${this.$t(
              "label.override_system_advertising_settings"
            )}</label></div>
            <div data-container-for="slideOverrideSystemAdvertising" class="k-edit-field pt pt--switch">
                <div class="p-switch-container">
                  <span class="p-switch-label">${this.$t("button.no")}</span>
                  <label class="p-switch">
                    <input type="checkbox" name="slideOverrideSystemAdvertising" data-type="boolean" data-bind="checked:slideOverrideSystemAdvertising">
                    <span class="switch"></span>
                  </label>
                  <span class="p-switch-label">${this.$t("button.yes")}</span>
                </div>
            </div>

            <div data-bind='visible: slideOverrideSystemAdvertising'>
              <div class="k-edit-label"><label for="slideAdvertisingDisplayDuration">${this.$t(
                "label.show_advertising_when_video_is_playing"
              )}</label></div>
              <div data-container-for="slideAdvertisingDisplayDuration" class="k-edit-field pt">
                  <input type="hidden" id="slideAdvertisingDisplayDuration" name="slideAdvertisingDisplayDuration" data-bind="value:slideAdvertisingDisplayDuration">
                  <div id="slideAdvertisingDisplayDurationText" class="p-range-description"></div>
                  <input type="range" id="slideAdvertisingDisplayDurationRange" min="5" max="65" step="5" value="0" class="p-range">
              </div>

              <div class="k-edit-label"><label for="slideAdvertisingInterval">${this.$t(
                "label.advertising_interval"
              )}</label></div>
              <div data-container-for="slideAdvertisingInterval" class="k-edit-field pt">
                  <input type="hidden" id="slideAdvertisingInterval" name="slideAdvertisingInterval" data-bind="value:slideAdvertisingInterval">
                  <div id="slideAdvertisingIntervalText" class="p-range-description"></div>
                  <input type="range" id="slideAdvertisingIntervalRange" min="1" max="10" value="1" class="p-range">
              </div>
            </div>

        </div>

        <!-- frames -->
        <div class="k-type-visible--frames">
            <div class="k-edit-label"><label for="frameInterval">${this.$t("label.frame_interval")}</label></div>
            <div data-container-for="frameInterval" class="k-edit-field">
                <input id="frameInterval" type="number" name="number" min="1" max="120" step="1" class="k-textbox k-textbox--number" data-bind="value:frameInterval">
                <span class="k-edit-suffix">${this.$t("label.minutes")}</span>
            </div>
        </div>

        <!-- tickers -->
        <div class="k-type-visible--tickers">
            <div class="k-edit-label"><label for="tickerBar">${this.$t("route.tickers")}</label></div>
            <div data-container-for="tickerBar" class="k-edit-field">
                <ul class="k-radio-list">
                  <li class="k-radio-item">
                    <label class="k-radio-label"><input class="k-radio k-radio-md" type="radio" name="tickerBar" value="1" data-bind="checked:tickerBar">${this.$t(
                      "label.ticker_1"
                    )}</label>
                  </li>
                  <li class="k-radio-item">
                    <label class="k-radio-label"><input class="k-radio k-radio-md" type="radio" name="tickerBar" value="2" data-bind="checked:tickerBar">${this.$t(
                      "label.ticker_2"
                    )}</label>
                  </li>
                </ul>
            </div>
        </div>

      </div>
      `;
    },
    showBrowsePlaylist(type) {
      this.playlistDialogType = type;
      this.showPlaylistDialog = true;
    },
  },
  watch: {
    playlistSelectedFromDialog: {
      handler(playlist) {
        this.setPlayListID(playlist.id);
        this.setPlayListType(playlist.type);
        this.setPlaylistAutocompleteTitle(playlist.title);
      },
    },
  },
};
</script>
