<template>
  <SchedulerUI v-if="!loading" :playlists="playlists" />
</template>
<script>
import { setBreadcrumbs } from "@/helpers";
import SchedulerUI from "@/components/_platform/SchedulerUI.vue";
import { useRoute } from "vue-router";
import { useSystemsStore } from "@/stores";
import { storeToRefs } from "pinia";

export default {
  setup() {
    setBreadcrumbs();
    const route = useRoute();

    const systemStore = useSystemsStore();
    const { system_id } = route.params;
    systemStore.getSystemAutoCompletePlaylists(system_id);

    const { systems_autocomplete_playlists: playlists, loading_autocomplete: loading } = storeToRefs(systemStore);

    return {
      playlists,
      loading,
    };
  },
  components: {
    SchedulerUI,
  },
};
</script>
