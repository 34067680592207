<template>
  <BackUI
    v-if="backUI"
    variant="primary"
    :title="title"
    size="lg"
    :to="backUITo ?? ''"
    class="back-ui"
    @click.prevent="backUITo ? null : this.$router.back()"
  />
  <h1 v-else v-html="title" />
</template>
<style lang="scss" scoped>
h1 {
  font-family: var(--font-family-headings);
  font-weight: 700;
  font-size: font-size("4xl");
}

h1,
.back-ui {
  margin: 0 0 1.5rem 0;
}
</style>
<script>
import BackUI from "@/components/BackUI.vue";

export default {
  props: {
    title: String,
    backUI: {
      type: Boolean,
      default: false,
    },
    backUITo: {
      type: Object,
    },
  },
  components: {
    BackUI,
  },
};
</script>
