<template>
  <span class="avatar">
    <span>{{ initials }}</span>
  </span>
</template>
<script>
import { setInitials } from "@/helpers";

export default {
  data() {
    return {};
  },
  props: {
    firstName: {
      type: String,
      default: "FirstName",
    },
    lastName: {
      type: String,
      default: "LastName",
    },
    name: {
      type: String,
    },
  },
  computed: {
    initials() {
      let firstName = this.firstName;
      let lastName = this.lastName;

      if (this.name) {
        const name = this.name.split(" ");
        firstName = name[0];
        lastName = this.name.replace(`${firstName} `, "");
      }
      return setInitials(firstName, lastName);
    },
  },
};
</script>
<style lang="scss" scoped>
span.avatar {
  width: math.div(38, 16) * 1em;
  height: math.div(38, 16) * 1em;
  border-radius: 50%;
  display: inline-flex;
  background: var(--profile-avatar-ground);
  color: var(--profile-avatar-color);
  text-transform: uppercase;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  font-family: var(--font-family-headings);
  overflow: hidden;
}
</style>
