import { getAppImage } from "@/helpers/utils/getThemeImage";
import { useAppStore } from "@/stores/app.store";

const dynamicTheme = () => {
  const { themeName } = useAppStore().getAppConfig;

  const changeFavicon = () => {
    const url = getAppImage("favicon/favicon.ico");
    const link = document.createElement("link");
    const oldFavicon = document.getElementById("favicon");

    link.id = "favicon";
    link.rel = "icon";
    link.href = url;

    if (oldFavicon) {
      document.head.removeChild(oldFavicon);
    }

    document.head.appendChild(link);
  };

  // Function to dynamically import and apply theme based on the domain
  const importAndApplyTheme = async () => {
    try {
      await import(`@/assets/scss/theme/themes/${themeName}/theme.scss`);
    } catch (error) {
      console.log(error);
    } finally {
      // set body style to make theme visible
      document.body.style.display = "block";
      changeFavicon();
      useAppStore().setThemeIsLoaded(true);
    }
  };

  // Set initial theme
  importAndApplyTheme();
};

export { dynamicTheme };
