<template>
  <PDataTable
    :class="[`p-datatable-${name}`, dynamicTableClasses]"
    :value="value"
    :rows="rows"
    :paginator="paginator"
    :breakpoint="breakpoint"
    :first="first"
    v-model:selection="selected"
    :selectionMode="multiselect ? 'multiple' : null"
    :rowHover="true"
    :metaKeySelection="false"
    dataKey="id"
    @row-click="rowClick"
    @page="onPage"
  >
    <!-- Render header -->
    <slot v-if="$slots.header" name="header" />
    <!-- Render columns -->

    <PColumn v-if="multiselect" selectionMode="multiple" :headerStyle="{ width: '3em' }">
      <template #header>
        <FormMultiSelectCheckbox class="multiple-select-checkbox" v-model="selectedByMode" />
      </template>
    </PColumn>
    <slot v-if="$slots.columns" name="columns" />
    <PColumn v-else v-for="col of columns" :field="col.field" :header="col.header" :key="col.field" />
  </PDataTable>
</template>
<script>
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import ColumnGroup from "primevue/columngroup";
import { router } from "@/helpers";
import FormMultiSelectCheckbox from "@/components/form/FormMultiSelectCheckbox.vue";
import { useNavStore } from "@/stores";
export default {
  emits: ["rowClick", "update:rowSelected", "update:rowSelectedMode"],
  components: {
    FormMultiSelectCheckbox,
    PDataTable: DataTable,
    PColumn: Column,
    // eslint-disable-next-line vue/no-unused-components
    PColumnGroup: ColumnGroup,
  },
  props: {
    name: {
      type: String,
    },
    rowSelected: {
      type: Array,
      default() {
        return [];
      },
    },
    rowSelectedMode: {
      type: String,
      default: "none",
    },
    value: {
      type: Array,
      default() {
        return [];
      },
    },
    rows: {
      type: Number,
      default: 30,
    },
    responsive: {
      type: Boolean,
      default: true,
    },
    multiselect: {
      type: Boolean,
      default: false,
    },
    columns: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      page: 0,
      selected: [],
      first: 0,
    };
  },
  computed: {
    breakpoint() {
      if (this.responsive) {
        let breakpoint = Number(
          getComputedStyle(document.documentElement).getPropertyValue("--breakpoint-lg").split("px")[0]
        );
        return `${--breakpoint}px`;
      }
      return "0";
    },
    total() {
      return this.value.length;
    },
    paginator() {
      return this.total > this.rows;
    },
    selectedByMode: {
      get() {
        return this.rowSelectedMode;
      },
      set(value) {
        this.$emit("update:rowSelectedMode", value);
      },
    },
    dynamicTableClasses() {
      return {
        "p-datatable-multiselect": this.multiselect,
        "p-datatable-has-sticky-header": this.selectedByMode !== "none",
      };
    },
  },
  mounted() {
    const page = this.$route.query.page;
    this.page = page;
    this.goToFirst(page ? page : 0);
  },
  methods: {
    goToFirst(page) {
      this.first = Number(page) * this.rows;
    },
    rowClick(e) {
      this.$emit("rowClick", e);
    },
    onPage(e) {
      this.page = e.page;
      router.push({
        replace: true,
        hash: this.$route.hash,
        query: { page: this.page },
        params: { scrollToBottom: true },
      });
      this.goToFirst(e.page);
    },
    selectByPage(page) {
      const values = this.value;
      const start = page > 0 ? page * this.rows : 0;
      const end = start + this.rows;
      return values.slice(start, end);
    },
    selectAll() {
      return this.value;
    },
    returnIDs(array) {
      if (!array) {
        return [];
      }
      return array.map(({ id }) => {
        return id;
      });
    },
  },
  watch: {
    filterData: {
      handler(data) {},
    },
    selected: {
      handler(selected) {
        const selectedLength = selected.length;
        if (selectedLength === 0) {
          this.selectedByMode = "none";
        } else if (selectedLength === this.value.length) {
          this.selectedByMode = "all";
        } else if (this.selectedByMode !== "page" && selectedLength > 0) {
          this.selectedByMode = "multiple";
        }
        selected = this.returnIDs(selected);
        this.$emit("update:rowSelected", selected);
      },
    },
    selectedByMode: {
      handler(mode) {
        if (mode === "page") {
          this.selected = this.selectByPage(this.page);
        } else if (mode === "all") {
          this.selected = this.selectAll();
        } else if (mode === "none") {
          this.selected = [];
        }
        this.$emit("update:rowSelectedMode", mode);
      },
      immediate: true,
    },
  },
};
</script>
