<template>
  <div class="view-body">
    <div class="content-wrapper">
      <NotificationMessage type="404" v-if="notFound" />
      <template v-else>
        <PageTitle :title="title" :backUI="true" />
        <TabNav
          :items="[
            {
              title: $t('label.settings'),
              feather: 'settings',
            },
          ]"
          variant="tab"
        >
          <template #controls>
            <ButtonUI
              :underline="true"
              variant="text"
              color="text-palette-1"
              size="lg"
              :label="$t('button.delete_playlist_group')"
              @click="deleteCategory()"
              v-if="getPermissions('delete music categories')"
            />
            <ButtonUI @click="onSubmit" size="lg" :label="$t('button.save_settings')" />
          </template>
          <template #panels>
            <form @submit.prevent="onSubmit">
              <div class="grid">
                <div class="col-7">
                  <SurfaceCard :title="$t('label.settings')" class="h-full">
                    <template #body>
                      <LimitWidth>
                        <NotificationMessage :error="error" />
                        <FormRow>
                          <FormLabel for="first_name" required>{{ $t("label.name") }}:</FormLabel>
                          <FormText
                            id="name"
                            type="text"
                            size="lg"
                            :class="{ 'is-invalid': error.data?.name }"
                            v-model="form.name"
                          />
                          <FormHelp type="invalid" :data="error.data" field="name" />
                        </FormRow>
                        <div class="grid">
                          <div class="col-6">
                            <FormRow>
                              <FormLabel for="upload">{{ $t("label.image") }}:</FormLabel>
                              <UploadUI :image="form.image_path" @uploadChange="uploadChange" @error="uploadError" />
                            </FormRow>
                          </div>
                        </div>
                      </LimitWidth>
                    </template>
                  </SurfaceCard>
                </div>
              </div>
            </form>
          </template>
        </TabNav>
      </template>
    </div>
  </div>
  <DialogConfirm
    :visible="showConfirm"
    :title="$t('button.delete_playlist_group')"
    :message="
      form?.playlists_count
        ? $t('message.associated_playlist_group', { playlist_count: form.playlists_count })
        : $t('message.confirmation_proceed')
    "
    @onConfirm="onConfirm"
    @onCancel="onCancel"
  />
</template>

<script>
import TabNav from "@/components/nav/TabNav.vue";
import PageTitle from "@/components/PageTitle.vue";
import SurfaceCard from "@/components/SurfaceCard.vue";
import FormRow from "@/components/form/FormRow.vue";
import FormLabel from "@/components/form/FormLabel.vue";
import FormHelp from "@/components/form/FormHelp.vue";
import FormText from "@/components/form/FormText.vue";
import ButtonUI from "@/components/ButtonUI.vue";
import LimitWidth from "@/components/LimitWidth.vue";
import DialogConfirm from "@/components/dialog/DialogConfirm.vue";
import NotificationMessage from "@/components/NotificationMessage.vue";
import UploadUI from "@/components/UploadUI.vue";

import { useMusicStore, useNavStore, useAuthStore } from "@/stores";

import { useRoute } from "vue-router";
import { storeToRefs } from "pinia";
import { onUnmounted } from "vue";
import { getPermissions, setBreadcrumbs } from "@/helpers";

export default {
  setup() {
    setBreadcrumbs();
    const route = useRoute();
    const { category_id } = route.params;

    const musicStore = useMusicStore();

    const authStore = useAuthStore();
    authStore.validateMe();

    musicStore.getCategoryDetails(category_id);

    const { categories_detail } = storeToRefs(musicStore);
    const form = categories_detail;

    onUnmounted(() => {
      musicStore.resetCategoryDetails();
    });

    return { category_id, form };
  },
  components: {
    TabNav,
    PageTitle,
    SurfaceCard,
    FormRow,
    FormLabel,
    FormText,
    FormHelp,
    UploadUI,
    ButtonUI,
    LimitWidth,
    DialogConfirm,
    NotificationMessage,
  },
  data() {
    return {
      error: {},
      showConfirm: false,
    };
  },
  methods: {
    async onSubmit() {
      const musicStore = useMusicStore();
      return musicStore.updateCategory(this.form).catch((error) => {
        this.error = error;
      });
    },
    deleteCategory() {
      this.showConfirm = true;
    },
    onConfirm() {
      this.showConfirm = false;
      const musicStore = useMusicStore();
      return musicStore.deleteCategory(this.category_id).catch((error) => {
        this.error = error;
      });
    },
    onCancel() {
      this.showConfirm = false;
    },
    uploadChange(image) {
      this.form.image_path = image;
    },
    uploadError(error) {
      this.error = {};
      if (error.length > 0) {
        this.error = { message: error[0], data: { upload: [error] } };
      }
    },
    getPermissions,
  },
  computed: {
    notFound() {
      return this.form?.status && this.form.status == 404;
    },
    title() {
      if (this.form?.page_name) {
        return this.form.page_name;
      }
      return null;
    },
  },
};
</script>

<style lang="scss" scoped></style>
