<template>
  <div class="view-body">
    <div class="content-wrapper">
      <PageTitle :title="$t('title.frames_overview')" />
      <TabNav :items="tabNavItems" :exact="true">
        <template #controls>
          <Transition name="page-fade" mode="out-in">
            <template v-if="!notFound">
              <div v-if="showControlsMyPlaylists">
                <ButtonUI
                  :label="$t('label.playlist_groups')"
                  color="secondary"
                  feather="settings"
                  :to="{ name: 'settings-playlist-groups-frames' }"
                  v-if="getPermissions('show music categories')"
                />
                <ButtonUI
                  :label="$t('button.create_playlist')"
                  color="primary"
                  feather="plus"
                  class="ml-2"
                  :to="{ name: 'frames-playlist-create', query: { ...this.$route.query } }"
                  v-if="getPermissions('create playlists')"
                />
              </div>
              <div v-else-if="showControlsMyFrames">
                <ButtonUI
                  :label="$t('button.create_frame')"
                  color="primary"
                  feather="plus"
                  class="ml-2"
                  :to="{ name: 'frames-user-frames-create', query: { ...this.$route.query } }"
                  v-if="getPermissions('create frames')"
                />
              </div>
            </template>
          </Transition>
        </template>
        <template #panels>
          <RouterView name="Frames" v-slot="{ Component }" @notFound="notFound = $event">
            <Transition name="page-fade" mode="out-in">
              <component :is="Component" />
            </Transition>
          </RouterView>
        </template>
      </TabNav>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/components/PageTitle.vue";
import TabNav from "@/components/nav/TabNav.vue";
import ButtonUI from "@/components/ButtonUI.vue";

import { getPermissions, i18n, setBreadcrumbs } from "@/helpers";
import { onUnmounted } from "vue";
import { useFramesStore } from "@/stores";

export default {
  setup() {
    setBreadcrumbs();
    const framesStore = useFramesStore();

    onUnmounted(() => {
      framesStore.resetPlaylists();
    });

    const tabNavItems = [
      {
        title: i18n.global.t("label.user_playlists"),
        route: "frames",
        route_match: ["frames", "frames-user-category-other", "frames-user-category"],
        permissions: "show playlists",
      },
      {
        title: i18n.global.t("label.user_frames"),
        route: "user-frames",
        route_match: ["user-frames"],
        permissions: "show frames",
      },
      {
        title: i18n.global.t("label.browse_frames"),
        route: "frames-browse",
        route_match: ["frames-browse", "frames-browse-category"],
        permissions: "show frames",
      },
    ];

    return {
      tabNavItems,
    };
  },
  data() {
    return {
      notFound: false,
      error: {},
    };
  },
  components: {
    ButtonUI,
    PageTitle,
    TabNav,
  },
  computed: {
    routerName() {
      return this.$route?.name;
    },
    showControlsMyPlaylists() {
      return ["frames", "frames-user-category-other", "frames-user-category"].includes(this.routerName);
    },
    showControlsBrowsePlaylists() {
      return ["frames-browse", "frames-browse-category"].includes(this.routerName);
    },
    showControlsMyFrames() {
      return ["user-frames"].includes(this.routerName);
    },
  },
  methods: {
    getPermissions,
  },
};
</script>
