import { defineStore } from "pinia";

import { useAuthStore as authStore } from "@/stores/_platform/auth.store";
import {
  customError,
  i18n,
  redirectToPreviousPage,
  isBase64,
  orderStringByKey,
  renameKeys,
  router,
  timeToSeconds,
  secondsToTime,
} from "@/helpers";
import { useNotificationStore as notificationStore } from "@/stores/notification.store";
import { useRoute } from "vue-router";
import { useNavStore } from "@/stores/nav.store";
import { useUsersStore } from "@/stores/_platform/users.store";
import { useAppStore } from "@/stores/app.store";
import axiosInstance from "@/helpers/axios";

export const useMusicStore = defineStore({
  id: "music",
  state: () => ({
    playlists: [],
    playlists_available: [],
    playlists_detail: {},
    categories: [],
    categories_detail: {},
    visibility_options: [
      {
        feather: "globe",
        value: "public",
        name: i18n.global.t("label.public_all_groups"),
      },
      {
        feather: "lock",
        value: "public_restricted",
        name: i18n.global.t("label.public_restricted"),
      },
      {
        feather: "lock",
        value: "private",
        name: i18n.global.t("label.private_only_for_me"),
      },
    ],
    search_results: {},
    loading: false,
    loading_available_playlists: false,
  }),
  getters: {},
  actions: {
    async updatePlaylist(formData) {
      let { id, title, description, categories, visibility, groups, image } = formData;
      useNavStore().setAppSpinner(true);
      let newData = {
        id,
        title,
        description,
        categories: categories.map(({ id }) => id), // rewrite object to array with ids
        visibility: "",
        groups: [],
      };

      if ((image && isBase64(image)) || image === null) {
        // merge new image with data
        newData = {
          ...newData,
          image,
        };
      }

      if (visibility?.value) {
        // merge visibility with data
        newData = {
          ...newData,
          visibility: visibility.value, // rewrite visibility with only value
        };

        if (visibility.value === "public_restricted") {
          newData = {
            ...newData,
            groups: groups.map(({ id }) => id), // rewrite object to array with ids
          };
        }
      }

      try {
        const playlist = await axiosInstance.put(`/music/my-playlists/${id}`, newData);

        // reset visibility restricted groups
        if (visibility.value != "public_restricted") {
          this.playlists_detail.groups = [];
        }

        notificationStore().saveAlert(playlist.data.message);
      } catch (error) {
        if ([401, 403].includes(error.response?.status)) {
          authStore().logout(error.response.status);
        } else {
          throw customError(error);
        }
      } finally {
        useNavStore().setAppSpinner(false);
      }
    },
    async deletePlaylist(playlist_id) {
      useNavStore().setAppSpinner(true);
      try {
        const playlist = await axiosInstance.delete(`/music/my-playlists/${playlist_id}`);

        notificationStore().saveAlert(playlist.data.message);
        await redirectToPreviousPage("music");
      } catch (error) {
        if ([401, 403].includes(error.response?.status)) {
          authStore().logout(error.response.status);
        } else {
          throw customError(error);
        }
      } finally {
        useNavStore().setAppSpinner(false);
      }
    },
    async createPlaylist(formData) {
      let { title, description, categories, visibility, groups, image } = formData;
      useNavStore().setAppSpinner(true);
      let newData = {
        title,
        description,
        categories: categories.map(({ id }) => id), // rewrite object to array with ids
        visibility: "",
        groups: [],
      };

      if ((image && isBase64(image)) || image === null) {
        // merge new image with data
        newData = {
          ...newData,
          image: image,
        };
      }

      if (visibility?.value) {
        // merge visibility with data
        newData = {
          ...newData,
          visibility: visibility.value, // rewrite visibility with only value
        };

        if (visibility.value === "public_restricted") {
          newData = {
            ...newData,
            groups: groups.map(({ id }) => id), // rewrite object to array with ids
          };
        }
      }

      try {
        const user = await axiosInstance.post(`/music/my-playlists`, newData);

        notificationStore().saveAlert(user.data.message);
        await redirectToPreviousPage("music");
      } catch (error) {
        if ([401, 403].includes(error.response?.status)) {
          authStore().logout(error.response.status);
        } else {
          throw customError(error);
        }
      } finally {
        useNavStore().setAppSpinner(false);
      }
    },
    async duplicatePlaylist(formData) {
      let { title, category_id, playlist_id, type } = formData;
      useNavStore().setAppSpinner(true);

      let endpoint = `my-playlists/${playlist_id}/duplicate`;
      if (type === "bcm") {
        endpoint = `browse/${category_id}/playlist/${playlist_id}/duplicate`;
      }
      try {
        const playlist = await axiosInstance.post(`/music/${endpoint}`, { title });

        notificationStore().saveAlert(playlist.data.message);
        type === "bcm"
          ? await router.push({ name: "music-user-category-other" })
          : await redirectToPreviousPage("music");
      } catch (error) {
        if ([401, 403].includes(error.response?.status)) {
          authStore().logout(error.response.status);
        } else {
          throw customError(error);
        }
      } finally {
        useNavStore().setAppSpinner(false);
      }
    },
    // -------------------------
    // Playlists overview
    // -------------------------
    async getAvailablePlaylistsByTrack() {
      this.loading_available_playlists = true;
      useNavStore().setAppSpinner(true);
      try {
        const playlists = await axiosInstance.get(`/music/my-playlists/track/available`);

        // update pinia state
        this.playlists_available = playlists.data.data;
        this.playlists_available = this.playlists_available.map((obj) => renameKeys(obj, { title: "name" })); // rename title to name
      } catch (error) {
        if ([401, 403].includes(error.response?.status)) {
          authStore().logout(error.response.status);
        } else {
          throw customError(error);
        }
      } finally {
        this.loading_available_playlists = false;
        useNavStore().setAppSpinner(false);
      }
    },
    async addTrackToPlaylist(data) {
      let { track_ids, playlists } = data;

      useNavStore().setAppSpinner(true);
      track_ids = Array.isArray(track_ids) ? track_ids : [track_ids]; // get always an array

      try {
        await axiosInstance.post(`/music/my-playlists/add-track`, {
          tracks: track_ids,
          playlists,
        });

        notificationStore().saveAlert(i18n.global.t("message.track_added_to_playlist"));
      } catch (error) {
        if ([401, 403].includes(error.response?.status)) {
          authStore().logout(error.response.status);
        } else {
          throw customError(error);
        }
      } finally {
        useNavStore().setAppSpinner(false);
      }
    },
    async removeTrackFromPlaylist(data) {
      const { playlist_id, track_id } = data;
      useNavStore().setAppSpinner(true);

      try {
        await axiosInstance.delete(`/music/my-playlists/${playlist_id}/tracks/${track_id}`);

        // find track in old overview
        const track = this.playlists_detail.tracks.find((item) => item.id === track_id);

        // remove track in item_count
        if (this.playlists_detail?.item_count) {
          this.playlists_detail.item_count = this.playlists_detail.item_count - 1;
        }

        // remove track duration on total time
        if (this.playlists_detail?.total_playing_time && track?.duration) {
          const total_playing_time =
            timeToSeconds(this.playlists_detail.total_playing_time) - timeToSeconds(track.duration);
          this.playlists_detail.total_playing_time = secondsToTime(total_playing_time);
        }

        // remove track in list
        const tracks = this.playlists_detail.tracks.filter((item) => item.id !== track_id);
        this.playlists_detail.tracks = tracks;

        notificationStore().saveAlert(i18n.global.t("message.track_removed_from_playlist"));
      } catch (error) {
        if ([401, 403].includes(error.response?.status)) {
          authStore().logout(error.response.status);
        } else {
          throw customError(error);
        }
      } finally {
        useNavStore().setAppSpinner(false);
      }
    },
    async getPlaylists() {
      let { name } = useRoute();

      let endpoint;
      switch (name) {
        case "music":
          endpoint = `my-playlists`;
          break;
        case "music-browse":
          endpoint = `browse`;
          break;
      }

      if (!endpoint) {
        return;
      }

      this.loading = true;
      useNavStore().setAppSpinner(true);

      try {
        const playlists = await axiosInstance.get(`/music/${endpoint}`);

        // update pinia state
        this.playlists = playlists.data.data;
      } catch (error) {
        if ([401, 403].includes(error.response?.status)) {
          authStore().logout(error.response.status);
        }
        this.playlists = customError(error);
        throw this.playlists;
      } finally {
        this.loading = false;
        useNavStore().setAppSpinner(false);
      }
    },
    async getCategories() {
      this.loading = true;
      useNavStore().setAppSpinner(true);
      try {
        const categories = await axiosInstance.get(`/settings/music/playlist-categories`);

        // update pinia state
        this.categories = orderStringByKey(categories.data.data, "name");
      } catch (error) {
        if ([401, 403].includes(error.response?.status)) {
          authStore().logout(error.response.status);
        } else {
          throw customError(error);
        }
      } finally {
        this.loading = false;
        useNavStore().setAppSpinner(false);
      }
    },
    async getCategoryDetails(category_id) {
      useNavStore().setAppSpinner(true);
      try {
        const category = await axiosInstance.get(`/settings/music/playlist-categories/${category_id}`);

        // update pinia state
        this.categories_detail = category.data.data;
        this.categories_detail.page_name = category.data.data.name;
      } catch (error) {
        this.categories_detail = customError(error);
        if ([401, 403].includes(error.response?.status)) {
          authStore().logout(error.response.status);
        } else {
          notificationStore().saveMessage(error);
        }
      } finally {
        useNavStore().setAppSpinner(false);
      }
    },
    async createCategory(formData) {
      let { id, name, image_path } = formData;
      useNavStore().setAppSpinner(true);

      let newData = {
        id,
        name,
      };

      if (image_path && isBase64(image_path)) {
        // merge new image with data
        newData = {
          ...newData,
          image: image_path,
        };
      }

      try {
        const category = await axiosInstance.post(`/settings/music/playlist-categories`, newData);

        notificationStore().saveAlert(category.data.message);
        await redirectToPreviousPage("settings-playlist-groups-music");
      } catch (error) {
        if ([401, 403].includes(error.response?.status)) {
          authStore().logout(error.response.status);
        } else {
          throw customError(error);
        }
      } finally {
        useNavStore().setAppSpinner(false);
      }
    },
    async updateCategory(formData) {
      let { id, name, image_path } = formData;
      useNavStore().setAppSpinner(true);

      let newData = {
        id,
        name,
      };

      if (image_path && isBase64(image_path)) {
        // merge new image with data
        newData = {
          ...newData,
          image: image_path,
        };
      }

      try {
        const category = await axiosInstance.put(`/settings/music/playlist-categories/${id}`, newData);

        notificationStore().saveAlert(category.data.message);
        await redirectToPreviousPage("settings-playlist-groups-music");
      } catch (error) {
        if ([401, 403].includes(error.response?.status)) {
          authStore().logout(error.response.status);
        } else {
          throw customError(error);
        }
      } finally {
        useNavStore().setAppSpinner(false);
      }
    },
    async deleteCategory(category_id) {
      useNavStore().setAppSpinner(true);
      try {
        const category = await axiosInstance.delete(`/settings/music/playlist-categories/${category_id}`);

        notificationStore().saveAlert(category.data.message);
        await redirectToPreviousPage("settings-playlist-groups-music");
      } catch (error) {
        if ([401, 403].includes(error.response?.status)) {
          authStore().logout(error.response.status);
        } else {
          throw customError(error);
        }
      } finally {
        useNavStore().setAppSpinner(false);
      }
    },
    resetCategories() {
      this.categories = [];
    },
    resetCategoryDetails() {
      this.categories_detail = {};
    },
    resetPlaylists() {
      this.playlists = [];
    },
    async getPlaylistsByCategory(category_id) {
      const { name } = useRoute();

      let endpoint;
      switch (name) {
        case "music-browse-category":
          endpoint = `browse/${category_id}`;
          break;
        case "music-user-category":
          endpoint = `my-playlists/category/${category_id}`;
          break;
        case "music-user-category-other":
          endpoint = `my-playlists/other`;
          break;
      }

      if (!endpoint) {
        return;
      }

      this.loading = true;
      useNavStore().setAppSpinner(true);

      try {
        const playlists = await axiosInstance.get(`/music/${endpoint}`);

        // update pinia state
        this.playlists = playlists.data.data;
      } catch (error) {
        if ([401, 403].includes(error.response?.status)) {
          authStore().logout(error.response.status);
        }
        this.playlists = customError(error);
        throw this.playlists;
      } finally {
        this.loading = false;
        useNavStore().setAppSpinner(false);
      }
    },
    // -------------------------
    // Playlists detail
    // -------------------------
    async getPlaylistsDetail(data) {
      const { playlist_id, category_id } = data;

      // groups_playlists_detail

      let endpoint;
      if (playlist_id) {
        endpoint = `my-playlists/${playlist_id}`;
        if (category_id) {
          endpoint = `browse/${category_id}/playlist/${playlist_id}`;
        }
      }

      if (!endpoint) {
        this.playlists_detail.status = "404";
        return;
      }

      this.loading = true;
      useNavStore().setAppSpinner(true);

      try {
        const playlist = await axiosInstance.get(`/music/${endpoint}`);

        // update pinia state
        this.playlists_detail = playlist.data.data;

        // rename old image_path key to image
        if (this.playlists_detail?.image_path) {
          delete Object.assign(this.playlists_detail, { image: this.playlists_detail.image_path })["image_path"];
        }

        const visibility = this.visibility_options.find((item) => item.value === this.playlists_detail.visibility);
        this.playlists_detail.visibility = visibility;

        // set media type
        const tracks = this.playlists_detail?.tracks.map((data) => {
          data.media = data?.is_audio ? "audio" : data?.is_video ? "video" : data?.is_karaoke ? "karoke" : null;
          return data;
        });
        this.playlists_detail.tracks = tracks;

        await useUsersStore().getPublicRestrictedGroups();
      } catch (error) {
        this.playlists_detail = customError(error);
        if ([401, 403].includes(error.response?.status)) {
          authStore().logout(error.response.status);
        }
      } finally {
        this.loading = false;
        useNavStore().setAppSpinner(false);
      }
    },
    async updateTrackOrder(playlist_id, tracks) {
      if (!playlist_id && !tracks) {
        return;
      }
      await axiosInstance.post(`/music/my-playlists/${playlist_id}/tracks/reorder`, {
        tracks,
      });
    },
    resetPlaylistsDetail() {
      this.playlists_detail = {};
    },
    async search(term, routeName) {
      if (!term || !routeName) {
        this.search_results = {};
        return;
      }

      let endpoint;
      switch (routeName) {
        case "music-search":
          endpoint = `search`;
          break;
        case "music-search-playlists":
          endpoint = `search/playlists`;
          break;
        case "music-search-tracks":
          endpoint = `search/tracks`;
          break;
      }

      useNavStore().setAppSpinner(true);

      try {
        const search = await axiosInstance.get(`/music/${endpoint}`, {
          params: { q: term },
        });

        switch (routeName) {
          case "music-search":
            this.search_results = search.data.data;
            break;
          case "music-search-playlists":
            this.search_results = { playlists: search.data.data };
            break;
          case "music-search-tracks":
            this.search_results = { tracks: search.data.data };
            break;
        }
      } catch (error) {
        if ([401, 403].includes(error.response?.status)) {
          authStore().logout(error.response.status);
        } else {
          throw customError(error);
        }
      } finally {
        useNavStore().setAppSpinner(false);
      }
    },
  },
});
