<template>
  <div>
    <TableUI
      v-if="slideList.length > 0"
      name="slides"
      :value="slideList"
      @rowReorder="onRowReorder"
      :class="[reorder ? `table-has-reorder` : '']"
    >
      <template #columns>
        <PColumn rowReorder headerStyle="width: 3rem" v-if="reorder" />

        <PColumn :header="$t('label.media')" :style="getCellWidth('image')">
          <template #body="slotProps">
            <ImageUI
              v-if="slotProps.data.media_type === 'image'"
              variant="image"
              :src="
                validateExtensions(slotProps.data.image?.thumb_small, ['jpg', 'jpeg', 'png'])
                  ? slotProps.data.image?.thumb_small
                  : null
              "
              ratio="16-9"
              :alt="slotProps.data.image?.name ?? slotProps.data.name"
            />
            <ImageUI
              v-else-if="slotProps.data.media_type === 'video'"
              variant="video"
              ratio="16-9"
              :src="slotProps.data.video?.preview_image ?? null"
              :alt="slotProps.data.video?.name ?? slotProps.data.name"
            />
          </template>
        </PColumn>

        <PColumn :header="$t('label.name')" :style="getCellWidth('name')">
          <template #body="slotProps">
            <div class="font-semibold">
              {{ slotProps.data.name ? slotProps.data.name : getFileName(slotProps.data.image.url) }}
            </div>
            <div class="file-size">{{ fileInfo(slotProps.data) }}</div>
          </template>
        </PColumn>

        <!-- Render controls column -->
        <PColumn
          headerStyle="text-align: right"
          bodyStyle="text-align: right; overflow: visible"
          :style="getCellWidth('controls')"
          v-if="
            (controls && getControlPermissions('add to playlist')) ||
            (controls && getControlPermissions('edit slides')) ||
            (controls && getControlPermissions('remove slides'))
          "
        >
          <template #body="slotProps">
            <div class="controls">
              <ButtonUI
                :label="$t('button.add_to_playlist')"
                variant="text"
                color="text-palette-1"
                feather="plus"
                class="p-button-text-inline"
                v-if="getControlPermissions('add to playlist')"
                @click="addSlidePlaylist(slotProps.data.id)"
              />
              <ButtonUI
                :label="$t('button.remove_from_playlist')"
                variant="text"
                color="text-palette-1"
                feather="trash-2"
                class="p-button-text-inline"
                v-if="getControlPermissions('remove slide') && remove"
                @click="removeSlidePlaylist(slotProps.data.id)"
              />
              <ButtonUI
                :label="$t('button.settings')"
                variant="text"
                color="text-palette-1"
                feather="settings"
                class="p-button-text-inline"
                :to="{
                  name: 'slides-user-slides-edit',
                  params: { slide_id: slotProps.data.id },
                  query: { ...this.$route.query },
                }"
                v-if="getPermissions('edit slides') && slotProps.data.type != 'bcm'"
              />
            </div>
          </template>
        </PColumn>
      </template>
    </TableUI>
    <NotificationMessage type="default" v-else>{{ $t("message.no_slides_found") }}</NotificationMessage>
    <DialogAddSlideToPlaylist
      :visible="true"
      :slideId="playlistSlideId"
      @onCancel="onCancelAddToPlaylist"
      v-if="!!playlistSlideId"
    />
    <DialogConfirm
      :visible="true"
      v-if="!!removeSlideId"
      :title="$t('button.remove_slide_from_playlist')"
      @onConfirm="onConfirmRemove"
      @onCancel="onCancelRemoveFromPlaylist"
    />
  </div>
</template>

<script>
import TableUI from "@/components/TableUI.vue";
import ButtonUI from "@/components/ButtonUI.vue";
import DialogAddSlideToPlaylist from "@/components/_platform/dialog/DialogAddSlideToPlaylist.vue";
import DialogConfirm from "@/components/dialog/DialogConfirm.vue";
import NotificationMessage from "@/components/NotificationMessage.vue";
import ImageUI from "@/components/ImageUI.vue";

import Column from "primevue/column";
import { getPermissions, getTableCellWidth, getFileSize, validateExtensions } from "@/helpers";

import { useSlidesStore } from "@/stores";
import { getFileName } from "@/helpers/utils/getFileName";

export default {
  props: {
    slides: {
      type: Array,
      default() {
        return [];
      },
    },
    reorder: {
      type: Boolean,
      default: false,
    },
    remove: {
      type: Boolean,
      default: false,
    },
    controls: {
      type: Boolean,
      default: true,
    },
    controlPermissions: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      slideList: [],
      playlistSlideId: null,
      removeSlideId: null,
    };
  },
  components: {
    TableUI,
    ButtonUI,
    ImageUI,
    NotificationMessage,
    PColumn: Column,
    DialogAddSlideToPlaylist,
    DialogConfirm,
  },
  watch: {
    slides: {
      handler(value) {
        this.slideList = value;
      },
      immediate: true,
    },
  },
  methods: {
    validateExtensions,
    getFileName,
    fileInfo(slide) {
      let size = 0;

      if (slide?.image) {
        size = slide.image.size;
      } else if (slide?.video) {
        size = slide.video.size;
      }

      const typeName = useSlidesStore().getStoreMediaType(slide.media_type)?.name ?? "";
      return `${typeName} ${size > 0 ? getFileSize(size) : ""}`;
    },
    addSlidePlaylist(id) {
      this.playlistSlideId = id;
    },
    removeSlidePlaylist(id) {
      this.removeSlideId = id;
    },
    onCancelAddToPlaylist() {
      this.playlistSlideId = null;
    },
    async onConfirmRemove() {
      const slidesStore = useSlidesStore();
      const { playlist_id } = this.$route.params;
      try {
        await slidesStore.removeSlideFromPlaylist({ slide_id: this.removeSlideId, playlist_id }).catch((error) => {
          this.error = error;
        });
      } catch (error) {
        this.error = error;
      } finally {
        this.removeSlideId = null;
      }
    },
    onCancelRemoveFromPlaylist() {
      this.removeSlideId = null;
    },
    getPermissions,
    getCellWidth(cell) {
      return getTableCellWidth("slides", cell);
    },
    getControlPermissions(permission) {
      if (this.controlPermissions.length > 0) {
        return permission ? this.controlPermissions.includes(permission) : false;
      }
      return true;
    },
    async onRowReorder(event) {
      if (!this.reorder) {
        return false;
      }
      this.slideList = event.value;

      const { playlist_id } = this.$route.params;
      const slides = this.slideList.map(({ id }) => id);

      const slidesStore = useSlidesStore();
      await slidesStore.updateSlidesOrder(playlist_id, slides);
    },
  },
};
</script>

<style lang="scss" scoped>
.file-size {
  font-size: font-size("xs");
  font-weight: normal;
  opacity: 0.5;
  line-height: 1.3;
  margin-top: 0.2rem;
}
.image {
  width: 150px;
}
</style>
