<template>
  <div>
    <TableUI v-if="systems.length > 0" name="systems" :value="systems">
      <template #columns>
        <PColumn field="name" :header="$t('label.system_name')" :style="getCellWidth('name')" />
        <PColumn field="group" :header="$t('label.group')" :style="getCellWidth('group')">
          <template #body="slotProps">
            {{ slotProps.data.group.name }}
          </template>
        </PColumn>

        <!-- Render controls column -->
        <PColumn
          headerStyle="text-align: right"
          bodyStyle="text-align: right; overflow: visible"
          :style="getCellWidth('controls')"
        >
          <template #body="slotProps">
            <div class="controls">
              <ButtonUI
                :label="$t('button.details')"
                variant="text"
                feather="box"
                color="text-palette-1"
                class="p-button-text-inline"
                :to="{
                  name: 'systems-approve',
                  params: { system_id: slotProps.data.id },
                  query: { ...this.$route.query },
                }"
                v-if="getPermissions('show systems')"
              />
            </div>
          </template>
        </PColumn>
      </template>
    </TableUI>
    <NotificationMessage type="default" v-else>{{ $t("message.no_pending_data_found") }}</NotificationMessage>
  </div>
</template>

<script>
import TableUI from "@/components/TableUI.vue";
import ButtonUI from "@/components/ButtonUI.vue";
import NotificationMessage from "@/components/NotificationMessage.vue";

import { storeToRefs } from "pinia";
import Column from "primevue/column";
import { useSystemsStore } from "@/stores";
import { ref, toRefs } from "vue";
import { getPermissions, getTableCellWidth, router } from "@/helpers";

export default {
  props: {
    systems: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  components: {
    TableUI,
    ButtonUI,
    NotificationMessage,
    PColumn: Column,
  },
  methods: {
    getPermissions,
    getCellWidth(cell) {
      return getTableCellWidth("systems", cell);
    },
  },
};
</script>
