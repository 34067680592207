import { onBeforeUnmount, onMounted } from "vue";

export function useDetectOutsideClick(component, callback) {
  if (!component) return;
  const listener = (event) => {
    if (component.value) {
      const isClickInside = component.value.contains(event.target);

      if (!isClickInside) {
        if (typeof callback === "function") {
          callback();
        }
      }
    }
    return;
  };
  onMounted(() => {
    window.addEventListener("click", listener);
  });
  onBeforeUnmount(() => {
    window.removeEventListener("click", listener);
  });

  return { listener };
}
