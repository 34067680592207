<template>
  <div v-resize="onResize">
    <div
      class="p-datatable-sticky-header"
      :class="{ 'is-sticky': isSticky }"
      ref="stickyContentEl"
      :style="`width:${controlWidth}px`"
    >
      <slot />
    </div>
    <div class="sticky-start" ref="stickyStartEl"></div>
  </div>
</template>
<script>
import { onMounted, onUnmounted, ref } from "vue";
import VueResizeObserver from "vue-resize-observer";
import { router } from "@/helpers";

export default {
  setup() {
    let isSticky = ref(false);
    const stickyStartEl = ref(null);
    const stickyContentEl = ref(null);
    const controlWidth = ref("100%");

    const topBarHeight = 70;
    const offset = 10;

    let scrollUpTimeout;
    const scrollTimeout = 100;

    const onScroll = () => {
      if (scrollUpTimeout) {
        clearTimeout(scrollUpTimeout);
      }

      scrollUpTimeout = setTimeout(() => {
        if (stickyStartEl.value && stickyContentEl.value?.innerHTML) {
          const offsetTop = stickyStartEl.value?.getBoundingClientRect().top - topBarHeight + offset;
          isSticky.value = offsetTop <= 0;
        } else {
          isSticky.value = false;
        }
      }, scrollTimeout);
    };

    const onResize = ({ width, height }) => {
      controlWidth.value = width;
    };

    onMounted(() => {
      window.addEventListener("scroll", onScroll);
    });
    onUnmounted(() => {
      window.removeEventListener("scroll", onScroll);
    });

    return {
      onResize,
      controlWidth,
      isSticky,
      stickyStartEl,
      stickyContentEl,
    };
  },
  async mounted() {
    await router.isReady();
    setTimeout(() => {
      this.controlWidth = this.$el.offsetWidth;
    }, 100);
  },
  directives: { resize: VueResizeObserver },
};
</script>
<style lang="scss">
.p-datatable-sticky-header {
  position: absolute;
  top: 54px;
  z-index: 100;
  height: 70px;
  width: 100%;
  border: 0;
  padding: spacing(2) spacing(2) spacing(2) spacing(4);
  color: currentColor;
  font-weight: normal;
  background: #532a1e;
  border-top: 3px solid var(--app-ground);
  display: flex;
  align-items: center;
  gap: spacing(6);
  transition: top 0.28s ease-out;

  &.is-sticky {
    position: fixed;
    top: 67px;
  }

  .p-button {
    min-height: 44px;
    margin-left: auto;
  }
}

.tab-controls.is-sticky {
  ~ div {
    .p-datatable-sticky-header {
      &.is-sticky {
        top: 67px + 78px;
      }
    }
  }
}
</style>
