// src/axios.js
import axios from "axios";
import { useAppStore } from "@/stores";
import { getApiHeaders } from "@/helpers/utils/getApiHeaders";

const axiosInstance = axios.create();

// Request interceptor to add headers
axiosInstance.interceptors.request.use(
  (config) => {
    const { apiUrl } = useAppStore().getAppConfig;
    config.baseURL = apiUrl;
    config.headers = getApiHeaders(config);
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosInstance.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default axiosInstance;
