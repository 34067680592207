<template>
  <div id="view" class="login-view">
    <BackUI feather="x" variant="primary" to="#" @click.prevent="logout" />
    <SurfaceCard type="login">
      <PageTitle :title="$t('title.hi', { name: authUser?.first_name })" class="mb-4" />
      <IntroText :text="$t('region.differentRegions')" class="mb-4" />
      <ChangeCustomerForm />
    </SurfaceCard>
  </div>
</template>
<script>
import { useAdminImpersonateStore, useAuthStore } from "@/stores";

import PageTitle from "@/components/PageTitle.vue";
import IntroText from "@/components/IntroText.vue";
import BackUI from "@/components/BackUI.vue";
import SurfaceCard from "@/components/SurfaceCard.vue";
import { router } from "@/helpers";
import ChangeCustomerForm from "@/components/_platform/profile/ChangeCustomerForm.vue";

export default {
  setup() {
    const authStore = useAuthStore();
    if (!authStore.customerHasRegions()) {
      router.push({ name: "dashboard" });
    }
    return { authUser: authStore.getUser };
  },

  components: {
    ChangeCustomerForm,
    PageTitle,
    IntroText,
    BackUI,
    SurfaceCard,
  },
  methods: {
    logout() {
      const isImpersonate = useAuthStore().impersonateIsActive;
      if (isImpersonate) {
        useAdminImpersonateStore().logoutImpersonate();
      } else {
        const returnURL = this.$route.query.returnUrl;
        return returnURL ? router.push(returnURL) : useAuthStore().logout();
      }
    },
  },
};
</script>
