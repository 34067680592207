<template>
  <div v-if="loggedIn">
    <div class="sidebar-logo">
      <LogoAvatar />
    </div>
    <PerfectScrollbar class="sidebar-nav" v-once :options="perfectScrollbarSettings">
      <NavSideBar />
    </PerfectScrollbar>
  </div>
</template>
<script>
import LogoAvatar from "@/components/LogoAvatar.vue";
import NavSideBar from "@/components/nav/NavSideBar.vue";
import { getPermissions } from "@/helpers";

import { PerfectScrollbar } from "vue3-perfect-scrollbar";
import "vue3-perfect-scrollbar/dist/vue3-perfect-scrollbar.css";
import { computed } from "vue";
import { useAdminAuthStore, useAuthStore, useNavStore } from "@/stores";

export default {
  setup() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 60,
      wheelPropagation: false,
    };

    const loggedIn = computed(() => {
      return useNavStore().isAdminPage ? useAdminAuthStore().loggedIn : useAuthStore().loggedIn;
    });

    return {
      loggedIn,
      perfectScrollbarSettings,
    };
  },
  components: {
    LogoAvatar,
    PerfectScrollbar,
    NavSideBar,
  },
  methods: {
    getPermissions,
  },
};
</script>
<style lang="scss" scoped>
.sidebar-logo {
  padding: spacing(5) spacing(5) 0;
  height: 75px;
}

.sidebar-nav {
  position: relative;
  height: calc(100% - 120px);
  padding: 6.5rem spacing(5) 0;

  &:after {
    content: "";
    background: var(--app-sidebar-gradient);
    height: 120px;
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    pointer-events: none;
  }
}
</style>
