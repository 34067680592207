<template>
  <div>
    <TableUI
      v-if="frameList.length > 0"
      name="frames"
      :value="frameList"
      @rowReorder="onRowReorder"
      :class="[reorder ? `table-has-reorder` : '']"
    >
      <template #columns>
        <PColumn rowReorder headerStyle="width: 3rem" v-if="reorder" />

        <PColumn :header="$t('label.media')" :style="getCellWidth('image')">
          <template #body="slotProps">
            <ImageUI
              v-if="slotProps.data?.image"
              variant="image"
              ratio="16-9"
              :src="slotProps.data.image?.url ?? null"
              :alt="slotProps.data.image?.name ?? slotProps.data.name"
            />
          </template>
        </PColumn>

        <PColumn :header="$t('label.name')" :style="getCellWidth('name')">
          <template #body="slotProps">
            <div class="font-semibold">
              {{ slotProps.data.name ? slotProps.data.name : getFileName(slotProps.data.image.url) }}
            </div>
          </template>
        </PColumn>

        <!-- Render controls column -->
        <PColumn
          headerStyle="text-align: right"
          bodyStyle="text-align: right; overflow: visible"
          :style="getCellWidth('controls')"
          v-if="
            (controls && getControlPermissions('add to playlist')) ||
            (controls && getControlPermissions('edit frames')) ||
            (controls && getControlPermissions('remove frames'))
          "
        >
          <template #body="slotProps">
            <div class="controls">
              <ButtonUI
                :label="$t('button.add_to_playlist')"
                variant="text"
                color="text-palette-1"
                feather="plus"
                class="p-button-text-inline"
                v-if="getControlPermissions('add to playlist')"
                @click="addFramePlaylist(slotProps.data.id)"
              />
              <ButtonUI
                :label="$t('button.remove_from_playlist')"
                variant="text"
                color="text-palette-1"
                feather="trash-2"
                class="p-button-text-inline"
                v-if="getControlPermissions('remove slide') && remove"
                @click="removeFramePlaylist(slotProps.data.id)"
              />
              <ButtonUI
                :label="$t('button.settings')"
                variant="text"
                color="text-palette-1"
                feather="settings"
                class="p-button-text-inline"
                :to="{
                  name: 'frames-user-frames-edit',
                  params: { frame_id: slotProps.data.id },
                  query: { ...this.$route.query },
                }"
                v-if="getPermissions('edit frames') && slotProps.data.type != 'bcm'"
              />
            </div>
          </template>
        </PColumn>
      </template>
    </TableUI>
    <NotificationMessage type="default" v-else>{{ $t("message.no_frames_found") }}</NotificationMessage>
    <DialogAddFrameToPlaylist
      :visible="true"
      :frameId="playlistFrameId"
      @onCancel="onCancelAddToPlaylist"
      v-if="!!playlistFrameId"
    />
    <DialogConfirm
      :visible="true"
      v-if="!!removeFrameId"
      :title="$t('button.remove_slide_from_playlist')"
      @onConfirm="onConfirmRemove"
      @onCancel="onCancelRemoveFromPlaylist"
    />
  </div>
</template>

<script>
import TableUI from "@/components/TableUI.vue";
import ButtonUI from "@/components/ButtonUI.vue";
import DialogAddFrameToPlaylist from "@/components/_platform/dialog/DialogAddFrameToPlaylist.vue";
import DialogConfirm from "@/components/dialog/DialogConfirm.vue";
import NotificationMessage from "@/components/NotificationMessage.vue";
import ImageUI from "@/components/ImageUI.vue";

import Column from "primevue/column";
import { getPermissions, getTableCellWidth, getFileSize } from "@/helpers";

import { useFramesStore } from "@/stores";

export default {
  props: {
    frames: {
      type: Array,
      default() {
        return [];
      },
    },
    reorder: {
      type: Boolean,
      default: false,
    },
    remove: {
      type: Boolean,
      default: false,
    },
    controls: {
      type: Boolean,
      default: true,
    },
    controlPermissions: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  emits: ["removedFrameId"],
  data() {
    return {
      frameList: [],
      playlistFrameId: null,
      removeFrameId: null,
    };
  },
  components: {
    TableUI,
    ButtonUI,
    ImageUI,
    NotificationMessage,
    PColumn: Column,
    DialogAddFrameToPlaylist,
    DialogConfirm,
  },
  watch: {
    frames: {
      handler(value) {
        this.frameList = value;
      },
      immediate: true,
    },
  },
  methods: {
    addFramePlaylist(id) {
      this.playlistFrameId = id;
    },
    removeFramePlaylist(id) {
      this.removeFrameId = id;
    },
    onCancelAddToPlaylist() {
      this.playlistFrameId = null;
    },
    async onConfirmRemove() {
      const framesStore = useFramesStore();
      const { playlist_id } = this.$route.params;
      try {
        this.$emit("removedFrameId", this.removeFrameId);
        await framesStore.removeFrameFromPlaylist({ frame_id: this.removeFrameId, playlist_id }).catch((error) => {
          this.error = error;
        });
      } catch (error) {
        this.error = error;
      } finally {
        this.removeFrameId = null;
      }
    },
    onCancelRemoveFromPlaylist() {
      this.removeFrameId = null;
    },
    getPermissions,
    getCellWidth(cell) {
      return getTableCellWidth("frames", cell);
    },
    getControlPermissions(permission) {
      if (this.controlPermissions.length > 0) {
        return permission ? this.controlPermissions.includes(permission) : false;
      }
      return true;
    },
    async onRowReorder(event) {
      if (!this.reorder) {
        return false;
      }
      this.frameList = event.value;

      const { playlist_id } = this.$route.params;
      const frames = this.frameList.map(({ id }) => id);

      const framesStore = useFramesStore();
      await framesStore.updateFramesOrder(playlist_id, frames);
    },
  },
};
</script>

<style lang="scss" scoped>
.file-size {
  font-size: font-size("xs");
  font-weight: normal;
  opacity: 0.5;
  line-height: 1.3;
  margin-top: 0.2rem;
}

.image {
  width: 150px;
}
</style>
