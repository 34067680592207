<template>
  <ButtonUI v-if="impersonateIsActive" color="secondary" size="sm" :to="{ name: 'dashboard' }">
    <span class="ui-icon"> </span>
    <span class="ui-txt"> {{ $t("label.open_impersonate") }} </span>
  </ButtonUI>
</template>
<script>
import { useAuthStore } from "@/stores";
import ButtonUI from "@/components/ButtonUI.vue";

export default {
  components: { ButtonUI },
  computed: {
    impersonateIsActive() {
      return useAuthStore().impersonateIsActive;
    },
  },
};
</script>
<style lang="scss" scoped>
/* Combine both animations for a smooth fade in and fade out effect */
@keyframes fadeInOut {
  0%,
  100% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
}

.ui-icon {
  background: var(--state-online);
  border-radius: 50%;
  width: 10px;
  height: 10px;
  display: inline-block;
  margin-right: 0.5rem;
  animation: fadeInOut 1200ms infinite; /* 4 seconds for a complete cycle */
  animation-timing-function: ease-in-out; /* Smooth easing */
}
</style>
