import { loginRoutes } from "@/helpers/router/_platform/login";
import { dashboardRoutes } from "@/helpers/router/_platform/dashboard";
import { forgotPasswordRoutes } from "@/helpers/router/_platform/forgotPassword";
import { registerRoutes } from "@/helpers/router/_platform/register";
import { customerRoutes } from "@/helpers/router/_platform/customer";
import { systemsRoutes } from "@/helpers/router/_platform/systems";
import { groupsRoutes } from "@/helpers/router/_platform/groups";
import { settingsRoutes } from "@/helpers/router/_platform/settings";
import { usersRoutes } from "@/helpers/router/_platform/users";
import { musicRoutes } from "@/helpers/router/_platform/music";
import { slidesRoutes } from "@/helpers/router/_platform/slides";
import { framesRoutes } from "@/helpers/router/_platform/frames";
import { tickersRoutes } from "@/helpers/router/_platform/tickers";

export const platformRoutes = [
  ...loginRoutes,
  ...dashboardRoutes,
  ...forgotPasswordRoutes,
  ...registerRoutes,
  ...customerRoutes,
  ...systemsRoutes,
  ...groupsRoutes,
  ...settingsRoutes,
  ...usersRoutes,
  ...musicRoutes,
  ...slidesRoutes,
  ...framesRoutes,
  ...tickersRoutes,
];
