<template>
  <PRadioButton :class="[color ? `p-radio-${color}` : '']" />
</template>
<script>
import RadioButton from "primevue/radiobutton";

export default {
  components: {
    PRadioButton: RadioButton,
  },
  props: {
    color: {
      type: String,
      default: "primary",
    },
  },
};
</script>
