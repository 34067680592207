<template>
  <PButton
    :label="label"
    :class="[
      `p-button-${size}`,
      `p-button-${variant}`,
      color ? `p-button-${color}` : '',
      underline ? 'p-button-underline' : '',
    ]"
    @click="onClick"
  >
    <template v-if="feather">
      <span class="p-button-icon p-button-icon-left">
        <vue-feather :type="feather" :size="iconSize" />
      </span>
      <span class="p-button-label" v-html="label" />
    </template>
    <slot v-else />
  </PButton>
</template>
<script>
import Button from "primevue/button";
import VueFeather from "vue-feather";

import { router } from "@/helpers";

export default {
  components: {
    PButton: Button,
    VueFeather,
  },
  props: {
    variant: {
      type: String,
      default: "default",
    },
    color: {
      type: String,
      default: "primary",
    },
    underline: {
      type: Boolean,
      default: false,
    },
    feather: {
      type: String,
    },
    label: {
      type: String,
    },
    to: {
      type: [String, Object],
    },
    size: {
      type: String,
      default: "md",
    },
  },
  methods: {
    onClick() {
      if (this.to) {
        router.push(this.to);
      }
    },
  },
  computed: {
    iconSize() {
      return this.size === "sm" ? 15 : 24;
    },
  },
};
</script>
