<template>
  <SurfaceCard :title="$t('label.customerProfile')" type="dashboard" class="h-full" v-show="isVisible">
    <template #body>
      <!--      <pre>{{ customerProfile }}</pre>-->
      <div class="profile">
        <table>
          <tr v-if="customerProfile?.customer?.name">
            <td class="pb-3">
              <div class="label">{{ $t("label.customer_name") }}:</div>
              <TextUI>{{ customerProfile.customer.name }}</TextUI>
            </td>
          </tr>
          <tr v-if="customerProfile?.region?.name">
            <td class="pb-3">
              <div class="label">{{ $t("label.customer_region") }}:</div>
              <TextUI>{{ customerProfile.region.name }}</TextUI>
            </td>
          </tr>
          <tr v-if="customerProfile?.code">
            <td class="pb-3">
              <div class="label">{{ $t("label.code") }}:</div>
              <TextUI>{{ customerProfile.code }}</TextUI>
            </td>
          </tr>
        </table>
      </div>
    </template>
  </SurfaceCard>
</template>
<script>
import SurfaceCard from "@/components/SurfaceCard.vue";
import TextUI from "@/components/TextUI.vue";

import { useAuthStore } from "@/stores";
import { storeToRefs } from "pinia";
import { getPermissions } from "@/helpers";

export default {
  setup(props, { emit }) {
    const authStore = useAuthStore();

    // const isVisible = getPermissions(["admin all"]);
    const isVisible = true;
    const { customerProfile } = storeToRefs(authStore);

    emit("isVisible", isVisible);

    return {
      customerProfile,
      isVisible,
    };
  },
  components: {
    SurfaceCard,
    TextUI,
  },
};
</script>
<style lang="scss" scoped>
.profile {
  padding: spacing(6);
  .label {
    margin-bottom: spacing(2);
    font-weight: bold;
    display: inline-block;
  }
  p {
    margin: 0;
  }
}
</style>
