<template>
  <template v-for="(field, index) in fieldItems" :key="index">
    <FormRow>
      <div class="flex justify-content-between align-items-center gap-2">
        <FormLabel :for="`static_text_${field}`" required>{{ $t("label.static_text") }} {{ field }}: </FormLabel>
        <div class="flex-shrink-0 flex controls">
          <ToolTip :content="$t('message.tooltip_tickers_static_text')" class="btn-tooltip" />
          <button
            v-if="index > 0 || fieldItems > 1"
            type="button"
            @click="removeText(index)"
            class="btn btn-remove-text"
          >
            <vue-feather type="trash-2" size="18" />
          </button>
        </div>
      </div>

      <FormText
        :id="`static_text_${field}`"
        :placeholder="$t('placeholder.ticket_static_text')"
        type="text"
        size="lg"
        required
        :class="{ 'is-invalid': error.data?.static_text }"
        v-model="inputValue.static_text[field - 1]"
      />
      <FormHelp type="invalid" :data="error" :field="`static_text_${field}`" />
    </FormRow>
  </template>

  <ButtonUI
    v-if="validateAddText"
    :label="$t('button.add_static_text')"
    color="secondary"
    feather="plus"
    @click="addText()"
  />
</template>
<script>
import FormHelp from "@/components/form/FormHelp.vue";
import FormText from "@/components/form/FormText.vue";
import ToolTip from "@/components/ToolTip.vue";
import FormLabel from "@/components/form/FormLabel.vue";
import FormRow from "@/components/form/FormRow.vue";
import ButtonUI from "@/components/ButtonUI.vue";
import VueFeather from "vue-feather";

export default {
  components: { VueFeather, ButtonUI, FormRow, FormLabel, ToolTip, FormText, FormHelp },
  data() {
    return {
      fieldItems: 1,
      fieldMax: 10,
    };
  },
  props: {
    form: {
      type: Object,
      default: () => {
        return {};
      },
    },
    error: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  emits: ["formValue", "fieldItems"],
  computed: {
    inputValue: {
      get() {
        return this.form;
      },
      set(value) {
        this.$emit("formValue", value);
      },
    },
    validateAddText() {
      return this.fieldItems < this.fieldMax;
    },
  },
  methods: {
    addText() {
      if (!this.validateAddText) {
        return;
      }
      this.fieldItems += 1;
    },
    removeText(index) {
      this.inputValue.static_text.splice(index, 1);
      this.fieldItems -= 1;
    },
  },
  watch: {
    fieldItems(val) {
      this.$emit("fieldItems", val);
    },
    form: {
      handler(form) {
        const items = form?.static_text.length;
        this.fieldItems = items <= 0 ? 1 : items;
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.label {
  margin-bottom: 0.5rem;
}

.btn-tooltip,
.btn {
  border: 12px solid transparent !important;
  border-width: 12px 6px !important;
}

.btn-tooltip {
  line-height: 0;
}

.btn {
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.2s;
  cursor: pointer;
  color: var(--link-color);
  border-width: 12px 6px;
  background: transparent;
  margin: 0;
  padding: 0;
  .vue-feather {
    pointer-events: none;
  }

  &:hover {
    color: var(--link-hover-color);
  }
  &:focus {
    color: var(--link-focus-color);
  }
}
</style>
