<template>
  <div class="tab-container" :class="{ 'is-subnav': subTabNav }" v-resize="onResize">
    <div
      class="tab-controls"
      v-if="$slots.controls"
      :class="{ 'is-sticky': isSticky && stickyControls }"
      :style="`width:${controlWidth}px`"
    >
      <div class="controls-inner" ref="stickyContentEl">
        <slot name="controls" />
      </div>
    </div>
    <div class="tab-nav">
      <div>
        <ul>
          <template v-for="(item, index) in filteredItems" :key="index">
            <li v-if="!item?.hidden">
              <span class="tab-item" @click="onClick(item, index)" :class="{ 'is-active': item.active }">
                <TabTitle :feather="item.feather" :title="item.title" />
              </span>
            </li>
          </template>
        </ul>
      </div>
    </div>
    <div class="sticky-start" ref="stickyStartEl"></div>
    <div class="tab-panels" v-if="$slots.panels">
      <slot name="panels" />
    </div>
  </div>
</template>

<script>
import { router, getPermissions, isNotEmpty, findRouteMatch } from "@/helpers";
import TabTitle from "@/components/TabTitle.vue";
import VueResizeObserver from "vue-resize-observer";

import { computed, onMounted, onUnmounted, ref, toRefs, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import { useAppStore } from "@/stores";

export default {
  setup(props, { emit }) {
    const { items, variant, exact, activeTab } = toRefs(props);

    const redirectEnabled = ref(false);
    const firstVisibleItem = ref({});
    const controlWidth = ref("100%");

    let isSticky = ref(false);
    const stickyStartEl = ref(null);
    const stickyContentEl = ref(null);

    const topBarHeight = 70;
    const offset = 10;

    let scrollUpTimeout;
    const scrollTimeout = 100;

    const route = useRoute();
    const router = useRouter();

    const redirectToFirstItemIfNoPermissions = computed(() => {
      return isNotEmpty(firstVisibleItem.value) > 0 && redirectEnabled.value;
    });

    const filteredItems = computed(() => {
      let firstItem = false;
      return items.value.filter((item, index) => {
        // merge active state
        item.active = isActive(item, index);

        if ("hidden" in item) {
          item.hidden = !item.active;
        }

        if (item?.permissions) {
          if (item?.permissions && permissions(item.permissions)) {
            // check if user has the correct permissions
            if (!firstItem) {
              firstVisibleItem.value = item;
              firstItem = true;
            }
            return item;
          } else {
            // user has not the correct permissions
            // if is link and not tab
            if (variant.value === "link") {
              if (item.route === route.name || (item?.route_match && item.route_match.includes(route.name))) {
                redirectEnabled.value = true;
              }
            }
          }
        } else {
          if (!firstItem) {
            firstVisibleItem.value = item;
            firstItem = true;
          }
          return item;
        }
      });
    });

    const isActive = (item, index) => {
      if (activeTab.value.index === undefined && index === 0) {
        return true;
      } else if (variant.value === "link" && item?.route) {
        let match;
        if (item?.route_match && exact.value) {
          match = findRouteMatch(router.options.routes, route.name, item.route_match);
        } else {
          const containsDynamicSlug = item.route.includes(":");
          if (containsDynamicSlug) {
            console.log("Route contains /:slug, this is not allowed");
          } else {
            if (exact.value) {
              match = route.name === item.route;
            } else {
              match = route.name.startsWith(item.route);
            }
          }
        }
        return !!match;
      } else if (variant.value === "tab" && activeTab.value.index === index) {
        return true;
      }
      return false;
    };

    const onResize = ({ width, height }) => {
      controlWidth.value = width;
    };

    const onClick = (item, index) => {
      redirect(item, index);
    };

    const redirect = (item, index) => {
      if (variant.value === "link") {
        setTimeout(async () => {
          const params = item?.params ? item.params : null;
          await router.push({
            name: item.route,
            params,
          });
        }, 100);
      } else if (variant.value === "tab") {
        emit("update:activeTab", { name: item.route, index: index });
      }
    };

    const permissions = (permission) => {
      if (!permission) {
        return true;
      }
      return getPermissions(permission);
    };

    // sticky

    const onScroll = () => {
      if (scrollUpTimeout) {
        clearTimeout(scrollUpTimeout);
      }

      scrollUpTimeout = setTimeout(() => {
        if (stickyStartEl.value && stickyContentEl.value?.innerHTML) {
          const offsetTop = stickyStartEl.value?.getBoundingClientRect().top - topBarHeight + offset;
          isSticky.value = offsetTop <= 0;
        } else {
          isSticky.value = false;
        }
      }, scrollTimeout);
    };
    onMounted(() => {
      window.addEventListener("scroll", onScroll);
    });
    onUnmounted(() => {
      window.removeEventListener("scroll", onScroll);
    });

    watch(
      redirectToFirstItemIfNoPermissions,
      () => {
        const { isDev } = useAppStore().getAppConfig;
        const name = firstVisibleItem.value.route;
        if (isDev) {
          console.log({
            redirectToFirstItemIfNoPermissions: name,
          });
        }
        return router.push({ name: name });
      },
      {}
    );

    let initPage = false;

    watch(
      filteredItems,
      (items) => {
        if (isNotEmpty(items)) {
          const isActive = items.find((item) => item.active);
          if (!isActive && !initPage) {
            // redirect to first item if not selected
            redirect(firstVisibleItem.value, 0);
            initPage = true;
          }
        }
      },
      {
        immediate: true,
        deep: true,
        flush: "post",
      }
    );

    return {
      isActive,
      onResize,
      onClick,
      redirect,
      isSticky,
      stickyStartEl,
      stickyContentEl,
      redirectEnabled,
      firstVisibleItem,
      controlWidth,
      redirectToFirstItemIfNoPermissions,
      filteredItems,
    };
  },
  directives: { resize: VueResizeObserver },
  props: {
    items: {
      type: Array,
      required: true,
      default() {
        return [];
      },
    },
    variant: {
      type: String,
      default: "link",
    },
    exact: {
      type: Boolean,
      default: false,
    },
    subTabNav: {
      type: Boolean,
      default: false,
    },
    stickyControls: {
      type: Boolean,
      default: true,
    },
    activeTab: {
      type: Object,
      default() {
        return {
          index: 0,
        };
      },
    },
  },
  components: {
    TabTitle,
  },
  async mounted() {
    await router.isReady();
    setTimeout(() => {
      this.controlWidth = this.$el.offsetWidth;
    }, 100);
  },
};
</script>

<style lang="scss">
.tab-container {
  position: relative;
  .tab-nav {
    border-bottom: 1px solid var(--tabnav-border-color);
    display: flex;
    ul {
      display: flex;
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        margin: 0;
        padding: 0;

        .tab-item {
          transition: all 0.2s;
          border-width: 0 0 3px 0;
          border-style: solid;
          color: var(--tabnav-item-color);
          border-color: transparent;
          padding: spacing(5) spacing(4);
          font-size: font-size("lg");
          font-weight: 600;
          margin: 0 0 -2px 0;
          user-select: none;
          cursor: pointer;
          display: flex;
          align-items: center;
          position: relative;
          text-decoration: none;
          overflow: hidden;
          white-space: nowrap;
          line-height: 1;

          &.is-active {
            color: var(--tabnav-item-active-color);
            border-color: var(--tabnav-item-active-border-color);
            &:hover {
              color: var(--tabnav-item-hover-color);
            }
          }

          &:hover {
            color: var(--tabnav-item-active-color);
          }
        }
      }
    }
  }
  .tab-panels {
    padding: spacing(8) 0;
  }
  .tab-controls {
    position: absolute;
    top: 0;
    width: 100%;
    text-align: right;
    pointer-events: none;

    .controls-inner {
      display: inline-block;
      margin-left: auto;
      pointer-events: auto;
    }

    &.is-sticky {
      position: fixed;
      top: 70px !important;
      height: 78px;
      z-index: 900;
      background: var(--app-ground);
      padding: spacing(4) 0;
      transition: top 0.28s ease-out;
      &:before {
        content: "";
        background: var(--app-ground);
        position: absolute;
        left: -20px;
        right: -20px;
        bottom: 0;
        height: 200%;
      }
    }
  }

  &.is-subnav {
    .tab-nav {
      border: 0;
      ul {
        li {
          padding-right: spacing(6);
          .tab-item {
            padding: 0.8rem 0;
            border: 0;
            //font-size: font-size("base");
            font-size: 1.1rem;
          }
          .tab-title {
            .tab-icon {
              margin-right: 0.4rem;
              .vue-feather__content {
                height: 16px !important;
                width: 16px !important;
              }
            }
          }
        }
      }
    }
    .tab-panels {
      padding-top: spacing(5);
    }
  }

  .tab-container {
    margin-top: spacing(5, true);
    .sticky-start {
      transform: translateY(spacing(5));
    }
  }
}
</style>
