export function timeToSeconds(timeString) {
  const parts = timeString.split(":");
  const hours = parts.length > 2 ? parseInt(parts[0]) : 0;
  const minutes = parseInt(parts[parts.length > 2 ? 1 : 0]);
  const seconds = parseInt(parts[parts.length > 2 ? 2 : 1]);
  return hours * 3600 + minutes * 60 + seconds;
}

export function secondsToTime(seconds) {
  const hours = Math.floor(seconds / 3600);
  let remainingSeconds = seconds % 3600;
  const minutes = Math.floor(remainingSeconds / 60);
  remainingSeconds = remainingSeconds % 60;
  return (
    (hours < 10 ? "0" : "") +
    hours +
    ":" +
    (minutes < 10 ? "0" : "") +
    minutes +
    ":" +
    (remainingSeconds < 10 ? "0" : "") +
    remainingSeconds
  );
}
