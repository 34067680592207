<template>
  <div v-html="highlightTerms" />
</template>
<script>
import { highlightTerms } from "@/helpers";

export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    terms: {
      type: Array,
      required: true,
    },
  },
  computed: {
    highlightTerms() {
      return highlightTerms(this.text, this.terms);
    },
  },
};
</script>
<style lang="scss">
.highlight-word {
  display: inline;
  background: var(--highlight-term-ground);
  color: var(--highlight-term-color);
}
</style>
