<template>
  <div>
    <TableUI v-if="groups.length > 0" name="groups" :value="groups">
      <template #columns>
        <PColumn field="name" :header="$t('label.group_name')" :style="getCellWidth('name')" />
        <PColumn field="systems" :header="$t('label.systems')" :style="getCellWidth('systems')" />

        <!-- Render description column -->
        <PColumn :style="getCellWidth('description')">
          <template #body="slotProps">
            <ToolTip :content="slotProps.data.description" v-if="slotProps.data.description" />
          </template>
        </PColumn>

        <!-- Render controls column -->
        <PColumn
          headerStyle="text-align: right"
          bodyStyle="text-align: right; overflow: visible"
          :style="getCellWidth('controls')"
        >
          <template #body="slotProps">
            <div class="controls">
              <ButtonUI
                v-if="getPermissions('show groups')"
                :disabled="getSyncGroupState(slotProps.data.id)"
                :label="$t('button.sync')"
                variant="text"
                color="text-palette-1"
                feather="refresh-cw"
                class="p-button-text-inline"
                @click="syncGroup(slotProps.data.id)"
              />

              <ButtonUI
                v-if="getPermissions('show group events')"
                :label="$t('button.schedule')"
                variant="text"
                color="text-palette-1"
                feather="calendar"
                class="p-button-text-inline"
                :to="{
                  name: 'groups-detail-schedule',
                  params: { group_id: slotProps.data.id },
                  query: { ...this.$route.query },
                }"
              />

              <ButtonUI
                v-if="getPermissions('edit groups')"
                :label="$t('button.settings')"
                variant="text"
                color="text-palette-1"
                feather="settings"
                class="p-button-text-inline"
                :to="{
                  name: 'groups-detail-edit',
                  params: { group_id: slotProps.data.id },
                  query: { ...this.$route.query },
                }"
              />
            </div>
          </template>
        </PColumn>
      </template>
    </TableUI>
    <NotificationMessage type="default" v-else>{{ $t("message.no_data_found") }}</NotificationMessage>
  </div>
</template>

<script>
import TableUI from "@/components/TableUI.vue";
import ButtonUI from "@/components/ButtonUI.vue";
import ToolTip from "@/components/ToolTip.vue";
import NotificationMessage from "@/components/NotificationMessage.vue";

import Column from "primevue/column";
import { getPermissions, getTableCellWidth } from "@/helpers";
import { watch } from "vue";
import { useGroupsStore, useNavStore } from "@/stores";
import { storeToRefs } from "pinia";

export default {
  setup() {
    const groupsStore = useGroupsStore();
    const { groups_state_sync } = storeToRefs(groupsStore);

    const getSyncGroupState = (system_id) => {
      return groupsStore.getSyncGroupState(system_id);
    };

    const syncGroup = (system_id) => {
      return groupsStore.syncGroup(system_id);
    };

    watch(
      groups_state_sync,
      (state) => {
        useNavStore().setAppSpinner(state.length > 0);
      },
      {
        deep: true,
      }
    );

    return {
      getSyncGroupState,
      syncGroup,
    };
  },
  props: {
    groups: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  components: {
    TableUI,
    ButtonUI,
    ToolTip,
    NotificationMessage,
    PColumn: Column,
  },
  methods: {
    getPermissions,
    getCellWidth(cell) {
      return getTableCellWidth("groups", cell);
    },
  },
};
</script>
