<template>
  <form
    id="filter-search"
    :class="[{ 'filter-search--has-dropdown': types.length > 0 }, `filter-search-${size}`]"
    @submit.prevent="submitFilterData()"
  >
    <div class="filter-term">
      <FormText type="search" v-model="filterTerm" :placeholder="placeholder">
        <ButtonUI
          v-if="filterTerm"
          variant="secondary"
          size="sm"
          feather="x"
          class="p-button-icon-only p-button-rounded filter-reset-term"
          @click="resetTerm()"
        />
      </FormText>
    </div>

    <PDropdown
      v-if="types.length > 0"
      v-model="filterType"
      :options="filterTypeOptions"
      optionLabel="name"
      panelClass="p-dropdown-panel--filter"
      checkmark
      :highlightOnSelect="false"
      class="filter-type"
    >
      <template #value="slotProps">
        <div class="type-item">
          <span class="item-label">{{ $t("label.type") }}: </span>
          <span v-if="slotProps.value" v-html="slotProps.value.name" />
          <span v-else v-html="slotProps.placeholder" />
        </div>
      </template>
    </PDropdown>
  </form>
</template>
<script>
import FormText from "@/components/form/FormText.vue";
import ButtonUI from "@/components/ButtonUI.vue";
import { computed, onMounted, ref, toRefs, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
import Dropdown from "primevue/dropdown";
import { i18n } from "@/helpers";

export default {
  emits: ["update:filterData"],
  setup(props, { emit }) {
    const route = useRoute();
    const router = useRouter();
    const filterTerm = ref(null);
    const filterType = ref({});
    let timeout = false;

    const { types, replaceTextType } = toRefs(props);

    const term = computed(() => {
      return route.query.term;
    });

    const type = computed(() => {
      return route.query.type ? route.query.type : types.value[0];
    });

    const filterTypeOptions = computed(() => {
      return types.value.map((type) => {
        return {
          value: type,
          name: replaceText(type),
        };
      });
    });

    const resetTerm = () => {
      filterTerm.value = null;
    };

    const setFilterData = () => {
      emit("update:filterData", {
        term: filterTerm.value,
        type: filterType.value.value,
      });
    };

    const replaceText = (type) => {
      const replaceText = replaceTextType.value.find((obj) => type in obj)?.[type];
      return replaceText ?? i18n.global.t(`label.${type}`);
    };

    const convertFilterType = (type) => {
      return {
        value: type,
        name: replaceText(type),
      };
    };

    const submitFilterData = () => {
      router.push({
        query: {
          term: filterTerm.value,
          type: filterType.value.value,
        },
      });
    };

    onMounted(() => {
      filterTerm.value = term.value;
      filterType.value = convertFilterType(type.value);
    });

    watch(filterTerm, (term) => {
      if (!term) {
        filterTerm.value = term;
      }
      if (timeout) {
        clearTimeout(timeout);
      }
      timeout = setTimeout(() => {
        setFilterData();
      }, 200);
    });

    watch(filterType, (type) => {
      if (!type) {
        return;
      }
      setFilterData();
    });

    return {
      resetTerm,
      filterTerm,
      filterType,
      filterTypeOptions,
      submitFilterData,
    };
  },
  props: {
    size: {
      type: String,
      default: "sm",
    },
    placeholder: {
      type: String,
      default: i18n.global.t("placeholder.search_by_term"),
    },
    types: {
      type: Array,
      default() {
        return [];
      },
    },
    replaceTextType: {
      type: Array,
      default() {
        return [];
      },
    },
    filterData: {},
  },
  components: {
    ButtonUI,
    PDropdown: Dropdown,
    FormText,
  },
};
</script>
<style lang="scss">
#filter-search {
  display: flex;
  height: 40px;
  --filter-border-radius: 26px;
  --filter-border-radius--filter: 4px;

  .filter-term,
  .filter-type {
    height: 100%;
    display: flex;
    input,
    .p-inputsearch {
      flex: 1;
      display: flex;
    }
  }

  .filter-reset-term {
    position: absolute;
    top: 8px;
    right: 8px;
    z-index: 3;
  }

  .filter-type {
    border-radius: 0;
    border-top-right-radius: var(--filter-border-radius--filter);
    border-bottom-right-radius: var(--filter-border-radius--filter);
    margin-left: -1px;
    z-index: 1;
    min-width: 180px;
    background: var(--dropdown-filter-ground);
    border-color: var(--dropdown-filter-border-color);
    font-size: font-size("sm");
    color: var(--dropdown-filter-color);
    //&.p-focus,
    //&:focus {
    //  border-color: #6d717c;
    //}

    .p-inputtext {
      font-size: inherit;
    }

    .type-item {
      display: flex;
      height: 100%;
      align-items: center;
      gap: spacing(7);

      .item-label {
        color: var(--dropdown-filter-label-color);
      }
    }
  }

  .filter-term {
    input[type="search"] {
      border-radius: var(--filter-border-radius);
      padding-right: 40px;
      position: relative;
      min-width: 400px;
      &:focus {
        z-index: 2;
      }
    }
  }

  &.filter-search--has-dropdown {
    .filter-term {
      input {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
      }
    }
  }
}
.p-dropdown-panel {
  &.p-dropdown-panel--filter {
    font-size: font-size("sm");

    .p-dropdown-items {
      .p-dropdown-item {
        color: var(--dropdown-filter-label-color);
        &.p-highlight {
          background: var(--dropdown-filter-highlight-ground);
          color: var(--dropdown-filter-highlight-color);
          position: relative;

          &:after {
            position: absolute;
            right: 0.8rem;
            top: 50%;
            transform: translateY(-50%);
            content: "";
            height: 10px;
            width: 10px;
            background: var(--dropdown-filter-highlight-icon);
            background-repeat: no-repeat;
            background-position: center;
          }

          &.p-focus {
            background: transparent;
            color: inherit;
          }
        }
      }
    }
  }
}
</style>
