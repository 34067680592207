<template>
  <div>
    <NotificationMessage type="404" v-if="notFound" />
    <template v-else>
      <TabNav
        :items="[
          {
            title: $t('route.settings'),
            feather: 'settings',
            route: 'settings-profile-account',
          },
          {
            title: $t('route.password'),
            feather: 'key',
            route: 'settings-profile-password',
          },
        ]"
        :stickyControls="true"
        :subTabNav="true"
      >
        <template #controls>
          <!-- TODO: correct permissions to show button -->
          <ButtonUI
            :underline="true"
            variant="text"
            color="text-palette-1"
            size="lg"
            :label="$t('button.delete_profile')"
            @click="deleteProfile()"
          />
        </template>
        <template #panels>
          <RouterView name="Profile" v-slot="{ Component }">
            <Transition name="page-fade" mode="out-in">
              <component :is="Component" />
            </Transition>
          </RouterView>
        </template>
      </TabNav>
    </template>
    <DialogConfirm
      :visible="showConfirm"
      :title="$t('button.delete_profile')"
      @onConfirm="onConfirm"
      @onCancel="onCancel"
    />
  </div>
</template>

<script>
import TabNav from "@/components/nav/TabNav.vue";
import ButtonUI from "@/components/ButtonUI.vue";
import NotificationMessage from "@/components/NotificationMessage.vue";
import DialogConfirm from "@/components/dialog/DialogConfirm.vue";
import { useAuthStore } from "@/stores";
import { getPermissions, setBreadcrumbs } from "@/helpers";

export default {
  setup() {
    setBreadcrumbs();
    const authStore = useAuthStore();
    authStore.validateMe();
  },
  components: {
    TabNav,
    ButtonUI,
    NotificationMessage,
    DialogConfirm,
  },
  data() {
    return {
      error: {},
      state: {},
      page_name: null,
      timeout: false,
      showConfirm: false,
    };
  },
  methods: {
    deleteProfile() {
      this.showConfirm = true;
    },
    onConfirm() {
      this.showConfirm = false;
      const authStore = useAuthStore();
      const form = { ...authStore.getUser };
      return authStore.deleteUser(form?.id).catch((error) => {
        this.error = error;
      });
    },
    onCancel() {
      this.showConfirm = false;
    },
    getPermissions,
  },
  computed: {},
};
</script>

<style lang="scss" scoped></style>
