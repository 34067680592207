import LeftSidebar from "@/components/LeftSidebar.vue";
import SystemsIndexView from "@/views/_platform/systems/IndexView.vue";
import SystemsOverView from "@/views/_platform/systems/partials/IndexView.vue";
import SystemsPendingView from "@/views/_platform/systems/partials/PendingView.vue";
import SystemsGroupView from "@/views/_platform/systems/partials/GroupView.vue";
import SystemsDetailView from "@/views/_platform/systems/DetailView.vue";
import SystemsEditView from "@/views/_platform/systems/partials/EditView.vue";
import SystemsScheduleView from "@/views/_platform/systems/partials/ScheduleView.vue";
import SystemsApproveView from "@/views/_platform/systems/ApproveView.vue";

export const systemsRoutes = [
  // Systems
  {
    path: "/systems",
    name: "systems-index",
    components: {
      LeftSidebar,
      default: SystemsIndexView,
    },
    children: [
      {
        path: "/systems",
        name: "systems",
        components: {
          Systems: SystemsOverView,
        },
        meta: {
          permission: ["show systems"],
          supportPageID: "004",
        },
      },
      {
        path: "/systems/pending",
        name: "systems-pending",
        components: {
          Systems: SystemsPendingView,
        },
        meta: {
          permission: ["show systems"],
          supportPageID: "007",
        },
      },
      {
        path: "/systems/groups",
        name: "systems-groups",
        components: {
          Systems: SystemsGroupView,
        },
        meta: {
          permission: ["show groups"],
          supportPageID: "009",
        },
      },
    ],
  },
  {
    name: "systems-detail",
    components: {
      LeftSidebar,
      default: SystemsDetailView,
    },
    children: [
      {
        name: "systems-detail-edit",
        path: "/systems/:system_id/edit",
        components: {
          Detail: SystemsEditView,
        },
        meta: {
          permission: "edit systems",
          supportPageID: "006",
        },
      },
      {
        name: "systems-detail-schedule",
        path: "/systems/:system_id/schedule",
        components: {
          Detail: SystemsScheduleView,
        },
        meta: {
          permission: "show system events",
          supportPageID: "005",
        },
      },
    ],
    meta: {
      permission: "edit systems",
    },
  },
  {
    name: "systems-approve",
    path: "/systems/:system_id/approve",
    components: {
      LeftSidebar,
      default: SystemsApproveView,
    },
    meta: {
      permission: "show systems",
      supportPageID: "008",
    },
  },
];
