import parseUrl from "parse-url";
import { providers } from "@/helpers/appProviders";

export function appConfig() {
  let url = parseUrl(window.location.href);

  // Fallback is production
  let mode = "production";
  let isDev = false;
  let isEncrypt = true;
  let supportUrl = "https://redirect.bcmserver.com";

  let apiUrl = import.meta.env.VITE_API_URL;
  let providerID = import.meta.env.VITE_PROVIDER_ID || "bcm";

  let uploadLimit = {
    image: Number(import.meta.env.VITE_UPLOAD_LIMIT_IMAGE || 10),
    video: Number(import.meta.env.VITE_UPLOAD_LIMIT_VIDEO || 100),
  };

  if (import.meta.env.DEV) {
    mode = "development";
  }

  const hostname = url.resource;

  // Set mode and apiURL
  switch (hostname) {
    case "localhost":
      mode = "local";
      break;
    // AWS test case
    case "aws--bcm-remote-webapp.vercel.app":
      apiUrl = "https://bcm-aws.exitable.dev/api/v1";
      mode = "development";
      break;
    case hostname.match(/^(.*)exitable\.vercel\.app$/) ? hostname : undefined:
    case "develop.remoteaccess-portal.com": // Orange Door
    case "swishhospitality--develop.mediacontrolcenter.com": // SwishHospitality
    case "bcmmusic--develop.mediacontrolcenter.com": // BCM
    case "develop--bcm-remote-webapp.vercel.app":
      apiUrl = "https://bcm-test.exitable.dev/api/v1";
      mode = "development";
      break;
    case "staging.remoteaccess-portal.com": // Orange Door
    case "swishhospitality--staging.mediacontrolcenter.com": // SwishHospitality
    case "bcmmusic--staging.mediacontrolcenter.com": // BCM
    case "staging--bcm-remote-webapp.vercel.app":
      apiUrl = "https://bcm-staging.exitable.dev/api/v1";
      mode = "staging";
      break;
  }

  // Set providerID
  switch (hostname) {
    // Orange Door
    case "remoteaccess-portal.com":
    case "develop.remoteaccess-portal.com":
    case "staging.remoteaccess-portal.com":
      providerID = "od";
      break;
    // SwishHospitality
    case "swishhospitality.mediacontrolcenter.com":
    case "swishhospitality--develop.mediacontrolcenter.com":
    case "swishhospitality--staging.mediacontrolcenter.com":
      providerID = "sh";
      break;
  }

  // Set themeName, companyName and appName
  const {
    theme_name: themeName,
    name: companyName,
    app_name: appName,
  } = providers.find((provider) => provider.id === providerID);

  if (mode !== "production") {
    isDev = true;
  }

  if (mode === "development" || mode === "local") {
    isEncrypt = false;
  }

  if (!apiUrl) {
    throw new Error("No API URL defined");
  }

  return {
    mode,
    isDev,
    isEncrypt,
    apiUrl,
    hostname,
    providerID,
    themeName,
    companyName,
    appName,
    uploadLimit,
    supportUrl,
  };
}
