<template>
  <div>
    <TableUI
      v-if="tickerList.length > 0"
      name="tracks"
      :value="tickerList"
      @rowReorder="onRowReorder"
      :class="[reorder ? `table-has-reorder` : '']"
    >
      <template #columns>
        <PColumn rowReorder headerStyle="width: 3rem" v-if="reorder" />
        <PColumn field="name" :header="$t('label.name')" :style="getCellWidth('name')">
          <template #body="slotProps"> {{ slotProps.data.name }} </template>
        </PColumn>
        <PColumn field="type" :header="$t('label.type')" :style="getCellWidth('type')">
          <template #body="slotProps">
            <span class="white-space-nowrap">{{
              slotProps.data.ticker_type == 1 ? $t("label.rss_feed") : $t("label.static_text")
            }}</span>
          </template>
        </PColumn>
        <!-- Render controls column -->
        <PColumn
          headerStyle="text-align: right"
          bodyStyle="text-align: right; overflow: visible"
          :style="getCellWidth('controls')"
          v-if="
            (controls && getControlPermissions('add to playlist')) ||
            (controls && getControlPermissions('remove tickers'))
          "
        >
          <template #body="slotProps">
            <div class="controls">
              <ButtonUI
                :label="$t('button.add_to_playlist')"
                variant="text"
                color="text-palette-1"
                feather="plus"
                class="p-button-text-inline"
                v-if="getControlPermissions('add to playlist')"
                @click="addTickerPlaylist(slotProps.data.id)"
              />
              <ButtonUI
                :label="$t('button.remove_from_playlist')"
                variant="text"
                color="text-palette-1"
                feather="trash-2"
                class="p-button-text-inline"
                v-if="getControlPermissions('remove tickers') && remove"
                @click="removeTickerPlaylist(slotProps.data.id)"
              />
              <ButtonUI
                :label="$t('button.settings')"
                variant="text"
                color="text-palette-1"
                feather="settings"
                class="p-button-text-inline"
                :to="{
                  name: 'tickers-user-tickers-edit',
                  params: { ticker_id: slotProps.data.id },
                  query: { ...this.$route.query },
                }"
                v-if="getPermissions('edit tickers') && slotProps.data.type != 'bcm'"
              />
            </div>
          </template>
        </PColumn>
      </template>
    </TableUI>
    <NotificationMessage type="default" v-else>{{ $t("message.no_tickers_found") }}</NotificationMessage>
    <DialogAddTickerToPlaylist
      :visible="true"
      :tickerId="playlistTickerId"
      @onCancel="onCancelAddToPlaylist"
      v-if="!!playlistTickerId"
    />
    <DialogConfirm
      :visible="true"
      v-if="!!removeTickerId"
      :title="$t('button.remove_track_from_playlist')"
      @onConfirm="onConfirmRemove"
      @onCancel="onCancelRemoveFromPlayList"
    />
  </div>
</template>

<script>
import TableUI from "@/components/TableUI.vue";
import ButtonUI from "@/components/ButtonUI.vue";
import DialogConfirm from "@/components/dialog/DialogConfirm.vue";
import DialogAddTickerToPlaylist from "@/components/_platform/dialog/DialogAddTickerToPlaylist.vue";
import NotificationMessage from "@/components/NotificationMessage.vue";

import Column from "primevue/column";
import { getPermissions, getTableCellWidth } from "@/helpers";

import { useTickersStore } from "@/stores";

export default {
  props: {
    tickers: {
      type: Array,
      default() {
        return [];
      },
    },
    reorder: {
      type: Boolean,
      default: false,
    },
    remove: {
      type: Boolean,
      default: false,
    },
    controls: {
      type: Boolean,
      default: true,
    },
    controlPermissions: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      tickerList: [],
      playlistTickerId: null,
      removeTickerId: null,
    };
  },
  components: {
    TableUI,
    ButtonUI,
    DialogConfirm,
    DialogAddTickerToPlaylist,
    NotificationMessage,
    PColumn: Column,
  },
  watch: {
    tickers: {
      handler(value) {
        this.tickerList = value;
      },
      immediate: true,
    },
  },
  methods: {
    addTickerPlaylist(id) {
      this.playlistTickerId = id;
    },
    removeTickerPlaylist(id) {
      this.removeTickerId = id;
    },
    async onConfirmRemove() {
      const tickerStore = useTickersStore();
      const { playlist_id } = this.$route.params;
      try {
        await tickerStore.removeTickerFromPlaylist({ ticker_id: this.removeTickerId, playlist_id }).catch((error) => {
          this.error = error;
        });
      } catch (error) {
        this.error = error;
      } finally {
        this.removeTickerId = null;
      }
    },
    onCancelRemoveFromPlayList() {
      this.removeTickerId = null;
    },
    onCancelAddToPlaylist() {
      this.playlistTickerId = null;
    },
    getPermissions,
    getCellWidth(cell) {
      return getTableCellWidth("tickers", cell);
    },
    getControlPermissions(permission) {
      if (this.controlPermissions.length > 0) {
        return permission ? this.controlPermissions.includes(permission) : false;
      }
      return true;
    },
    async onRowReorder(event) {
      if (!this.reorder) {
        return false;
      }
      this.tickerList = event.value;

      const { playlist_id } = this.$route.params;
      const tickers = this.tickerList.map(({ id }) => id);

      const tickerStore = useTickersStore();
      await tickerStore.updateTickersOrder(playlist_id, tickers);
    },
  },
};
</script>
