<template>
  <div class="surface-card" :class="[`surface-card--${type}`]">
    <div class="card-header" v-if="title">
      <h3 v-html="title" class="card-title" />
    </div>
    <div class="card-body">
      <div class="body-header">
        <slot name="body" v-if="$slots.body" />
        <slot v-else />
      </div>
      <div class="body-footer" v-if="$slots.footer">
        <slot name="footer" />
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    title: {
      type: String,
    },
    type: {
      type: String,
      default: "default",
    },
  },
};
</script>
