<template>
  <SchedulerUI v-if="!loading" :playlists="playlists" />
</template>
<script>
import { setBreadcrumbs } from "@/helpers";
import SchedulerUI from "@/components/_platform/SchedulerUI.vue";
import { useRoute } from "vue-router";
import { useGroupsStore } from "@/stores";
import { storeToRefs } from "pinia";

export default {
  setup() {
    setBreadcrumbs();
    const route = useRoute();

    const groupsStore = useGroupsStore();
    const { group_id } = route.params;

    groupsStore.getGroupAutoCompletePlaylists(group_id);
    const { groups_autocomplete_playlists: playlists, loading_autocomplete: loading } = storeToRefs(groupsStore);

    return {
      loading,
      playlists,
    };
  },
  components: {
    SchedulerUI,
  },
};
</script>
