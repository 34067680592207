import { onMounted, onUnmounted } from "vue";
import { useNavStore } from "@/stores";
export function setBreadcrumbs(page = null) {
  onMounted(() => {
    useNavStore().setBreadcrumb(page);
  });
  // onUnmounted(() => {
  //   useNavStore().resetBreadcrumb();
  // });
}
