import { i18n } from "@/helpers";

let label = { one: "", multiple: "" };
export function getCountTitle({ count, type }) {
  switch (type) {
    case "music":
      label = { one: i18n.global.t("label.track"), multiple: i18n.global.t("label.tracks") };
      break;
    case "slides":
      label = { one: i18n.global.t("label.slide"), multiple: i18n.global.t("label.slides") };
      break;
    case "frames":
      label = { one: i18n.global.t("label.frame"), multiple: i18n.global.t("label.frames") };
      break;
    case "tickers":
      label = { one: i18n.global.t("label.ticker"), multiple: i18n.global.t("label.tickers") };
      break;
  }

  return `${count} ${(count === 1 ? label.one : label.multiple).toLowerCase()}`;
}
