<template>
  <PTextarea type="text" :autoResize="true" :class="['w-full', `p-inputtextarea-${size}`]" />
</template>
<script>
import Textarea from "primevue/textarea";

export default {
  components: {
    PTextarea: Textarea,
  },
  props: {
    size: {
      type: String,
      default: "md",
    },
  },
};
</script>
