<template>
  <div class="view-body">
    <div class="content-wrapper">
      <PageTitle :title="$t('route.admin_impersonate')" />

      <div class="tabview">
        <TabNav
          :items="[
            {
              title: $t('label.customers'),
            },
          ]"
          variant="tab"
        >
          <template #controls>
            <div class="flex justify-content-end gap-5 align-items-center">
              <OpenImpersonateLogin />
              <form id="search" @submit.prevent="onSearch" class="flex align-items-center">
                <FormText
                  type="search"
                  size="lg"
                  :minlength="2"
                  v-model="searchTerm"
                  :placeholder="$t('placeholder.search_by_term')"
                />
                <ButtonUI
                  v-if="term"
                  :label="$t('button.reset')"
                  :underline="true"
                  variant="text"
                  size="lg"
                  color="text-palette-1"
                  @click="resetSearch()"
                />
              </form>
            </div>
          </template>

          <template #panels>
            <div v-if="!loading">
              <TableUI v-if="customers.length > 0" name="users" :value="customers" @rowClick="rowClick">
                <template #columns>
                  <PColumn field="name" :header="$t('label.name')" :style="getCellWidth('name')" />

                  <!-- Render controls column -->
                  <PColumn
                    headerStyle="text-align: right"
                    bodyStyle="text-align: right; overflow: visible"
                    :style="getCellWidth('controls')"
                  >
                    <template #body="slotProps">
                      <div class="controls">
                        <ButtonUI
                          :label="$t('button.details')"
                          variant="text"
                          color="text-palette-1"
                          feather="box"
                          class="p-button-text-inline"
                          :to="{
                            name: 'admin-impersonate-detail',
                            params: { customer_id: slotProps.data.id },
                            query: { ...this.$route.query },
                          }"
                        />
                      </div>
                    </template>
                  </PColumn>
                </template>
              </TableUI>
              <NotificationMessage type="default" v-else>{{ $t("message.no_data_found") }}</NotificationMessage>
            </div>
          </template>
        </TabNav>
      </div>
    </div>
  </div>
</template>

<script>
import TableUI from "@/components/TableUI.vue";
import Column from "primevue/column";
import PageTitle from "@/components/PageTitle.vue";
import TabNav from "@/components/nav/TabNav.vue";
import ButtonUI from "@/components/ButtonUI.vue";
import NotificationMessage from "@/components/NotificationMessage.vue";
import { storeToRefs } from "pinia";
import { useAdminAuthStore, useAdminImpersonateStore, useNavStore } from "@/stores";
import { getPermissions, getTableCellWidth, isNotEmpty, router, setBreadcrumbs } from "@/helpers";
import FormText from "@/components/form/FormText.vue";
import { computed, onMounted, onUnmounted, ref, watch } from "vue";
import { useRoute } from "vue-router";
import OpenImpersonateLogin from "@/components/_admin/OpenImpersonateLogin.vue";

export default {
  setup() {
    setBreadcrumbs();
    const route = useRoute();

    const authStore = useAdminAuthStore();
    authStore.validateMe();

    const impersonateStore = useAdminImpersonateStore();
    const { customers, loading } = storeToRefs(impersonateStore);

    const searchTerm = ref(null);

    const term = computed(() => {
      return route.query.cterm;
    });

    onMounted(() => {
      searchTerm.value = term.value;
    });
    onUnmounted(() => {
      searchTerm.value = null;
    });

    watch(
      term,
      (value) => {
        impersonateStore.getCustomers({ term: value });
      },
      {
        immediate: true,
      }
    );

    return {
      term,
      searchTerm,
      loading,
      customers,
    };
  },
  components: {
    OpenImpersonateLogin,
    FormText,
    TableUI,
    PColumn: Column,
    PageTitle,
    ButtonUI,
    NotificationMessage,
    TabNav,
  },
  methods: {
    getPermissions,
    getCellWidth(cell) {
      return getTableCellWidth("users", cell);
    },
    rowClick(e) {
      router.push({
        name: "admin-impersonate-detail",
        params: { customer_id: e.data.id },
        query: { ...this.$route.query },
      });
    },
    async resetSearch() {
      this.searchTerm = null;
      await router.push({
        query: {
          page: this.$route?.query?.page ?? null,
          cterm: null,
        },
      });
    },
    async onSearch() {
      await router.push({
        query: {
          page: this.$route?.query?.page ?? null,
          cterm: this.searchTerm,
        },
      });
    },
  },
};
</script>
