<template>
  <div>
    <PDialog
      v-model:visible="state"
      :header="
        $t(trackAmount > 1 ? 'button.add_tracks_to_playlist' : 'button.add_track_to_playlist', { amount: trackAmount })
      "
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '540px' }"
      :draggable="false"
      :modal="true"
      v-if="!loading"
    >
      <div class="dialog-content line-height-3" v-if="!loading">
        <NotificationMessage :error="error" />
        <div v-if="showSelect">
          <p v-html="$t('message.select_playlist_to_connect')" class="m-0" />
          <ManageList
            class="mt-8"
            v-model:selected="selectedPlaylist"
            :add="playlistOptions"
            :confirmTitle="$t('button.delete_playlist')"
            :messageNoData="false"
            :placeholder="$t('placeholder.enter_name')"
            :triggerSubmit="triggerSubmit"
            @isReady="optionsAreReady = $event"
          />
        </div>
        <TextUI v-else>
          <span v-html="$t('message.no_playlists_found_select_other_track')"></span>
        </TextUI>
      </div>
      <template #footer>
        <ButtonUI
          size="lg"
          :label="
            $t(trackAmount > 1 ? 'button.add_tracks_to_playlist' : 'button.add_track_to_playlist', {
              amount: trackAmount,
            })
          "
          @click="onSubmit"
          class="w-full"
          v-if="showSelect"
        />
        <ButtonUI
          size="lg"
          :label="$t('button.create_playlist')"
          :to="{ name: 'music-create', query: { ...this.$route.query } }"
          v-if="!showSelect && getPermissions('create playlists')"
        />
      </template>
    </PDialog>
  </div>
</template>
<script>
import Dialog from "primevue/dialog";
import ButtonUI from "@/components/ButtonUI.vue";
import TextUI from "@/components/TextUI.vue";
import ManageList from "@/components/ManageList.vue";
import NotificationMessage from "@/components/NotificationMessage.vue";
import { useMusicStore } from "@/stores";
import { ref, toRefs } from "vue";
import { storeToRefs } from "pinia";
import { getPermissions } from "@/helpers";

export default {
  setup(props) {
    const { trackId } = toRefs(props);

    const trackAmount = ref(0);
    trackAmount.value = Array.isArray(trackId?.value) ? trackId.value.length : 1;

    const musicStore = useMusicStore();
    musicStore.getAvailablePlaylistsByTrack();

    const { playlists_available: playlistOptions, loading_available_playlists: loading } = storeToRefs(musicStore);
    return {
      playlistOptions,
      trackAmount,
      loading,
    };
  },
  components: {
    ButtonUI,
    TextUI,
    ManageList,
    NotificationMessage,
    PDialog: Dialog,
  },
  props: {
    trackId: {
      type: [String, Array],
      default: "",
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      error: {},
      triggerSubmit: false,
      optionsAreReady: false,
      selectedPlaylist: [],
    };
  },
  emits: ["onCancel"],
  methods: {
    getPermissions,
    resetManageList() {
      this.optionsAreReady = false;
    },
    async onSubmit() {
      this.triggerSubmit = true;
      this.resetManageList();
    },
  },
  computed: {
    showSelect() {
      return this.playlistOptions.length > 0 || this.selectedPlaylist.length > 0;
    },
    validatedSubmit() {
      const { triggerSubmit, optionsAreReady } = this;
      return triggerSubmit && optionsAreReady;
    },
    state: {
      get() {
        return this.visible;
      },
      set(state) {
        if (!state) {
          this.$emit("onCancel");
        }
      },
    },
  },
  watch: {
    async validatedSubmit(submit) {
      if (submit && this.selectedPlaylist.length > 0) {
        const musicStore = useMusicStore();
        const data = {
          track_ids: this.trackId,
          playlists: this.selectedPlaylist.map(({ id }) => id),
        };
        try {
          await musicStore.addTrackToPlaylist(data);
          this.error = {};
          this.state = false;
        } catch (error) {
          this.error = error;
        } finally {
          this.triggerSubmit = false;
        }
      } else {
        this.triggerSubmit = false;
      }
    },
  },
  unmounted() {
    this.loading = true;
  },
};
</script>
