<template>
  <div class="view-body">
    <div class="content-wrapper">
      <!--      <PageTitle :title="title" />-->
      <BackUI variant="primary" to="#" :title="title" class="mb-7" @click.prevent="goBack()" />
      <div class="tabview">
        <TabNav :items="tabNavItems">
          <template #controls>
            <span class="flex gap-5" v-if="showControlsSystem">
              <ButtonUI
                v-if="getPermissions('show groups')"
                :disabled="syncGroupState(groups_detail.id)"
                :underline="true"
                variant="text"
                color="text-palette-1"
                size="lg"
                :label="$t('button.sync')"
                @click="syncGroup(groups_detail.id)"
              />
              <ButtonUI
                v-if="getPermissions('delete groups')"
                :underline="true"
                variant="text"
                color="text-palette-1"
                size="lg"
                :label="$t('button.delete_group')"
                @click="onDelete()"
              />
              <ButtonUI @click="onSubmit()" size="lg" :label="$t('button.save_settings')" />
            </span>
            <!--            <ButtonUI size="lg" :label="$t('button.add_media')" v-else />-->
          </template>
          <template #panels>
            <RouterView name="Detail" v-slot="{ Component }">
              <Transition name="page-fade" mode="out-in">
                <component :is="Component" ref="childComponentRef" />
              </Transition>
            </RouterView>
          </template>
        </TabNav>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/components/PageTitle.vue";
import TabNav from "@/components/nav/TabNav.vue";
import ButtonUI from "@/components/ButtonUI.vue";
import BackUI from "@/components/BackUI.vue";

import { storeToRefs } from "pinia";
import { onUnmounted, ref, watch } from "vue";
import { useGroupsStore, useNavStore } from "@/stores";
import { getPermissions, i18n, router, setBreadcrumbs } from "@/helpers";
import { useRoute } from "vue-router";

export default {
  setup() {
    setBreadcrumbs();

    const route = useRoute();
    const { group_id } = route.params;

    const groupsStore = useGroupsStore();
    groupsStore.getGroupDetails(group_id);
    const { groups_detail, groups_state_sync } = storeToRefs(groupsStore);

    const tabNavItems = [
      {
        title: i18n.global.t("button.schedule"),
        feather: "calendar",
        route: "groups-detail-schedule",
        params: route.params,
        permissions: "show group events",
      },
      {
        title: i18n.global.t("label.settings"),
        feather: "settings",
        route: "groups-detail-edit",
        params: route.params,
        permissions: "edit groups",
      },
    ];

    onUnmounted(() => {
      groupsStore.resetGroupDetails();
    });

    const syncGroup = (system_id) => {
      return groupsStore.syncGroup(system_id);
    };

    const syncGroupState = (system_id) => {
      return groupsStore.getSyncGroupState(system_id);
    };

    const childComponentRef = ref(null);

    const onSubmit = () => {
      childComponentRef.value.onSubmit();
    };

    const onDelete = () => {
      childComponentRef.value.onDelete();
    };

    watch(
      groups_state_sync,
      (state) => {
        useNavStore().setAppSpinner(state.length > 0);
      },
      {
        deep: true,
      }
    );

    return {
      groups_detail,
      tabNavItems,
      childComponentRef,
      syncGroup,
      syncGroupState,
      onDelete,
      onSubmit,
    };
  },
  components: {
    // PageTitle,
    TabNav,
    ButtonUI,
    BackUI,
  },
  data() {
    return {
      submit: null,
      showLeaveConfirm: false,
    };
  },
  methods: {
    getPermissions,
    deleteSystem() {
      this.showConfirm = true;
    },
    goBack() {
      router.push({ name: "systems-groups" });
    },
  },
  computed: {
    title() {
      if (this.groups_detail?.page_name) {
        return this.groups_detail.page_name;
      }
      return null;
    },
    routerName() {
      return this.$route?.name;
    },
    showControlsSystem() {
      return ["groups-detail-edit"].includes(this.routerName);
    },
  },
  // watch: {
  //   title(val) {
  //     useNavStore().setBreadcrumbTitle(val, 3);
  //   },
  // },
};
</script>
<style lang="scss" scoped>
.tabview .p-button-underline {
  padding-left: 0 !important;
  padding-right: 0 !important;
}
</style>
