<template>
  <label class="label" v-if="label">
    <slot />
    <span class="required" v-if="required">*</span>
  </label>
  <div class="label" v-else>
    <slot />
    <span class="required" v-if="required">*</span>
  </div>
</template>
<style lang="scss" scoped>
.label {
  color: var(--label-text-color);
}

.required {
  color: red;
  margin-left: spacing(1);
  font-size: small;
}
</style>
<script>
export default {
  props: {
    required: {
      type: Boolean,
    },
    label: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
