import * as Sentry from "@sentry/vue";
import { BrowserTracing } from "@sentry/tracing";
import { useAppStore } from "@/stores/app.store";

const sentry = (app, router) => {
  const { apiUrl } = useAppStore().getAppConfig;
  Sentry.init({
    app,
    trackComponents: true,
    environment: process.env.NODE_ENV,
    dsn: "https://cc3707c73bf74bee84c23d00f73c7301@o323270.ingest.sentry.io/4504315491385344",

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: process.env.NODE_ENV == "development" ? 1.0 : 0.1,

    integrations: [
      new Sentry.Replay(),
      new BrowserTracing({
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
        tracePropagationTargets: ["localhost", /^\//],
      }),
    ],
    enabled: process.env.NODE_ENV != "development",
    // enabled: true,
    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    ignoreErrors: ["ResizeObserver loop limit exceeded", "getKendoToolBar"],
    denyUrls: [/sdc1storage\.bcmserver\.com/i],
    beforeSend(event, hint) {
      // Check if the event is an HTTP request and has a 401 status code
      if (
        event.request &&
        event.request.url &&
        event.request.url.includes(apiUrl) &&
        event.response &&
        event.response.status === 401
      ) {
        // Return null to ignore this event
        return null;
      }
      return event;
    },
  });

  app.config.errorHandler = (err, vm, info) => {
    // Log the error to the console

    console.error("Vue Error:", err, info);
    const exception = err.message || err.originalError || err.error || err;

    const authUser = JSON.parse(localStorage.getItem("auth"));
    const customerProfile = JSON.parse(localStorage.getItem("customer_profile"));

    const authSettings = {
      userID: authUser?.user?.id,
      customerPermissions: customerProfile?.permissions.join("|"),
      customerID: customerProfile?.customer?.id,
      customerName: customerProfile?.customer?.name,
      regionID: customerProfile?.region?.id,
      regionName: customerProfile?.region?.name,
    };

    const config = useAppStore().getAppConfig;
    Sentry.captureException(exception, {
      extra: {
        info,
        url: window.location.href,
        appConfig: config,
        authSettings,
      },
    });
  };
};

export { sentry };
