<template>
  <div class="view-body">
    <div class="content-wrapper">
      <PageTitle :title="$t('title.playlist_settings')" :backUI="true" />
      <TabNav
        :items="[
          {
            title: $t('label.settings'),
            feather: 'settings',
          },
        ]"
        variant="tab"
      >
        <template #controls>
          <ButtonUI @click="onSubmit" size="lg" :label="$t('button.save_settings')" />
        </template>
        <template #panels>
          <div class="grid">
            <div class="col-7">
              <SurfaceCard :title="$t('label.settings')">
                <form @submit.prevent="onSubmit">
                  <div class="card-body">
                    <NotificationMessage :error="error" />

                    <div class="flex">
                      <div class="col-upload flex-shrink-0">
                        <UploadUI :image="form.image" @uploadChange="uploadChange" @error="uploadError" />
                      </div>

                      <div class="col-fields pl-5 flex-grow-1 flex-shrink-1">
                        <FormRow>
                          <FormLabel for="title" required>{{ $t("label.playlist_name") }}:</FormLabel>
                          <FormText
                            id="title"
                            type="text"
                            size="lg"
                            :class="{ 'is-invalid': error.data?.title }"
                            v-model="form.title"
                          />
                          <FormHelp type="invalid" :data="error.data" field="title" />
                        </FormRow>
                        <FormRow class="mb-0">
                          <FormLabel for="description">{{ $t("label.description") }}:</FormLabel>
                          <FormTextarea
                            id="description"
                            type="text"
                            size="lg"
                            :class="{ 'is-invalid': error.data?.description }"
                            v-model="form.description"
                          />
                          <FormHelp type="invalid" :data="error.data" field="description" />
                        </FormRow>
                      </div>
                    </div>
                    <FormHelp type="invalid" :data="error.data" field="image" />

                    <FormRow class="mt-7">
                      <FormLabel for="visibility" required>{{ $t("label.visibility") }}:</FormLabel>
                      <FormSelect
                        id="customers"
                        class="max-w-20rem"
                        size="lg"
                        :class="{ 'is-invalid': error.data?.visibility }"
                        v-model="form.visibility"
                        :options="visibilityOptions"
                        optionLabel="name"
                        :placeholder="$t('placeholder.select_a_visibility')"
                      />
                      <FormHelp type="invalid" :data="error.data" field="visibility" />
                    </FormRow>

                    <FormRow v-show="form.visibility?.value === 'public_restricted'">
                      <ManageList
                        v-model:selected="form.groups"
                        :add="visibilityRestrictedOptions"
                        :confirmTitle="$t('button.delete_public_selected_groups')"
                        :messageNoData="$t('message.no_selected_groups')"
                        :placeholder="$t('placeholder.enter_name')"
                        :triggerSubmit="triggerSubmit"
                        @isReady="restrictedOptionsAreReady = $event"
                      >
                        <template #footer>
                          <TextUI v-if="visibilityRestrictedOptions.length == 0" class="mb-5">
                            {{ $t("message.no_data_to_add_found") }}
                          </TextUI>
                          <FormHelp type="invalid" :data="error.data" field="groups" :multipleFields="true" />
                        </template>
                      </ManageList>
                    </FormRow>
                  </div>
                </form>
              </SurfaceCard>
            </div>
            <!--            <div class="col-5" v-show="getPermissions('show tickers categories')">-->
            <!--              <SurfaceCard :title="$t('label.playlist_groups')" class="mb-5">-->
            <!--                <ManageList-->
            <!--                  v-model:selected="form.categories"-->
            <!--                  :add="categories"-->
            <!--                  :limit="1"-->
            <!--                  :badge="$t('label.groups')"-->
            <!--                  :confirmTitle="$t('label.playlist_groups')"-->
            <!--                  :placeholder="$t('placeholder.enter_name')"-->
            <!--                  :triggerSubmit="triggerSubmit"-->
            <!--                  @isReady="categoriesAreReady = $event"-->
            <!--                >-->
            <!--                  <template #footer>-->
            <!--                    <FormHelp type="invalid" :data="error.data" field="admins" :multipleFields="true" />-->
            <!--                  </template>-->
            <!--                </ManageList>-->
            <!--              </SurfaceCard>-->
            <!--            </div>-->
          </div>
        </template>
      </TabNav>
    </div>
  </div>
</template>

<script>
import TabNav from "@/components/nav/TabNav.vue";
import PageTitle from "@/components/PageTitle.vue";
import SurfaceCard from "@/components/SurfaceCard.vue";
import FormRow from "@/components/form/FormRow.vue";
import FormHelp from "@/components/form/FormHelp.vue";
import FormLabel from "@/components/form/FormLabel.vue";
import FormText from "@/components/form/FormText.vue";
import FormTextarea from "@/components/form/FormTextarea.vue";
import FormSelect from "@/components/form/FormSelect.vue";
import ButtonUI from "@/components/ButtonUI.vue";
import UploadUI from "@/components/UploadUI.vue";
import ManageList from "@/components/ManageList.vue";

import { useUsersStore, useTickersStore } from "@/stores";
import { storeToRefs } from "pinia";
import NotificationMessage from "@/components/NotificationMessage.vue";
import { getPermissions, setBreadcrumbs } from "@/helpers";
import TextUI from "@/components/TextUI.vue";

export default {
  setup() {
    setBreadcrumbs();

    const tickersStore = useTickersStore();
    tickersStore.getCategories();

    const usersStore = useUsersStore();
    usersStore.getPublicRestrictedGroups();

    const { categories, visibility_options: visibilityOptions } = storeToRefs(tickersStore);
    const { visibility_restricted_group_options: visibilityRestrictedOptions } = storeToRefs(usersStore);

    return { categories, visibilityOptions, visibilityRestrictedOptions };
  },
  components: {
    TabNav,
    TextUI,
    PageTitle,
    SurfaceCard,
    FormRow,
    FormLabel,
    FormText,
    FormTextarea,
    FormSelect,
    FormHelp,
    ButtonUI,
    NotificationMessage,
    UploadUI,
    ManageList,
  },
  data() {
    return {
      triggerSubmit: false,
      categoriesAreReady: false,
      restrictedOptionsAreReady: false,
      error: {},
      showConfirm: false,
      state: {},
      form: {
        title: null,
        description: null,
        categories: [],
        groups: [],
        image: null,
      },
    };
  },
  methods: {
    async onSubmit() {
      this.triggerSubmit = true;
      this.resetManageList();
    },
    resetManageList() {
      this.categoriesAreReady = false;
      this.restrictedOptionsAreReady = false;
    },
    uploadChange(image) {
      this.form.image = image;
    },
    uploadError(error) {
      this.error = {};
      if (error.length > 0) {
        this.error = { message: error[0], data: { upload: [error] } };
        this.form.image = "";
      }
    },
    getPermissions,
  },
  computed: {
    validatedSubmit() {
      // const { triggerSubmit, categoriesAreReady, restrictedOptionsAreReady } = this;
      // return triggerSubmit && categoriesAreReady && restrictedOptionsAreReady;
      const { triggerSubmit, restrictedOptionsAreReady } = this;
      return triggerSubmit && restrictedOptionsAreReady;
    },
  },
  mounted() {
    this.form.visibility = this.form.visibility ?? this.visibilityOptions.find((option) => option.value === "private");
  },
  watch: {
    validatedSubmit(submit) {
      if (submit) {
        const tickersStore = useTickersStore();
        return tickersStore.createPlaylist(this.form).catch((error) => {
          this.error = error;
          this.triggerSubmit = false;
          this.resetManageList();
        });
      }
    },
  },
};
</script>
