<template>
  <div class="view-body">
    <div class="content-wrapper">
      <PageTitle :title="$t('route.dashboard')" class="mb-7" />

      <div class="grid">
        <div class="col-12 md:col-6" v-show="DashboardPendingSystemsIsVisible">
          <DashboardPendingSystems @isVisible="(val) => (DashboardPendingSystemsIsVisible = val)" />
        </div>
        <div class="col-12 md:col-6" v-show="DashboardProfileIsVisible">
          <DashboardProfile @isVisible="(val) => (DashboardProfileIsVisible = val)" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useAuthStore } from "@/stores";

import DashboardPendingSystems from "@/components/_platform/dashboard/DashboardPendingSystems.vue";
import DashboardProfile from "@/components/_platform/dashboard/DashboardProfile.vue";
import PageTitle from "@/components/PageTitle.vue";
import { setBreadcrumbs } from "@/helpers";

export default {
  setup() {
    setBreadcrumbs();
    const authStore = useAuthStore();
    authStore.validateMe();
  },
  components: {
    PageTitle,
    DashboardPendingSystems,
    DashboardProfile,
  },
  data() {
    return {
      DashboardPendingSystemsIsVisible: false,
      DashboardProfileIsVisible: false,
    };
  },
};
</script>
