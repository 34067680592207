import { createGtm } from "@gtm-support/vue-gtm";
import { appConfig, router } from "@/helpers";
const { isDev } = appConfig();

export const gtm = createGtm({
  id: "GTM-WF5J8BF6",
  defer: false,
  compatibility: false,
  enabled: true,
  debug: isDev,
  loadScript: true,
  vueRouter: router,
  trackOnNextTick: false,
});
