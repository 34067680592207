<template>
  <div class="view-body">
    <div class="content-wrapper">
      <PageTitle :title="$t('title.music_overview')" />
      <TabNav :items="tabNavItems" :exact="true">
        <template #controls>
          <div class="flex justify-content-end">
            <form id="search" @submit.prevent="onSearch" v-if="getPermissions('search music')">
              <FormText
                type="search"
                size="lg"
                :minlength="2"
                v-model="searchTerm"
                :placeholder="$t('placeholder.search_by_topic')"
              />
              <FormHelp type="invalid" :data="error.data" field="q" />
            </form>
            <Transition name="page-fade" mode="out-in">
              <div v-if="!notFound && showControlsMyPlaylists" class="ml-2">
                <ButtonUI
                  :label="$t('label.playlist_groups')"
                  color="secondary"
                  feather="settings"
                  :to="{ name: 'settings-playlist-groups-music' }"
                  v-if="getPermissions('show music categories')"
                />
                <ButtonUI
                  :label="$t('button.create_playlist')"
                  color="primary"
                  feather="plus"
                  class="ml-2"
                  :to="{ name: 'music-create', query: { ...this.$route.query } }"
                  v-if="getPermissions('create playlists')"
                />
              </div>
            </Transition>
          </div>
        </template>
        <template #panels>
          <RouterView name="Music" v-slot="{ Component }" @notFound="notFound = $event">
            <Transition name="page-fade" mode="out-in">
              <component :is="Component" />
            </Transition>
          </RouterView>
        </template>
      </TabNav>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/components/PageTitle.vue";
import TabNav from "@/components/nav/TabNav.vue";
import ButtonUI from "@/components/ButtonUI.vue";
import FormText from "@/components/form/FormText.vue";
import FormHelp from "@/components/form/FormHelp.vue";

import { getPermissions, i18n, router, setBreadcrumbs } from "@/helpers";
import { onUnmounted } from "vue";
import { useMusicStore } from "@/stores";

export default {
  setup() {
    setBreadcrumbs();
    const musicStore = useMusicStore();

    onUnmounted(() => {
      musicStore.resetPlaylists();
    });

    const tabNavItems = [
      {
        title: i18n.global.t("label.user_playlists"),
        route: "music",
        route_match: ["music", "music-user-category-other", "music-user-category"],
        permissions: "show playlists",
      },
      {
        title: i18n.global.t("label.browse_playlists"),
        route: "music-browse",
        route_match: ["music-browse", "music-browse-category"],
        permissions: "show playlists",
      },
      {
        title: i18n.global.t("label.search"),
        route: "music-search",
        route_match: ["music-search", "music-search-playlists", "music-search-tracks"],
        permissions: "search music",
        hidden: true,
      },
    ];

    return {
      tabNavItems,
    };
  },
  data() {
    return {
      notFound: false,
      searchTerm: "",
      error: {},
    };
  },
  components: {
    ButtonUI,
    FormText,
    FormHelp,
    PageTitle,
    TabNav,
  },
  computed: {
    routerName() {
      return this.$route?.name;
    },
    term() {
      const { term } = this.$route.params;
      return term;
    },
    showControlsMyPlaylists() {
      return ["music", "music-user-category-other", "music-user-category"].includes(this.routerName);
    },
    showControlsBrowsePlaylists() {
      return [
        "music-browse",
        "music-browse-category",
        "music-search",
        "music-search-playlists",
        "music-search-tracks",
      ].includes(this.routerName);
    },
  },
  mounted() {
    this.searchTerm = this.term;
  },
  unmounted() {
    this.searchTerm = "";
  },
  methods: {
    async onSearch() {
      const name = ["music-search", "music-search-playlists", "music-search-tracks"].includes(this.routerName)
        ? null
        : "music-search";

      await router.push({
        name,
        params: {
          term: this.searchTerm,
        },
      });
    },
    getPermissions,
  },
  watch: {
    routerName(name) {
      if (name === "music-search" || name === "music-search-playlists" || name === "music-search-tracks") {
        return;
      }
      this.searchTerm = "";
    },
  },
};
</script>
<style lang="scss" scoped>
#search {
  display: flex;
  justify-content: flex-end;

  @media screen and (min-width: 1200px) and (max-width: 1300px) {
    max-width: 20%;
  }

  @include media-breakpoint-up(xxl) {
    min-width: 390px;
  }

  .p-inputsearch {
    width: 100%;
  }
}
</style>
