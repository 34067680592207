<template>
  <table v-if="list.length > 0">
    <tbody>
      <template v-for="(item, index) in list" :key="index">
        <tr @click="goToRoute(item.id)">
          <td class="name">{{ name(item) }}</td>
          <td class="controls">
            <ButtonUI
              :label="this.type === 'admin-users' ? $t('button.settings') : $t('button.details')"
              variant="text"
              :feather="this.type === 'admin-users' ? 'settings' : 'box'"
              size="md"
              color="text-palette-1"
              class="p-button-text-inline"
            />
          </td>
        </tr>
      </template>
    </tbody>
  </table>
  <TextUI class="no-data-found" v-else>{{ $t("message.no_data_found") }}</TextUI>
</template>
<script>
import ButtonUI from "@/components/ButtonUI.vue";
import { router } from "@/helpers";
import TextUI from "@/components/TextUI.vue";
export default {
  props: {
    type: {
      type: String,
      required: true,
    },
    list: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  components: {
    ButtonUI,
    TextUI,
  },
  methods: {
    name(item) {
      switch (this.type) {
        case "admin-users":
          return `${item.first_name} ${item.last_name}`;
        case "impersonate-users":
          return `${item.name}`;
      }
      return "";
    },
    goToRoute(id) {
      switch (this.type) {
        case "admin-users":
          return router.push({
            name: "admin-users-edit",
            params: { user_id: id },
            query: { ...this.$route.query },
          });
        case "impersonate-users":
          return router.push({
            name: "admin-impersonate-detail",
            params: { customer_id: id },
            query: { ...this.$route.query },
          });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.no-data-found {
  padding: spacing(6);
}

table {
  width: 100%;
  border-spacing: 0;

  tr {
    td {
      padding: spacing(2) spacing(6);
      border-bottom: 1px solid var(--list-border-color);
      transition: 0.2s all;
      font-weight: 500;
      font-size: font-size("base");

      &.name {
        font-weight: bold;
      }

      &.controls {
        text-align: right;
      }
    }

    &:hover {
      td {
        background: var(--list-item-active-ground);
      }
    }
  }

  thead {
    tr {
      td {
        font-size: font-size("sm");
        font-weight: normal;
      }
    }
  }

  tbody {
    tr {
      td {
        cursor: pointer;
      }
    }
  }

  @include media-breakpoint-down(xxl) {
    tr {
      td {
        &.group {
          display: none;
        }
      }
    }
  }
}
</style>
