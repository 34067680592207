import LeftSidebar from "@/components/LeftSidebar.vue";
import UsersIndexView from "@/views/_admin/users/IndexView.vue";
import UsersCreateView from "@/views/_admin/users/CreateView.vue";
import UsersEditView from "@/views/_admin/users/EditView.vue";

export const usersRoutes = [
  // Users
  {
    name: "admin-users",
    path: "/admin/users",
    components: {
      LeftSidebar,
      default: UsersIndexView,
    },
    meta: {
      permission: "show admin users",
    },
  },
  {
    name: "admin-users-create",
    path: "/admin/users/create",
    components: {
      LeftSidebar,
      default: UsersCreateView,
    },
    meta: {
      permission: "create admin users",
    },
  },
  {
    name: "admin-users-edit",
    path: "/admin/users/:user_id/edit",
    components: {
      LeftSidebar,
      default: UsersEditView,
    },
    meta: {
      permission: "edit admin users",
    },
  },
];
