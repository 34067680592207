import { i18n } from "@/helpers";

export function customError(error) {
  const status = error?.status ? error.status : error.response?.status;
  const message = error.response?.data.message ? error.response.data.message : error?.message;
  const data = error.response?.data.data ? error.response.data.data : error?.data;

  return {
    status,
    message: status === 500 ? i18n.global.t("message.please_contact_bcm") : message,
    data,
  };
}
