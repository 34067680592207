<template>
  <div id="view" class="login-view">
    <SurfaceCard type="login">
      <PageTitle :title="$t('route.login')" class="mb-5 text-3xl" />

      <form @submit.prevent="onSubmit">
        <NotificationMessage :error="error" />
        <FormRow>
          <FormLabel for="username">{{ $t("label.email") }}:</FormLabel>
          <FormText
            id="username"
            autocomplete="username"
            type="email"
            size="lg"
            :class="{ 'is-invalid': error.data?.email }"
            v-model="form.email"
            data-lpignore="true"
            tabindex="1"
          />
          <FormHelp type="invalid" :data="error.data" field="email" />
        </FormRow>

        <FormRow>
          <div class="flex">
            <FormLabel for="current-password" class="col-6 p-0 font-medium mb-2">{{ $t("label.password") }}:</FormLabel>
            <div class="col-6 text-right p-0">
              <RouterLink :to="{ name: 'forgot-password' }" class="text-sm" tabindex="6">Forgot password?</RouterLink>
            </div>
          </div>
          <FormText
            inputId="current-password"
            :inputProps="{
              autocomplete: 'current-password',
            }"
            type="password"
            size="lg"
            :class="{ 'is-invalid': error.data?.password }"
            v-model="form.password"
            tabindex="2"
          />
          <FormHelp type="invalid" :data="error.data" field="password" />
        </FormRow>

        <FormRow type="checkbox" class="mb-5">
          <FormCheckbox
            inputId="remember_me"
            name="remember_me"
            value="1"
            :binary="true"
            v-model="form.remember_me"
            tabindex="3"
          />
          <FormLabel for="remember_me" class="font-medium">{{ $t("label.remember_me") }}</FormLabel>
        </FormRow>

        <div class="field mb-0">
          <ButtonUI
            type="submit"
            size="xl"
            class="w-full justify-content-center"
            :label="$t('button.login')"
            :disabled="isSubmitting"
            tabindex="4"
          />
        </div>

        <hr />

        <div>
          <ButtonUI
            size="xl"
            :to="{ name: 'register' }"
            color="secondary"
            class="w-full justify-content-center text-base"
            :label="$t('button.register_account')"
            tabindex="5"
          />
        </div>
      </form>
    </SurfaceCard>
    <SupportLink :showSuperAdminLink="true" />
  </div>
</template>

<script>
import { useAuthStore } from "@/stores";

import PageTitle from "@/components/PageTitle.vue";
import FormRow from "@/components/form/FormRow.vue";
import FormText from "@/components/form/FormText.vue";
import ButtonUI from "@/components/ButtonUI.vue";
import FormCheckbox from "@/components/form/FormCheckbox.vue";
import NotificationMessage from "@/components/NotificationMessage.vue";
import FormLabel from "@/components/form/FormLabel.vue";
import SurfaceCard from "@/components/SurfaceCard.vue";
import { router } from "@/helpers";
import SupportLink from "@/components/SupportLink.vue";
import FormHelp from "@/components/form/FormHelp.vue";

export default {
  setup() {
    const authStore = useAuthStore();
    if (authStore.loggedIn && authStore.hasCustomerProfile) {
      router.push({ name: "dashboard" });
    } else if (authStore.customerHasRegions()) {
      router.push({ name: "login-region" });
    }
  },
  components: {
    FormHelp,
    SupportLink,
    PageTitle,
    FormRow,
    ButtonUI,
    FormText,
    FormCheckbox,
    NotificationMessage,
    FormLabel,
    SurfaceCard,
  },
  data() {
    return {
      error: {},
      form: {
        remember_me: false,
      },
      isSubmitting: false,
    };
  },
  methods: {
    async onSubmit() {
      const authStore = useAuthStore();
      const { email, password, remember_me } = this.form;
      return authStore.login(email, password, remember_me).catch((error) => {
        this.error = error;
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.v-toast {
  max-width: 480px;
}

.help {
  position: fixed;
  right: 15px;
  top: 15px;
}
</style>
