<template>
  <RouterLink :to="{ name: isAdmin ? 'admin-dashboard' : 'dashboard' }" class="logo-avatar">
    <div class="avatar mr-3">
      <img :src="getAppImage('logo--sidebar.svg')" :alt="companyName" />
    </div>
    <div class="logo-title line-height-2">
      <div class="title">{{ companyName }}</div>
      <div class="subtitle">{{ appTitle }}</div>
    </div>
  </RouterLink>
</template>
<script>
import { getAppImage, getAppCompany, getAppTitle } from "@/helpers";
import { computed } from "vue";
import { useNavStore } from "@/stores";

export default {
  setup() {
    const companyName = getAppCompany();
    const appTitle = getAppTitle();
    const isAdmin = computed(() => useNavStore().isAdminPage);

    return {
      isAdmin,
      companyName,
      appTitle,
    };
  },
  methods: {
    getAppImage,
  },
};
</script>
<style lang="scss" scoped>
.logo-avatar {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  text-decoration: none;
  color: currentColor;

  .avatar {
    width: 54px;
    height: 54px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    img {
      width: 100%;
    }
  }

  .title {
    font-size: font-size("lg");
    font-weight: bold;
    font-family: var(--font-family-headings);
  }

  .subtitle {
    font-size: font-size("xs");
  }
}
</style>
