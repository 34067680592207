import { defineStore } from "pinia";

export const useBreakpointStore = defineStore({
  id: "breakpoint",
  state: () => ({
    width: 0,
    type: null,
  }),
  getters: {
    currentType(state) {
      return state.type;
    },
    currentWidth(state) {
      return state.width;
    },
  },
  actions: {
    save(value) {
      const { width, type } = value;
      this.width = width;
      this.type = type;
    },
  },
});
