<template>
  <div>
    <div v-if="!loading">
      <NotificationMessage type="default" v-if="notFound">{{ $t("404.text") }}</NotificationMessage>
      <template v-else>
        <BackUI
          variant="primary"
          to="#"
          :title="categories_detail.category.name"
          class="mb-7"
          @click.prevent="goBack()"
        />

        <CategoryList :list="categories_detail.categories" v-if="categories_detail.categories.length > 0" />

        <div class="mt-2" v-if="categories_detail.tickers.length > 0">
          <hr v-if="categories_detail.categories.length > 0" class="lg:mb-8" />
          <h2 class="mb-5 text-2xl" v-html="$t('label.tickers')" />
          <TickerList :list="categories_detail.tickers" type="bcm" />
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import BackUI from "@/components/BackUI.vue";
import NotificationMessage from "@/components/NotificationMessage.vue";

import CategoryList from "@/components/_platform/tickers/CategoryList.vue";
import TickerList from "@/components/_platform/tickers/TickerList.vue";

import { getPermissions, router } from "@/helpers";
import { useNavStore, useTickersStore } from "@/stores";
import { storeToRefs } from "pinia";
import { useRoute } from "vue-router";

import { ref, watchEffect } from "vue";

export default {
  setup() {
    const route = useRoute();
    const id = ref(route.params.id); // Create a reactive reference to the route parameter

    const tickersStore = useTickersStore();
    tickersStore.getTickersByCategory(id.value);

    const { categories_detail, loading } = storeToRefs(tickersStore);

    // Watch for changes in the route parameter and update the ref accordingly
    watchEffect(() => {
      id.value = route.params.id;
      if (id.value) {
        tickersStore.getTickersByCategory(id.value);
      }
    });

    return {
      categories_detail,
      loading,
      id,
    };
  },
  emits: ["notFound"],
  components: {
    BackUI,
    CategoryList,
    TickerList,
    NotificationMessage,
  },
  methods: {
    getPermissions,
    goBack() {
      const parents = this.categories_detail?.parents ?? [];
      if (parents.length > 0) {
        const lastParent = parents[parents.length - 1];
        // parent page
        router.push({
          name: "tickers-browse-category",
          params: { id: lastParent.id },
          query: { ...this.$route.query },
        });
      } else {
        // main page
        router.push({ name: "tickers-browse" });
      }
    },
  },
  computed: {
    notFound() {
      const notFound = this.categories_detail?.status && this.categories_detail.status == 404;
      this.$emit("notFound", notFound);
      return notFound;
    },
  },
  watch: {
    categories_detail: {
      handler(category) {
        if (category?.category?.name) {
          const navStore = useNavStore();
          navStore.setBreadcrumb("tickers-user-category");

          // set breadcrumbs for parents
          let breadcrumbIndex = 1;
          const parents = this.categories_detail?.parents ?? [];
          if (parents.length > 0) {
            parents.forEach((item) => {
              useNavStore().setBreadcrumbLink(
                {
                  name: "tickers-browse-category",
                  title: item.name,
                  params: {
                    id: item.id,
                  },
                },
                breadcrumbIndex
              );
              breadcrumbIndex++;
            });
          }
          navStore.setBreadcrumbTitle(category.category.name, breadcrumbIndex);
        }
      },
      immediate: true,
    },
  },
};
</script>
