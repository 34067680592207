import { defineStore } from "pinia";

import { useAuthStore as authStore } from "@/stores/_platform/auth.store";
import { customError, i18n, redirectToPreviousPage, isBase64, orderStringByKey, renameKeys } from "@/helpers";
import { useNotificationStore as notificationStore } from "@/stores/notification.store";
import { useRoute } from "vue-router";
import { useNavStore } from "@/stores/nav.store";
import { useAppStore } from "@/stores/app.store";
import axiosInstance from "@/helpers/axios";

export const useTickersStore = defineStore({
  id: "tickers",
  state: () => ({
    playlists: [],
    playlists_available: [],
    playlists_detail: {},
    categories: [],
    categories_detail: {},
    visibility_options: [
      {
        feather: "globe",
        value: "public",
        name: i18n.global.t("label.public_all_groups"),
      },
      {
        feather: "lock",
        value: "public_restricted",
        name: i18n.global.t("label.public_restricted"),
      },
      {
        feather: "lock",
        value: "private",
        name: i18n.global.t("label.private_only_for_me"),
      },
    ],
    tickers: [],
    tickers_detail: {},
    loading: false,
    loading_available_playlists: false,
  }),
  getters: {},
  actions: {
    getStoreMediaType(find) {
      return this.media_type_options.find((option) => option.value === find);
    },
    async getCategories() {
      const { name } = useRoute();
      this.loading = true;
      useNavStore().setAppSpinner(true);

      let endpoint;
      switch (name) {
        case "tickers-browse":
          endpoint = `tickers/browse`;
          break;
        default:
          endpoint = `settings/tickers/playlist-categories`;
      }

      if (!endpoint) {
        return;
      }

      try {
        const categories = await axiosInstance.get(`/${endpoint}`);

        // update pinia state
        this.categories = orderStringByKey(categories.data.data.categories, "name");
      } catch (error) {
        if ([401, 403].includes(error.response?.status)) {
          authStore().logout(error.response.status);
        } else {
          this.categories = [];
          throw customError(error);
        }
      } finally {
        this.loading = false;
        useNavStore().setAppSpinner(false);
      }
    },
    async getCategoryDetails(category_id) {
      useNavStore().setAppSpinner(true);
      try {
        const category = await axiosInstance.get(`/settings/tickers/playlist-categories/${category_id}`);

        // update pinia state
        this.categories_detail = category.data.data;
        this.categories_detail.page_name = category.data.data.name;
      } catch (error) {
        this.categories_detail = customError(error);
        if ([401, 403].includes(error.response?.status)) {
          authStore().logout(error.response.status);
        } else {
          notificationStore().saveMessage(error);
        }
      } finally {
        useNavStore().setAppSpinner(false);
      }
    },
    async createCategory(formData) {
      let { id, name, image_path } = formData;
      useNavStore().setAppSpinner(true);

      let newData = {
        id,
        name,
      };

      if (image_path && isBase64(image_path)) {
        // merge new image with data
        newData = {
          ...newData,
          image: image_path,
        };
      }

      try {
        const category = await axiosInstance.post(`/settings/tickers/playlist-categories`, newData);

        notificationStore().saveAlert(category.data.message);
        await redirectToPreviousPage("settings-playlist-groups-tickers");
      } catch (error) {
        if ([401, 403].includes(error.response?.status)) {
          authStore().logout(error.response.status);
        } else {
          throw customError(error);
        }
      } finally {
        useNavStore().setAppSpinner(false);
      }
    },
    async updateCategory(formData) {
      let { id, name, image_path } = formData;
      useNavStore().setAppSpinner(true);

      let newData = {
        id,
        name,
      };

      if (image_path && isBase64(image_path)) {
        // merge new image with data
        newData = {
          ...newData,
          image: image_path,
        };
      }

      try {
        const category = await axiosInstance.put(`/settings/tickers/playlist-categories/${id}`, newData);

        notificationStore().saveAlert(category.data.message);
        await redirectToPreviousPage("settings-playlist-groups-tickers");
      } catch (error) {
        if ([401, 403].includes(error.response?.status)) {
          authStore().logout(error.response.status);
        } else {
          throw customError(error);
        }
      } finally {
        useNavStore().setAppSpinner(false);
      }
    },
    async deleteCategory(category_id) {
      useNavStore().setAppSpinner(true);
      try {
        const category = await axiosInstance.delete(`/settings/tickers/playlist-categories/${category_id}`);

        notificationStore().saveAlert(category.data.message);
        await redirectToPreviousPage("settings-playlist-groups-tickers");
      } catch (error) {
        if ([401, 403].includes(error.response?.status)) {
          authStore().logout(error.response.status);
        } else {
          throw customError(error);
        }
      } finally {
        useNavStore().setAppSpinner(false);
      }
    },
    async updateTickersOrder(playlist_id, tickers) {
      if (!playlist_id && !tickers) {
        return;
      }
      await axiosInstance.post(`/tickers/my-playlists/${playlist_id}/tickers/reorder`, {
        tickers,
      });
    },
    resetCategories() {
      this.categories = [];
    },
    resetCategoryDetails() {
      this.categories_detail = {};
    },
    resetPlaylistsDetail() {
      this.playlists_detail = {};
    },
    resetPlaylists() {
      this.playlists = [];
    },
    async getPlaylists() {
      this.loading = true;
      useNavStore().setAppSpinner(true);

      try {
        const playlists = await axiosInstance.get(`/tickers/my-playlists`);

        // update pinia state
        this.playlists = playlists.data.data;
      } catch (error) {
        if ([401, 403].includes(error.response?.status)) {
          authStore().logout(error.response.status);
        }
        this.playlists = customError(error);
        throw this.playlists;
      } finally {
        this.loading = false;
        useNavStore().setAppSpinner(false);
      }
    },
    async getTickersByCategory(category_id) {
      this.loading = true;
      useNavStore().setAppSpinner(true);

      try {
        const categories = await axiosInstance.get(`/tickers/browse/${category_id}`);

        // update pinia state
        this.categories_detail = categories.data.data;
      } catch (error) {
        this.categories_detail = customError(error);
        if ([401, 403].includes(error.response?.status)) {
          authStore().logout(error.response.status);
        }
      } finally {
        this.loading = false;
        useNavStore().setAppSpinner(false);
      }
    },
    async getPlaylistsByCategory(category_id) {
      const { name } = useRoute();

      let endpoint;
      switch (name) {
        case "tickers-browse-category":
          endpoint = `browse/${category_id}`;
          break;
        case "tickers-my-category":
          endpoint = `my-playlists/category/${category_id}`;
          break;
        case "tickers-my-category-other":
          endpoint = `my-playlists/other`;
          break;
      }

      if (!endpoint) {
        return;
      }

      this.loading = true;
      useNavStore().setAppSpinner(true);

      try {
        const playlists = await axiosInstance.get(`/tickers/${endpoint}`);

        // update pinia state
        this.playlists = playlists.data.data;
      } catch (error) {
        if ([401, 403].includes(error.response?.status)) {
          authStore().logout(error.response.status);
        }
        this.playlists = customError(error);
        throw this.playlists;
      } finally {
        this.loading = false;
        useNavStore().setAppSpinner(false);
      }
    },
    // -------------------------
    // Playlists detail
    // -------------------------
    async getPlaylistsDetail(data) {
      const { playlist_id, category_id } = data;

      let endpoint;
      if (playlist_id) {
        endpoint = `my-playlists/${playlist_id}`;
        if (category_id) {
          endpoint = `browse/${category_id}/playlist/${playlist_id}`;
        }
      }

      if (!endpoint) {
        this.playlists_detail.status = "404";
        return;
      }

      this.loading = true;
      useNavStore().setAppSpinner(true);

      try {
        const playlist = await axiosInstance.get(`/tickers/${endpoint}`);

        // update pinia state
        this.playlists_detail = playlist.data.data;

        // rename old image_path key to image
        if (this.playlists_detail?.image_path) {
          delete Object.assign(this.playlists_detail, { image: this.playlists_detail.image_path })["image_path"];
        }

        const visibility = this.visibility_options.find((item) => item.value === this.playlists_detail.visibility);
        this.playlists_detail.visibility = visibility;
      } catch (error) {
        this.playlists_detail = customError(error);
        if ([401, 403].includes(error.response?.status)) {
          authStore().logout(error.response.status);
        }
      } finally {
        this.loading = false;
        useNavStore().setAppSpinner(false);
      }
    },

    async updatePlaylist(formData) {
      let { id, title, description, categories, visibility, groups, image } = formData;
      useNavStore().setAppSpinner(true);
      let newData = {
        id,
        title,
        description,
        categories: categories.map(({ id }) => id), // rewrite object to array with ids
        visibility: "",
        groups: [],
      };

      if ((image && isBase64(image)) || image === null) {
        // merge new image with data
        newData = {
          ...newData,
          image: image,
        };
      }

      if (visibility?.value) {
        // merge visibility with data
        newData = {
          ...newData,
          visibility: visibility.value, // rewrite visibility with only value
        };

        if (visibility.value === "public_restricted") {
          newData = {
            ...newData,
            groups: groups.map(({ id }) => id), // rewrite object to array with ids
          };
        }
      }

      try {
        const playlist = await axiosInstance.put(`/tickers/my-playlists/${id}`, newData);

        // reset visibility restricted groups
        if (visibility.value != "public_restricted") {
          this.playlists_detail.groups = [];
        }

        notificationStore().saveAlert(playlist.data.message);
      } catch (error) {
        if ([401, 403].includes(error.response?.status)) {
          authStore().logout(error.response.status);
        } else {
          throw customError(error);
        }
      } finally {
        useNavStore().setAppSpinner(false);
      }
    },
    async deletePlaylist(playlist_id) {
      useNavStore().setAppSpinner(true);
      try {
        const playlist = await axiosInstance.delete(`/tickers/my-playlists/${playlist_id}`);

        notificationStore().saveAlert(playlist.data.message);
        await redirectToPreviousPage("tickers");
      } catch (error) {
        if ([401, 403].includes(error.response?.status)) {
          authStore().logout(error.response.status);
        } else {
          throw customError(error);
        }
      } finally {
        useNavStore().setAppSpinner(false);
      }
    },
    async createPlaylist(formData) {
      let { title, description, categories, visibility, groups, image } = formData;
      useNavStore().setAppSpinner(true);
      let newData = {
        title,
        description,
        categories: categories.map(({ id }) => id), // rewrite object to array with ids
        visibility: "",
        groups: [],
      };

      if ((image && isBase64(image)) || image === null) {
        // merge new image with data
        newData = {
          ...newData,
          image: image,
        };
      }

      if (visibility?.value) {
        // merge visibility with data
        newData = {
          ...newData,
          visibility: visibility.value, // rewrite visibility with only value
        };

        if (visibility.value === "public_restricted") {
          newData = {
            ...newData,
            groups: groups.map(({ id }) => id), // rewrite object to array with ids
          };
        }
      }

      try {
        const user = await axiosInstance.post(`/tickers/my-playlists`, newData);

        notificationStore().saveAlert(user.data.message);
        await redirectToPreviousPage("tickers");
      } catch (error) {
        if ([401, 403].includes(error.response?.status)) {
          authStore().logout(error.response.status);
        } else {
          throw customError(error);
        }
      } finally {
        useNavStore().setAppSpinner(false);
      }
    },
    async duplicatePlaylist(formData) {
      let { title, playlist_id } = formData;
      useNavStore().setAppSpinner(true);

      try {
        const playlist = await axiosInstance.post(`/tickers/my-playlists/${playlist_id}/duplicate`, { title });

        notificationStore().saveAlert(playlist.data.message);
        await redirectToPreviousPage("tickers");
      } catch (error) {
        if ([401, 403].includes(error.response?.status)) {
          authStore().logout(error.response.status);
        } else {
          throw customError(error);
        }
      } finally {
        useNavStore().setAppSpinner(false);
      }
    },
    // -------------------------
    // Playlists overview
    // -------------------------
    async getAvailablePlaylistsByTicker(ticker_id = null) {
      this.loading_available_playlists = true;
      useNavStore().setAppSpinner(true);

      const endpoint = ticker_id
        ? `tickers/my-playlists/ticker/${ticker_id}/available`
        : `tickers/my-tickers/playlists`;

      try {
        const playlists = await axiosInstance.get(`/${endpoint}`);

        // update pinia state
        this.playlists_available = playlists.data.data;
        this.playlists_available = this.playlists_available.map((obj) => renameKeys(obj, { title: "name" })); // rename title to name
      } catch (error) {
        if ([401, 403].includes(error.response?.status)) {
          authStore().logout(error.response.status);
        } else {
          throw customError(error);
        }
      } finally {
        this.loading_available_playlists = false;
        useNavStore().setAppSpinner(false);
      }
    },
    async addTickerToPlaylist(data) {
      useNavStore().setAppSpinner(true);

      try {
        const tickers = await axiosInstance.post(`/tickers/my-playlists/add-ticker`, data);

        notificationStore().saveAlert(i18n.global.t("message.ticker_added_to_playlist"));
      } catch (error) {
        if ([401, 403].includes(error.response?.status)) {
          authStore().logout(error.response.status);
        } else {
          throw customError(error);
        }
      } finally {
        useNavStore().setAppSpinner(false);
      }
    },
    async removeTickerFromPlaylist(data) {
      const { playlist_id, ticker_id } = data;
      useNavStore().setAppSpinner(true);

      try {
        await axiosInstance.delete(`/tickers/my-playlists/${playlist_id}/tickers/${ticker_id}`);

        // remove ticker in item_count
        if (this.playlists_detail?.item_count) {
          this.playlists_detail.item_count = this.playlists_detail.item_count - 1;
        }

        // remove ticker from list
        const tickers = this.playlists_detail.tickers.filter((item) => item.id !== ticker_id);
        this.playlists_detail.tickers = tickers;

        notificationStore().saveAlert(i18n.global.t("message.ticker_removed_from_playlist"));
      } catch (error) {
        if ([401, 403].includes(error.response?.status)) {
          authStore().logout(error.response.status);
        } else {
          throw customError(error);
        }
      } finally {
        useNavStore().setAppSpinner(false);
      }
    },
    async getMyTickers() {
      const { name } = useRoute();
      let endpoint;
      switch (name) {
        case "user-tickers":
          endpoint = `my-tickers`;
          break;
        case "tickers-browse":
          endpoint = `browse`;
          break;
      }

      if (!endpoint) {
        return;
      }

      this.loading = true;
      useNavStore().setAppSpinner(true);

      try {
        const tickers = await axiosInstance.get(`/tickers/${endpoint}`);

        // update pinia state
        this.tickers = tickers.data.data;
      } catch (error) {
        if ([401, 403].includes(error.response?.status)) {
          authStore().logout(error.response.status);
        } else {
          throw customError(error);
        }
      } finally {
        this.loading = false;
        useNavStore().setAppSpinner(false);
      }
    },
    async updateSlidesOrder(playlist_id, tickers) {
      if (!playlist_id && !tickers) {
        return;
      }
      await axiosInstance.post(`/tickers/my-playlists/${playlist_id}/tickers/reorder`, {
        tickers,
      });
    },
    async createMyTickers(formData) {
      formData = { ...formData };

      let { static_text, type, amount } = formData;
      useNavStore().setAppSpinner(true);

      const requiredMessage = i18n.global.t("message.field_required");

      formData.ticker_type = Number(type);
      const staticTextLength = static_text.length;

      // set amount
      if (formData.ticker_type == 2) {
        amount = staticTextLength;
        amount = amount == 0 ? 1 : amount;
      }

      // rewrite object to array with ids
      formData.playlists = formData.playlists.map(({ id }) => id);

      try {
        const tickers = await axiosInstance.post(`/tickers/my-tickers`, formData);

        notificationStore().saveAlert(tickers.data.message);
        await redirectToPreviousPage("user-tickers");
      } catch (error) {
        if ([401, 403].includes(error.response?.status)) {
          authStore().logout(error.response.status);
        } else {
          throw customError(error);
        }
      } finally {
        useNavStore().setAppSpinner(false);
      }
    },
    async updateMyTicker(formData) {
      formData = { ...formData };
      let { id } = formData;

      useNavStore().setAppSpinner(true);
      formData.ticker_type = Number(formData.type);

      if (formData.ticker_type == 2) {
        formData.amount = formData.static_text.length;
        formData.amount = formData.amount == 0 ? 1 : formData.amount;
      } else {
        formData.static_text = [];
      }

      // rewrite object to array with ids
      formData.playlists = formData.playlists.map(({ id }) => id);

      try {
        const tickers = await axiosInstance.put(`/tickers/my-tickers/${id}`, formData);

        notificationStore().saveAlert(tickers.data.message);
        await redirectToPreviousPage("user-tickers");
      } catch (error) {
        if ([401, 403].includes(error.response?.status)) {
          authStore().logout();
        }
        throw customError(error);
      } finally {
        useNavStore().setAppSpinner(false);
      }
    },
    async deleteMyTicker(ticker_id) {
      useNavStore().setAppSpinner(true);
      try {
        const tickers = await axiosInstance.delete(`/tickers/my-tickers/${ticker_id}`);

        notificationStore().saveAlert(tickers.data.message);
        await redirectToPreviousPage("user-tickers");
      } catch (error) {
        if ([401, 403].includes(error.response?.status)) {
          authStore().logout();
        } else {
          throw customError(error);
        }
      } finally {
        useNavStore().setAppSpinner(false);
      }
    },
    // -------------------------
    // Playlists detail
    // -------------------------
    async getMyTickersDetail(ticker_id, category_id = null) {
      const { name } = useRoute();

      let endpoint;
      switch (name) {
        case "tickers-user-tickers-edit":
          endpoint = `my-tickers/${ticker_id}`;
          break;
      }

      if (!endpoint) {
        this.tickers_detail.status = "404";
        return;
      }

      this.loading = true;
      useNavStore().setAppSpinner(true);

      try {
        const tickers = await axiosInstance.get(`/tickers/${endpoint}`);

        // update pinia state
        this.tickers_detail = tickers.data.data;
        this.tickers_detail.type = this.tickers_detail.ticker_type.toString();

        if (this.tickers_detail.type == 2) {
          this.tickers_detail.static_text = this.tickers_detail.static_text.map(({ text }) => text);
        }
      } catch (error) {
        this.tickers_detail = customError(error);
        if ([401, 403].includes(error.response?.status)) {
          authStore().logout();
        }
      } finally {
        this.loading = false;
        useNavStore().setAppSpinner(false);
      }
    },
  },
});
