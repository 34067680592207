<template>
  <div class="grid" :class="{ 'flex-nowrap': limitByViewport }" v-if="filteredPlaylists.length > 0">
    <div
      v-for="(item, index) in filteredPlaylists"
      :key="index"
      class="col-3 col-card"
      :class="[`col-card--${variant}`]"
    >
      <PlayListCard
        :id="item.id"
        :image="item.image_path"
        :title="item.title"
        :text="item.description"
        :visibility="item.visibility"
        :class="{ 'no-hover no-cursor': variant === 'scheduler' }"
        @onClick="this.variant === 'scheduler' ? null : openPlaylist(item)"
        @onClickImage="openPlaylist(item)"
        @onClickTitle="openPlaylist(item)"
      >
        <template v-if="variant === 'scheduler'" #footer>
          <div class="card-footer">
            <div class="grid text-xs">
              <div v-if="item.item_count > 0" class="col-5" v-html="getCountTitle({ count: item.item_count, type })" />
              <div v-if="item.item_count && type === 'music'" class="col-7">
                {{ `${item.total_playing_time} ${$t("label.total_time").toLowerCase()} ` }}
              </div>
            </div>

            <div class="flex justify-content-between controls">
              <div>
                <ButtonUI @click="selectPlaylist(item)" size="sm" :label="$t('button.select')" />
              </div>
              <div>
                <ButtonUI
                  variant="text"
                  color="text-palette-1"
                  size="sm"
                  class="white-space-nowrap"
                  :label="$t('button.view_playlist')"
                  @click="openPlaylist(item)"
                />
              </div>
            </div>
          </div>
        </template>
      </PlayListCard>
    </div>
    <div class="col-6 sm:col-3 col-card" :class="[`col-card--${variant}`]" v-if="showMoreCol">
      <MoreCard :class="{ 'no-hover': variant === 'scheduler' }" @onShowMoreClick="onShowMoreClick($event)" />
    </div>
  </div>
</template>
<script>
import PlayListCard from "@/components/_platform/playlist/PlayListCard.vue";
import MoreCard from "@/components/_platform/playlist/MoreCard.vue";
import { getCountTitle, router } from "@/helpers";
import { useBreakpointStore } from "@/stores";
import ButtonUI from "@/components/ButtonUI.vue";

export default {
  data() {
    return {
      showMore: false,
      limitByTotal: false,
    };
  },
  props: {
    variant: {
      type: String,
      default: "default",
    },
    total: {
      type: Number,
      default: 0,
    },
    limit: {
      type: Number,
      default: 0,
    },
    type: {
      type: String,
      default: "music",
    },
    list: {
      type: Array,
    },
    limitByViewport: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["onShowMoreClick", "showMore", "openPlaylist", "onSelectPlaylist"],
  components: { ButtonUI, PlayListCard, MoreCard },
  methods: {
    getCountTitle,
    setShowMore(val) {
      this.showMore = val;
      this.limitCheck();
    },
    onShowMoreClick() {
      this.$emit("onShowMoreClick");
    },
    limitCheck() {
      if (this.limit > 0 && this.total > 0 && this.total > this.limit) {
        this.showMore = true;
        this.limitByTotal = true;
      }
    },
    openPlaylist(item) {
      this.$emit("openPlaylist", item);

      if (this.variant === "scheduler") {
        return;
      }

      let name, params;
      switch (this.$route.name) {
        case "music-browse-category":
        case "music-search":
        case "music-search-playlists":
          name = "music-browse-detail";
          params = { playlist_id: item.id, category_id: item?.category ? item.category.id : this.$route.params.id };
          break;
        case "slides":
        case "slides-user-category-other":
          name = "slides-detail";
          params = { playlist_id: item.id };
          break;
        case "slides-browse-category":
          name = "slides-browse-detail";
          params = { playlist_id: item.id, category_id: item?.category ? item.category.id : this.$route.params.id };
          break;
        case "frames":
        case "frames-user-category-other":
          name = "frames-detail";
          params = { playlist_id: item.id };
          break;
        case "frames-browse-category":
          name = "frames-browse-detail";
          params = { playlist_id: item.id, category_id: item?.category ? item.category.id : this.$route.params.id };
          break;
        case "tickers":
        case "tickers-user-category-other":
          name = "tickers-detail";
          params = { playlist_id: item.id };
          break;
        case "tickers-browse-category":
          name = "slides-browse-detail";
          params = { playlist_id: item.id, category_id: item?.category ? item.category.id : this.$route.params.id };
          break;
        default:
          name = "music-detail";
          params = { playlist_id: item.id };
      }

      router.push({
        name,
        params,
        query: { ...this.$route.query },
      });
    },
    selectPlaylist(item) {
      this.$emit("onSelectPlaylist", item);
    },
  },
  computed: {
    showMoreCol() {
      return (this.limitByViewport && this.showMore) || (this.limitByTotal && this.showMore);
    },
    filteredPlaylists() {
      if (!Array.isArray(this.list)) {
        return [];
      }

      let filteredItems = 4;
      const viewportWidth = useBreakpointStore().currentWidth;
      if (viewportWidth > 1400) {
        filteredItems = 5;
      }
      if (viewportWidth > 2000) {
        filteredItems = 7;
      }

      if (this.variant === "scheduler") {
        if (viewportWidth > 1024) {
          filteredItems = 5;
        }
        if (viewportWidth > 1600) {
          filteredItems = 6;
        }
      }

      if (this.list?.length > filteredItems && this.limitByViewport) {
        this.setShowMore(true);
        let filteredArray = { ...this.list, length: filteredItems - 1 };
        return Array.from(filteredArray);
      }
      this.setShowMore(false);
      return this.list;
    },
  },
  watch: {
    breakpoint: {
      handler(size) {},
      immediate: true,
    },
    showMoreCol: {
      handler(showMore) {
        this.$emit("showMore", showMore);
      },
      immediate: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.col-card {
  &--default {
    @media (min-width: 1400px) {
      width: 20%;
    }

    @include media-breakpoint-up(xxxl) {
      width: 14.28%;
    }
  }

  &--scheduler {
    @include media-breakpoint-up(lg) {
      width: 20%;
    }

    @include media-breakpoint-up(xxl) {
      width: 16.6667%;
    }
  }
}

.card-footer {
  .controls {
    padding-top: spacing(4);
    margin-top: spacing(4);
    position: relative;
    &:before {
      border-top: 1px solid currentColor;
      content: "";
      opacity: 0.2;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
    }
  }
}
</style>
