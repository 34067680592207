<template>
  <div id="view" class="login-view">
    <SurfaceCard type="login">
      <PageTitle :title="$t('route.admin_login')" class="mb-5 text-3xl" />
      <form @submit.prevent="onSubmit">
        <NotificationMessage :error="error" />

        <div v-if="showVerification">
          <FormRow>
            <FormLabel for="code">{{ $t("label.code") }}:</FormLabel>
            <FormText
              id="code"
              autocomplete="code"
              size="lg"
              :class="{ 'is-invalid': error.data?.code }"
              v-model="form.code"
              data-lpignore="true"
              tabindex="1"
            />
            <FormHelp type="invalid" :data="error.data" field="code" />
          </FormRow>
          <div class="field mb-0 flex">
            <ButtonUI
              type="submit"
              size="xl"
              class="px-7"
              :label="$t('button.verify')"
              :disabled="isSubmitting"
              tabindex="2"
            />
            <ButtonUI
              :underline="true"
              variant="text"
              color="text-palette-1"
              class="px-7"
              size="xl"
              :label="$t('button.send_code_again')"
              @click="resetVerification"
              tabindex="3"
            />
          </div>
        </div>
        <div v-else>
          <FormRow>
            <FormLabel for="username">{{ $t("label.email") }}:</FormLabel>
            <FormText
              id="username"
              autocomplete="username"
              type="email"
              size="lg"
              :class="{ 'is-invalid': error.data?.email }"
              v-model="form.email"
              data-lpignore="true"
              tabindex="1"
            />
            <FormHelp type="invalid" :data="error.data" field="email" />
          </FormRow>

          <FormRow>
            <div class="flex">
              <FormLabel for="current-password" class="col-6 p-0 font-medium mb-2"
                >{{ $t("label.password") }}:</FormLabel
              >
              <div class="col-6 text-right p-0">
                <RouterLink :to="{ name: 'admin-forgot-password' }" class="text-sm">Forgot password?</RouterLink>
              </div>
            </div>
            <FormText
              inputId="current-password"
              :inputProps="{
                autocomplete: 'current-password',
              }"
              type="password"
              size="lg"
              :class="{ 'is-invalid': error.data?.password }"
              v-model="form.password"
              tabindex="2"
            />
            <FormHelp type="invalid" :data="error.data" field="password" />
          </FormRow>

          <FormRow type="checkbox" class="mb-5">
            <FormCheckbox
              inputId="remember_me"
              name="remember_me"
              value="1"
              :binary="true"
              v-model="form.remember_me"
              tabindex="3"
            />
            <FormLabel for="remember_me" class="font-medium">{{ $t("label.remember_me") }}</FormLabel>
          </FormRow>

          <div class="field mb-0">
            <ButtonUI
              type="submit"
              size="xl"
              class="w-full justify-content-center"
              :label="$t('button.login')"
              :disabled="isSubmitting"
              tabindex="4"
            />
          </div>
        </div>
      </form>
      <AdminMode class="admin-mode" />
    </SurfaceCard>
    <SupportLink />
  </div>
</template>

<script>
import { useAdminAuthStore } from "@/stores";

import PageTitle from "@/components/PageTitle.vue";
import FormRow from "@/components/form/FormRow.vue";
import FormText from "@/components/form/FormText.vue";
import ButtonUI from "@/components/ButtonUI.vue";
import FormCheckbox from "@/components/form/FormCheckbox.vue";
import NotificationMessage from "@/components/NotificationMessage.vue";
import FormLabel from "@/components/form/FormLabel.vue";
import SurfaceCard from "@/components/SurfaceCard.vue";
import SupportLink from "@/components/SupportLink.vue";
import { computed } from "vue";
import { isNotEmpty, router } from "@/helpers";
import FormHelp from "@/components/form/FormHelp.vue";
import AdminMode from "@/components/AdminMode.vue";

export default {
  setup() {
    const authStore = useAdminAuthStore();
    const verification = computed(() => {
      return authStore.getVerification;
    });

    const showVerification = computed(() => {
      return isNotEmpty(verification.value);
    });

    if (authStore.loggedIn) {
      router.push({ name: "admin-dashboard" });
    }

    return {
      verification,
      showVerification,
    };
  },
  components: {
    AdminMode,
    FormHelp,
    SupportLink,
    PageTitle,
    FormRow,
    ButtonUI,
    FormText,
    FormCheckbox,
    NotificationMessage,
    FormLabel,
    SurfaceCard,
  },
  data() {
    return {
      error: {},
      form: {
        email: null,
        password: null,
        code: null,
        remember_me: false,
      },
      isSubmitting: false,
    };
  },
  methods: {
    resetVerification() {
      useAdminAuthStore().resetVerification();
    },
    async onSubmit() {
      this.error = {};
      this.isSubmitting = true;

      if (this.showVerification) {
        return useAdminAuthStore()
          .auth2fa(this.form)
          .catch((error) => {
            this.error = error;
          })
          .finally(() => {
            this.isSubmitting = false;
          });
      } else {
        return useAdminAuthStore()
          .login(this.form)
          .catch((error) => {
            this.error = error;
          })
          .finally(() => {
            this.isSubmitting = false;
          });
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.v-toast {
  max-width: 480px;
}

.help {
  position: fixed;
  right: 15px;
  top: 15px;
}
</style>
