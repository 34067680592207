<template>
  <div class="view-body">
    <div class="content-wrapper">
      <PageTitle
        :title="`${this.$route.name === 'systems-groups' ? $t('title.groups_overview') : $t('title.system_overview')}`"
      />
      <TabNav :items="tabNavItems" :exact="true">
        <template #controls>
          <ButtonUI
            :label="$t('button.add_group')"
            color="primary"
            feather="plus"
            :to="{ name: 'groups-create', query: { ...this.$route.query } }"
            v-if="getPermissions('create groups') && this.$route.name === 'systems-groups'"
          />
        </template>
        <template #panels>
          <RouterView name="Systems" v-slot="{ Component }">
            <Transition name="page-fade" mode="out-in">
              <component :is="Component" />
            </Transition>
          </RouterView>
        </template>
      </TabNav>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/components/PageTitle.vue";
import TabNav from "@/components/nav/TabNav.vue";
import ButtonUI from "@/components/ButtonUI.vue";

import { getPermissions, i18n } from "@/helpers";
import { onMounted, onUnmounted, ref, watch } from "vue";
import { useSystemsStore, useGroupsStore } from "@/stores";
import { storeToRefs } from "pinia";

export default {
  setup() {
    const systemsStore = useSystemsStore();

    const { systems_pending: pendingSystems } = storeToRefs(systemsStore);
    let tabNavItems = ref([]);

    onMounted(() => {
      if (getPermissions("show systems")) {
        systemsStore.getPendingSystems();
      }
    });

    const setNavItems = (pendingTotal) => {
      return [
        {
          title: i18n.global.t("label.systems"),
          route: "systems",
          permissions: "show systems",
        },
        {
          title:
            pendingTotal > 0
              ? i18n.global.t("label.pending_total", { total: pendingTotal })
              : i18n.global.t("label.pending"),
          route: "systems-pending",
          permissions: "show systems",
        },
        {
          title: i18n.global.t("label.groups"),
          route: "systems-groups",
          permissions: ["show groups", "create groups"],
        },
      ];
    };

    tabNavItems.value = setNavItems();

    watch(pendingSystems, (systems) => {
      if (systems.length > 0) {
        tabNavItems.value = setNavItems(systems.length);
      }
    });

    return {
      tabNavItems,
    };
  },
  components: {
    PageTitle,
    TabNav,
    ButtonUI,
  },
  methods: {
    getPermissions,
  },
};
</script>
