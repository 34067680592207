<template>
  <div>
    <div v-if="!loading">
      <template v-if="playlists.length > 0">
        <div v-for="(item, index) in playlists" :key="index" class="playlist-group mb-8">
          <PlayLists :list="item.playlists" />
        </div>
      </template>
      <NotificationMessage type="default" v-else>{{ $t("message.no_playlists_found") }}</NotificationMessage>
    </div>
  </div>
</template>

<script>
import PlayLists from "@/components/_platform/playlist/PlayLists.vue";
import NotificationMessage from "@/components/NotificationMessage.vue";

import { getPermissions, setBreadcrumbs } from "@/helpers";
import { useTickersStore } from "@/stores";
import { storeToRefs } from "pinia";

export default {
  setup() {
    const tickersStore = useTickersStore();
    tickersStore.getPlaylists();

    setBreadcrumbs("tickers");

    const { playlists, loading } = storeToRefs(tickersStore);

    return {
      playlists,
      loading,
    };
  },
  components: {
    PlayLists,
    NotificationMessage,
  },
  methods: {
    getPermissions,
  },
};
</script>
