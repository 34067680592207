import LeftSidebar from "@/components/LeftSidebar.vue";
import GroupsCreateView from "@/views/_platform/groups/CreateView.vue";
import GroupsDetailView from "@/views/_platform/groups/DetailView.vue";
import GroupsEditView from "@/views/_platform/groups/partials/EditView.vue";
import GroupsScheduleView from "@/views/_platform/groups/partials/ScheduleView.vue";

export const groupsRoutes = [
  {
    name: "groups-create",
    path: "/systems/groups/create",
    components: {
      LeftSidebar,
      default: GroupsCreateView,
    },
    meta: {
      permission: "create groups",
      supportPageID: "034",
    },
  },
  {
    name: "groups-detail",
    components: {
      LeftSidebar,
      default: GroupsDetailView,
    },
    children: [
      {
        name: "groups-detail-edit",
        path: "/systems/groups/:group_id/edit",
        components: {
          Detail: GroupsEditView,
        },
        meta: {
          permission: "edit groups",
          supportPageID: "011",
        },
      },
      {
        name: "groups-detail-schedule",
        path: "/systems/groups/:group_id/schedule",
        components: {
          Detail: GroupsScheduleView,
        },
        meta: {
          permission: "show group events",
          supportPageID: "010",
        },
      },
    ],
    meta: {
      permission: "edit groups",
    },
  },
];
