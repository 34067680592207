<template>
  <div class="dummy-player">
    <audio-player
      ref="audioPlayer"
      :isLoop="false"
      :audio-list="[audioUrl]"
      theme-color="#ff2929"
      @pause="stopAudio"
      class="player"
    />
  </div>
</template>
<script>
import AudioPlayer from "@liripeng/vue-audio-player";

export default {
  props: {
    data: {
      type: Object,
    },
  },
  emits: ["playingAudioID", "stop"],
  data() {
    return {
      audioUrl: null,
      audioIsPlaying: false,
    };
  },
  components: {
    AudioPlayer,
  },
  computed: {
    routerName() {
      return this.$route?.name;
    },
    audioID: {
      get() {
        return this.data?.id;
      },
      set(id) {
        this.$emit("playingAudioID", id);
      },
    },
  },
  methods: {
    keydownCallback(event) {
      if (event.key === "Escape") {
        this.stopAudio();
      }
    },
    stopAudio() {
      this.audioIsPlaying = false;
      this.audioUrl = null;
      this.audioID = null;
      this.$emit("stop");
    },
    async playAudio(data) {
      const load = () => {
        const _self = this;
        const audio = data?.url;
        const id = data?.id;

        if (_self.audioUrl != audio) {
          _self.audioUrl = audio;
          if (id != null) {
            const request = new XMLHttpRequest();
            request.open("GET", _self.audioUrl);
            request.responseType = "arraybuffer";
            request.onload = function () {
              // setTimeout(function () {
              _self.$refs.audioPlayer.play();
              _self.audioIsPlaying = true;
              _self.audioID = id;
              // }, 150);
            };
            request.send();
          }
        } else if (_self.audioIsPlaying) {
          _self.$refs.audioPlayer.pause();
        }
      };
      load();
    },
  },
  watch: {
    data: {
      handler(val) {
        this.playAudio(val);
      },
    },
    routerName() {
      this.stopAudio();
    },
  },
  mounted() {
    document.addEventListener("keydown", this.keydownCallback);
  },
  unmounted() {
    document.removeEventListener("keydown", this.keydownCallback);
  },
};
</script>
<style lang="scss" scoped>
.dummy-player {
  position: relative;
  height: 0;
  overflow: hidden;

  .player {
    position: absolute;
    top: 100%;
  }
}
</style>
