<template>
  <div class="view-body">
    <div class="content-wrapper">
      <PageTitle :title="$t('label.settings')" />

      <div class="tabview">
        <TabNav :items="tabNavItems">
          <template #panels>
            <RouterView name="Settings" v-slot="{ Component }">
              <Transition name="page-fade" mode="out-in">
                <component :is="Component" />
              </Transition>
            </RouterView>
          </template>
        </TabNav>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/components/PageTitle.vue";
import TabNav from "@/components/nav/TabNav.vue";
import { storeToRefs } from "pinia";
import { useAuthStore, useUsersStore } from "@/stores";
import { getPermissions, i18n, setBreadcrumbs } from "@/helpers";

export default {
  setup() {
    setBreadcrumbs();
    const authStore = useAuthStore();
    authStore.validateMe();

    const tabNavItems = [
      {
        title: i18n.global.t("label.playlist_groups"),
        route: "settings-playlist-groups",
        permissions: [
          "show music categories",
          "show slides categories",
          "show frames categories",
          "show tickers categories",
        ],
      },
      {
        title: "Profile",
        route: "settings-profile",
      },
    ];

    return {
      tabNavItems,
    };
  },
  components: {
    PageTitle,
    TabNav,
  },
  methods: {
    getPermissions,
  },
};
</script>

<style lang="scss" scoped></style>
