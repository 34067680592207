<template>
  <PDialog
    v-model:visible="state"
    :header="$t('button.delete_user')"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :style="{ width: '540px' }"
    :draggable="false"
    :modal="true"
    :dismissableMask="true"
  >
    <div class="confirmation-content line-height-3">
      <div v-if="!isAdmin">
        <p v-html="$t('message.confirmation_migrate_delete_user')" class="mt-0" />
        <hr class="-ml-8 -mr-8 my-8" />
        <FormRow class="mt-8">
          <FormLabel class="mb-3" required>{{ $t("label.what_would_you_want_to_do") }}</FormLabel>
          <div class="flex flex-wrap gap-6">
            <div class="flex align-items-center gap-2">
              <FormRadio inputId="type_1" name="type" value="migrate" color="palette-1" v-model="form.type" />
              <FormLabel for="type_1">{{ $t("button.migrate_user") }}</FormLabel>
            </div>
            <div class="flex align-items-center gap-2">
              <FormRadio inputId="type_2" name="type" value="delete" color="palette-1" v-model="form.type" />
              <FormLabel for="type_2">{{ $t("button.delete_user") }}</FormLabel>
            </div>
          </div>
        </FormRow>
        <FormRow v-if="form?.type === 'migrate'" class="mt-8">
          <FormLabel class="mb-3" required>{{ $t("button.migrate_user") }}:</FormLabel>
          <ManageList
            v-model:selected="selectedMigrateUser"
            :add="migrateUserOptions"
            :showConfirmDialog="false"
            :showAddButton="false"
            :messageNoData="$t('message.no_selected_migrate_users')"
            :placeholder="$t('placeholder.enter_name')"
            :triggerSubmit="triggerSubmit"
            :limit="1"
            @isReady="optionsAreReady = $event"
          />
        </FormRow>
      </div>
      <p v-else v-html="$t('message.confirmation_delete_user')" class="mt-0" />
    </div>
    <template #footer>
      <ButtonUI
        size="lg"
        :label="$t('button.cancel')"
        @click="cancel"
        variant="default"
        color="primary"
        icon="pi pi-times"
      />
      <ButtonUI
        v-if="form.type || isAdmin"
        size="lg"
        :label="form.type === 'migrate' ? $t('button.migrate_user') : $t('button.delete_user')"
        :disabled="form.type === 'migrate' && selectedMigrateUser.length === 0"
        icon="pi pi-check"
        @click="ok"
        autofocus
      />
    </template>
  </PDialog>
</template>
<script>
import Dialog from "primevue/dialog";
import ButtonUI from "@/components/ButtonUI.vue";
import ManageList from "@/components/ManageList.vue";
import FormRadio from "@/components/form/FormRadio.vue";
import FormLabel from "@/components/form/FormLabel.vue";
import FormRow from "@/components/form/FormRow.vue";
import { useNavStore, useUsersStore, useAdminUsersStore } from "@/stores";
import { storeToRefs } from "pinia";
import { computed, ref, watch } from "vue";
import { useRoute } from "vue-router";

export default {
  setup() {
    const route = useRoute();
    const isAdmin = computed(() => useNavStore().isAdminPage);

    const { user_id } = route.params;

    const migrateUserOptions = ref([]);
    const form = ref({ type: null });

    const selectedMigrateUser = ref([]);
    const triggerSubmit = ref(false);
    const optionsAreReady = ref(false);
    const error = ref([]);

    if (!isAdmin.value) {
      const usersStore = useUsersStore();
      usersStore.getMigrateUsers(user_id);
      const { migrate_users } = storeToRefs(usersStore);
      migrateUserOptions.value = migrate_users;
    }

    return {
      selectedMigrateUser,
      triggerSubmit,
      optionsAreReady,
      migrateUserOptions,
      form,
      error,
      user_id,
      isAdmin,
    };
  },
  components: {
    FormRow,
    FormLabel,
    FormRadio,
    ManageList,
    ButtonUI,
    PDialog: Dialog,
  },
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["onCancel", "onDiscard", "onConfirm"],
  methods: {
    cancel() {
      this.$emit("onCancel");
      this.$emit("onDiscard");
    },
    async ok() {
      let migrateUserID = this.selectedMigrateUser ?? [];

      // get first entry and id key
      migrateUserID = migrateUserID[0] ? migrateUserID[0].id : null;
      try {
        if (this.isAdmin) {
          await useAdminUsersStore().deleteOrMigrateUser({
            user_id: this.user_id,
            type: this.form?.type,
            migrate_user_id: migrateUserID,
          });
        } else {
          await useUsersStore().deleteOrMigrateUser({
            user_id: this.user_id,
            type: this.form?.type,
            migrate_user_id: migrateUserID,
          });
        }

        // reset values
        this.form.type = null;
        this.selectedMigrateUser = [];
      } catch (error) {
        this.error = error;
      } finally {
        this.$emit("onConfirm");
      }
    },
  },
  computed: {
    state: {
      get() {
        return this.visible;
      },
      set(state) {
        if (!state) {
          this.$emit("onCancel");
        }
      },
    },
  },
};
</script>
