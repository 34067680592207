<template>
  <span v-if="value === 'online'" class="state state--online"
    ><span>{{ $t("label.online") }}</span></span
  >
  <span v-else-if="value === 'offline'" class="state state--offline"
    ><span>{{ $t("label.offline") }}</span></span
  >
  <span v-else-if="value === 'warning'" class="state state--warning"
    ><span>{{ $t("label.warning") }}</span></span
  >
  <span v-else-if="value === 'pending'" class="state state--pending"
    ><span>{{ $t("label.pending") }}</span></span
  >
  <span v-else class="state"
    ><span>{{ $t("label.loading") }}</span></span
  >
</template>
<script>
export default {
  props: {
    value: {
      type: String,
    },
  },
};
</script>
<style lang="scss" scoped>
.state {
  display: inline-flex;
  align-items: center;
  color: var(--state-loading);
  transition: 0.2s all;

  &:before {
    content: "";
    width: 9px;
    height: 9px;
    border-radius: 50%;
    background: currentColor;
    margin-right: spacing(2);
    transition: 0.2s all;
  }

  &--online {
    color: var(--state-online);
  }

  &--warning {
    color: var(--state-warning);
  }

  &--offline {
    color: var(--state-offline);
  }

  &--pending {
    color: var(--state-pending);
  }
}
</style>
