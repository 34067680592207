import LeftSidebar from "@/components/LeftSidebar.vue";
import MusicIndexView from "@/views/_platform/music/IndexView.vue";
import MusicOverView from "@/views/_platform/music/partials/user-playlists/IndexView.vue";
import MusicBrowseView from "@/views/_platform/music/partials/browse/IndexView.vue";
import MusicSearchView from "@/views/_platform/music/partials/search/IndexView.vue";
import MusicBrowseCategoryView from "@/views/_platform/music/partials/browse/CategoryView.vue";
import MusicCategoryView from "@/views/_platform/music/partials/user-playlists/CategoryView.vue";
import MusicDetailView from "@/views/_platform/music/partials/user-playlists/DetailView.vue";
import MusicBrowseDetailView from "@/views/_platform/music/partials/browse/DetailView.vue";
import MusicCreateView from "@/views/_platform/music/partials/user-playlists/CreateView.vue";

export const musicRoutes = [
  // Music / Playlists
  {
    path: "/music",
    name: "music-index",
    components: {
      LeftSidebar,
      default: MusicIndexView,
    },
    children: [
      {
        path: "/music",
        name: "music",
        components: {
          Music: MusicOverView,
        },
        meta: {
          permission: "search music",
          supportPageID: "019",
        },
      },
      {
        path: "/music/browse",
        name: "music-browse",
        components: {
          Music: MusicBrowseView,
        },
        meta: {
          permission: "search music",
          supportPageID: "020",
        },
      },
      {
        path: "/music/search/:term",
        name: "music-search",
        components: {
          Music: MusicSearchView,
        },
        meta: {
          permission: "search music",
          supportPageID: "019",
        },
      },
      {
        path: "/music/search/:term/playlists",
        name: "music-search-playlists",
        components: {
          Music: MusicSearchView,
        },
        meta: {
          permission: "search music",
          supportPageID: "019",
        },
      },
      {
        path: "/music/search/:term/tracks",
        name: "music-search-tracks",
        components: {
          Music: MusicSearchView,
        },
        meta: {
          permission: "search music",
          supportPageID: "019",
        },
      },
      {
        name: "music-browse-category",
        path: "/music/browse/category/:id",
        components: {
          Music: MusicBrowseCategoryView,
        },
        meta: {
          permission: "search music",
          supportPageID: "020",
        },
      },
      {
        name: "music-user-category",
        path: "/music/category/:id",
        components: {
          Music: MusicCategoryView,
        },
        meta: {
          permission: "search music",
          supportPageID: "019",
        },
      },
      {
        name: "music-user-category-other",
        path: "/music/other",
        components: {
          Music: MusicCategoryView,
        },
        meta: {
          permission: "search music",
          supportPageID: "019",
        },
      },
    ],
  },
  {
    name: "music-detail",
    path: "/music/playlist/:playlist_id/detail",
    components: {
      LeftSidebar,
      default: MusicDetailView,
    },
    meta: {
      permission: "search music",
      supportPageID: "036",
    },
  },
  {
    name: "music-browse-detail",
    path: "/music/browse/:category_id-:playlist_id/detail",
    components: {
      LeftSidebar,
      default: MusicBrowseDetailView,
    },
    meta: {
      permission: "search music",
    },
  },
  {
    name: "music-create",
    path: "/music/playlist/create",
    components: {
      LeftSidebar,
      default: MusicCreateView,
    },
    meta: {
      permission: "create playlists",
      supportPageID: "035",
    },
  },
];
