<template>
  <div class="flex gap-2 mb-4 mt-3" style="color: var(--primary-500)">
    <vue-feather type="alert-triangle" size="18" />
    <span v-html="text" />
  </div>
</template>
<script>
import VueFeather from "vue-feather";

export default {
  props: {
    text: String,
  },
  components: {
    VueFeather,
  },
};
</script>
<style scoped>
.vue-feather {
  overflow: unset;
}
</style>
