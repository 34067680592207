<template>
  <PDialog
    v-model:visible="state"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :draggable="false"
    :modal="true"
    :dismissableMask="true"
    class="dialog-video"
  >
    <template #header>
      <div class="p-dialog-title">{{ title ? title : $t("label.video") }}</div>
    </template>

    <div class="dialog-content line-height-0">
      <video-player v-if="videoOptions" :options="videoOptions" class="player" />
    </div>
  </PDialog>
</template>
<script>
import Dialog from "primevue/dialog";
import { VideoPlayer } from "@videojs-player/vue";
import "video.js/dist/video-js.css";
import { getHeightWithAspectRatio } from "@/helpers";

export default {
  components: {
    PDialog: Dialog,
    VideoPlayer,
  },
  props: {
    data: {
      type: Object,
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    cancel() {
      this.$emit("onCancel");
      this.$emit("onDiscard");
    },
    ok() {
      this.$emit("onConfirm");
    },
  },
  computed: {
    title() {
      if (this.data?.name) {
        return this.data.name;
      } else if (this.data?.title) {
        return this.data.title;
      }
      return null;
    },
    videoOptions() {
      let data = this.data;
      data.type = data?.mime_type ? data.mime_type : data.type;
      if (data?.url) {
        return {
          loop: false,
          autoplay: true,
          controls: true,
          disablePictureInPicture: true,
          volume: 0.6,
          width: 640,
          height: getHeightWithAspectRatio(640),
          sources: [
            {
              src: data.url,
              type: data.type,
            },
          ],
        };
      }
      return null;
    },
    state: {
      get() {
        return this.visible;
      },
      set(state) {
        if (!state) {
          this.$emit("onCancel");
        }
      },
    },
  },
};
</script>
<style lang="scss">
.p-dialog.dialog-video {
  .p-dialog-content {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }
}
</style>
