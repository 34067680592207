<template>
  <div id="view" class="register-view">
    <div class="controls inline-flex gap-4">
      <SupportLink />
      <BackUI feather="x" variant="primary" :to="{ name: 'login' }" />
    </div>
    <SurfaceCard type="register">
      <PageTitle :title="$t('route.register')" class="mb-5 text-3xl" />
      <form @submit.prevent="onSubmit">
        <NotificationMessage :error="error" />

        <fieldset>
          <legend>{{ $t("label.account") }}</legend>
          <FormRow>
            <FormLabel for="first_name" required>{{ $t("label.first_name") }}:</FormLabel>
            <FormText
              id="first_name"
              autocomplete="first_name"
              type="text"
              size="lg"
              :class="{ 'is-invalid': error.data?.first_name }"
              v-model="form.first_name"
            />
            <FormHelp type="invalid" :data="error.data" field="first_name" />
          </FormRow>
          <FormRow>
            <FormLabel for="last_name" required>{{ $t("label.last_name") }}:</FormLabel>
            <FormText
              id="last_name"
              autocomplete="last_name"
              type="text"
              size="lg"
              :class="{ 'is-invalid': error.data?.last_name }"
              v-model="form.last_name"
            />
            <FormHelp type="invalid" :data="error.data" field="last_name" />
          </FormRow>
          <FormRow>
            <FormLabel for="email" required>{{ $t("label.email") }}:</FormLabel>
            <FormText
              id="email"
              autocomplete="email"
              type="email"
              size="lg"
              :class="{ 'is-invalid': error.data?.email }"
              v-model="form.email"
            />
            <FormHelp type="invalid" :data="error.data" field="email" />
          </FormRow>
        </fieldset>
        <fieldset>
          <legend>{{ $t("label.company") }}</legend>
          <FormRow>
            <FormLabel for="customer_name" required>{{ $t("label.name") }}:</FormLabel>
            <FormText
              id="customer_name"
              autocomplete="customer_name"
              type="text"
              size="lg"
              :class="{ 'is-invalid': error.customer?.name }"
              v-model="form.customer.name"
            />
            <FormHelp type="invalid" :data="error.data" field="customer.name" />
          </FormRow>

          <FormRow>
            <FormLabel for="customer_description">{{ $t("label.description") }}:</FormLabel>
            <FormTextarea
              id="customer_description"
              autocomplete="customer_description"
              type="text"
              size="lg"
              :class="{ 'is-invalid': error.customer?.description }"
              v-model="form.customer.description"
            />
            <FormHelp type="invalid" :data="error.data" field="customer.description" />
          </FormRow>

          <FormRow>
            <FormLabel for="customer_location">{{ $t("label.location") }}:</FormLabel>
            <FormText
              id="customer_location"
              autocomplete="customer_location"
              type="text"
              size="lg"
              :class="{ 'is-invalid': error.customer?.location }"
              v-model="form.customer.location"
            />
            <FormHelp type="invalid" :data="error.data" field="customer.location" />
          </FormRow>
        </fieldset>

        <div class="field mb-0">
          <ButtonUI type="submit" size="lg" class="px-7" :label="$t('button.sign_up')" :disabled="isSubmitting" />
          <ButtonUI
            :underline="true"
            variant="text"
            color="text-palette-1"
            class="px-7"
            size="lg"
            :label="$t('button.cancel')"
            :to="{ name: 'login' }"
          />
        </div>
      </form>
    </SurfaceCard>
  </div>
</template>
<script>
import { useAppStore, useAuthStore } from "@/stores";

import PageTitle from "@/components/PageTitle.vue";
import FormRow from "@/components/form/FormRow.vue";
import FormText from "@/components/form/FormText.vue";
import FormTextarea from "@/components/form/FormTextarea.vue";
import FormHelp from "@/components/form/FormHelp.vue";
import ButtonUI from "@/components/ButtonUI.vue";
import NotificationMessage from "@/components/NotificationMessage.vue";
import FormLabel from "@/components/form/FormLabel.vue";
import SurfaceCard from "@/components/SurfaceCard.vue";
import BackUI from "@/components/BackUI.vue";
import SupportLink from "@/components/SupportLink.vue";

export default {
  setup() {},
  components: {
    SupportLink,
    PageTitle,
    FormRow,
    ButtonUI,
    FormText,
    FormTextarea,
    NotificationMessage,
    FormLabel,
    SurfaceCard,
    FormHelp,
    BackUI,
  },
  data() {
    return {
      error: {},
      form: {
        first_name: "",
        last_name: "",
        email: "",
        language: "en-gb",
        customer: {
          provider_id: "",
          name: "",
          description: "",
          location: "",
        },
      },
      isSubmitting: false,
    };
  },
  methods: {
    async onSubmit() {
      const authStore = useAuthStore();
      this.isSubmitting = true;

      let formData = this.form;
      formData.language = this.$i18n.locale;

      const { providerID } = useAppStore().getAppConfig;
      formData.customer.provider_id = providerID;

      try {
        await authStore.register(formData);
      } catch (error) {
        this.error = error;
      } finally {
        this.isSubmitting = false;
      }
    },
  },
};
</script>
