<template>
  <PDialog
    v-model:visible="state"
    :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
    :draggable="false"
    :modal="true"
    :dismissableMask="true"
    class="dialog-video"
    :style="{ width: '640px' }"
  >
    <template #header>
      <div class="p-dialog-title">{{ title ? title : $t("label.image") }}</div>
    </template>

    <div class="dialog-content line-height-0">
      <img :src="url" :alt="title" class="w-full" />
    </div>
  </PDialog>
</template>
<script>
import Dialog from "primevue/dialog";

export default {
  components: {
    PDialog: Dialog,
  },
  props: {
    data: {
      type: Object,
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    cancel() {
      this.$emit("onCancel");
      this.$emit("onDiscard");
    },
    ok() {
      this.$emit("onConfirm");
    },
  },
  computed: {
    title() {
      return this.data?.name ? this.data.name : "";
    },
    url() {
      return this.data?.url ? this.data.url : null;
    },
    state: {
      get() {
        return this.visible;
      },
      set(state) {
        if (!state) {
          this.$emit("onCancel");
        }
      },
    },
  },
};
</script>
<style lang="scss">
.p-dialog.dialog-video {
  .p-dialog-content {
    padding-left: 0;
    padding-right: 0;
    padding-bottom: 0;
  }
}
</style>
