<template>
  <div class="admin-mode">
    <span v-if="label" v-html="label" class="mode-label" :style="labelStyle" />
    <div class="mode-line" :style="backgroundImageStyle"></div>
  </div>
</template>

<script>
import { useAppStore } from "@/stores";

export default {
  props: {
    label: {
      type: String,
      default: "",
    },
  },
  computed: {
    bgColor() {
      const themeIsLoaded = useAppStore().themeIsLoaded;
      return themeIsLoaded ? getComputedStyle(document.documentElement).getPropertyValue(`--admin-mode-ground`) : null;
    },
    color() {
      const themeIsLoaded = useAppStore().themeIsLoaded;
      return themeIsLoaded ? getComputedStyle(document.documentElement).getPropertyValue(`--admin-mode-color`) : null;
    },
    backgroundImageStyle() {
      const svg = `
        <svg xmlns="http://www.w3.org/2000/svg" width="40" height="5" viewBox="0 0 40 5">
          <path fill-rule="evenodd" d="M22.033 0H33l-5 5H7l5-5h10.033Z" fill="${this.bgColor}" />
        </svg>`;
      const encodedSvg = encodeURIComponent(svg);
      return {
        backgroundImage: `url("data:image/svg+xml,${encodedSvg}")`,
      };
    },
    labelStyle() {
      return {
        backgroundColor: this.bgColor,
        color: this.color,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
.admin-mode {
  width: 100%;
}

.mode-label {
  padding: 5px 8px;
  display: inline-block;
  position: absolute;
  right: 0;
  bottom: 4px;
  border-radius: 4px 0 0 0;
  font-size: 0.875rem;
}

.mode-line {
  background-repeat: repeat-x;
  background-position: -24px 0;
  flex: 0 0 4px;
  min-height: 4px;
  width: 100%;
}
</style>
