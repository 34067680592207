<template>
  <div>
    <div v-if="!loading">
      <template v-if="playlists.length > 0">
        <div class="grid">
          <div v-for="(item, index) in playlists" :key="index" class="col-6 sm:col-3 col-card">
            <Category :title="item.name" :image="item.image_path" @onClick="gotoCategory(item)" />
          </div>
        </div>
      </template>
      <NotificationMessage type="default" v-else>{{ $t("message.no_playlists_found") }}</NotificationMessage>
    </div>
  </div>
</template>

<script>
import Category from "@/components/_platform/playlist/CategoryCard.vue";
import NotificationMessage from "@/components/NotificationMessage.vue";

import { getPermissions, router, setBreadcrumbs } from "@/helpers";
import { useMusicStore } from "@/stores";
import { storeToRefs } from "pinia";

export default {
  setup() {
    const musicStore = useMusicStore();
    musicStore.getPlaylists();

    setBreadcrumbs("music");

    const { playlists, loading } = storeToRefs(musicStore);

    return {
      playlists,
      loading,
    };
  },
  components: {
    Category,
    NotificationMessage,
  },
  methods: {
    gotoCategory(item) {
      router.push({
        name: "music-browse-category",
        params: { id: item.id },
        query: { ...this.$route.query },
      });
    },
    getPermissions,
  },
};
</script>
<style lang="scss" scoped>
.col-card {
  @media (min-width: 1400px) {
    width: 20%;
  }

  @include media-breakpoint-up(xxxl) {
    width: 14.28%;
  }
}
</style>
