<template>
  <div>
    <TableUI v-if="groups.length > 0" name="groups" :value="groups">
      <template #columns>
        <PColumn field="name" :header="$t('label.group_name')" :style="getCellWidth('name')" />
        <PColumn field="playlists_count" :header="$t('label.playlists')" :style="getCellWidth('count')" />

        <!-- Render controls column -->
        <PColumn
          headerStyle="text-align: right"
          bodyStyle="text-align: right; overflow: visible"
          :style="getCellWidth('controls')"
        >
          <template #body="slotProps">
            <div class="controls">
              <ButtonUI
                :label="$t('button.settings')"
                variant="text"
                color="text-palette-1"
                feather="settings"
                class="p-button-text-inline"
                v-if="getPermissions('edit music categories')"
                :to="{
                  name: 'settings-playlist-group-frames-edit',
                  params: { category_id: slotProps.data.id },
                  query: { ...this.$route.query },
                }"
              />
            </div>
          </template>
        </PColumn>
      </template>
    </TableUI>
    <NotificationMessage type="default" v-else>{{ $t("message.no_groups_found") }}</NotificationMessage>
  </div>
</template>

<script>
import TableUI from "@/components/TableUI.vue";
import ButtonUI from "@/components/ButtonUI.vue";
import NotificationMessage from "@/components/NotificationMessage.vue";

import Column from "primevue/column";
import { getPermissions, getTableCellWidth } from "@/helpers";

export default {
  props: {
    groups: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  components: {
    TableUI,
    ButtonUI,
    NotificationMessage,
    PColumn: Column,
  },
  methods: {
    getPermissions,
    getCellWidth(cell) {
      return getTableCellWidth("categories", cell);
    },
  },
};
</script>
