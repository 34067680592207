<template>
  <div class="grid">
    <div class="col-7">
      <form @submit.prevent="onSubmitPassword">
        <SurfaceCard :title="$t('label.password')">
          <template #body>
            <div class="card-body">
              <LimitWidth>
                <NotificationMessage :error="error" />
                <FormRow>
                  <FormLabel for="current-password" required>{{ $t("label.current_password") }}:</FormLabel>
                  <FormText
                    inputId="current-password"
                    :inputProps="{
                      autocomplete: 'off',
                    }"
                    type="password"
                    size="lg"
                    :class="{ 'is-invalid': error.data?.current_password }"
                    v-model="form_password.current_password"
                  />
                  <FormHelp type="invalid" :data="error.data" field="current_password" />
                </FormRow>
                <FormRow>
                  <FormLabel for="password" required>{{ $t("label.new_password") }}:</FormLabel>
                  <FormText
                    inputId="password"
                    :inputProps="{
                      autocomplete: 'off',
                    }"
                    type="password"
                    size="lg"
                    :class="{ 'is-invalid': error.data?.password }"
                    v-model="form_password.password"
                  />
                  <FormHelp type="invalid" :data="error.data" field="password" />
                </FormRow>

                <FormRow>
                  <FormLabel for="confirm-password" required>{{ $t("label.confirm_new_password") }}:</FormLabel>
                  <FormText
                    inputId="confirm-password"
                    :inputProps="{
                      autocomplete: 'off',
                    }"
                    type="password"
                    size="lg"
                    :class="{ 'is-invalid': error.data?.password_confirmation }"
                    v-model="form_password.password_confirmation"
                  />
                  <FormHelp type="invalid" :data="error.data" field="password_confirmation" />
                </FormRow>
              </LimitWidth>
            </div>
          </template>
          <template #footer>
            <div class="card-footer">
              <ButtonUI type="submit" size="lg" :label="$t('button.save_password')" />
            </div>
          </template>
        </SurfaceCard>
      </form>
    </div>
  </div>
</template>
<script>
import SurfaceCard from "@/components/SurfaceCard.vue";
import FormRow from "@/components/form/FormRow.vue";
import FormHelp from "@/components/form/FormHelp.vue";
import FormLabel from "@/components/form/FormLabel.vue";
import FormText from "@/components/form/FormText.vue";
import ButtonUI from "@/components/ButtonUI.vue";
import LimitWidth from "@/components/LimitWidth.vue";
import NotificationMessage from "@/components/NotificationMessage.vue";

import { useAuthStore } from "@/stores";
import { getPermissions, setBreadcrumbs } from "@/helpers";
import { ref } from "vue";

export default {
  setup() {
    setBreadcrumbs();
    const authStore = useAuthStore();
    authStore.validateMe();
    const form_password = ref({});
    return { form_password };
  },
  components: {
    SurfaceCard,
    FormRow,
    FormLabel,
    FormText,
    FormHelp,
    ButtonUI,
    LimitWidth,
    NotificationMessage,
  },
  data() {
    return {
      error: {},
      state: {},
      page_name: null,
      timeout: false,
      showConfirm: false,
    };
  },
  methods: {
    async onSubmitPassword() {
      this.error = [];
      const authStore = useAuthStore();

      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        return authStore.updateUserPassword(this.form_password).catch((error) => {
          this.error = error;
        });
      }, 500);
    },
    getPermissions,
  },
  computed: {
    notFound() {
      return this.form && this.form?.status == 404;
    },
  },
};
</script>

<style lang="scss" scoped></style>
