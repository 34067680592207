import { defineStore } from "pinia";

import { useAuthStore as authStore } from "@/stores/_platform/auth.store";
import { customError, i18n, redirectToPreviousPage, isBase64, orderStringByKey, renameKeys } from "@/helpers";
import { useNotificationStore as notificationStore } from "@/stores/notification.store";
import { useRoute } from "vue-router";
import { useNavStore } from "@/stores/nav.store";
import { useUsersStore } from "@/stores/_platform/users.store";
import { useAppStore } from "@/stores/app.store";
import axiosInstance from "@/helpers/axios";

export const useFramesStore = defineStore({
  id: "frames",
  state: () => ({
    playlists: [],
    playlists_available: [],
    playlists_detail: {},
    categories: [],
    categories_detail: {},
    visibility_options: [
      {
        feather: "globe",
        value: "public",
        name: i18n.global.t("label.public_all_groups"),
      },
      {
        feather: "lock",
        value: "public_restricted",
        name: i18n.global.t("label.public_restricted"),
      },
      {
        feather: "lock",
        value: "private",
        name: i18n.global.t("label.private_only_for_me"),
      },
    ],
    loading: false,
    loading_available_playlists: false,
    frames: [],
    frames_detail: {},
    temp_created_frame: {},
  }),
  getters: {},
  actions: {
    async getCategories() {
      const { name } = useRoute();
      this.loading = true;
      useNavStore().setAppSpinner(true);

      let endpoint;
      switch (name) {
        case "frames-browse":
          endpoint = `frames/browse`;
          break;
        default:
          endpoint = `settings/frames/playlist-categories`;
      }

      if (!endpoint) {
        return;
      }

      try {
        const categories = await axiosInstance.get(`/${endpoint}`);

        // update pinia state
        this.categories = orderStringByKey(categories.data.data, "name");
      } catch (error) {
        if ([401, 403].includes(error.response?.status)) {
          authStore().logout(error.response.status);
        } else {
          throw customError(error);
        }
      } finally {
        this.loading = false;
        useNavStore().setAppSpinner(false);
      }
    },
    async getCategoryDetails(category_id) {
      useNavStore().setAppSpinner(true);
      try {
        const category = await axiosInstance.get(`/settings/frames/playlist-categories/${category_id}`);

        // update pinia state
        this.categories_detail = category.data.data;
        this.categories_detail.page_name = category.data.data.name;
      } catch (error) {
        this.categories_detail = customError(error);
        if ([401, 403].includes(error.response?.status)) {
          authStore().logout(error.response.status);
        } else {
          notificationStore().saveMessage(error);
        }
      } finally {
        useNavStore().setAppSpinner(false);
      }
    },
    async createCategory(formData) {
      let { id, name, image_path } = formData;
      useNavStore().setAppSpinner(true);

      let newData = {
        id,
        name,
      };

      if (image_path && isBase64(image_path)) {
        // merge new image with data
        newData = {
          ...newData,
          image: image_path,
        };
      }

      try {
        const category = await axiosInstance.post(`/settings/frames/playlist-categories`, newData);

        notificationStore().saveAlert(category.data.message);
        await redirectToPreviousPage("settings-playlist-groups-frames");
      } catch (error) {
        if ([401, 403].includes(error.response?.status)) {
          authStore().logout(error.response.status);
        } else {
          throw customError(error);
        }
      } finally {
        useNavStore().setAppSpinner(false);
      }
    },
    async updateCategory(formData) {
      let { id, name, image_path } = formData;
      useNavStore().setAppSpinner(true);

      let newData = {
        id,
        name,
      };

      if (image_path && isBase64(image_path)) {
        // merge new image with data
        newData = {
          ...newData,
          image: image_path,
        };
      }

      try {
        const category = await axiosInstance.put(`/settings/frames/playlist-categories/${id}`, newData);

        notificationStore().saveAlert(category.data.message);
        await redirectToPreviousPage("settings-playlist-groups-frames");
      } catch (error) {
        if ([401, 403].includes(error.response?.status)) {
          authStore().logout(error.response.status);
        } else {
          throw customError(error);
        }
      } finally {
        useNavStore().setAppSpinner(false);
      }
    },
    async deleteCategory(category_id) {
      useNavStore().setAppSpinner(true);
      try {
        const category = await axiosInstance.delete(`/settings/frames/playlist-categories/${category_id}`);

        notificationStore().saveAlert(category.data.message);
        await redirectToPreviousPage("settings-playlist-groups-frames");
      } catch (error) {
        if ([401, 403].includes(error.response?.status)) {
          authStore().logout(error.response.status);
        } else {
          throw customError(error);
        }
      } finally {
        useNavStore().setAppSpinner(false);
      }
    },
    resetCategories() {
      this.categories = [];
    },
    resetCategoryDetails() {
      this.categories_detail = {};
    },
    resetPlaylistsDetail() {
      this.playlists_detail = {};
    },
    resetPlaylists() {
      this.playlists = [];
    },
    async getPlaylists() {
      this.loading = true;
      useNavStore().setAppSpinner(true);

      try {
        const playlists = await axiosInstance.get(`/frames/my-playlists`);

        // update pinia state
        this.playlists = playlists.data.data;
      } catch (error) {
        if ([401, 403].includes(error.response?.status)) {
          authStore().logout(error.response.status);
        }
        this.playlists = customError(error);
        throw this.playlists;
      } finally {
        this.loading = false;
        useNavStore().setAppSpinner(false);
      }
    },
    async getFramesByCategory(category_id) {
      this.loading = true;
      useNavStore().setAppSpinner(true);

      try {
        const categories = await axiosInstance.get(`/frames/browse/${category_id}`);

        // update pinia state
        this.categories_detail = categories.data.data;
      } catch (error) {
        this.categories_detail = customError(error);
        if ([401, 403].includes(error.response?.status)) {
          authStore().logout(error.response.status);
        }
      } finally {
        this.loading = false;
        useNavStore().setAppSpinner(false);
      }
    },
    async getPlaylistsByCategory(category_id) {
      const { name } = useRoute();

      let endpoint;
      switch (name) {
        case "frames-user-category":
          endpoint = `my-playlists/category/${category_id}`;
          break;
        case "frames-user-category-other":
          endpoint = `my-playlists/other`;
          break;
      }

      if (!endpoint) {
        return;
      }

      this.loading = true;
      useNavStore().setAppSpinner(true);

      try {
        const playlists = await axiosInstance.get(`/frames/${endpoint}`);

        // update pinia state
        this.playlists = playlists.data.data;
      } catch (error) {
        if ([401, 403].includes(error.response?.status)) {
          authStore().logout(error.response.status);
        }
        this.playlists = customError(error);
        throw this.playlists;
      } finally {
        this.loading = false;
        useNavStore().setAppSpinner(false);
      }
    },
    // -------------------------
    // Playlists overview
    // -------------------------
    async getAvailablePlaylistsByFrame(frame_id = null) {
      this.loading_available_playlists = true;
      useNavStore().setAppSpinner(true);

      const endpoint = frame_id ? `frames/my-playlists/frame/${frame_id}/available` : `frames/my-frames/playlists`;

      try {
        const playlists = await axiosInstance.get(`/${endpoint}`);

        // update pinia state
        this.playlists_available = playlists.data.data;
        this.playlists_available = this.playlists_available.map((obj) => renameKeys(obj, { title: "name" })); // rename title to name
      } catch (error) {
        if ([401, 403].includes(error.response?.status)) {
          authStore().logout(error.response.status);
        } else {
          throw customError(error);
        }
      } finally {
        this.loading_available_playlists = false;
        useNavStore().setAppSpinner(false);
      }
    },
    async addFrameToPlaylist(data) {
      useNavStore().setAppSpinner(true);

      try {
        const frames = await axiosInstance.post(`/frames/my-playlists/add-frame`, data);

        notificationStore().saveAlert(i18n.global.t("message.frame_added_to_playlist"));
      } catch (error) {
        if ([401, 403].includes(error.response?.status)) {
          authStore().logout(error.response.status);
        } else {
          throw customError(error);
        }
      } finally {
        useNavStore().setAppSpinner(false);
      }
    },
    async removeFrameFromPlaylist(data) {
      const { playlist_id, frame_id } = data;
      useNavStore().setAppSpinner(true);

      try {
        await axiosInstance.delete(`/frames/my-playlists/${playlist_id}/frames/${frame_id}`);

        // remove ticker in item_count
        if (this.playlists_detail?.item_count) {
          this.playlists_detail.item_count = this.playlists_detail.item_count - 1;
        }

        // remove frames from list
        const frames = this.playlists_detail.frames.filter((item) => item.id !== frame_id);
        this.playlists_detail.frames = frames;

        notificationStore().saveAlert(i18n.global.t("message.frame_removed_from_playlist"));
      } catch (error) {
        if ([401, 403].includes(error.response?.status)) {
          authStore().logout(error.response.status);
        } else {
          throw customError(error);
        }
      } finally {
        useNavStore().setAppSpinner(false);
      }
    },
    async getMyFrames(intervalIsRunning = false, routeName) {
      let name;
      if (!routeName) {
        name = useRoute().name;
      } else {
        name = routeName;
      }

      let endpoint;
      switch (name) {
        case "user-frames":
          endpoint = `my-frames`;
          break;
        case "frames-browse":
          endpoint = `browse`;
          break;
      }

      if (!endpoint) {
        return;
      }

      if (!intervalIsRunning) {
        this.loading = true;
        useNavStore().setAppSpinner(true);
      }

      try {
        const frames = await axiosInstance.get(`/frames/${endpoint}`);
        // update pinia state
        this.frames = frames.data.data;
      } catch (error) {
        if ([401, 403].includes(error.response?.status)) {
          authStore().logout(error.response.status);
        } else {
          throw customError(error);
        }
      } finally {
        this.loading = false;
        useNavStore().setAppSpinner(false);
      }
    },
    async updateFramesOrder(playlist_id, frames) {
      if (!playlist_id && !frames) {
        return;
      }
      await axiosInstance.post(`/frames/my-playlists/${playlist_id}/frames/reorder`, {
        frames,
      });
    },
    resetCreatedTempFrame() {
      this.temp_created_frame = {};
    },
    async createMyFrames(formData) {
      formData = { ...formData };

      useNavStore().setAppSpinner(true);

      this.resetCreatedTempFrame();

      // rewrite object to array with ids
      formData.playlists = formData.playlists.map(({ id }) => id);

      try {
        const frames = await axiosInstance.post(`/frames/my-frames`, formData);

        this.temp_created_frame = frames.data;
      } catch (error) {
        if ([401, 403].includes(error.response?.status)) {
          authStore().logout(error.response.status);
        }
        throw customError(error);
      } finally {
        useNavStore().setAppSpinner(false);
      }
    },
    async updateMyFrames(formData) {
      formData = { ...formData };

      let { id } = formData;

      useNavStore().setAppSpinner(true);

      this.resetCreatedTempFrame();

      // rewrite object to array with ids
      formData.playlists = formData.playlists.map(({ id }) => id);

      try {
        const frames = await axiosInstance.put(`/frames/my-frames/${id}`, formData);

        this.temp_created_frame = frames.data;
      } catch (error) {
        if ([401, 403].includes(error.response?.status)) {
          authStore().logout(error.response.status);
        }
        throw customError(error);
      } finally {
        useNavStore().setAppSpinner(false);
      }
    },
    async deleteMyFrame(frame_id) {
      useNavStore().setAppSpinner(true);
      try {
        const frames = await axiosInstance.delete(`/frames/my-frames/${frame_id}`);

        notificationStore().saveAlert(frames.data.message);
        await redirectToPreviousPage("user-frames");
      } catch (error) {
        if ([401, 403].includes(error.response?.status)) {
          authStore().logout();
        }
        throw customError(error);
      } finally {
        useNavStore().setAppSpinner(false);
      }
    },

    // -------------------------
    // Frames detail
    // -------------------------
    async getMyFramesDetail(frame_id) {
      this.loading = true;
      useNavStore().setAppSpinner(true);

      try {
        const frames = await axiosInstance.get(`/frames/my-frames/${frame_id}`);

        // update pinia state
        this.frames_detail = frames.data.data;

        // rename old image_path key to image
        if (this.frames_detail?.image_path) {
          delete Object.assign(this.frames_detail, { image: this.frames_detail.image_path })["image_path"];
        }
      } catch (error) {
        this.frames_detail = customError(error);
        if ([401, 403].includes(error.response?.status)) {
          authStore().logout();
        }
      } finally {
        this.loading = false;
        useNavStore().setAppSpinner(false);
      }
    },

    // -------------------------
    // Playlists detail
    // -------------------------
    async getPlaylistsDetail(data) {
      const { playlist_id, category_id } = data;

      let endpoint;
      if (playlist_id) {
        endpoint = `my-playlists/${playlist_id}`;
        if (category_id) {
          endpoint = `browse/${category_id}/playlist/${playlist_id}`;
        }
      }

      if (!endpoint) {
        this.playlists_detail.status = "404";
        return;
      }

      this.loading = true;
      useNavStore().setAppSpinner(true);

      try {
        const playlist = await axiosInstance.get(`/frames/${endpoint}`);

        // update pinia state
        this.playlists_detail = playlist.data.data;

        // rename old image_path key to image
        if (this.playlists_detail?.image_path) {
          delete Object.assign(this.playlists_detail, { image: this.playlists_detail.image_path })["image_path"];
        }

        const visibility = this.visibility_options.find((item) => item.value === this.playlists_detail.visibility);
        this.playlists_detail.visibility = visibility;

        await useUsersStore().getPublicRestrictedGroups();
      } catch (error) {
        this.playlists_detail = customError(error);
        if ([401, 403].includes(error.response?.status)) {
          authStore().logout();
        }
      } finally {
        this.loading = false;
        useNavStore().setAppSpinner(false);
      }
    },

    async updatePlaylist(formData) {
      let { id, title, description, categories, visibility, groups, image } = formData;
      useNavStore().setAppSpinner(true);
      let newData = {
        id,
        title,
        description,
        categories: categories.map(({ id }) => id), // rewrite object to array with ids
        visibility: "",
        groups: [],
      };

      if ((image && isBase64(image)) || image === null) {
        // merge new image with data
        newData = {
          ...newData,
          image: image,
        };
      }

      if (visibility?.value) {
        // merge visibility with data
        newData = {
          ...newData,
          visibility: visibility.value, // rewrite visibility with only value
        };

        if (visibility.value === "public_restricted") {
          newData = {
            ...newData,
            groups: groups.map(({ id }) => id), // rewrite object to array with ids
          };
        }
      }

      try {
        const playlist = await axiosInstance.put(`/frames/my-playlists/${id}`, newData);

        // reset visibility restricted groups
        if (visibility.value != "public_restricted") {
          this.playlists_detail.groups = [];
        }

        notificationStore().saveAlert(playlist.data.message);
      } catch (error) {
        if ([401, 403].includes(error.response?.status)) {
          authStore().logout();
        } else {
          throw customError(error);
        }
      } finally {
        useNavStore().setAppSpinner(false);
      }
    },
    async deletePlaylist(playlist_id) {
      useNavStore().setAppSpinner(true);
      try {
        const playlist = await axiosInstance.delete(`/frames/my-playlists/${playlist_id}`);

        notificationStore().saveAlert(playlist.data.message);
        await redirectToPreviousPage("frames");
      } catch (error) {
        if ([401, 403].includes(error.response?.status)) {
          authStore().logout();
        } else {
          throw customError(error);
        }
      } finally {
        useNavStore().setAppSpinner(false);
      }
    },
    async createPlaylist(formData) {
      let { title, description, categories, visibility, groups, image } = formData;
      useNavStore().setAppSpinner(true);
      let newData = {
        title,
        description,
        categories: categories.map(({ id }) => id), // rewrite object to array with ids
        visibility: "",
        groups: [],
      };

      if ((image && isBase64(image)) || image === null) {
        // merge new image with data
        newData = {
          ...newData,
          image: image,
        };
      }

      if (visibility?.value) {
        // merge visibility with data
        newData = {
          ...newData,
          visibility: visibility.value, // rewrite visibility with only value
        };

        if (visibility.value === "public_restricted") {
          newData = {
            ...newData,
            groups: groups.map(({ id }) => id), // rewrite object to array with ids
          };
        }
      }

      try {
        const user = await axiosInstance.post(`/frames/my-playlists`, newData);

        notificationStore().saveAlert(user.data.message);
        await redirectToPreviousPage("frames");
      } catch (error) {
        if ([401, 403].includes(error.response?.status)) {
          authStore().logout();
        } else {
          throw customError(error);
        }
      } finally {
        useNavStore().setAppSpinner(false);
      }
    },
    async duplicatePlaylist(formData) {
      let { title, playlist_id } = formData;
      useNavStore().setAppSpinner(true);

      try {
        const playlist = await axiosInstance.post(`/frames/my-playlists/${playlist_id}/duplicate`, { title });

        notificationStore().saveAlert(playlist.data.message);
        await redirectToPreviousPage("frames");
      } catch (error) {
        if ([401, 403].includes(error.response?.status)) {
          authStore().logout();
        } else {
          throw customError(error);
        }
      } finally {
        useNavStore().setAppSpinner(false);
      }
    },
  },
});
