import LoginView from "@/views/_platform/login/LoginView.vue";
import RegionView from "@/views/_platform/login/RegionView.vue";
export const loginRoutes = [
  // Login
  {
    name: "login",
    path: "/login",
    component: LoginView,
    meta: {
      permission: "public",
      supportPageID: "001",
    },
  },
  {
    name: "login-region",
    path: "/login/region",
    component: RegionView,
  },
];
