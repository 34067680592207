<template>
  <RouterLink
    :to="to"
    class="nav-item"
    :class="{ 'router-link-exact-active': IsActive }"
    @click="closeSidebar"
    v-ripple
  >
    <vue-feather :type="feather" size="20" class="ui-icon" />
    <span class="ui-txt"> {{ title }} </span>
  </RouterLink>
</template>
<script>
import Ripple from "primevue/ripple";
import VueFeather from "vue-feather";
import { useNavStore } from "@/stores";
import { RouterLink, useLink } from "vue-router";

export default {
  setup(props) {
    const { href } = useLink(props);
    return {
      href,
    };
  },
  props: {
    ...RouterLink.props,
    feather: {
      type: String,
      required: true,
    },
    to: {
      type: [String, Object],
    },
    name: {
      type: String,
    },
  },
  directives: {
    ripple: Ripple,
  },
  components: {
    VueFeather,
  },
  methods: {
    closeSidebar() {
      setTimeout(() => {
        useNavStore().sidebarClose();
      }, 500);
    },
  },
  computed: {
    IsActive() {
      return this.$route.path.startsWith(this.href);
    },
    title() {
      if (this.name) {
        return this.name;
      }
      return this.$t(`route.${this.to.name}`);
    },
  },
};
</script>
<style lang="scss">
.nav-item {
  transition: 0.2s all;
  display: flex;
  align-items: center;
  position: relative;

  padding: spacing(4) spacing(5);
  text-decoration: none;
  font-size: font-size("base");
  line-height: 1.125em;
  font-weight: 500;
  border-radius: 10px;
  overflow: hidden;

  color: var(--app-navitem-color);
  background: var(--app-navitem-ground);

  .ui-icon {
    flex-shrink: 0;
    color: var(--app-navitem-icon);
    + .ui-txt {
      margin-left: spacing(4);
    }
  }

  .p-ink {
    background: rgba(white, 0.3);
  }

  &:hover {
    text-decoration: none;
    color: var(--app-navitem-hover-color);
    background: var(--app-navitem-hover-ground);
    .ui-icon {
      color: var(--app-navitem-hover-icon);
    }
  }

  &.router-link-exact-active {
    color: var(--app-navitem-active-color);
    background: var(--app-navitem-active-ground);
    .ui-icon {
      color: var(--app-navitem-active-icon);
    }
  }
}
</style>
