<template>
  <div>
    <BackUI variant="primary" to="#" :title="playlists.name" class="mb-7" @click.prevent="goBack()" />
    <div v-if="!loading">
      <NotificationMessage type="default" v-if="notFound">{{ $t("404.text") }}</NotificationMessage>
      <template v-else>
        <PlayLists :list="playlists.playlists" />
      </template>
    </div>
  </div>
</template>

<script>
import BackUI from "@/components/BackUI.vue";
import NotificationMessage from "@/components/NotificationMessage.vue";
import PlayLists from "@/components/_platform/playlist/PlayLists.vue";

import { getPermissions, router } from "@/helpers";
import { useNavStore, useMusicStore } from "@/stores";
import { storeToRefs } from "pinia";
import { useRoute } from "vue-router";

export default {
  setup() {
    const route = useRoute();
    const { id } = route.params;

    const musicStore = useMusicStore();
    musicStore.getPlaylistsByCategory(id);

    const { playlists, loading } = storeToRefs(musicStore);

    return {
      playlists,
      loading,
    };
  },
  emits: ["notFound"],
  components: {
    BackUI,
    PlayLists,
    NotificationMessage,
  },
  methods: {
    getPermissions,
    goBack() {
      router.push({ name: "music-browse" });
    },
  },
  computed: {
    notFound() {
      const notFound = this.playlists?.status && this.playlists.status == 404;
      this.$emit("notFound", notFound);
      return notFound;
    },
  },
  watch: {
    playlists: {
      handler(playlist) {
        if (playlist?.name) {
          const navStore = useNavStore();
          navStore.setBreadcrumb("music-user-category");
          navStore.setBreadcrumbTitle(playlist.name, 2);
        }
      },
      immediate: true,
    },
  },
};
</script>
