import { defineStore } from "pinia";
import { router, customError } from "@/helpers";
import * as jose from "jose";
import { useAppStore } from "@/stores/app.store";
const validateLogin = (credentials) => {
  const { username, password } = credentials;
  const { mode } = useAppStore().getAppConfig;

  let appCredentials = {
    username: import.meta.env.VITE_BASICAUTH_USERNAME || "william@exitable.nl",
    password: import.meta.env.VITE_BASICAUTH_PASSWORD || "1234567890",
  };

  if (mode === "development") {
    appCredentials = {
      username: "bcm",
      password: "scelisEStorE",
    };
  }

  return username === appCredentials.username && password === appCredentials.password;
};

const secretKey = () => {
  return new TextEncoder().encode("bcm");
};

export const useBasicAuthStore = defineStore({
  id: "basic_auth",
  state: () => ({
    auth: localStorage.getItem("basic_auth"),
  }),
  getters: {},
  actions: {
    async basicAuthEnabled() {
      const { isDev } = useAppStore().getAppConfig;

      try {
        if (isDev) {
          if (this.auth) {
            const { payload } = await jose.jwtVerify(this.auth, secretKey());
            const username = payload?.username ? payload.username : null;
            const password = payload?.password ? payload.password : null;

            if (validateLogin({ username, password })) {
              return false;
            }
          }
          return true;
        }
        return false;
      } catch (error) {
        return true;
      }
    },

    async login(username, password) {
      try {
        let credentials = {
          username,
          password,
        };

        if (validateLogin(credentials)) {
          const alg = "HS256";
          const jwt = await new jose.SignJWT(credentials)
            .setProtectedHeader({ alg })
            .setExpirationTime("24h")
            .sign(secretKey());

          this.auth = jwt;
          await localStorage.setItem("basic_auth", jwt);
          await router.push("/");
        } else {
          throw new Error("Invalid credentials!");
        }
      } catch (error) {
        throw customError(error);
      }
    },
  },
});
