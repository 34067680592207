<template>
  <div>
    <PDialog
      v-model:visible="isVisible"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '1920px', height: '900px', maxWidth: '90vw' }"
      :draggable="false"
      :modal="true"
      :blockScroll="true"
      :baseZIndex="11000"
      appendTo="#app"
    >
      <!-- start dialog headers -->
      <template #header>
        <div v-show="!playlist_id" class="playlist-header w-full">
          <div class="p-dialog-title mb-6" v-html="$t('title.select_a_playlist')" />
          <TextUI>{{ $t("message.select_the_playlist_you_would_like_to_link") }}</TextUI>
          <TabNav :items="tabNavItems" variant="tab" v-model:activeTab="activeTab">
            <template #controls>
              <div class="flex justify-content-end">
                <SearchUI
                  v-if="getPermissions(`search ${type}`)"
                  v-model:filterData="filterData"
                  :placeholder="$t('placeholder.search_by_topic')"
                />
              </div>
            </template>
          </TabNav>
        </div>
        <div v-if="playlist_id" class="playlist-header w-full">
          <BackUI variant="primary" to="#" @click.prevent="goBackFromPlaylist" class="mb-4" />
          <PlaylistHeader
            :image="playlistDetail.image"
            :title="playlistDetail.title"
            :text="playlistDetail.description"
            :visibility="playlistDetail.visibility"
            class="mb-6"
          >
            <template #details>
              <ButtonUI
                :label="$t('button.select_this_playlist')"
                color="primary"
                size="lg"
                class="btn-select-playlist"
                @click="selectPlaylist(playlistDetail)"
              />

              <div class="flex mt-3 gap-3">
                <BadgeUI
                  v-if="playlistDetail.item_count > 0"
                  :title="getCountTitle({ count: playlistDetail.item_count, type })"
                  color="primary"
                />

                <BadgeUI
                  v-if="playlistDetail.item_count && type === 'music'"
                  :title="`${playlistDetail.total_playing_time} ${$t('label.total_time').toLowerCase()} `"
                  color="primary"
                />
              </div>
            </template>
          </PlaylistHeader>
          <TabNav :items="tabNavPlaylistItems" variant="tab" />
        </div>
      </template>
      <!-- end dialog headers -->

      <div class="dialog-content line-height-3">
        <div v-if="!playlist_id">
          <div class="tab-panel -my-8 pt-8" ref="tabPanel">
            <!-- TAB: Search playlists -->
            <div v-if="searchTermTimeout">
              <div class="panel-inner pb-8">
                <template v-if="!loading">
                  <template v-if="playlists.length > 0">
                    <PlayLists
                      :list="playlists"
                      variant="scheduler"
                      @onSelectPlaylist="selectPlaylist"
                      @openPlaylist="openPlaylist"
                    />
                  </template>
                  <NotificationMessage v-else type="default">{{
                    $t("message.no_playlists_found")
                  }}</NotificationMessage>
                </template>
              </div>
            </div>

            <!-- TAB: User playlists -->
            <div v-else-if="showTab(0)">
              <div class="panel-inner">
                <template v-if="!loading">
                  <template v-if="playlists.length > 0">
                    <div v-for="(item, index) in playlists" :key="index" class="playlist-group mb-8">
                      <div class="grid align-items-end mb-6">
                        <div class="col-6">
                          <h2 class="mb-0">
                            {{ item.name }}
                            <span v-if="item.total_playlists > 0">({{ item.total_playlists }})</span>
                          </h2>
                        </div>
                        <div class="col-6 text-right" v-if="showMoreState(index)">
                          <RouterLink to="#" @click.prevent="onShowMoreClick(item)" class="show-more">{{
                            $t("button.show_more")
                          }}</RouterLink>
                        </div>
                      </div>
                      <PlayLists
                        v-if="item?.playlists"
                        :list="item.playlists"
                        variant="scheduler"
                        :type="type"
                        :limitByViewport="true"
                        @showMore="showMoreRow(index, $event)"
                        @onShowMoreClick="onShowMoreClick(item)"
                        @onSelectPlaylist="selectPlaylist"
                        @openPlaylist="openPlaylist"
                      />
                    </div>
                  </template>
                  <template v-else-if="isNotEmpty(category)">
                    <BackUI
                      variant="primary"
                      to="#"
                      :focus="false"
                      :title="category?.name ? `${category.name} (${category.total_playlists})` : ''"
                      class="mb-7"
                      @click.prevent="goBackFromCategory"
                    />
                    <PlayLists
                      v-if="category?.playlists"
                      :list="category.playlists"
                      variant="scheduler"
                      @onSelectPlaylist="selectPlaylist"
                      @openPlaylist="openPlaylist"
                    />
                  </template>
                  <NotificationMessage v-else type="default">{{
                    $t("message.no_playlists_found")
                  }}</NotificationMessage>
                </template>
              </div>
            </div>
            <!-- TAB: Browse -->
            <div v-else-if="showTab(1)">
              <div class="panel-inner pb-8">
                <template v-if="playlists.length > 0">
                  <div class="grid">
                    <div v-for="(item, index) in playlists" :key="index" class="col-6 sm:col-3 xxl:col-2 col-category">
                      <Category
                        class="no-hover"
                        :id="item.id"
                        :title="item.name"
                        :image="
                          item?.image_path ? item.image_path : item?.thumbnail_small ? item.thumbnail_small : null
                        "
                        @onClick="openCategory(item)"
                      />
                    </div>
                  </div>
                </template>
                <template v-else-if="isNotEmpty(category)">
                  <BackUI
                    variant="primary"
                    to="#"
                    :focus="false"
                    :title="category?.name ? category.name : ''"
                    class="mb-7"
                    @click.prevent="goBackFromCategory"
                  />
                  <PlayLists
                    v-if="category?.playlists"
                    :list="category.playlists"
                    variant="scheduler"
                    @onSelectPlaylist="selectPlaylist"
                    @openPlaylist="openPlaylist"
                  />
                </template>
              </div>
            </div>
          </div>
        </div>
        <div v-else>
          <!-- Detail -->
          <div class="tab-panel -my-8 pt-8" ref="tabPanel">
            <template v-if="!loadingDetail">
              <TableTracks
                v-if="playlistDetail?.tracks"
                :tracks="playlistDetail.tracks"
                :multiselect="false"
                :controls="false"
                :controlPermissions="[]"
              />
              <TableSlides
                v-else-if="playlistDetail?.slides"
                :slides="playlistDetail.slides"
                :controls="false"
                :controlPermissions="[]"
              />
              <TableFrames
                v-else-if="playlistDetail?.frames"
                :frames="playlistDetail.frames"
                :controls="false"
                :controlPermissions="[]"
              />
              <TableTickers
                v-else-if="playlistDetail?.tickers"
                :tickers="playlistDetail.tickers"
                :controls="false"
                :controlPermissions="[]"
              />
            </template>
          </div>
        </div>
      </div>
    </PDialog>
  </div>
</template>
<script>
import Dialog from "primevue/dialog";
import TextUI from "@/components/TextUI.vue";
import { getCountTitle, getPermissions, getScrollBarWidth, i18n, isNotEmpty } from "@/helpers";
import TabNav from "@/components/nav/TabNav.vue";
import ButtonUI from "@/components/ButtonUI.vue";
import BadgeUI from "@/components/BadgeUI.vue";
import PlaylistHeader from "@/components/_platform/playlist/PlaylistHeader.vue";
import TableTracks from "@/components/_platform/table/playlists/TableTracks.vue";
import TableFrames from "@/components/_platform/table/frames/TableFrames.vue";
import { computed, onMounted, ref, toRefs, watch, watchEffect } from "vue";
import BackUI from "@/components/BackUI.vue";
import PlayLists from "@/components/_platform/playlist/PlayLists.vue";
import NotificationMessage from "@/components/NotificationMessage.vue";
import { setShowMoreRow, setShowMoreState } from "@/helpers/utils/showMoreCol";
import {
  useFramesStore,
  useGroupsStore,
  useMusicStore,
  useSlidesStore,
  useSystemsStore,
  useTickersStore,
} from "@/stores";
import { useRoute } from "vue-router";
import Category from "@/components/_platform/playlist/CategoryCard.vue";
import TableSlides from "@/components/_platform/table/slides/TableSlides.vue";
import TableTickers from "@/components/_platform/table/tickers/TableTickers.vue";
import SearchUI from "@/components/SearchUI.vue";

export default {
  methods: { getCountTitle },
  emits: ["onCancel", "update:visible", "onSelected"],
  setup(props, { emit }) {
    const { visible, type } = toRefs(props);

    let activeTab = ref({});
    const tabPanel = ref(null);
    const isBrowse = ref(false);
    const filterData = ref({});
    let searchTimeout = false;
    let searchTermTimeout = ref("");
    let scrollTimeout = false;

    const isVisible = computed({
      get() {
        return visible.value;
      },
      set(state) {
        visible.value = state;
        emit("update:visible", state);
        if (!state) {
          emit("onCancel");
        }
      },
    });

    const showTab = (index) => {
      return (activeTab.value?.index === undefined && index === 0) || activeTab.value.index === index;
    };

    const selectPlaylist = (item) => {
      isVisible.value = false;
      emit("onSelected", { type: isBrowse.value ? "bcm" : "custom", ...item });
    };
    const openPlaylist = (item) => {
      playlist_id.value = item.id;
      if (item?.category?.id) {
        category_id.value = item.category.id;
      }
      scrollToTop();
    };
    const goBackFromPlaylist = () => {
      const scrollID = playlist_id.value;
      playlist_id.value = null;

      if (scrollTimeout) {
        clearTimeout(scrollTimeout);
      }
      scrollTimeout = setTimeout(() => {
        document
          .getElementById(`playlist_${scrollID}`)
          .scrollIntoView({ behavior: "instant", block: "start", inline: "nearest" });
      }, 200);
    };

    const goBackFromCategory = () => {
      const scrollID = category_id.value;
      category_id.value = null;

      if (scrollTimeout) {
        clearTimeout(scrollTimeout);
      }
      scrollTimeout = setTimeout(() => {
        document
          .getElementById(`category_${scrollID}`)
          .scrollIntoView({ behavior: "instant", block: "start", inline: "nearest" });
      }, 200);
    };

    const openCategory = (item) => {
      category_id.value = item.id;
      scrollToTop();
    };

    const scrollToTop = () => {
      if (scrollTimeout) {
        clearTimeout(scrollTimeout);
      }
      scrollTimeout = setTimeout(() => {
        document.querySelector(".p-dialog-content").scrollTop = 0;
      }, 200);
    };

    const tabNavItems = computed(() => {
      const items = {
        title: i18n.global.t("label.user_playlists"),
        permissions: "show playlists",
      };
      switch (type.value) {
        case "music":
          return [
            items,
            {
              title: i18n.global.t("label.browse_playlists"),
              permissions: "show playlists",
            },
          ];
      }
      return [items];
    });

    const tabNavPlaylistItems = computed(() => {
      switch (type.value) {
        case "music":
          return [
            {
              title: i18n.global.t("label.tracks"),
              feather: "music",
              permissions: "show playlists",
            },
          ];
        case "slides":
          return [
            {
              title: i18n.global.t("label.slides"),
              feather: "monitor",
              permissions: "show playlists",
            },
          ];
        case "frames":
          return [
            {
              title: i18n.global.t("label.frames"),
              feather: "table",
              permissions: "show playlists",
            },
          ];
        case "tickers":
          return [
            {
              title: i18n.global.t("label.tickers"),
              feather: "table",
              permissions: "show playlists",
            },
          ];
      }
      return [];
    });

    let showMoreRows = ref([]);
    const showMoreRow = (index, value) => {
      setShowMoreRow(showMoreRows.value, index, value);
    };
    const showMoreState = (index) => {
      return setShowMoreState(showMoreRows.value, index);
    };
    const onShowMoreClick = (item) => {
      category_id.value = item.id ?? "other";
    };

    const route = useRoute();
    const { system_id, group_id } = route.params;

    let category_id = ref(null);
    let playlist_id = ref(null);

    const playlistDetail = computed(() => {
      switch (type.value) {
        case "music":
          return useMusicStore().playlists_detail;
        case "frames":
          return useFramesStore().playlists_detail;
        case "slides":
          return useSlidesStore().playlists_detail;
        case "tickers":
          return useTickersStore().playlists_detail;
      }
      return {};
    });

    const loadingDetail = computed(() => {
      switch (type.value) {
        case "music":
          return useMusicStore().loading;
        case "frames":
          return useFramesStore().loading;
        case "slides":
          return useSlidesStore().loading;
        case "tickers":
          return useTickersStore().loading;
      }
      return true;
    });

    const playlists = computed(() => {
      return system_id ? useSystemsStore().systems_playlists : group_id ? useGroupsStore().groups_playlists : [];
    });

    const category = computed(() => {
      return isNotEmpty(playlists.value) && !Array.isArray(playlists.value) ? playlists.value : {}; // check if playlists is object and no array --> then category
    });

    const loading = computed(() => {
      return system_id ? useSystemsStore().loading : group_id ? useGroupsStore().loading : [];
    });

    const searchTerm = computed(() => {
      return filterData.value?.term && filterData.value.term.length > 1 ? filterData.value.term : "";
    });

    const fetchData = computed(() => {
      return {
        visible: isVisible.value,
        type: type.value,
        search_term: searchTermTimeout.value,
        category_id: category_id.value,
        browse: isBrowse.value,
      };
    });

    watch(searchTerm, (term) => {
      if (searchTimeout) {
        clearTimeout(searchTimeout);
      }

      searchTimeout = setTimeout(() => {
        searchTermTimeout.value = term;
      }, 500);
    });

    watch(activeTab, (tab) => {
      category_id.value = null;
      isBrowse.value = activeTab.value?.index === 1;
    });

    watch(fetchData, (data) => {
      if (data.visible && data.type) {
        if (system_id) {
          useSystemsStore().getSystemPlaylists({
            system_id,
            ...data,
          });
        } else if (group_id) {
          useGroupsStore().getGroupPlaylists({
            group_id,
            ...data,
          });
        }
      }
    });

    // get playlist detail
    watch(playlist_id, (id) => {
      if (id) {
        const categoryID = isBrowse.value ? category_id.value : null;
        switch (type.value) {
          case "music":
            useMusicStore().getPlaylistsDetail({ playlist_id: playlist_id.value, category_id: categoryID });
            break;
          case "frames":
            useFramesStore().getPlaylistsDetail({ playlist_id: playlist_id.value, category_id: categoryID });
            break;
          case "slides":
            useSlidesStore().getPlaylistsDetail({ playlist_id: playlist_id.value, category_id: categoryID });
            break;
          case "tickers":
            useTickersStore().getPlaylistsDetail({ playlist_id: playlist_id.value, category_id: categoryID });
            break;
        }
      }
    });

    watch(
      isVisible,
      (visible) => {
        // reset values
        category_id.value = null;
        playlist_id.value = null;
        activeTab.value.index = 0;
        isBrowse.value = false;

        if (visible) {
          document.body.classList.add("no-scroll");
          setTimeout(() => {
            getScrollBarWidth(".p-dialog-content");
          }, 200);
        } else {
          document.body.classList.remove("no-scroll");
        }
      },
      {
        immediate: true,
      }
    );

    return {
      getPermissions,
      showTab,
      selectPlaylist,
      openPlaylist,
      isVisible,
      tabNavItems,
      tabNavPlaylistItems,
      activeTab,
      playlistDetail,
      goBackFromPlaylist,
      goBackFromCategory,
      openCategory,
      showMoreRow,
      showMoreState,
      onShowMoreClick,
      isNotEmpty,
      scrollToTop,
      showMoreRows,
      playlists,
      category_id,
      category,
      loading,
      loadingDetail,
      playlist_id,
      tabPanel,
      filterData,
      searchTerm,
      searchTermTimeout,
    };
  },
  components: {
    SearchUI,
    Category,
    NotificationMessage,
    PlayLists,
    ButtonUI,
    BackUI,
    TableTracks,
    TableFrames,
    TableSlides,
    TableTickers,
    PlaylistHeader,
    BadgeUI,
    TabNav,
    TextUI,
    PDialog: Dialog,
  },
  props: {
    visible: {},
    type: {
      type: String,
      default: "music",
    },
  },
};
</script>
<style lang="scss" scoped>
.playlist-header {
  position: relative;
  .btn-select-playlist {
    position: absolute;
    right: 0;
    top: 48px;
  }
}

.tab-panel {
  color: var(--text-color);
  min-height: 380px;
}

.col-category {
  @include media-breakpoint-up(lg) {
    width: 20%;
  }

  @include media-breakpoint-up(xxl) {
    width: 16.6667%;
  }
}
</style>
