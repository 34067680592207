import { onMounted, onUnmounted, watch } from "vue";
import { useSystemsStore } from "@/stores";

export function startStatusTimer(cb, timeout = 2000) {
  let timerInterval;
  const systemsStore = useSystemsStore();

  const stopTimer = () => {
    clearInterval(timerInterval);
  };

  const startTimer = () => {
    timerInterval = setInterval(() => {
      cb();
    }, timeout);
  };

  onMounted(() => {
    startTimer();
  });
  onUnmounted(() => {
    stopTimer();
  });
}
