<template>
  <div class="view-body">
    <div class="content-wrapper">
      <NotificationMessage type="404" v-if="notFound" />
      <template v-else>
        <PageTitle :title="$t('route.tickers_edit')" class="mb-7" :backUI="true" />
        <TabNav
          :items="[
            {
              title: $t('label.settings'),
              feather: 'settings',
            },
          ]"
          variant="tab"
        >
          <template #controls>
            <ButtonUI
              :underline="true"
              variant="text"
              color="text-palette-1"
              size="lg"
              :label="$t('button.delete_ticker')"
              @click="deleteTicker()"
              v-if="getPermissions('delete tickers')"
            />
            <ButtonUI @click="triggerFormSubmit()" size="lg" :label="$t('button.save_settings')" />
          </template>
          <template #panels>
            <form @submit.prevent="onSubmit">
              <div class="grid">
                <div class="col-7">
                  <SurfaceCard :title="$t('label.settings')">
                    <template #body>
                      <LimitWidth>
                        <NotificationMessage :error="error" />
                        <FormRow>
                          <div class="flex flex-wrap gap-6">
                            <div class="flex align-items-center gap-2">
                              <FormRadio
                                inputId="type_1"
                                name="ticker_type"
                                value="1"
                                color="palette-1"
                                v-model="form.type"
                              />
                              <FormLabel for="type_1">{{ $t("label.rss_feed") }}</FormLabel>
                            </div>
                            <div class="flex align-items-center gap-2">
                              <FormRadio
                                inputId="type_2"
                                name="ticker_type"
                                value="2"
                                color="palette-1"
                                v-model="form.type"
                              />
                              <FormLabel for="type_2">{{ $t("label.static_text") }}</FormLabel>
                            </div>
                          </div>
                        </FormRow>
                        <FormRow>
                          <FormLabel for="name" required>{{ $t("label.name") }}:</FormLabel>
                          <FormText
                            id="name"
                            type="text"
                            size="lg"
                            :class="{ 'is-invalid': error.data?.name }"
                            v-model="form.name"
                          />
                          <FormHelp type="invalid" :data="error.data" field="name" />
                        </FormRow>
                        <FormRow v-if="form.type == 2">
                          <StaticTextFields v-model:form="form" @formValue="form = $event" />
                        </FormRow>
                        <div v-else>
                          <FormRow>
                            <FormLabel for="url" required>{{ $t("label.rss_url") }}:</FormLabel>
                            <FormText
                              id="url"
                              type="text"
                              size="lg"
                              :class="{ 'is-invalid': error.data?.url }"
                              v-model="form.url"
                            />
                            <FormHelp type="invalid" :data="error.data" field="url" />
                          </FormRow>
                          <FormRow>
                            <FormLabel for="amount" required>{{ $t("label.amount") }}:</FormLabel>
                            <FormText
                              id="amount"
                              type="text"
                              size="lg"
                              :class="{ 'is-invalid': error.data?.amount }"
                              v-model="form.amount"
                            />
                            <FormHelp type="invalid" :data="error.data" field="amount" />
                          </FormRow>
                        </div>
                      </LimitWidth>
                    </template>
                  </SurfaceCard>
                </div>
                <div class="col-5">
                  <SurfaceCard :title="$t('label.user_playlists')" class="mb-5">
                    <ManageList
                      v-model:selected="form.playlists"
                      :add="playlistOptions"
                      :confirmTitle="$t('label.disconnect_user_playlist')"
                      :placeholder="$t('placeholder.enter_name')"
                      :messageNoData="$t('message.no_connected_playlists')"
                      :triggerSubmit="triggerSubmit"
                      @isReady="optionsAreReady = $event"
                    >
                      <template #footer>
                        <ButtonUI
                          :label="$t('button.create_playlist')"
                          color="secondary"
                          :to="{ name: 'tickers-playlist-create' }"
                          v-if="
                            playlistOptions.length === 0 &&
                            form?.playlists &&
                            form.playlists.length === 0 &&
                            getPermissions('create playlists')
                          "
                        />
                        <FormHelp type="invalid" :data="error.data" field="playlists" :multipleFields="true" />
                      </template>
                    </ManageList>
                  </SurfaceCard>
                </div>
              </div>
              <button class="p-button-hidden" id="submit" type="submit" :label="$t('button.save_settings')" />
            </form>
          </template>
        </TabNav>
      </template>
    </div>
    <DialogConfirm
      :visible="showConfirm"
      :title="$t('button.delete_ticker')"
      :message="
        form?.playlist_count
          ? $t('message.associated_playlist_ticker', { playlist_count: form.playlist_count })
          : $t('message.confirmation_proceed')
      "
      @onConfirm="onConfirm"
      @onCancel="onCancel"
    />
  </div>
</template>

<script>
import PageTitle from "@/components/PageTitle.vue";
import SurfaceCard from "@/components/SurfaceCard.vue";
import FormRow from "@/components/form/FormRow.vue";
import FormLabel from "@/components/form/FormLabel.vue";
import FormText from "@/components/form/FormText.vue";
import FormHelp from "@/components/form/FormHelp.vue";
import ButtonUI from "@/components/ButtonUI.vue";
import DialogConfirm from "@/components/dialog/DialogConfirm.vue";
import NotificationMessage from "@/components/NotificationMessage.vue";
import LimitWidth from "@/components/LimitWidth.vue";
import { useTickersStore } from "@/stores";
import { setBreadcrumbs, getPermissions } from "@/helpers";
import TabNav from "@/components/nav/TabNav.vue";
import { storeToRefs } from "pinia";
import { useRoute } from "vue-router";
import FormRadio from "@/components/form/FormRadio.vue";
import StaticTextFields from "@/components/_platform/tickers/StaticTextFields.vue";
import ManageList from "@/components/ManageList.vue";

export default {
  setup() {
    setBreadcrumbs();

    const route = useRoute();
    const { ticker_id } = route.params;

    const tickersStore = useTickersStore();
    tickersStore.getMyTickersDetail(ticker_id);
    tickersStore.getAvailablePlaylistsByTicker(ticker_id);

    const { tickers_detail: form, loading, playlists_available: playlistOptions } = storeToRefs(tickersStore);

    return {
      form,
      loading,
      ticker_id,
      playlistOptions,
    };
  },
  components: {
    ManageList,
    StaticTextFields,
    FormRadio,
    TabNav,
    PageTitle,
    SurfaceCard,
    NotificationMessage,
    FormRow,
    FormLabel,
    FormText,
    LimitWidth,
    FormHelp,
    ButtonUI,
    DialogConfirm,
  },
  data() {
    return {
      error: {},
      showConfirm: false,
      triggerSubmit: false,
      optionsAreReady: false,
      selectedPlaylist: [],
    };
  },
  computed: {
    notFound() {
      return this.form?.status && this.form.status == 404;
    },
    validatedSubmit() {
      const { triggerSubmit, optionsAreReady } = this;
      return triggerSubmit && optionsAreReady;
    },
  },
  methods: {
    getPermissions,
    deleteTicker() {
      this.showConfirm = true;
    },
    onConfirm() {
      this.showConfirm = false;
      const tickersStore = useTickersStore();
      return tickersStore.deleteMyTicker(this.ticker_id).catch((error) => {
        this.error = error;
      });
    },
    onCancel() {
      this.showConfirm = false;
    },
    triggerFormSubmit() {
      document.getElementById("submit").click();
    },
    resetManageList() {
      this.optionsAreReady = false;
    },
    async onSubmit() {
      this.triggerSubmit = true;
      this.resetManageList();
    },
  },
  watch: {
    validatedSubmit(submit) {
      if (submit) {
        this.error = {};
        return useTickersStore()
          .updateMyTicker(this.form)
          .catch((error) => {
            this.error = error;
            this.triggerSubmit = false;
            this.resetManageList();
          });
      }
    },
  },
};
</script>
