function bytesToKB(bytes) {
  return (bytes / 1024).toFixed(1);
}

function bytesToMB(bytes) {
  return (bytes / (1024 * 1024)).toFixed(1);
}

export function getFileSize(bytes) {
  if (bytes <= 100000) {
    return `${parseFloat(bytesToKB(bytes))}KB`;
  }
  return `${parseFloat(bytesToMB(bytes))}MB`;
}
