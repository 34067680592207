import { filterByTerm, uniqueID } from "@/helpers";

export function filterTracks({ tracks, filterData }) {
  if (tracks && filterData) {
    const term = filterData?.term;
    const type = filterData?.type;

    // Filter by term
    if (term) {
      const filteredByTitle = filterByTerm(tracks, "title", term);
      // OR
      const filteredByArtist = filterByTerm(tracks, "artist", term);
      tracks = filteredByTitle.concat(filteredByArtist);
    }

    // AND
    // Filter by type
    if (type !== "all") {
      tracks = filterByTerm(tracks, "media", type);
    }
    tracks = uniqueID(tracks);
  }
  return tracks;
}
