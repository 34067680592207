import { createRouter, createWebHistory } from "vue-router";
import { setBrowserTitle } from "./utils/setBrowserTitle";
import { getPermissions } from "./utils/getPermissions";

import { useAuthStore, useAdminAuthStore, useNavStore, useNotificationStore as notificationStore } from "@/stores";
import { useBasicAuthStore } from "@/stores/basicAuth.store";

import NotFoundView from "@/views/NotFoundView.vue";
import BasicAuthView from "@/views/BasicAuthView.vue";

import { useAppStore } from "@/stores/app.store";

import { platformRoutes } from "@/helpers/router/platform.router";
import { adminRoutes } from "@/helpers/router/admin.router";
import EmptyView from "@/views/EmptyView.vue";

export const router = createRouter({
  history: createWebHistory(),
  linkActiveClass: "active",
  routes: [
    {
      path: "",
      alias: "/",
      redirect: { name: "login" },
    },
    // Basic auth
    {
      name: "auth",
      path: "/auth",
      component: BasicAuthView,
      meta: {
        permission: "basic_auth",
      },
    },
    // 404
    {
      path: "/:pathMatch(.*)*",
      name: "not-found",
      component: NotFoundView,
      meta: {
        permission: "public",
      },
    },
    // Impersonate Leave
    {
      path: "/impersonate-leave",
      name: "impersonate-leave",
      component: EmptyView,
      meta: {
        permission: "public",
      },
    },
    ...platformRoutes,
    ...adminRoutes,
  ],
  scrollBehavior(to, from, savedPosition) {
    // scroll to top by default
    let position = { top: 0 };
    const hash = to.hash;

    // if the returned position is falsy or an empty object,
    // will retain current scroll position.
    if (to.params.savePosition) return {};

    return new Promise((resolve, reject) => {
      setTimeout(() => {
        // savedPosition is only available for popstate navigations.
        if (savedPosition) {
          position = savedPosition;
        }

        // Scroll to bottom of page
        else if (to.params.scrollToBottom) {
          position = { top: document.documentElement.scrollHeight };
        } else if (hash && hash.length > 1) {
          const hashEl = document.querySelector(hash);
          if (hashEl) {
            position = { top: hashEl.offsetTop };
          }
        }

        resolve(position);
      }, 400);
    });
  },
});

router.beforeEach(async (to, from) => {
  const navStore = useNavStore();
  const appStore = useAppStore();

  const isAdmin = to.fullPath.startsWith("/admin");
  navStore.setAdminPage(isAdmin);
  setBrowserTitle(to.name);
  notificationStore().resetMessage();

  appStore.setSupportUrl(to);

  // set returnUrl, except urls with hash
  if (!to.hash) {
    navStore.returnOptions = {
      from,
      to,
    };
  }

  const auth = useAuthStore();
  const adminAuth = useAdminAuthStore();

  const metaPermissions = to?.meta?.permission;
  const authPermissions = metaPermissions ? getPermissions(metaPermissions) : true;
  const publicPermission = metaPermissions === "public";

  const basicAuth = useBasicAuthStore();
  if (await basicAuth.basicAuthEnabled()) {
    const basicAuthPermission = to?.meta?.permission === "basic_auth";
    if (!basicAuthPermission) {
      return "/auth";
    }
  } else if (!publicPermission && !isAdmin && auth.loggedIn) {
    if (!authPermissions && auth.hasCustomerProfile) {
      return "/dashboard";
    }
  } else if (!publicPermission && isAdmin && adminAuth.loggedIn) {
    if (!authPermissions) {
      return "/admin/dashboard";
    }
  } else {
    //   if (!publicPermission) {
    //     // return "/login";
    //   }
  }
});

// Disable refresh token
// let refreshTokenTimeout = false;
// router.afterEach(async (to, from) => {
//   if (refreshTokenTimeout) {
//     clearTimeout(refreshTokenTimeout);
//   }
//   refreshTokenTimeout = setTimeout(() => {
//     const auth = useAuthStore();
//     auth.refresh();
//   }, 5000); // refresh token after 5 seconds after each page
// });
