import { i18n } from "@/helpers/i18n";

export const providers = [
  {
    id: "bcm",
    name: "BCM",
    theme_name: "default",
    app_name: i18n.global.t("label.media_control_center"),
  },
  {
    id: "od",
    name: "Orange Door",
    theme_name: "orange-door",
    app_name: i18n.global.t("label.remote_access_portal"),
  },
  {
    id: "sh",
    name: "Swish Hospitality",
    theme_name: "swish-hospitality",
    app_name: i18n.global.t("label.media_control_center"),
  },
];
