<template>
  <SurfaceCard :title="$t('label.pending_systems')" type="dashboard" class="h-full" v-show="isVisible">
    <template #body>
      <ListPendingSystems :list="systems" />
    </template>
    <template #footer>
      <ButtonUI size="sm" :to="{ name: 'systems-pending' }" :label="$t('button.view_all_pending_systems')" />
    </template>
  </SurfaceCard>
</template>
<script>
import SurfaceCard from "@/components/SurfaceCard.vue";
import ListPendingSystems from "@/components/_platform/dashboard/list/ListPendingSystems.vue";
import ButtonUI from "@/components/ButtonUI.vue";

import { useSystemsStore } from "@/stores";

import { storeToRefs } from "pinia";
import { onMounted, onUnmounted, ref } from "vue";
import { getPermissions } from "@/helpers";

export default {
  setup() {
    const systemsStore = useSystemsStore();
    const { systems_pending: systems } = storeToRefs(systemsStore);

    onMounted(() => {
      if (getPermissions("show systems")) {
        systemsStore.getPendingSystems(5);
      }
    });

    onUnmounted(() => {
      systemsStore.resetPendingSystems();
    });

    const isVisible = ref(false);

    return {
      systems,
      isVisible,
    };
  },
  emits: ["isVisible"],
  components: {
    SurfaceCard,
    ListPendingSystems,
    ButtonUI,
  },
  methods: {
    getPermissions,
  },
  watch: {
    systems(val) {
      this.isVisible = val.length > 0;
      this.$emit("isVisible", this.isVisible);
    },
  },
};
</script>
