<template>
  <PDropdown :class="['w-full', `p-dropdown-${size}`]">
    <template #value="slotProps">
      <div class="p-dropdown-inner" v-if="hasData(slotProps.value)">
        <vue-feather
          :type="slotProps.value.feather"
          size="16"
          class="p-dropdown-icon"
          v-if="slotProps.value?.feather"
        />
        <span class="p-dropdown-txt">{{ slotProps.value[optionLabel] }}</span>
      </div>
      <div class="p-dropdown-inner" v-else>
        <span class="p-dropdown-txt">{{ slotProps.placeholder }}</span>
      </div>
    </template>
    <template #option="slotProps">
      <div class="p-dropdown-inner">
        <vue-feather
          :type="slotProps.option.feather"
          size="16"
          class="p-dropdown-icon"
          v-if="slotProps.option?.feather"
        />
        <span class="p-dropdown-txt">{{ slotProps.option[optionLabel] }}</span>
      </div>
    </template>
  </PDropdown>
</template>
<style lang="scss">
.p-dropdown-inner {
  display: flex;
  align-items: center;
  .p-dropdown-icon {
    margin-right: spacing(2);
  }
}
.p-dropdown {
  &.p-dropdown-lg {
    .p-inputtext {
      font-size: font-size("base");
      padding: 0.862rem 0.625rem;
    }

    .p-dropdown-trigger {
      width: 3rem;
    }
  }

  &.p-dropdown-xl {
    .p-inputtext {
      font-size: font-size("base");
      padding: 1.1rem 1rem;
    }

    .p-dropdown-trigger {
      width: 3rem;
    }
  }
}
</style>
<script>
import Dropdown from "primevue/dropdown";
import VueFeather from "vue-feather";
import { isNotEmpty } from "@/helpers";
export default {
  components: {
    PDropdown: Dropdown,
    VueFeather,
  },
  props: {
    optionLabel: {
      type: String,
      default: null,
    },
    size: {
      type: String,
      default: "md",
    },
  },
  methods: {
    isObject(val) {
      if (val === null) {
        return false;
      }
      return typeof val === "function" || typeof val === "object";
    },
    hasData(value) {
      if (value) {
        if (this.isObject(value)) {
          return this.isNotEmpty(value);
        } else if (typeof value === "string") {
          return true;
        }
      }
      return false;
    },
    isNotEmpty,
  },
};
</script>
