<template>
  <PInputPassword
    v-if="type == 'password'"
    :name="inputID"
    :id="inputID"
    :toggleMask="true"
    :feedback="false"
    :placeholder="placeholder"
    class="w-full"
    :inputClass="inputClass"
    v-model="inputValue"
  />
  <div v-else-if="type == 'search'" class="p-inputsearch" :class="fieldClass">
    <ButtonUI
      type="submit"
      size="md"
      feather="search"
      color="text-palette-1"
      class="p-button-icon-only p-button-rounded"
    />
    <PInputText
      :type="type"
      :name="inputID"
      :id="inputID"
      :placeholder="placeholder"
      :minlength="minlength"
      :class="inputClass"
      v-model="inputValue"
    />
    <slot />
  </div>
  <PInputText
    v-else
    :name="inputID"
    :id="inputID"
    :type="type"
    :placeholder="placeholder"
    :minlength="minlength"
    v-model="inputValue"
    :class="inputClass"
  />
</template>
<script>
import InputText from "primevue/inputtext";
import Password from "primevue/password";
import ButtonUI from "@/components/ButtonUI.vue";
import { computed, toRefs } from "vue";

export default {
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const { modelValue } = toRefs(props);

    const inputValue = computed({
      get() {
        return modelValue.value;
      },
      set(value) {
        // modelValue.value = value;
        emit("update:modelValue", value);
      },
    });

    return {
      inputValue,
    };
  },
  components: {
    ButtonUI,
    PInputText: InputText,
    PInputPassword: Password,
  },
  props: {
    id: {
      type: String,
    },
    class: {
      type: String,
    },
    size: {
      type: String,
      default: "md",
    },
    placeholder: {
      type: String,
    },
    minlength: {
      type: Number,
      default: 0,
    },
    type: {
      type: String,
      default: "text",
    },
    modelValue: {},
  },
  computed: {
    inputClass() {
      return `w-full p-inputtext-${this.size} ${this.type === "search" ? "" : this.class}`;
    },
    fieldClass() {
      return this.class;
    },
    inputID() {
      return this.id ?? this.type == "text" ? "id" : this.type;
    },
  },
};
</script>

<style lang="scss" scoped>
.p-inputsearch {
  position: relative;
  .p-button {
    position: absolute;
    left: spacing(2);
    top: 50%;
    transform: translateY(-50%);
    z-index: 4;
  }
  .p-inputtext {
    width: 100%;
  }
}

input[type="search"] {
  border-radius: 26px;
  padding-left: spacing(8);
}

/* clears the 'X' from Internet Explorer */
input[type="search"]::-ms-clear,
input[type="search"]::-ms-reveal {
  display: none;
  width: 0;
  height: 0;
}

/* clears the 'X' from Chrome */
input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  display: none;
}
</style>
