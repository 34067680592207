import { i18n } from "@/helpers";

export const adminBreadcrumbs = (page) => {
  let breadcrumbs = [];

  switch (page) {
    case "admin-dashboard":
      breadcrumbs = [
        {
          name: "dashboard",
        },
      ];
      break;
    case "admin-users":
      breadcrumbs = [
        {
          name: "admin-users",
        },
      ];
      break;
    case "admin-users-create":
      breadcrumbs = [
        {
          name: "admin-users",
        },
        {
          name: "admin-users-create",
        },
      ];
      break;
    case "admin-users-edit":
      breadcrumbs = [
        {
          name: "admin-users",
        },
        {
          name: "admin-settings",
          link: false,
        },
      ];
      break;
    case "admin-impersonate":
      breadcrumbs = [
        {
          name: "admin-impersonate",
        },
      ];
      break;
    case "admin-impersonate-detail":
      breadcrumbs = [
        {
          name: "admin-impersonate",
        },
        {
          name: "admin-impersonate-detail",
          link: false,
        },
      ];
      break;
  }

  return [
    {
      title: i18n.global.t("label.admin"),
    },
    ...breadcrumbs,
  ];
};
