import LoginView from "@/views/_admin/login/LoginView.vue";
export const loginRoutes = [
  // Login
  {
    name: "admin-login",
    path: "/admin",
    component: LoginView,
    meta: {
      permission: "public",
    },
  },
];
