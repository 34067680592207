<template>
  <p v-html="text" />
</template>
<style lang="scss" scoped>
p {
  font-size: font-size("base");
  line-height: 1.5rem;
  color: var(--text-color);
  margin: 0 0 1.5rem 0;
}
</style>
<script>
export default {
  props: {
    text: String,
  },
};
</script>
