<template>
  <nav>
    <template v-if="isAdmin">
      <ul>
        <li>
          <NavItem :to="{ name: 'admin-dashboard' }" :name="$t('route.admin_dashboard')" feather="grid" />
        </li>
        <li v-if="getAdminPermissions('show admin users')">
          <NavItem :to="{ name: 'admin-users' }" :name="$t('route.admin_users')" feather="users" />
        </li>
        <li v-if="getAdminPermissions('impersonate users')">
          <NavItem :to="{ name: 'admin-impersonate' }" :name="$t('route.admin_impersonate')" feather="globe" />
        </li>
      </ul>
      <AdminMode class="admin-mode" />
    </template>

    <template v-else>
      <ul>
        <li>
          <NavItem :to="{ name: 'dashboard' }" feather="grid" />
        </li>
        <li v-if="getPermissions('show systems')">
          <NavItem :to="{ name: 'systems' }" feather="hard-drive" />
        </li>
        <!-- show systems if permissions for systems-groups but no permissions for general systems -->
        <li v-else-if="getPermissions('show groups')">
          <NavItem :to="{ name: 'systems-groups' }" :name="$t('route.systems')" feather="hard-drive" />
        </li>
        <li v-if="getPermissions('show users')">
          <NavItem :to="{ name: 'users' }" feather="users" />
        </li>
        <!-- TODO: PERMISSION SETTINGS -->
        <li>
          <NavItem :to="{ name: 'settings' }" :name="$t('route.settings')" feather="settings" />
        </li>
        <li class="divider"></li>
        <li v-if="getPermissions('search music')">
          <NavItem :to="{ name: 'music' }" feather="music" />
        </li>
        <li v-if="getPermissions('search slides')">
          <NavItem :to="{ name: 'slides' }" feather="monitor" />
        </li>
        <li v-if="getPermissions('search frames')">
          <NavItem :to="{ name: 'frames' }" feather="table" />
        </li>
        <li></li>
        <li v-if="getPermissions('search tickers')">
          <NavItem :to="{ name: 'tickers' }" feather="terminal" />
        </li>
      </ul>
    </template>
  </nav>
  <span class="help">
    <SupportLink />
  </span>
</template>
<script>
import NavItem from "@/components/nav/NavItem.vue";
import { getPermissions, getAdminPermissions } from "@/helpers";
import SupportLink from "@/components/SupportLink.vue";
import AdminMode from "@/components/AdminMode.vue";
import { computed } from "vue";
import { useNavStore } from "@/stores";

export default {
  setup() {
    const isAdmin = computed(() => useNavStore().isAdminPage);
    return {
      isAdmin,
    };
  },
  components: {
    AdminMode,
    SupportLink,
    NavItem,
  },
  methods: {
    getPermissions,
    getAdminPermissions,
  },
};
</script>
<style lang="scss" scoped>
.admin-mode {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 1;
}

.help {
  position: fixed;
  left: 15px;
  bottom: 15px;
  z-index: 1;
}

nav {
  ul {
    margin: 0;
    padding: 0;
    list-style: none;

    li {
      margin-bottom: 3px;
      &:last-child {
        margin-bottom: 0;
      }

      &.divider {
        border-bottom: 1px solid var(--app-sidebar-divider);
        margin: spacing(7) 0;
      }
    }
  }
}
</style>
