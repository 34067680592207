<template>
  <div ref="target">
    <slot></slot>
  </div>
</template>

<script>
import { useIntersectionObserver } from "@vueuse/core";
import { ref } from "vue";

export default {
  setup(props, { emit }) {
    const target = ref(null);

    const { stop } = useIntersectionObserver(target, ([{ isIntersecting }], observerElement) => {
      emit("onChange", { entry: target.value, isIntersecting });
    });

    return { target };
  },
};
</script>
