<template>
  <div id="view" class="login-view">
    <BackUI feather="x" variant="primary" :to="{ name: 'login' }" />

    <SurfaceCard type="register">
      <PageTitle :title="$t('route.forgot_password')" class="mb-5 text-3xl" />
      <form @submit.prevent="onSubmit">
        <NotificationMessage :error="error" />

        <FormRow>
          <FormLabel for="email" required>{{ $t("label.email") }}:</FormLabel>
          <FormText
            id="email"
            autocomplete="email"
            type="email"
            size="lg"
            :class="{ 'is-invalid': error.data?.email }"
            v-model="form.email"
          />
          <FormHelp type="invalid" :data="error.data" field="email" />
        </FormRow>

        <div class="field mb-0">
          <ButtonUI
            type="submit"
            size="lg"
            class="px-7"
            :label="$t('button.reset_password')"
            :disabled="isSubmitting"
          />
          <ButtonUI
            :underline="true"
            variant="text"
            color="text-palette-1"
            class="px-7"
            size="lg"
            :label="$t('button.cancel')"
            :to="{ name: 'login' }"
          />
        </div>
      </form>
    </SurfaceCard>
  </div>
</template>
<script>
import { useAuthStore } from "@/stores";

import PageTitle from "@/components/PageTitle.vue";
import FormRow from "@/components/form/FormRow.vue";
import FormText from "@/components/form/FormText.vue";
import FormHelp from "@/components/form/FormHelp.vue";
import ButtonUI from "@/components/ButtonUI.vue";
import NotificationMessage from "@/components/NotificationMessage.vue";
import FormLabel from "@/components/form/FormLabel.vue";
import SurfaceCard from "@/components/SurfaceCard.vue";
import BackUI from "@/components/BackUI.vue";
import { router } from "@/helpers";

export default {
  setup() {
    const authStore = useAuthStore();
    if (authStore.loggedIn) {
      router.push({ name: "dashboard" });
    }
  },
  components: {
    PageTitle,
    FormRow,
    ButtonUI,
    FormText,
    NotificationMessage,
    FormLabel,
    SurfaceCard,
    FormHelp,
    BackUI,
  },
  data() {
    return {
      error: {},
      form: {
        email: "",
      },
      isSubmitting: false,
    };
  },
  methods: {
    async onSubmit() {
      const authStore = useAuthStore();
      this.isSubmitting = true;

      try {
        await authStore.resetUserPasswordRequest(this.form.email);
      } catch (error) {
        this.error = error;
      } finally {
        this.isSubmitting = false;
      }
    },
  },
};
</script>
