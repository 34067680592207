import { defineStore } from "pinia";

import { useAuthStore as authStore } from "@/stores/_platform/auth.store";
import { useNotificationStore as notificationStore } from "@/stores/notification.store";
import { arrayHasChanged, customError, redirectToPreviousPage } from "@/helpers";
import { useNavStore } from "@/stores/nav.store";
import { useAppStore } from "@/stores/app.store";
import axiosInstance from "@/helpers/axios";

export const useUsersStore = defineStore({
  id: "users",
  state: () => ({
    users: [],
    migrate_users: [],
    users_detail: {},
    visibility_restricted_group_options: [],
    loading: false,
  }),
  getters: {},
  actions: {
    async createUser(formData) {
      const { first_name, last_name, email, description, roles } = formData;
      const is_active = true;
      useNavStore().setAppSpinner(true);
      try {
        const user = await axiosInstance.post(`/users`, {
          first_name,
          last_name,
          email,
          description,
          roles,
          is_active,
        });

        notificationStore().saveAlert(user.data.message);
        await redirectToPreviousPage("users");
      } catch (error) {
        if ([401, 403].includes(error.response?.status)) {
          await authStore().logout(error.response.status);
        } else {
          throw customError(error);
        }
      } finally {
        useNavStore().setAppSpinner(false);
      }
    },
    async updateUser(formData) {
      const { roles: tempRoles } = formData.tempForm;
      const { user_id, first_name, last_name, email, description, roles } = formData.form;
      const is_active = true;
      useNavStore().setAppSpinner(true);
      try {
        const rolesChanged = arrayHasChanged(roles, tempRoles);
        const user = await axiosInstance.put(`/users/${user_id}`, {
          first_name,
          last_name,
          email,
          description,
          roles,
          is_active,
        });

        // update permissions if roles changes
        if (rolesChanged) {
          await authStore().refreshPermissionsCustomerProfile();
        }
        notificationStore().saveAlert(user.data.message);
        await redirectToPreviousPage("users");
      } catch (error) {
        if ([401, 403].includes(error.response?.status)) {
          await authStore().logout(error.response.status);
        } else {
          throw customError(error);
        }
      } finally {
        useNavStore().setAppSpinner(false);
      }
    },
    async deleteOrMigrateUser(data) {
      const { user_id, type, migrate_user_id } = data;
      const authUser = { ...authStore().getUser };

      let options = {};

      if (type === "migrate" && migrate_user_id) {
        options.migrate_user_id = migrate_user_id;
      }

      useNavStore().setAppSpinner(true);
      try {
        const user = await axiosInstance.delete(`/users/${user_id}`, {
          data: options,
        });

        notificationStore().saveAlert(user.data.message);
        if (authUser?.id && authUser.id === user_id) {
          await authStore().logout();
        } else {
          await redirectToPreviousPage("users");
        }
      } catch (error) {
        if ([401, 403].includes(error.response?.status)) {
          await authStore().logout(error.response.status);
        } else {
          throw customError(error);
        }
      } finally {
        useNavStore().setAppSpinner(false);
      }
    },
    async getUserDetails(user_id) {
      useNavStore().setAppSpinner(true);
      try {
        const user = await axiosInstance.get(`/users/${user_id}`);

        const user_data = user.data.data;
        const roles = user_data.roles.map(({ id }) => id);

        // update pinia state
        this.users_detail = {
          user_id,
          page_name: `${user_data.first_name} ${user_data.last_name}`,
          first_name: user_data.first_name,
          last_name: user_data.last_name,
          email: user_data.email,
          description: user_data.description,
          roles,
        };
      } catch (error) {
        this.users_detail = customError(error);
        if ([401, 403].includes(error.response?.status)) {
          await authStore().logout(error.response.status);
        } else {
          notificationStore().saveMessage(error);
        }
      } finally {
        useNavStore().setAppSpinner(false);
      }
    },
    resetUserDetails() {
      this.users_detail = {};
    },
    async getUsers(group_id = null) {
      this.loading = true;
      useNavStore().setAppSpinner(true);
      try {
        const users = await axiosInstance.get(`/${group_id == null ? `users` : `groups/${group_id}/users`}`, {
          params: {
            Pagination: false,
          },
        });

        // update pinia state
        this.users = users.data.data;
      } catch (error) {
        this.users = customError(error);
        if ([401, 403].includes(error.response?.status)) {
          await authStore().logout(error.response.status);
        }
      } finally {
        this.loading = false;
        useNavStore().setAppSpinner(false);
      }
    },
    async getMigrateUsers(user_id) {
      if (!user_id) {
        return;
      }
      useNavStore().setAppSpinner(true);
      try {
        const users = await axiosInstance.get(`/users/${user_id}/content-migration`);

        // update pinia state
        this.migrate_users = users.data.data;
      } catch (error) {
        this.migrate_users = customError(error);
        if ([401, 403].includes(error.response?.status)) {
          await authStore().logout(error.response.status);
        }
      } finally {
        useNavStore().setAppSpinner(false);
      }
    },
    async getPublicRestrictedGroups() {
      try {
        const restrictedGroups = await axiosInstance.get(`/users/me`);
        this.visibility_restricted_group_options = restrictedGroups.data.data.groups;
      } catch (error) {
        if ([401, 403].includes(error.response?.status)) {
          await authStore().logout(error.response.status);
        } else {
          throw customError(error);
        }
      }
    },
  },
});
