<template>
  <form @submit.prevent="onSubmit">
    <DialogConfirm
      :visible="showLeaveConfirm"
      :cancelTextLink="true"
      :title="$t('title.leave_page')"
      :message="$t('message.changes_not_be_saved')"
      :yes="$t('button.save_changes')"
      :no="$t('button.discard_changes')"
      @onConfirm="onLeaveEditConfirm"
      @onCancel="onLeaveEditCancel"
      @onDiscard="onLeaveEditDiscard"
    />
    <DialogConfirm
      :visible="showConfirm"
      :title="$t('button.delete_group')"
      @onConfirm="onConfirm"
      @onCancel="onCancel"
    />
    <div class="grid">
      <div class="col-7">
        <SurfaceCard :title="$t('label.settings')" class="h-full">
          <template #body>
            <LimitWidth>
              <NotificationMessage :error="error" />
              <FormRow>
                <FormLabel for="first_name" required>{{ $t("label.name") }}:</FormLabel>
                <FormText
                  id="name"
                  type="text"
                  size="lg"
                  :class="{ 'is-invalid': error.data?.name }"
                  v-model="form.name"
                />
                <FormHelp type="invalid" :data="error.data" field="name" />
              </FormRow>
              <FormRow>
                <FormLabel for="last_name">{{ $t("label.description") }}:</FormLabel>
                <FormTextarea
                  id="description"
                  type="text"
                  size="lg"
                  :class="{ 'is-invalid': error.data?.description }"
                  v-model="form.description"
                />
                <FormHelp type="invalid" :data="error.data" field="description" />
              </FormRow>
              <FormRow>
                <FormLabel for="location">{{ $t("label.location") }}:</FormLabel>
                <FormText
                  id="location"
                  type="text"
                  size="lg"
                  :class="{ 'is-invalid': error.data?.location }"
                  v-model="form.location"
                />
                <FormHelp type="invalid" :data="error.data" field="location" />
              </FormRow>
              <FormRow type="checkbox">
                <FormCheckbox inputId="is_active" name="is_active" value="1" :binary="true" v-model="form.is_active" />
                <FormLabel for="is_active" class="font-medium">{{ $t("label.active") }}</FormLabel>
              </FormRow>
            </LimitWidth>
          </template>
        </SurfaceCard>
      </div>
      <div class="col-5">
        <SurfaceCard :title="$t('label.group_admins')" class="mb-5">
          <ManageList
            v-model:selected="form.admins"
            :add="admins"
            badge="credentials"
            :confirmTitle="$t('button.delete_group_admin')"
            :messageNoData="$t('message.no_connected_admins')"
            :placeholder="$t('placeholder.enter_name_of_user')"
            :triggerSubmit="triggerSubmit"
            @isReady="adminsAreReady = $event"
          >
            <template #footer>
              <FormHelp type="invalid" :data="error.data" field="admins" :multipleFields="true" />
            </template>
          </ManageList>
        </SurfaceCard>
        <SurfaceCard :title="$t('label.manage_systems')" v-show="getPermissions('show systems')">
          <ManageList
            v-model:selected="form.systems"
            :add="systems"
            :badge="$t('label.system')"
            :confirmTitle="$t('button.delete_group_system')"
            :messageNoData="$t('message.no_connected_systems')"
            :placeholder="$t('placeholder.enter_name_of_system')"
            :triggerSubmit="triggerSubmit"
            @isReady="systemsAreReady = $event"
          >
            <template #footer>
              <FormHelp type="invalid" :data="error.data" field="systems" :multipleFields="true" />
            </template>
          </ManageList>
        </SurfaceCard>
      </div>
      <div class="col-12"></div>
    </div>
  </form>
</template>

<script>
import SurfaceCard from "@/components/SurfaceCard.vue";
import FormRow from "@/components/form/FormRow.vue";
import FormLabel from "@/components/form/FormLabel.vue";
import FormHelp from "@/components/form/FormHelp.vue";
import FormText from "@/components/form/FormText.vue";
import FormTextarea from "@/components/form/FormTextarea.vue";
import FormCheckbox from "@/components/form/FormCheckbox.vue";
import LimitWidth from "@/components/LimitWidth.vue";
import ManageList from "@/components/ManageList.vue";
import DialogConfirm from "@/components/dialog/DialogConfirm.vue";
import NotificationMessage from "@/components/NotificationMessage.vue";

import { useUsersStore, useGroupsStore, useNavStore, useSystemsStore, useAuthStore } from "@/stores";

import { useRoute } from "vue-router";
import { storeToRefs } from "pinia";
import { getPermissions, router, setBreadcrumbs } from "@/helpers";
import { onMounted } from "vue";

export default {
  setup() {
    setBreadcrumbs();
    const route = useRoute();
    const { group_id } = route.params;

    const usersStore = useUsersStore();
    const groupsStore = useGroupsStore();
    const systemStore = useSystemsStore();

    const authStore = useAuthStore();
    authStore.validateMe();

    groupsStore.getGroupDetails(group_id);

    const { groups_detail } = storeToRefs(groupsStore);
    const form = groups_detail;

    usersStore.getUsers(group_id);
    const { users: admins } = storeToRefs(usersStore);

    const { systems } = storeToRefs(systemStore);
    onMounted(() => {
      if (getPermissions("show systems")) {
        systemStore.getGroupSystems();
      }
    });

    return { group_id, form, admins, systems };
  },
  components: {
    SurfaceCard,
    FormRow,
    FormLabel,
    FormText,
    FormTextarea,
    FormCheckbox,
    FormHelp,
    LimitWidth,
    ManageList,
    DialogConfirm,
    NotificationMessage,
  },
  data() {
    return {
      triggerSubmit: false,
      adminsAreReady: false,
      systemsAreReady: false,
      error: {},
      showConfirm: false,
      state: {},
      tempForm: {},
      playlistHasChanged: false,
      showLeaveConfirm: false,
      nextRouteName: null,
    };
  },
  methods: {
    async onSubmit() {
      this.triggerSubmit = true;
      this.resetManageList();
    },
    onDelete() {
      this.showConfirm = true;
    },
    onConfirm() {
      this.showConfirm = false;
      const groupsStore = useGroupsStore();
      return groupsStore.deleteGroup(this.group_id).catch((error) => {
        this.error = error;
      });
    },
    onCancel() {
      this.showConfirm = false;
    },
    onLeaveEditConfirm() {
      this.showLeaveConfirm = false;
      this.onSubmit();
    },
    onLeaveEditCancel() {
      this.showLeaveConfirm = false;
    },
    onLeaveEditDiscard() {
      this.pushNextRoute();
    },
    pushNextRoute() {
      if (this.nextRouteName) {
        this.playlistHasChanged = false;
        return router.push({ name: this.nextRouteName });
      }
    },
    resetManageList() {
      this.adminsAreReady = false;
      this.systemsAreReady = false;
    },
    getPermissions,
  },
  computed: {
    validatedSubmit() {
      const { triggerSubmit, adminsAreReady, systemsAreReady } = this;
      return triggerSubmit && adminsAreReady && systemsAreReady;
    },
    notFound() {
      return this.form?.status && this.form.status == 404;
    },
    title() {
      if (this.form?.page_name) {
        return this.form.page_name;
      }
      return null;
    },
  },
  watch: {
    validatedSubmit(submit) {
      if (submit) {
        const groupsStore = useGroupsStore();
        return groupsStore.updateGroup(this.form, this.nextRouteName).catch((error) => {
          this.error = error;
          this.triggerSubmit = false;
          this.resetManageList();
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
