import { i18n } from "@/helpers/i18n";

export function getRouteTranslation(key) {
  const route = `route.${key.replaceAll("-", "_")}`;
  const translation = i18n.global.t(route);

  if (!translation.match(/route\./)) {
    return translation;
  }
  return null;
}
