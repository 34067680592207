<template>
  <div class="view-body">
    <div class="content-wrapper">
      <PageTitle :title="$t('route.groups_create')" class="mb-7" :backUI="true" />
      <TabNav
        :items="[
          {
            title: $t('label.settings'),
            feather: 'settings',
          },
        ]"
        variant="tab"
      >
        <template #controls>
          <ButtonUI @click="onSubmit" size="lg" :label="$t('button.save_settings')" />
          <!--                      <ButtonUI-->
          <!--                        :underline="true"-->
          <!--                        variant="text"-->
          <!--                        color="text-palette-1"-->
          <!--                        size="lg"-->
          <!--                        :label="$t('button.cancel')"-->
          <!--                        @click="$router.back()"-->
          <!--                      />-->
        </template>
        <template #panels>
          <form @submit.prevent="onSubmit">
            <div class="grid">
              <div class="col-7">
                <SurfaceCard :title="$t('label.settings')">
                  <template #body>
                    <LimitWidth>
                      <NotificationMessage :error="error" />
                      <FormRow>
                        <FormLabel for="name" required>{{ $t("label.name") }}:</FormLabel>
                        <FormText
                          id="name"
                          type="text"
                          size="lg"
                          :class="{ 'is-invalid': error.data?.name }"
                          v-model="form.name"
                        />
                        <FormHelp type="invalid" :data="error.data" field="name" />
                      </FormRow>
                      <FormRow>
                        <FormLabel for="description">{{ $t("label.description") }}:</FormLabel>
                        <FormTextarea
                          id="description"
                          type="text"
                          size="lg"
                          :class="{ 'is-invalid': error.data?.description }"
                          v-model="form.description"
                        />
                        <FormHelp type="invalid" :data="error.data" field="description" />
                      </FormRow>
                      <FormRow>
                        <FormLabel for="location">{{ $t("label.location") }}:</FormLabel>
                        <FormText
                          id="location"
                          type="text"
                          size="lg"
                          :class="{ 'is-invalid': error.data?.location }"
                          v-model="form.location"
                        />
                        <FormHelp type="invalid" :data="error.data" field="location" />
                      </FormRow>
                      <FormRow type="checkbox">
                        <FormCheckbox
                          inputId="is_active"
                          name="is_active"
                          value="1"
                          :binary="true"
                          v-model="form.is_active"
                        />
                        <FormLabel for="is_active" class="font-medium">{{ $t("label.active") }}</FormLabel>
                      </FormRow>
                    </LimitWidth>
                  </template>
                </SurfaceCard>
              </div>
              <div class="col-5">
                <SurfaceCard :title="$t('label.group_admins')" class="mb-5">
                  <ManageList
                    v-model:selected="form.admins"
                    :add="admins"
                    badge="credentials"
                    :confirmTitle="$t('button.delete_group_admin')"
                    :placeholder="$t('placeholder.enter_name_of_user')"
                    :triggerSubmit="triggerSubmit"
                    @isReady="adminsAreReady = $event"
                  >
                    <template #footer>
                      <FormHelp type="invalid" :data="error.data" field="admins" :multipleFields="true" />
                    </template>
                  </ManageList>
                </SurfaceCard>
                <SurfaceCard :title="$t('label.manage_systems')" v-show="getPermissions('show systems')">
                  <ManageList
                    v-model:selected="form.systems"
                    :add="systems"
                    :badge="$t('label.system')"
                    :confirmTitle="$t('button.delete_group_system')"
                    :placeholder="$t('placeholder.enter_name_of_system')"
                    :triggerSubmit="triggerSubmit"
                    @isReady="systemsAreReady = $event"
                  >
                    <template #footer>
                      <FormHelp type="invalid" :data="error.data" field="systems" :multipleFields="true" />
                    </template>
                  </ManageList>
                </SurfaceCard>
              </div>
            </div>
          </form>
        </template>
      </TabNav>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/components/PageTitle.vue";
import SurfaceCard from "@/components/SurfaceCard.vue";
import FormRow from "@/components/form/FormRow.vue";
import FormLabel from "@/components/form/FormLabel.vue";
import FormText from "@/components/form/FormText.vue";
import FormTextarea from "@/components/form/FormTextarea.vue";
import FormCheckbox from "@/components/form/FormCheckbox.vue";
import FormHelp from "@/components/form/FormHelp.vue";
import ButtonUI from "@/components/ButtonUI.vue";
import LimitWidth from "@/components/LimitWidth.vue";
import ManageList from "@/components/ManageList.vue";
import NotificationMessage from "@/components/NotificationMessage.vue";
import { useAuthStore, useGroupsStore, useNavStore, useSystemsStore, useUsersStore } from "@/stores";
import { onMounted } from "vue";
import { getPermissions, i18n, setBreadcrumbs } from "@/helpers";
import { storeToRefs } from "pinia";
import TabNav from "@/components/nav/TabNav.vue";

export default {
  setup() {
    setBreadcrumbs();
    const usersStore = useUsersStore();
    const systemStore = useSystemsStore();

    const authStore = useAuthStore();
    authStore.validateMe();

    usersStore.getUsers();
    const { users: admins } = storeToRefs(usersStore);

    const { systems } = storeToRefs(systemStore);

    onMounted(() => {
      if (getPermissions("show systems")) {
        systemStore.getGroupSystems();
      }
    });

    return { admins, systems };
  },
  components: {
    TabNav,
    PageTitle,
    SurfaceCard,
    NotificationMessage,
    FormRow,
    FormLabel,
    FormText,
    FormTextarea,
    FormCheckbox,
    FormHelp,
    ButtonUI,
    LimitWidth,
    ManageList,
  },
  data() {
    return {
      triggerSubmit: false,
      adminsAreReady: false,
      systemsAreReady: false,
      error: {},
      form: {
        name: "",
        description: "",
        location: "",
        admins: [],
        systems: [],
        is_active: true,
      },
    };
  },
  computed: {
    validatedSubmit() {
      const { triggerSubmit, adminsAreReady, systemsAreReady } = this;
      return triggerSubmit && adminsAreReady && systemsAreReady;
    },
  },
  methods: {
    async onSubmit() {
      this.triggerSubmit = true;
      this.resetManageList();
    },
    resetManageList() {
      this.adminsAreReady = false;
      this.systemsAreReady = false;
    },
    getPermissions,
  },
  watch: {
    validatedSubmit(submit) {
      if (submit) {
        const groupsStore = useGroupsStore();
        return groupsStore.createGroup(this.form).catch((error) => {
          this.error = error;
          this.triggerSubmit = false;
          this.resetManageList();
        });
      }
    },
  },
};
</script>
