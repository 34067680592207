export function getHeightWithAspectRatio(width, aspectRatio = "16:9") {
  const ratioParts = aspectRatio.split(":");
  const aspectRatioWidth = parseInt(ratioParts[0]);
  const aspectRatioHeight = parseInt(ratioParts[1]);
  return (aspectRatioHeight / aspectRatioWidth) * width;
}

export function isAspectRatio(width, height, aspectRatio = "16:9") {
  const ratioParts = aspectRatio.split(":");
  const aspectRatioWidth = parseInt(ratioParts[0]);
  const aspectRatioHeight = parseInt(ratioParts[1]);
  return width / height === aspectRatioWidth / aspectRatioHeight;
}
