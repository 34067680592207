import { defineStore } from "pinia";

import { useAuthStore as authStore } from "@/stores/_platform/auth.store";
import { customError } from "@/helpers";
import { useNavStore } from "@/stores/nav.store";
import { useRoute } from "vue-router";
import axiosInstance from "@/helpers/axios";

export const useScheduleStore = defineStore({
  id: "schedule",
  state: () => ({
    conflicting: {},
  }),
  getters: {},
  actions: {
    async getScheduleConflicting() {
      const { name, params } = useRoute();
      useNavStore().setAppSpinner(true);

      let endpoint;
      let type;
      switch (name) {
        case "systems-detail-schedule":
          endpoint = `systems/${params.system_id}`;
          type = "systems";
          break;
        case "groups-detail-schedule":
          endpoint = `groups/${params.group_id}`;
          type = "groups";
          break;
      }

      try {
        const conflicted = await axiosInstance.get(`/${endpoint}/schedule-conflicting`);
        // update pinia state
        this.conflicting = { state: !!conflicted.data.hasConflictingEvents, type };
      } catch (error) {
        if ([401, 403].includes(error.response?.status)) {
          await authStore().logout(error.response.status);
        } else {
          throw customError(error);
        }
      } finally {
        useNavStore().setAppSpinner(false);
      }
    },
  },
});
