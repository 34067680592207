<template>
  <div class="view-body">
    <div class="content-wrapper">
      <PageTitle :title="$t('title.slides_overview')" />
      <TabNav :items="tabNavItems" :exact="true">
        <template #controls>
          <Transition name="page-fade" mode="out-in">
            <template v-if="!notFound">
              <div v-if="showControlsMyPlaylists">
                <ButtonUI
                  :label="$t('label.playlist_groups')"
                  color="secondary"
                  feather="settings"
                  :to="{ name: 'settings-playlist-groups-slides' }"
                  v-if="getPermissions('show slides categories')"
                />
                <ButtonUI
                  :label="$t('button.create_playlist')"
                  color="primary"
                  feather="plus"
                  class="ml-2"
                  :to="{ name: 'slides-playlist-create', query: { ...this.$route.query } }"
                  v-if="getPermissions('create playlists')"
                />
              </div>
              <div v-else-if="showControlsMySlides">
                <ButtonUI
                  :label="$t('button.upload_new_slide')"
                  color="primary"
                  feather="plus"
                  class="ml-2"
                  :to="{ name: 'slides-user-slides-create', query: { ...this.$route.query } }"
                  v-if="getPermissions('create slides')"
                />
              </div>
            </template>
          </Transition>
        </template>
        <template #panels>
          <RouterView name="Slides" v-slot="{ Component }" @notFound="notFound = $event">
            <Transition name="page-fade" mode="out-in">
              <component :is="Component" />
            </Transition>
          </RouterView>
        </template>
      </TabNav>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/components/PageTitle.vue";
import TabNav from "@/components/nav/TabNav.vue";
import ButtonUI from "@/components/ButtonUI.vue";

import { getPermissions, i18n, router, setBreadcrumbs } from "@/helpers";
import { onUnmounted } from "vue";
import { useSlidesStore } from "@/stores";

export default {
  setup() {
    setBreadcrumbs();
    const slideStore = useSlidesStore();

    onUnmounted(() => {
      slideStore.resetPlaylists();
    });

    const tabNavItems = [
      {
        title: i18n.global.t("label.user_playlists"),
        route: "slides",
        route_match: ["slides", "slides-user-category-other", "slides-user-category"],
        permissions: "show slides",
      },
      {
        title: i18n.global.t("label.user_slides"),
        route: "user-slides",
        route_match: ["user-slides"],
        permissions: "show slides",
      },
      {
        title: i18n.global.t("label.browse_slides"),
        route: "slides-browse",
        route_match: ["slides-browse", "slides-browse-category"],
        permissions: "show slides",
      },
    ];

    return {
      tabNavItems,
    };
  },
  data() {
    return {
      notFound: false,
      error: {},
    };
  },
  components: {
    ButtonUI,
    PageTitle,
    TabNav,
  },
  computed: {
    routerName() {
      return this.$route?.name;
    },
    term() {
      const { term } = this.$route.params;
      return term;
    },
    showControlsMyPlaylists() {
      return ["slides", "slides-user-category-other", "slides-user-category"].includes(this.routerName);
    },
    showControlsMySlides() {
      return ["user-slides"].includes(this.routerName);
    },
  },
  methods: {
    getPermissions,
  },
};
</script>
