<template>
  <div>
    <div v-if="!loading">
      <template v-if="playlists.length > 0">
        <div v-for="(item, index) in playlists" :key="index" class="playlist-group mb-8">
          <div class="grid align-items-end mb-6">
            <div class="col-6">
              <h2 class="mb-0">
                {{ item.name }} <span v-if="item.total_playlists > 0">({{ item.total_playlists }})</span>
              </h2>
            </div>
            <div class="col-6 text-right" v-if="showMoreState(index)">
              <RouterLink to="#" @click.prevent="onShowMoreClick(item)" class="show-more">{{
                $t("button.show_more")
              }}</RouterLink>
            </div>
          </div>
          <PlayLists
            :list="item.playlists"
            :limitByViewport="true"
            @showMore="showMoreRow(index, $event)"
            @onShowMoreClick="onShowMoreClick(item)"
          />
        </div>
      </template>
      <NotificationMessage type="default" v-else>{{ $t("message.no_playlists_found") }}</NotificationMessage>
    </div>
  </div>
</template>

<script>
import PlayLists from "@/components/_platform/playlist/PlayLists.vue";
import NotificationMessage from "@/components/NotificationMessage.vue";

import { getPermissions, router, setBreadcrumbs } from "@/helpers";
import { useMusicStore } from "@/stores";
import { storeToRefs } from "pinia";
import { setShowMoreRow, setShowMoreState } from "@/helpers/utils/showMoreCol";

export default {
  setup() {
    const musicStore = useMusicStore();
    musicStore.getPlaylists();

    setBreadcrumbs("music");

    const { playlists, loading } = storeToRefs(musicStore);

    return {
      playlists,
      loading,
    };
  },
  data() {
    return {
      showMoreRows: [],
    };
  },
  components: {
    PlayLists,
    NotificationMessage,
  },
  methods: {
    showMoreRow(index, value) {
      setShowMoreRow(this.showMoreRows, index, value);
    },
    showMoreState(index) {
      return setShowMoreState(this.showMoreRows, index);
    },
    onShowMoreClick(item) {
      router.push({
        name: item.id ? "music-user-category" : "music-user-category-other",
        params: { id: item.id },
        query: { ...this.$route.query },
      });
    },
    getPermissions,
  },
};
</script>
