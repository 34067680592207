import LeftSidebar from "@/components/LeftSidebar.vue";
import FramesIndexView from "@/views/_platform/frames/IndexView.vue";
import FramesOverView from "@/views/_platform/frames/partials/user-playlists/IndexView.vue";
import FramesUserFramesOverView from "@/views/_platform/frames/partials/user-frames/IndexView.vue";
import FramesBrowseView from "@/views/_platform/frames/partials/browse/IndexView.vue";
import FramesBrowseCategoryView from "@/views/_platform/frames/partials/browse/CategoryView.vue";
import FramesCategoryView from "@/views/_platform/frames/partials/user-playlists/CategoryView.vue";
import FramesDetailView from "@/views/_platform/frames/partials/user-playlists/DetailView.vue";
import FramesBrowseDetailView from "@/views/_platform/frames/partials/browse/DetailView.vue";
import FramesCreateView from "@/views/_platform/frames/partials/user-playlists/CreateView.vue";
import FramesUserFramesCreateView from "@/views/_platform/frames/partials/user-frames/CreateView.vue";
import FramesUserFramesEditView from "@/views/_platform/frames/partials/user-frames/EditView.vue";

export const framesRoutes = [
  // Frames
  {
    path: "/frames",
    name: "frames-index",
    components: {
      LeftSidebar,
      default: FramesIndexView,
    },
    children: [
      {
        path: "/frames",
        name: "frames",
        components: {
          Frames: FramesOverView,
        },
        meta: {
          permission: "search frames",
          supportPageID: "024",
        },
      },
      {
        path: "/frames/user-frames",
        name: "user-frames",
        components: {
          Frames: FramesUserFramesOverView,
        },
        meta: {
          permission: "search frames",
          supportPageID: "025",
        },
      },
      {
        path: "/frames/browse",
        name: "frames-browse",
        components: {
          Frames: FramesBrowseView,
        },
        meta: {
          permission: "search frames",
          supportPageID: "026",
        },
      },
      {
        name: "frames-browse-category",
        path: "/frames/browse/category/:id",
        components: {
          Frames: FramesBrowseCategoryView,
        },
        meta: {
          permission: "search frames",
          supportPageID: "026",
        },
      },
      {
        name: "frames-user-category",
        path: "/frames/category/:id",
        components: {
          Frames: FramesCategoryView,
        },
        meta: {
          permission: "search frames",
        },
      },
      {
        name: "frames-user-category-other",
        path: "/frames/other",
        components: {
          Frames: FramesCategoryView,
        },
        meta: {
          permission: "search frames",
          supportPageID: "024",
        },
      },
    ],
  },
  {
    name: "frames-detail",
    path: "/frames/playlist/:playlist_id/detail",
    components: {
      LeftSidebar,
      default: FramesDetailView,
    },
    meta: {
      permission: "search frames",
      supportPageID: "045",
    },
  },
  {
    name: "frames-browse-detail",
    path: "/frames/browse/:category_id-:playlist_id/detail",
    components: {
      LeftSidebar,
      default: FramesBrowseDetailView,
    },
    meta: {
      permission: "search frames",
    },
  },
  {
    name: "frames-playlist-create",
    path: "/frames/playlist/create",
    components: {
      LeftSidebar,
      default: FramesCreateView,
    },
    meta: {
      permission: "create playlists",
      supportPageID: "043",
    },
  },
  // Create frame inside my frames
  {
    name: "frames-user-frames-create",
    path: "/frames/user-frames/create",
    components: {
      LeftSidebar,
      default: FramesUserFramesCreateView,
    },
    meta: {
      permission: "create frames",
      supportPageID: "044",
    },
  },
  {
    name: "frames-user-frames-edit",
    path: "/frames/user-frames/:frame_id/edit",
    components: {
      LeftSidebar,
      default: FramesUserFramesEditView,
    },
    meta: {
      permission: "edit frames",
      supportPageID: "058",
    },
  },
];
