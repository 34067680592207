<template>
  <div id="view" class="register-view">
    <div class="controls inline-flex gap-4">
      <BackUI feather="x" variant="primary" :to="{ name: 'dashboard' }" />
    </div>
    <SurfaceCard type="create-customer">
      <form v-if="!isNotEmpty(profile)" @submit.prevent="onSubmit">
        <PageTitle :title="$t('route.customer_create')" class="mb-5 text-3xl" />
        <NotificationMessage :error="error" />
        <fieldset>
          <legend>{{ $t("label.company") }}</legend>
          <FormRow>
            <FormLabel for="customer_name" required>{{ $t("label.name") }}:</FormLabel>
            <FormText
              id="customer_name"
              autocomplete="customer_name"
              type="text"
              size="lg"
              :class="{ 'is-invalid': error?.name }"
              v-model="form.name"
            />
            <FormHelp type="invalid" :data="error.data" field="name" />
          </FormRow>

          <FormRow>
            <FormLabel for="customer_description">{{ $t("label.description") }}:</FormLabel>
            <FormTextarea
              id="customer_description"
              autocomplete="customer_description"
              type="text"
              size="lg"
              :class="{ 'is-invalid': error?.description }"
              v-model="form.description"
            />
            <FormHelp type="invalid" :data="error.data" field="description" />
          </FormRow>

          <FormRow>
            <FormLabel for="customer_location">{{ $t("label.location") }}:</FormLabel>
            <FormText
              id="customer_location"
              autocomplete="customer_location"
              type="text"
              size="lg"
              :class="{ 'is-invalid': error?.location }"
              v-model="form.location"
            />
            <FormHelp type="invalid" :data="error.data" field="location" />
          </FormRow>
        </fieldset>

        <div class="field mb-0">
          <ButtonUI
            type="submit"
            size="lg"
            class="px-7"
            :label="$t('button.create_customer')"
            :disabled="isSubmitting"
          />
          <ButtonUI
            :underline="true"
            variant="text"
            color="text-palette-1"
            class="px-7"
            size="lg"
            :label="$t('button.cancel')"
            :to="{ name: 'login' }"
          />
        </div>
      </form>
      <div v-else>
        <div v-if="multipleCustomersAndRegions">
          <PageTitle :title="$t('title.login_as_customer')" class="mb-5 text-3xl" />
          <IntroText :text="$t('region.differentRegions')" class="mb-4" />
          <ChangeCustomerForm size="lg" />
        </div>
        <div v-else>
          <PageTitle :title="$t('route.customer_create')" class="mb-5 text-3xl" />
          <IntroText :text="$t('message.create_customer_successfully')" />
          <div class="profile mb-3">
            <table>
              <tr v-if="profile?.name">
                <td class="pb-3">
                  <div class="label">{{ $t("label.customer_name") }}:</div>
                  <TextUI>{{ profile.name }}</TextUI>
                </td>
              </tr>
              <tr v-if="profile?.code">
                <td class="pb-3">
                  <div class="label">{{ $t("label.code") }}:</div>
                  <TextUI>{{ profile.code }}</TextUI>
                </td>
              </tr>
            </table>
          </div>
          <IntroText :text="$t('message.make_your_choice_to_continue')" />
          <div class="flex gap-2 white-space-nowrap">
            <ButtonUI type="submit" size="lg" :label="$t('button.login_as_customer')" @click="loginAsCustomer()" />
            <ButtonUI
              type="submit"
              size="lg"
              color="secondary"
              :label="$t('button.create_another_customer')"
              @click="createAnotherCustomer()"
            />
            <ButtonUI
              :underline="true"
              variant="text"
              color="text-palette-1"
              size="lg"
              :label="$t('button.go_to_dashboard')"
              :to="{ name: 'dashboard' }"
              class="ml-auto pr-0"
            />
          </div>
        </div>
      </div>
    </SurfaceCard>
  </div>
</template>
<script>
import { useAuthStore } from "@/stores";

import PageTitle from "@/components/PageTitle.vue";
import FormRow from "@/components/form/FormRow.vue";
import FormText from "@/components/form/FormText.vue";
import FormTextarea from "@/components/form/FormTextarea.vue";
import FormHelp from "@/components/form/FormHelp.vue";
import ButtonUI from "@/components/ButtonUI.vue";
import NotificationMessage from "@/components/NotificationMessage.vue";
import FormLabel from "@/components/form/FormLabel.vue";
import SurfaceCard from "@/components/SurfaceCard.vue";
import BackUI from "@/components/BackUI.vue";
import IntroText from "@/components/IntroText.vue";
import ChangeCustomerForm from "@/components/_platform/profile/ChangeCustomerForm.vue";
import TextUI from "@/components/TextUI.vue";
import { isNotEmpty } from "@/helpers";

export default {
  setup() {},
  components: {
    ChangeCustomerForm,
    TextUI,
    IntroText,
    PageTitle,
    FormRow,
    ButtonUI,
    FormText,
    FormTextarea,
    NotificationMessage,
    FormLabel,
    SurfaceCard,
    FormHelp,
    BackUI,
  },
  data() {
    return {
      profile: {},
      error: {},
      form: {
        name: "",
        description: "",
        location: "",
      },
      isSubmitting: false,
      multipleCustomersAndRegions: false,
    };
  },
  methods: {
    isNotEmpty,
    createAnotherCustomer() {
      // reset all values
      this.profile = {};
      this.error = {};
      this.form = {
        name: "",
        description: "",
        location: "",
      };
      this.isSubmitting = false;
      this.multipleCustomersAndRegions = false;
    },
    loginAsCustomer() {
      if (!this.customerHasRegions) {
        const profile = {
          customer: {
            id: this.profile.customer.id,
            name: this.profile.customer.name,
          },
          region: {
            id: this.profile.region.id,
            name: this.profile.region.name,
          },
          code: this.profile.code,
        };

        return useAuthStore().setCustomerProfile({ scenario: "change-customer", profile });
      } else {
        this.multipleCustomersAndRegions = true;
      }
    },
    async onSubmit() {
      const authStore = useAuthStore();
      this.isSubmitting = true;
      this.profile = {};

      try {
        this.profile = await authStore.createCustomer(this.form);
        await authStore.updateCustomersAuth(this.profile?.customers);
      } catch (error) {
        this.error = error;
      } finally {
        this.isSubmitting = false;
      }
    },
  },
  computed: {
    customerHasRegions() {
      return useAuthStore().customerHasRegions();
    },
  },
};
</script>
<style lang="scss" scoped>
.profile {
  .label {
    margin-bottom: spacing(2);
    font-weight: bold;
    display: inline-block;
  }
  p {
    margin: 0;
  }
}
</style>
