<template>
  <nav ref="breadcrumb" :class="{ 'is-ellipsis': isEllipsis }">
    <ol>
      <li v-for="(breadcrumb, index) in breadcrumbs" :key="index">
        <span v-if="index !== 0" class="separator px-2">></span>
        <RouterLink
          v-if="breadcrumb.name && index < total"
          :to="{ name: breadcrumb.name, params: breadcrumb.params }"
          >{{ title(breadcrumb) }}</RouterLink
        >
        <span v-else>{{ title(breadcrumb) }}</span>
      </li>
    </ol>
  </nav>
</template>
<script>
import { useNavStore } from "@/stores";
import { storeToRefs } from "pinia";

export default {
  setup() {
    const navStore = useNavStore();
    const { breadcrumbs } = storeToRefs(navStore);
    return {
      breadcrumbs,
    };
  },
  data() {
    return {
      setTitleTimeout: false,
      isEllipsis: false,
    };
  },
  computed: {
    total() {
      return Object.keys(this.breadcrumbs).length - 1;
    },
  },
  methods: {
    title(breadcrumb) {
      if (breadcrumb?.title) {
        return breadcrumb.title;
      } else {
        const translation = this.$t(`route.${breadcrumb.name.replaceAll("-", "_")}`);
        return translation.startsWith("route.") ? "NO TRANSLATION FOUND" : translation;
      }
    },
    setWidth() {
      this.$nextTick(() => {
        const breadcrumbRef = this.$refs.breadcrumb;
        const breadcrumbWidth = breadcrumbRef.clientWidth;

        const parentEl = breadcrumbRef.closest(".breadcrumbs");
        const parentWidth = parentEl.clientWidth - 10;
        this.isEllipsis = breadcrumbWidth > parentWidth;
      });
    },
  },
  watch: {
    breadcrumbs: {
      handler(breadcrumbs) {
        this.setWidth();
      },
      deep: true,
    },
  },
  mounted() {},
};
</script>
<style lang="scss" scoped>
nav {
  ol {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-flow: row;

    a {
      color: currentColor;
      text-decoration: none;
      &:hover {
        color: var(--link-hover-color);
      }
    }
  }

  &.is-ellipsis {
    ol {
      li:last-child {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        width: 200px;
      }
    }
  }
}
</style>
