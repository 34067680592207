<template>
  <div>
    <PDialog
      v-model:visible="state"
      :header="$t('title.duplicate_playlist')"
      :breakpoints="{ '960px': '75vw', '640px': '90vw' }"
      :style="{ width: '540px' }"
      :draggable="false"
      :modal="true"
    >
      <div class="dialog-content line-height-3">
        <TextUI>{{ $t("message.duplicate_playlist_and_tracks") }}</TextUI>
        <NotificationMessage :error="error" />
        <FormRow>
          <FormLabel for="title" required>{{ $t("label.title") }}:</FormLabel>
          <FormText
            id="title"
            type="text"
            size="lg"
            :class="{ 'is-invalid': error.data?.title }"
            v-model="title"
            @keyup.enter="handleEnterPress"
          />
          <FormHelp type="invalid" :data="error.data" field="title" />
        </FormRow>

        <!--        <template v-if="this.component === 'music'">-->
        <!--          <FormRow v-if="showSelect">-->
        <!--            <FormLabel for="category">{{ $t("label.group") }}:</FormLabel>-->
        <!--            <ManageList-->
        <!--              v-model:selected="selectedCategory"-->
        <!--              :limit="1"-->
        <!--              :showConfirmDialog="false"-->
        <!--              :add="categories"-->
        <!--              :messageNoData="false"-->
        <!--              :placeholder="$t('placeholder.enter_name')"-->
        <!--              :triggerSubmit="triggerSubmit"-->
        <!--              @isReady="manageListIsReady = $event"-->
        <!--            />-->
        <!--          </FormRow>-->
        <!--          <TextUI v-else>-->
        <!--            <span v-html="$t('message.no_groups_found')"></span>-->
        <!--          </TextUI>-->
        <!--        </template>-->
      </div>
      <template #footer>
        <ButtonUI size="lg" :label="$t('button.cancel')" @click="onCancel" />
        <ButtonUI size="lg" :label="$t('button.save')" icon="pi pi-check" @click="onSubmit" />
      </template>
    </PDialog>
  </div>
</template>
<script>
import Dialog from "primevue/dialog";
import ButtonUI from "@/components/ButtonUI.vue";
import TextUI from "@/components/TextUI.vue";
import NotificationMessage from "@/components/NotificationMessage.vue";
import { useFramesStore, useMusicStore, useSlidesStore, useTickersStore } from "@/stores";
import { storeToRefs } from "pinia";
import { getPermissions } from "@/helpers";
import FormText from "@/components/form/FormText.vue";
import FormRow from "@/components/form/FormRow.vue";
import FormHelp from "@/components/form/FormHelp.vue";
import FormLabel from "@/components/form/FormLabel.vue";
// import ManageList from "@/components/ManageList.vue";

export default {
  setup() {
    const musicStore = useMusicStore();
    const { categories } = storeToRefs(musicStore);
    return {
      categories,
    };
  },
  components: {
    // ManageList,
    FormLabel,
    FormHelp,
    FormRow,
    FormText,
    ButtonUI,
    TextUI,
    NotificationMessage,
    PDialog: Dialog,
  },
  props: {
    playlist_id: {
      type: String,
      default: "",
    },
    category_id: {
      type: String,
      default: "",
    },
    component: {
      type: String,
      default: "music",
      validator(value) {
        return ["music", "frames", "slides", "tickers"].includes(value);
      },
    },
    type: {
      type: String,
      default: "custom",
      validator(value) {
        return ["bcm", "custom"].includes(value);
      },
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      error: {},
      triggerSubmit: false,
      manageListIsReady: false,
      title: null,
      selectedCategory: [],
    };
  },
  emits: ["onCancel"],
  methods: {
    getPermissions,
    resetManageList() {
      this.manageListIsReady = false;
    },
    async onCancel() {
      this.state = false;
    },
    async onSubmit() {
      this.triggerSubmit = true;
      this.resetManageList();
    },
    handleEnterPress() {
      this.onSubmit();
    },
  },
  computed: {
    showSelect() {
      return this.categories.length > 0 || this.selectedCategory.length > 0;
    },
    validatedSubmit() {
      const { triggerSubmit, manageListIsReady } = this;
      return triggerSubmit;
      // return this.component === "music" ? triggerSubmit && manageListIsReady : triggerSubmit;
    },
    state: {
      get() {
        return this.visible;
      },
      set(state) {
        if (!state) {
          this.$emit("onCancel");
        }
      },
    },
  },
  watch: {
    async validatedSubmit(submit) {
      if (submit) {
        try {
          const { title, playlist_id, category_id, type } = this;
          switch (this.component) {
            case "music":
              await useMusicStore().duplicatePlaylist({ title, category_id, playlist_id, type });
              break;
            case "frames":
              await useFramesStore().duplicatePlaylist({ title, playlist_id });
              break;
            case "slides":
              await useSlidesStore().duplicatePlaylist({ title, playlist_id });
              break;
            case "tickers":
              await useTickersStore().duplicatePlaylist({ title, playlist_id });
              break;
          }

          this.error = {};
          this.state = false;
        } catch (error) {
          this.error = error;
        } finally {
          this.triggerSubmit = false;
        }
      } else {
        this.triggerSubmit = false;
      }
    },
  },
};
</script>
