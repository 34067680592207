<template>
  <div>
    <BackUI variant="primary" to="#" :title="categories_detail.name" class="mb-7" @click.prevent="goBack()" />
    <div v-if="!loading">
      <NotificationMessage type="default" v-if="notFound">{{ $t("404.text") }}</NotificationMessage>
      <FrameList :list="categories_detail.frames" type="bcm" />
    </div>
  </div>
</template>

<script>
import BackUI from "@/components/BackUI.vue";
import NotificationMessage from "@/components/NotificationMessage.vue";
import FrameList from "@/components/_platform/frames/FrameList.vue";

import { getPermissions, router, setBreadcrumbs } from "@/helpers";
import { useNavStore, useFramesStore } from "@/stores";
import { storeToRefs } from "pinia";
import { useRoute } from "vue-router";

export default {
  setup() {
    const route = useRoute();
    const { id } = route.params;

    const framesStore = useFramesStore();
    framesStore.getFramesByCategory(id);

    const { categories_detail, loading } = storeToRefs(framesStore);

    return {
      categories_detail,
      loading,
    };
  },
  emits: ["notFound"],
  components: {
    BackUI,
    FrameList,
    NotificationMessage,
  },
  methods: {
    getPermissions,
    goBack() {
      router.push({ name: "frames-browse" });
    },
  },
  computed: {
    notFound() {
      const notFound = this.categories_detail?.status && this.categories_detail.status == 404;
      this.$emit("notFound", notFound);
      return notFound;
    },
  },
  watch: {
    categories_detail: {
      handler(category) {
        if (category?.name) {
          const navStore = useNavStore();
          navStore.setBreadcrumb("frames-user-category");
          navStore.setBreadcrumbTitle(category.name, 2);
        }
      },
      immediate: true,
    },
  },
};
</script>
