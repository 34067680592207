<template>
  <div class="view-body">
    <DialogConfirm
      :visible="showLeaveConfirm"
      :cancelTextLink="true"
      :title="$t('title.leave_page')"
      :message="$t('message.changes_not_be_saved')"
      :yes="$t('button.save_changes')"
      :no="$t('button.discard_changes')"
      @onConfirm="onLeaveEditConfirm"
      @onCancel="onLeaveEditCancel"
      @onDiscard="onLeaveEditDiscard"
    />
    <DialogDuplicatePlaylist
      v-if="showDuplicateConfirm"
      :visible="showDuplicateConfirm"
      :playlist_id="playlist_id"
      component="tickers"
      @onCancel="onDuplicateCancel"
    />
    <div class="content-wrapper">
      <div v-if="!loading">
        <div class="position-relative">
          <BackUI variant="primary" to="#" class="mb-7" @click.prevent="goBack()" />
          <NotificationMessage type="404" v-if="notFound" />
          <template v-else>
            <template v-if="showTab(0)">
              <PlaylistHeader
                :image="header.image"
                :title="header.title"
                :text="header.description"
                :visibility="header.visibility?.value"
                class="mb-6"
              >
                <template #details>
                  <!-- TODO: PERMISSIONS -->
                  <ButtonUI
                    class="duplicate-playlist"
                    variant="text"
                    color="text-palette-1"
                    size="lg"
                    feather="copy"
                    :label="$t('button.duplicate_playlist')"
                    @click="showDuplicateConfirm = true"
                  />

                  <div class="flex mt-3 gap-2">
                    <BadgeUI
                      v-if="form.item_count > 0"
                      :title="getCountTitle({ count: form.item_count, type: 'tickers' })"
                      color="primary"
                    />
                  </div>
                </template>
              </PlaylistHeader>
            </template>
            <template v-else>
              <PageTitle :title="$t('title.playlist_settings')" />
            </template>
            <TabNav :items="tabNavItems" variant="tab" v-model:activeTab="activeTab">
              <template #controls>
                <div v-if="!showTab(0)">
                  <ButtonUI
                    :underline="true"
                    variant="text"
                    color="text-palette-1"
                    size="lg"
                    :label="$t('button.delete_playlist')"
                    @click="deletePlaylist()"
                    v-if="getPermissions('delete playlists')"
                  />
                  <ButtonUI @click="onSubmit" size="lg" :label="$t('button.save_settings')" />
                </div>
              </template>
              <template #panels>
                <div class="tab-panel" v-if="showTab(0)">
                  <TableTickers
                    :reorder="true"
                    :remove="true"
                    :tickers="form.tickers"
                    @removedTickerId="removedTickerId = $event"
                  />
                </div>
                <div class="tab-panel" v-show="showTab(1)">
                  <div class="grid">
                    <div class="col-7">
                      <SurfaceCard :title="$t('label.settings')">
                        <form>
                          <div class="card-body">
                            <NotificationMessage :error="error" />

                            <div class="flex">
                              <div class="col-upload flex-shrink-0">
                                <UploadUI :image="form.image" @uploadChange="uploadChange" @error="uploadError" />
                              </div>

                              <div class="col-fields pl-5 flex-grow-1 flex-shrink-1">
                                <FormRow>
                                  <FormLabel for="playlist_name" required>{{ $t("label.playlist_name") }}:</FormLabel>
                                  <FormText
                                    id="playlist_name"
                                    type="text"
                                    size="lg"
                                    :class="{ 'is-invalid': error.data?.title }"
                                    v-model="form.title"
                                    @change="changeInput"
                                  />
                                  <FormHelp type="invalid" :data="error.data" field="title" />
                                </FormRow>
                                <FormRow class="mb-0">
                                  <FormLabel for="description">{{ $t("label.description") }}:</FormLabel>
                                  <FormTextarea
                                    id="description"
                                    type="text"
                                    size="lg"
                                    :class="{ 'is-invalid': error.data?.description }"
                                    v-model="form.description"
                                    @change="changeInput"
                                  />
                                  <FormHelp type="invalid" :data="error.data" field="description" />
                                </FormRow>
                              </div>
                            </div>
                            <FormHelp type="invalid" :data="error.data" field="image" />

                            <FormRow class="mt-7">
                              <FormLabel for="visibility" required>{{ $t("label.visibility") }}:</FormLabel>
                              <FormSelect
                                id="customers"
                                class="max-w-20rem"
                                size="lg"
                                :class="{ 'is-invalid': error.data?.visibility }"
                                v-model="form.visibility"
                                :options="visibilityOptions"
                                optionLabel="name"
                                :placeholder="$t('placeholder.select_a_visibility')"
                                @change="changeInput"
                              />
                              <FormHelp type="invalid" :data="error.data" field="visibility" />
                            </FormRow>

                            <FormRow v-show="form.visibility?.value === 'public_restricted'">
                              <ManageList
                                v-model:selected="form.groups"
                                :add="visibilityRestrictedOptions"
                                :confirmTitle="$t('button.delete_public_selected_groups')"
                                :messageNoData="$t('message.no_selected_groups')"
                                :placeholder="$t('placeholder.enter_name')"
                                :triggerSubmit="triggerSubmit"
                                @isReady="restrictedOptionsAreReady = $event"
                                @change="changeInput"
                              >
                                <template #footer>
                                  <TextUI v-if="visibilityRestrictedOptions.length == 0" class="mb-5">
                                    {{ $t("message.no_data_to_add_found") }}
                                  </TextUI>
                                  <FormHelp type="invalid" :data="error.data" field="groups" :multipleFields="true" />
                                </template>
                              </ManageList>
                            </FormRow>
                          </div>
                        </form>
                        <DialogConfirm
                          :visible="showConfirm"
                          :title="$t('button.delete_playlist')"
                          @onConfirm="onDeleteConfirm"
                          @onCancel="onDeleteCancel"
                        >
                          <template #body>
                            {{ this.$t("message.confirmation_proceed") }}
                            <NoteAlert
                              v-if="form.is_scheduled"
                              :text="this.$t('message.note_scheduled_for_the_future')"
                              class="mt-6"
                            />
                          </template>
                        </DialogConfirm>
                      </SurfaceCard>
                    </div>
                    <!--                  <div class="col-5" v-show="getPermissions('show tickers categories')">-->
                    <!--                    <SurfaceCard :title="$t('label.playlist_groups')" class="mb-5">-->
                    <!--                      <ManageList-->
                    <!--                        v-model:selected="form.categories"-->
                    <!--                        :add="categories"-->
                    <!--                        :limit="1"-->
                    <!--                        :badge="$t('label.groups')"-->
                    <!--                        :confirmTitle="$t('label.disconnect_playlist_group')"-->
                    <!--                        :messageNoData="$t('message.no_connected_groups')"-->
                    <!--                        :placeholder="$t('placeholder.enter_name')"-->
                    <!--                        :triggerSubmit="triggerSubmit"-->
                    <!--                        @isReady="categoriesAreReady = $event"-->
                    <!--                        @change="changeInput"-->
                    <!--                      >-->
                    <!--                        <template #footer>-->
                    <!--                          <FormHelp type="invalid" :data="error.data" field="admins" :multipleFields="true" />-->
                    <!--                        </template>-->
                    <!--                      </ManageList>-->
                    <!--                    </SurfaceCard>-->
                    <!--                  </div>-->
                  </div>
                </div>
              </template>
            </TabNav>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TabNav from "@/components/nav/TabNav.vue";
import PageTitle from "@/components/PageTitle.vue";
import SurfaceCard from "@/components/SurfaceCard.vue";
import FormRow from "@/components/form/FormRow.vue";
import FormHelp from "@/components/form/FormHelp.vue";
import FormLabel from "@/components/form/FormLabel.vue";
import FormText from "@/components/form/FormText.vue";
import FormTextarea from "@/components/form/FormTextarea.vue";
import FormSelect from "@/components/form/FormSelect.vue";
import ButtonUI from "@/components/ButtonUI.vue";
import DialogConfirm from "@/components/dialog/DialogConfirm.vue";
import ManageList from "@/components/ManageList.vue";
import UploadUI from "@/components/UploadUI.vue";
import PlaylistHeader from "@/components/_platform/playlist/PlaylistHeader.vue";
import BackUI from "@/components/BackUI.vue";
import TableTickers from "@/components/_platform/table/tickers/TableTickers.vue";

import { useRoute } from "vue-router";
import { useNavStore, useTickersStore, useUsersStore } from "@/stores";
import { storeToRefs } from "pinia";
import NotificationMessage from "@/components/NotificationMessage.vue";
import { onUnmounted } from "vue";
import {
  formHasChanged,
  getCountTitle,
  getPermissions,
  i18n,
  isNotEmpty,
  redirectToBackPage,
  router,
  setBreadcrumbs,
} from "@/helpers";
import DialogDuplicatePlaylist from "@/components/_platform/dialog/DialogDuplicatePlaylist.vue";
import TextUI from "@/components/TextUI.vue";
import BadgeUI from "@/components/BadgeUI.vue";
import NoteAlert from "@/components/NoteAlert.vue";

export default {
  setup() {
    setBreadcrumbs();
    const route = useRoute();
    const { playlist_id, hash } = route.params;

    const tickersStore = useTickersStore();
    tickersStore.getPlaylistsDetail({ playlist_id });
    tickersStore.getCategories();

    const usersStore = useUsersStore();
    usersStore.getPublicRestrictedGroups();

    const {
      playlists_detail: form,
      visibility_options: visibilityOptions,
      categories,
      loading,
    } = storeToRefs(tickersStore);

    const { visibility_restricted_group_options: visibilityRestrictedOptions } = storeToRefs(usersStore);

    onUnmounted(() => {
      tickersStore.resetPlaylistsDetail();
    });

    const tabNavItems = [
      {
        title: i18n.global.t("label.tickers"),
        feather: "terminal",
        permissions: "show playlists",
      },
      {
        title: i18n.global.t("label.settings"),
        feather: "settings",
        permissions: "edit playlists",
      },
    ];

    return {
      tabNavItems,
      playlist_id,
      form,
      visibilityOptions,
      visibilityRestrictedOptions,
      hash,
      categories,
      loading,
    };
  },
  components: {
    NoteAlert,
    BadgeUI,
    TextUI,
    DialogDuplicatePlaylist,
    TabNav,
    PageTitle,
    SurfaceCard,
    FormRow,
    FormLabel,
    FormText,
    FormTextarea,
    FormSelect,
    FormHelp,
    ButtonUI,
    ManageList,
    DialogConfirm,
    NotificationMessage,
    UploadUI,
    PlaylistHeader,
    BackUI,
    TableTickers,
  },
  data() {
    return {
      showDuplicateConfirm: false,
      triggerSubmit: false,
      categoriesAreReady: false,
      restrictedOptionsAreReady: false,
      error: {},
      showConfirm: false,
      showLeaveConfirm: false,
      playlistHasChanged: false,
      tempForm: {},
      tempCategories: {},
      activeTab: {},
      nextRouteName: null,
      removedTickerId: null,
    };
  },
  methods: {
    getCountTitle,
    async onSubmit() {
      this.triggerSubmit = true;
      this.resetManageList();
    },
    async goBack() {
      return redirectToBackPage({ name: "tickers" });
    },
    pushNextRoute() {
      if (this.nextRouteName) {
        this.playlistHasChanged = false;
        return router.push({ name: this.nextRouteName });
      }
    },
    showTab(index) {
      if (this.activeTab?.index === undefined && index === 0) {
        return true;
      } else if (this.activeTab.index === index) {
        return true;
      }
      return false;
    },
    resetManageList() {
      this.categoriesAreReady = false;
      this.restrictedOptionsAreReady = false;
    },
    // Duplicate
    onDuplicateCancel() {
      this.showDuplicateConfirm = false;
    },
    deletePlaylist() {
      this.showConfirm = true;
    },
    onDeleteConfirm() {
      this.showConfirm = false;
      const tickersStore = useTickersStore();
      return tickersStore.deletePlaylist(this.playlist_id).catch((error) => {
        this.error = error;
      });
    },
    onDeleteCancel() {
      this.showConfirm = false;
    },
    onLeaveEditConfirm() {
      this.showLeaveConfirm = false;
      this.onSubmit();
    },
    onLeaveEditCancel() {
      this.showLeaveConfirm = false;
      if (!this.nextRouteName) {
        this.activeTab.index = 1;
      }
    },
    onLeaveEditDiscard() {
      if (this.nextRouteName) {
        this.pushNextRoute();
      } else {
        this.playlistHasChanged = false;

        // reset form
        this.error = {};
        this.form = JSON.parse(JSON.stringify(this.tempForm));
        this.categories = JSON.parse(JSON.stringify(this.tempCategories));
        this.activeTab.index = 0;
      }
    },
    uploadChange(image) {
      this.form.image = image;
      this.changeInput();
    },
    uploadError(error) {
      this.error = {};
      if (error.length > 0) {
        this.error = { message: error[0], data: { upload: [error] } };
      }
    },
    changeInput() {
      this.playlistHasChanged = this.formHasChanged;
    },
    getPermissions,
    isNotEmpty(e) {
      return isNotEmpty(e);
    },
  },
  computed: {
    header() {
      return this.tempForm;
    },
    notFound() {
      return this.form?.status && this.form.status == 404;
    },
    validatedSubmit() {
      // const { triggerSubmit, categoriesAreReady, restrictedOptionsAreReady } = this;
      // return triggerSubmit && categoriesAreReady && restrictedOptionsAreReady;
      const { triggerSubmit, restrictedOptionsAreReady } = this;
      return triggerSubmit && restrictedOptionsAreReady;
    },
    formHasChanged() {
      const validatedField = [
        {
          field: "categories",
          type: "Array",
          key: "id",
        },
        // {
        //   field: "groups",
        //   type: "Array",
        //   key: "id",
        // },
        {
          field: "title",
          type: "String",
        },
        {
          field: "visibility",
          type: "Object",
          key: "value",
        },
        {
          field: "image",
          type: "String",
        },
        {
          field: "description",
          type: "String",
        },
      ];

      return formHasChanged({
        validatedField,
        tempForm: this.tempForm,
        form: this.form,
      });
    },
  },
  beforeRouteLeave(to, from) {
    if (this.playlistHasChanged) {
      this.showLeaveConfirm = true;
      this.nextRouteName = to.name;
      return false;
    }
  },
  watch: {
    tempForm(value) {
      const playlist = value;
      if (playlist?.title) {
        useNavStore().setBreadcrumbTitle(playlist.title, 1);
      }
    },
    form(value) {
      this.tempForm = JSON.parse(JSON.stringify(value));
    },
    categories(value) {
      this.tempCategories = JSON.parse(JSON.stringify(value));
    },
    activeTab(tab, oldTab) {
      // if tab changed by index
      const settingsTabIndex = 1;
      if (oldTab.index === settingsTabIndex && tab.index !== settingsTabIndex && this.playlistHasChanged) {
        this.showLeaveConfirm = true;
      }
    },
    async validatedSubmit(submit) {
      if (submit) {
        const tickersStore = useTickersStore();
        try {
          await tickersStore.updatePlaylist(this.form);

          if (this.nextRouteName) {
            this.pushNextRoute();
          } else {
            this.playlistHasChanged = false;
            this.activeTab.index = 0;
            this.tempForm = JSON.parse(JSON.stringify(this.form));
            this.tempCategories = JSON.parse(JSON.stringify(this.categories));
            this.error = {};
          }
        } catch (error) {
          this.error = error;
          this.resetManageList();
        } finally {
          this.triggerSubmit = false;
        }
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.position-relative {
  position: relative;
  .duplicate-playlist {
    position: absolute;
    right: 0;
    top: 0;
  }
}
</style>
