import { defineStore } from "pinia";
import { router, customError, redirectToPreviousPage, i18n, isNotEmpty } from "@/helpers";
import * as Sentry from "@sentry/browser";
import { useGtm } from "@gtm-support/vue-gtm";

import {
  useUsersStore,
  useNotificationStore,
  useGroupsStore,
  useNotificationStore as notificationStore,
  useSystemsStore,
  useNavStore,
  useMusicStore,
  useFramesStore,
  useSlidesStore,
} from "@/stores";
import { useAppStore } from "@/stores/app.store";
import axiosInstance from "@/helpers/axios";

export const useAuthStore = defineStore({
  id: "auth",
  state: () => ({
    // initialize state from local storage to enable user to stay logged in
    auth: localStorage.getDecryptedItem("auth"),
    customerProfile: localStorage.getDecryptedItem("customer_profile"),
    validateExpirationInterval: false,
    authImpersonateLogin: localStorage.getDecryptedItem("auth_impersonate_login"),
  }),
  getters: {
    loggedIn() {
      return !!this.auth;
    },
    impersonateIsActive() {
      return !!this.authImpersonateLogin;
    },
    hasCustomerProfile() {
      return !!this.customerProfile;
    },
    getUser(state) {
      if (state.auth?.user) {
        return state.auth.user;
      }
      return {};
    },
    getCustomers(state) {
      if (state.auth?.user?.customers) {
        return state.auth.user.customers;
      }
      return [];
    },
    getCustomerProfile(state) {
      return state.customerProfile;
    },
    getToken(state) {
      return state.auth?.authorisation?.type && state.auth?.authorisation?.token
        ? `${state.auth.authorisation.type} ${state.auth.authorisation.token}`
        : null;
    },
    getExpirationDate(state) {
      return state.auth?.authorisation?.expiration ? parseInt(state.auth.authorisation.expiration) : null;
    },
  },
  actions: {
    // async refresh() {
    //   const { apiUrl } = appConfig();
    //   if (this.loggedIn) {
    //     try {
    //       const refresh = await axiosInstance.post(
    //         `/auth/refresh`,
    //         {},
    //       );
    //
    //       const authorisationData = refresh.data?.data?.authorisation;
    //
    //       // refresh current auth data
    //       this.auth.authorisation = {
    //         expiration: authorisationData?.expiration,
    //         token: authorisationData?.token,
    //         type: authorisationData?.type,
    //       };
    //       localStorage.setItem("auth", JSON.stringify(this.auth));
    //     } catch (error) {
    //       throw customError(error);
    //     }
    //   } else {
    //     this.auth = null;
    //   }
    // },
    backupForImpersonate({ user_id, returnUrl }) {
      const backup = {
        user_id,
        returnUrl,
        auth: this.auth,
        customerProfile: this.customerProfile,
      };

      this.authImpersonateLogin = backup;
      localStorage.setEncryptedItem("auth_impersonate_login", backup);
    },
    restoreForImpersonate() {
      const restore = this.authImpersonateLogin;

      // check if restore data exists
      if (
        restore?.auth &&
        isNotEmpty(restore.auth) &&
        restore?.customerProfile &&
        isNotEmpty(restore.customerProfile)
      ) {
        this.auth = restore.auth;
        this.customerProfile = restore.customerProfile;
        localStorage.setEncryptedItem("auth", this.auth);
        localStorage.setEncryptedItem("customer_profile", this.customerProfile);
      } else {
        // no restore data --> reset and logout auth
        this.resetAuth();
      }

      this.resetImpersonate();
    },
    async register(formData) {
      const { first_name, last_name, email, language, customer } = formData;
      useNavStore().setAppSpinner(true);
      try {
        const user = await axiosInstance.post(`/auth/register`, {
          first_name,
          last_name,
          email,
          language,
          customer,
        });

        notificationStore().saveAlert(i18n.global.t("message.register_success"), {
          duration: 30000,
          position: "top",
        });
        // await router.push({ name: "login", query: { register: true } });
        await redirectToPreviousPage("login");
      } catch (error) {
        const error_provider_id = error.response?.data?.data["customer.provider_id"];
        if (error_provider_id) {
          error.status = "BCM";
          error.message = `${i18n.global.t("message.please_contact_bcm")} ${
            error.response.data.data["customer.provider_id"][0]
          }`;
        }
        throw customError(error);
      } finally {
        useNavStore().setAppSpinner(false);
      }
    },
    async verify(formData, route) {
      const { password, password_confirmation } = formData;
      useNavStore().setAppSpinner(true);
      try {
        const user = await axiosInstance.post(
          `/auth/verify/${route.query.id}`,
          { password, password_confirmation },
          {
            params: {
              expires: route.query.expires,
              hash: route.query.hash,
              signature: route.query.signature,
            }, //Add mail as a param
          }
        );

        notificationStore().saveAlert(user.data.message);
        await redirectToPreviousPage("login");
      } catch (error) {
        throw customError(error);
      } finally {
        useNavStore().setAppSpinner(false);
      }
    },
    setAuth(data) {
      if (!data) {
        return;
      }

      this.resetCustomerProfile();

      const auth = {
        user: {
          id: data.user.id,
          email: data.user.email,
          first_name: data.user.first_name,
          last_name: data.user.last_name,
          customers: data.user.customers,
        },
        authorisation: data.authorisation,
      };

      // update pinia state
      this.auth = auth;

      // store user details and jwt in local storage to keep user logged in between page refreshes
      localStorage.setEncryptedItem("auth", auth);
      Sentry.setUser({ id: data.user.id });

      this.startExpirationValidation();
      this.initCustomerProfile();
    },
    async login(email, password, remember_me) {
      const { providerID: provider } = useAppStore().getAppConfig;
      useNavStore().setAppSpinner(true);
      try {
        const login = await axiosInstance.post(`/auth/login`, { email, password, remember_me, provider });
        this.resetImpersonate();
        this.setAuth(login.data.data);
        this.initCustomerProfile();
      } catch (error) {
        throw customError(error);
      } finally {
        useNavStore().setAppSpinner(false);
      }
    },
    async createCustomer(formData) {
      const { name, description, location } = formData;
      useNavStore().setAppSpinner(true);
      try {
        const customer = await axiosInstance.post(`/customers/register`, { name, description, location });
        return customer.data.data;
      } catch (error) {
        if ([401, 403].includes(error.response?.status)) {
          this.logout(error.response.status);
        } else {
          throw customError(error);
        }
      } finally {
        useNavStore().setAppSpinner(false);
      }
    },
    async initCustomerProfile() {
      // user has Regions
      if (this.customerHasRegions()) {
        await router.push({ name: "login-region" });
      } else {
        // update profile if 1 or no regions
        const customers = this.getCustomers;

        if (customers.length > 0) {
          const customer = customers[0];
          const region = customer.regions[0];
          const profile = {
            customer: {
              id: customer.id,
              name: customer.name,
            },
            region: {
              id: region.id,
              name: region.name,
            },
            code: customer.code,
            permissions: customer.permissions,
          };
          await this.setCustomerProfile({ scenario: "login", profile });
        } else {
          this.resetAuth();
          console.log("User has no customers");
          await router.push({ name: "login" });
        }
      }
    },
    async setCustomerProfile(data) {
      const { scenario, profile } = data;

      const tempCustomerProfilePermissions = this.customerProfile?.permissions;
      this.customerProfile = profile;

      if (this.customerProfile?.permissions && tempCustomerProfilePermissions) {
        this.customerProfile.permissions = tempCustomerProfilePermissions;
      }

      localStorage.setEncryptedItem("customer_profile", this.customerProfile);

      const gtm = useGtm();
      gtm.trackEvent({
        event: scenario, // login or change-customer
        user_id: "dddd",
        customer_id: this.customerProfile?.customer?.id,
        region: this.customerProfile?.region?.name,
        code: this.customerProfile?.code,
      });
      await router.push({ name: "dashboard" });
    },
    customerHasRegions() {
      const customers = this.getCustomers;
      let redirect = false;

      if (customers.length == 1) {
        const regions = customers[0].regions;
        if (regions.length > 1) {
          redirect = true;
        }
      } else if (customers.length > 1) {
        redirect = true;
      }

      return redirect;
    },
    customerHasPermissions(permissions) {
      const profile = this.getCustomerProfile;
      if (profile) {
        const profile_permissions = profile?.permissions;

        if (profile_permissions) {
          const include_permissions = Array.isArray(permissions) ? permissions : permissions.split(","); // check if permissions is an array
          return include_permissions.some((i) => profile_permissions.includes(i));
        }
      }
      return false;
    },
    async updateUserProfile(formData) {
      const { first_name, last_name, email } = formData;
      const language = "en";

      useNavStore().setAppSpinner(true);

      try {
        const user = await axiosInstance.put(`/users/profile`, { first_name, last_name, email, language });
        const data = user.data.data;

        // update pinia state
        this.auth.user.email = data.email;
        this.auth.user.first_name = data.first_name;
        this.auth.user.last_name = data.last_name;

        notificationStore().saveAlert(user.data.message);
        notificationStore().resetMessage();
        notificationStore().showAlerts();
      } catch (error) {
        if ([401, 403].includes(error.response?.status)) {
          this.logout(error.response.status);
        } else {
          throw customError(error);
        }
      } finally {
        useNavStore().setAppSpinner(false);
      }
    },
    async updateUserPassword(formData) {
      const { current_password, password, password_confirmation } = formData;
      useNavStore().setAppSpinner(true);
      try {
        const user = await axiosInstance.put(`/users/password`, {
          current_password,
          password,
          password_confirmation,
        });

        notificationStore().saveAlert(user.data.message);
        notificationStore().resetMessage();
        notificationStore().showAlerts();
      } catch (error) {
        if ([401, 403].includes(error.response?.status)) {
          this.logout(error.response.status);
        } else {
          throw customError(error);
        }
      } finally {
        useNavStore().setAppSpinner(false);
      }
    },
    async deleteUser(user_id) {
      useNavStore().setAppSpinner(true);
      try {
        const user = await axiosInstance.delete(`/users/profile/${user_id}`);
        notificationStore().saveAlert(user.data.message);
        await this.logout();
      } catch (error) {
        if ([401, 403].includes(error.response?.status)) {
          this.logout(error.response.status);
        } else {
          throw customError(error);
        }
      } finally {
        useNavStore().setAppSpinner(false);
      }
    },
    async resetUserPasswordRequest(email) {
      useNavStore().setAppSpinner(true);
      try {
        const user = await axiosInstance.post(`/users/forgot-password/email`, { email });

        notificationStore().saveAlert(user.data.message);
        await redirectToPreviousPage("login");
      } catch (error) {
        throw customError(error);
      } finally {
        useNavStore().setAppSpinner(false);
      }
    },
    async resetUserPasswordVerification(formData) {
      const { email, password, password_confirmation, token } = formData;
      useNavStore().setAppSpinner(true);
      try {
        const user = await axiosInstance.post(`/users/forgot-password/reset`, {
          email,
          password,
          password_confirmation,
          token,
        });
        notificationStore().saveAlert(user.data.message);
        await redirectToPreviousPage("login");
      } catch (error) {
        throw customError(error);
      } finally {
        useNavStore().setAppSpinner(false);
      }
    },
    async validateMe() {
      try {
        await axiosInstance.get(`/users/me`);
      } catch (error) {
        if ([401, 403].includes(error.response?.status)) {
          await this.logout(error.response.status);
        } else {
          throw customError(error);
        }
      }
    },
    async updateCustomersAuth(customers) {
      if (!customers) {
        return;
      }

      const auth = this.auth;
      auth.user.customers = customers;

      // update auth state
      localStorage.setEncryptedItem("auth", auth);
    },
    async refreshPermissionsCustomerProfile() {
      try {
        const userMe = await axiosInstance.get(`/users/me`);
        const permissions = userMe.data.data?.permissions;
        if (permissions) {
          this.customerProfile.permissions = permissions;
          localStorage.setEncryptedItem("customer_profile", this.customerProfile);
        }
      } catch (error) {
        if ([401, 403].includes(error.response?.status)) {
          await this.logout(error.response.status);
        } else {
          throw customError(error);
        }
      }
    },
    async expirationValidation() {
      if (this.loggedIn) {
        const localDate = new Date();
        const currentUtcTimezone = localDate.getTime();
        const currentUtcTimezoneStripped = parseInt(currentUtcTimezone / 1000);

        // const currentUtcTimezoneStripped = (currentUtcTimezone - last3digits) / 1000;
        // const currentUtcTimezoneStripped =  currentUtcTimezone % 1000;

        if (currentUtcTimezoneStripped > this.getExpirationDate) {
          await this.logout();
        }
      } else {
        if (this.validateExpirationInterval) {
          clearInterval(this.validateExpirationInterval);
        }
        this.validateExpirationInterval = false;
      }
    },
    startExpirationValidation() {
      this.expirationValidation();
      this.validateExpirationInterval = setInterval(this.expirationValidation, 1000 * 60); // every minute
    },

    resetAuth() {
      this.auth = null;
      localStorage.removeItem("auth");
      Sentry.setUser(null);
      this.resetImpersonate();
      this.resetCustomerProfile();
      this.resetStore();
    },

    resetCustomerProfile() {
      this.customerProfile = null;
      localStorage.removeItem("customer_profile");
    },

    resetImpersonate() {
      // reset impersonate
      this.authImpersonateLogin = null;
      localStorage.removeItem("auth_impersonate_login");
    },

    resetStore() {
      // reset data in store
      setTimeout(() => {
        useUsersStore().$reset();
        useNotificationStore().$reset();
        useGroupsStore().$reset();
        useSystemsStore().$reset();
        useMusicStore().$reset();
        useMusicStore().$reset();
        useSlidesStore().$reset();
        useFramesStore().$reset();
        localStorage.removeItem("alerts");
      }, 500);
    },
    async logout(status = null) {
      const isImpersonate = this.impersonateIsActive;

      if (this.loggedIn) {
        if (!isImpersonate) {
          try {
            await axiosInstance.post(`/auth/logout`);
          } catch (error) {
            throw customError(error);
          } finally {
            await this.resetAuth();
            await router.push({ name: "login" });
          }
        } else {
          await router.push({ name: "login" });
        }
      }
      await router.push({ name: "login" });
    },
  },
});
