<template>
  <TablePlayListGroups :groups="categories" v-show="!loading" />
</template>

<script>
import TablePlayListGroups from "@/components/_platform/table/settings/TablePlayListGroups.vue";

import { getPermissions, setBreadcrumbs } from "@/helpers";
import { useMusicStore } from "@/stores";
import { storeToRefs } from "pinia";

export default {
  setup() {
    setBreadcrumbs();
    const musicStore = useMusicStore();

    // Groups
    musicStore.getCategories();
    const { categories, loading } = storeToRefs(musicStore);

    return {
      categories,
      loading,
    };
  },
  components: {
    TablePlayListGroups,
  },
  methods: {
    getPermissions,
  },
};
</script>
