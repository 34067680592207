<template>
  <div>
    <TableUI
      v-if="systems.length > 0"
      name="systems"
      :value="systems"
      @value-change="onTableChange"
      @page="onTablePage"
    >
      <template #columns>
        <PColumn field="name" :header="$t('label.system_name')" :style="getCellWidth('name')" />

        <PColumn
          field="state"
          :header="$t('label.state')"
          :style="getCellWidth('state')"
          v-if="getPermissions('get system status')"
        >
          <template #body="slotProps">
            <ObserveUI @onChange="onChange" :data-system-id="slotProps.data.id">
              <SystemStatus :value="getStatus(slotProps.data.id)" />
            </ObserveUI>
          </template>
        </PColumn>

        <PColumn field="group" :header="$t('label.group')" :style="getCellWidth('group')">
          <template #body="slotProps">
            <span v-if="slotProps.data.group?.name">
              {{ slotProps.data.group.name }}
            </span>
          </template>
        </PColumn>

        <!-- Render description column -->
        <PColumn :style="getCellWidth('description')">
          <template #body="slotProps">
            <ToolTip :content="slotProps.data.description" v-if="slotProps.data.description" />
          </template>
        </PColumn>

        <!-- Render controls column -->
        <PColumn
          headerStyle="text-align: right"
          bodyStyle="text-align: right; overflow: visible"
          :style="getCellWidth('controls')"
        >
          <template #body="slotProps">
            <div class="controls">
              <!-- TODO: Permissions-->
              <ButtonUI
                v-if="getPermissions('show systems')"
                :disabled="getSyncSystemState(slotProps.data.id)"
                :label="$t('button.sync')"
                variant="text"
                color="text-palette-1"
                feather="refresh-cw"
                class="p-button-text-inline"
                @click="syncSystem(slotProps.data.id)"
              />

              <ButtonUI
                v-if="getPermissions('show system events')"
                :label="$t('button.schedule')"
                variant="text"
                color="text-palette-1"
                feather="calendar"
                class="p-button-text-inline"
                :to="{
                  name: 'systems-detail-schedule',
                  params: { system_id: slotProps.data.id },
                  query: { ...this.$route.query },
                }"
              />

              <ButtonUI
                v-if="getPermissions('edit systems')"
                :label="$t('button.settings')"
                variant="text"
                color="text-palette-1"
                feather="settings"
                class="p-button-text-inline"
                :to="{
                  name: 'systems-detail-edit',
                  params: { system_id: slotProps.data.id },
                  query: { ...this.$route.query },
                }"
              />
            </div>
          </template>
        </PColumn>
      </template>
    </TableUI>
    <NotificationMessage type="default" v-else>{{ $t("message.no_data_found") }}</NotificationMessage>
  </div>
</template>

<script>
import TableUI from "@/components/TableUI.vue";
import ButtonUI from "@/components/ButtonUI.vue";
import ToolTip from "@/components/ToolTip.vue";
import ObserveUI from "@/components/ObserveUI.vue";
import NotificationMessage from "@/components/NotificationMessage.vue";
import SystemStatus from "@/components/SystemStatus.vue";

import { storeToRefs } from "pinia";
import Column from "primevue/column";
import { watch, ref, toRefs } from "vue";
import { useNavStore, useSystemsStore } from "@/stores";
import { startStatusTimer } from "@/helpers/utils/statusTimer";
import { getPermissions, getTableCellWidth } from "@/helpers";

export default {
  setup(props) {
    const { systems } = toRefs(props);

    const systemsStore = useSystemsStore();
    const { systems_state_sync } = storeToRefs(systemsStore);

    // systemsStore.getSystems();

    const tableFirstRow = ref(0);
    const tableLastRow = ref(30);

    const onTablePage = (e) => {
      // set first and last row to variable
      tableFirstRow.value = e.first;
      tableLastRow.value = e.first + e.rows;
    };

    const onTableChange = (value) => {
      systemsStore.resetVisibleSystems();
    };

    const getStatus = (system_id) => {
      return systemsStore.getStatus(system_id);
    };

    const getSyncSystemState = (system_id) => {
      return systemsStore.getSyncSystemState(system_id);
    };

    const syncSystem = (system_id) => {
      return systemsStore.syncSystem(system_id);
    };

    let runFirst = true;
    watch(systems, (state) => {
      if (runFirst) {
        onTableChange(state);
        runFirst = false;
      }
    });

    watch(
      systems_state_sync,
      (state) => {
        useNavStore().setAppSpinner(state.length > 0);
      },
      {
        deep: true,
      }
    );

    startStatusTimer(() => {
      systemsStore.syncStatus();
    });

    return {
      getSyncSystemState,
      getStatus,
      onTableChange,
      onTablePage,
      syncSystem,
    };
  },
  props: {
    systems: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  components: {
    TableUI,
    ButtonUI,
    ToolTip,
    NotificationMessage,
    PColumn: Column,
    SystemStatus,
    ObserveUI,
  },
  methods: {
    getPermissions,
    getCellWidth(cell) {
      return getTableCellWidth("systems", cell);
    },
    onChange({ entry, isIntersecting }) {
      const systemsStore = useSystemsStore();

      if (entry && isIntersecting) {
        const systemID = entry.dataset.systemId;
        if (systemID) {
          systemsStore.saveVisibleSystem(systemID);
        }
      }
    },
  },
};
</script>
