import LeftSidebar from "@/components/LeftSidebar.vue";
import SettingsIndexView from "@/views/_platform/settings/IndexView.vue";
import SettingsPlaylistGroupIndexView from "@/views/_platform/settings/partials/PlaylistGroups.vue";
import SettingsPlaylistGroupMusicOverView from "@/views/_platform/settings/partials/playlist-groups/music/IndexView.vue";
import SettingsPlaylistGroupSlidesOverView from "@/views/_platform/settings/partials/playlist-groups/slides/IndexView.vue";
import SettingsPlaylistGroupFramesOverView from "@/views/_platform/settings/partials/playlist-groups/frames/IndexView.vue";
import SettingsProfileIndexView from "@/views/_platform/settings/partials/ProfileView.vue";
import SettingsProfileAccountView from "@/views/_platform/settings/partials/profile/AccountView.vue";
import SettingsProfilePasswordView from "@/views/_platform/settings/partials/profile/PasswordView.vue";
import SettingsPlaylistGroupPlaylistEditView from "@/views/_platform/settings/partials/playlist-groups/music/EditView.vue";
import SettingsPlaylistGroupPlaylistCreateView from "@/views/_platform/settings/partials/playlist-groups/music/CreateView.vue";
import SettingsPlaylistGroupSlidesEditView from "@/views/_platform/settings/partials/playlist-groups/slides/EditView.vue";
import SettingsPlaylistGroupSlidesCreateView from "@/views/_platform/settings/partials/playlist-groups/slides/CreateView.vue";
import SettingsPlaylistGroupFramesEditView from "@/views/_platform/settings/partials/playlist-groups/frames/EditView.vue";
import SettingsPlaylistGroupFramesCreateView from "@/views/_platform/settings/partials/playlist-groups/frames/CreateView.vue";

export const settingsRoutes = [
  // Settings
  {
    path: "/settings",
    name: "settings",
    components: {
      LeftSidebar,
      default: SettingsIndexView,
    },
    children: [
      {
        path: "/settings/playlist-groups",
        name: "settings-playlist-groups",
        components: {
          Settings: SettingsPlaylistGroupIndexView,
        },
        children: [
          {
            path: "/settings/playlist-groups/music",
            name: "settings-playlist-groups-music",
            components: {
              Categories: SettingsPlaylistGroupMusicOverView,
            },
            meta: {
              permission: "show music categories",
              supportPageID: "014",
            },
          },
          {
            path: "/settings/playlist-groups/slides",
            name: "settings-playlist-groups-slides",
            components: {
              Categories: SettingsPlaylistGroupSlidesOverView,
            },
            meta: {
              permission: "show slides categories",
              supportPageID: "015",
            },
          },
          {
            path: "/settings/playlist-groups/frames",
            name: "settings-playlist-groups-frames",
            components: {
              Categories: SettingsPlaylistGroupFramesOverView,
            },
            meta: {
              permission: "show frames categories",
              supportPageID: "016",
            },
          },
        ],
        meta: {},
      },
      {
        path: "/settings/profile",
        name: "settings-profile",
        components: {
          Settings: SettingsProfileIndexView,
        },
        children: [
          {
            path: "/settings/profile/account",
            name: "settings-profile-account",
            components: {
              Profile: SettingsProfileAccountView,
            },
            meta: {
              supportPageID: "017",
            },
          },
          {
            path: "/settings/profile/password",
            name: "settings-profile-password",
            components: {
              Profile: SettingsProfilePasswordView,
            },
            meta: {
              supportPageID: "018",
            },
          },
        ],
      },
    ],
  },

  // Settings - Playlist group - Playlist - Edit
  {
    path: "/settings/playlist-group/playlist/:category_id/edit",
    name: "settings-playlist-group-music-edit",
    components: {
      LeftSidebar,
      default: SettingsPlaylistGroupPlaylistEditView,
    },
    meta: {
      permission: "edit music categories",
    },
  },

  // Settings - Playlist group - Music - Create
  {
    path: "/settings/playlist-group/music/create",
    name: "settings-playlist-group-music-create",
    components: {
      LeftSidebar,
      default: SettingsPlaylistGroupPlaylistCreateView,
    },
    meta: {
      permission: "create music categories",
      supportPageID: "054",
    },
  },

  // Settings - Playlist group - Slides - Edit
  {
    path: "/settings/playlist-group/slides/:category_id/edit",
    name: "settings-playlist-group-slides-edit",
    components: {
      LeftSidebar,
      default: SettingsPlaylistGroupSlidesEditView,
    },
    meta: {
      permission: "edit slides categories",
    },
  },

  // Settings - Playlist group - Slides - Create
  {
    path: "/settings/playlist-group/slides/create",
    name: "settings-playlist-group-slides-create",
    components: {
      LeftSidebar,
      default: SettingsPlaylistGroupSlidesCreateView,
    },
    meta: {
      permission: "create slides categories",
      supportPageID: "055",
    },
  },

  // Settings - Playlist group - Frames - Edit
  {
    path: "/settings/playlist-group/frames/:category_id/edit",
    name: "settings-playlist-group-frames-edit",
    components: {
      LeftSidebar,
      default: SettingsPlaylistGroupFramesEditView,
    },
    meta: {
      permission: "edit frames categories",
    },
  },

  // Settings - Playlist group - Frames - Create
  {
    path: "/settings/playlist-group/frames/create",
    name: "settings-playlist-group-frames-create",
    components: {
      LeftSidebar,
      default: SettingsPlaylistGroupFramesCreateView,
    },
    meta: {
      permission: "create frames categories",
      supportPageID: "056",
    },
  },
];
