import { defineStore } from "pinia";
import { appConfig, i18n, setLocale } from "@/helpers";

export const useAppStore = defineStore({
  id: "app",
  state: () => ({
    config: {},
    lang: "en-gb",
    supportUrl: null,
    themeIsLoaded: false,
    maintenance: false,
  }),
  getters: {
    getAppConfig(state) {
      return state.config;
    },
  },
  actions: {
    setLang() {
      const globalLang = i18n.global.locale.value;
      setLocale(globalLang).then((r) => {});
      this.lang = globalLang;
    },
    setAppConfig() {
      this.config = appConfig(); // will be set in main.js
    },
    setMaintenance(state) {
      this.maintenance = state;
    },
    setThemeIsLoaded(state) {
      this.themeIsLoaded = state;
    },
    setSupportUrl(route) {
      this.getSupportUrl(route?.meta?.supportPageID);
    },
    getSupportUrl(pageID) {
      let providerID = this.config?.providerID;
      let lang = this.lang;
      let supportUrl = this.config?.supportUrl;

      // rewrite variables to correct BCM Help Url
      switch (providerID) {
        case "od":
          providerID = "orangedoor";
          break;
        case "sh":
          providerID = "swish";
          break;
      }

      switch (lang) {
        case "en-gb":
          lang = "en";
          break;
        case "nl-nl":
          lang = "nl";
          break;
      }

      const id = pageID ? `&id=${pageID}` : "";

      // https://redirect.bcmserver.com?pro=bcm&lng=nl&id=00001
      this.supportUrl = `${supportUrl}?pro=${providerID}&lng=${lang}${id}`;
    },
  },
});
