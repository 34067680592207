<template>
  <div class="p-checkbox p-component" @click="clickCheckbox()">
    <div class="p-hidden-accessible"><input type="checkbox" tabindex="-1" aria-label="Row Selected" /></div>
    <div class="p-checkbox-box p-component" :class="{ 'p-highlight': checkboxValue === 'all' }">
      <span v-if="checkboxValue === 'all'" class="p-checkbox-icon pi pi-check"></span>
      <svg
        v-else-if="checkboxValue !== 'none'"
        xmlns="http://www.w3.org/2000/svg"
        width="11"
        height="2"
        viewBox="0 0 11 2"
      >
        <path fill="#FFF" fill-rule="nonzero" d="M10 0a1 1 0 1 1 0 2H1a1 1 0 1 1 0-2h9Z" />
      </svg>
    </div>
  </div>
</template>
<script>
import { computed, toRefs } from "vue";

export default {
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const { modelValue, mode } = toRefs(props);

    const checkboxValue = computed({
      get() {
        return modelValue.value;
      },
      set(value) {
        // modelValue.value = value;
        emit("update:modelValue", value);
      },
    });

    const clickCheckbox = () => {
      if (checkboxValue.value === "none") {
        checkboxValue.value = "page";
      } else {
        checkboxValue.value = "none";
      }
    };

    return {
      clickCheckbox,
      checkboxValue,
    };
  },
  props: {
    modelValue: {},
  },
};
</script>
