<template>
  <div class="progress-bar" v-if="progress > 0">
    <div class="progress" :style="{ width: progress + '%' }">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    progress: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style lang="scss" scoped>
.progress-bar {
  height: 4px;
  width: 100%;
  z-index: 10;
  position: relative;

  &.is-sticky {
    position: fixed;
    top: 0;
    left: 0;
  }

  .progress {
    height: 100%;
    background-color: var(--progress-color);
    position: absolute;
    left: 0;
    top: 0;
    transition: all 0.2s ease-in;
  }
}
</style>
