<template>
  <div class="view-body">
    <div class="content-wrapper">
      <PageTitle :title="$t('title.tickers_overview')" />
      <TabNav :items="tabNavItems" :exact="true">
        <template #controls>
          <Transition name="page-fade" mode="out-in">
            <div v-if="!notFound && showControlsMyPlaylists">
              <ButtonUI
                :label="$t('button.create_playlist')"
                color="primary"
                feather="plus"
                class="ml-2"
                :to="{ name: 'tickers-playlist-create', query: { ...this.$route.query } }"
                v-if="getPermissions('create playlists')"
              />
            </div>
            <div v-else-if="showControlsMyTickers">
              <ButtonUI
                :label="$t('button.create_ticker')"
                color="primary"
                feather="plus"
                class="ml-2"
                :to="{ name: 'tickers-user-tickers-create', query: { ...this.$route.query } }"
                v-if="getPermissions('create tickers')"
              />
            </div>
          </Transition>
        </template>
        <template #panels>
          <RouterView name="Tickers" v-slot="{ Component }" @notFound="notFound = $event">
            <Transition name="page-fade" mode="out-in">
              <component :is="Component" />
            </Transition>
          </RouterView>
        </template>
      </TabNav>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/components/PageTitle.vue";
import TabNav from "@/components/nav/TabNav.vue";
import ButtonUI from "@/components/ButtonUI.vue";

import { getPermissions, i18n, setBreadcrumbs } from "@/helpers";
import { onUnmounted } from "vue";
import { useTickersStore } from "@/stores";

export default {
  setup() {
    setBreadcrumbs();
    const tickersStore = useTickersStore();

    onUnmounted(() => {
      tickersStore.resetPlaylists();
    });

    const tabNavItems = [
      {
        title: i18n.global.t("label.user_playlists"),
        route: "tickers",
        route_match: ["tickers", "tickers-user-category-other", "tickers-user-category"],
        permissions: "show tickers",
      },
      {
        title: i18n.global.t("label.user_tickers"),
        route: "user-tickers",
        route_match: ["user-tickers"],
        permissions: "show tickers",
      },
      {
        title: i18n.global.t("label.browse_tickers"),
        route: "tickers-browse",
        route_match: ["tickers-browse", "tickers-browse-category"],
        permissions: "show tickers",
      },
    ];

    return {
      tabNavItems,
    };
  },
  data() {
    return {
      notFound: false,
      error: {},
    };
  },
  components: {
    ButtonUI,
    PageTitle,
    TabNav,
  },
  computed: {
    routerName() {
      return this.$route?.name;
    },
    showControlsMyPlaylists() {
      return ["tickers", "tickers-user-category-other", "tickers-user-category"].includes(this.routerName);
    },
    showControlsBrowsePlaylists() {
      return ["tickers-browse", "tickers-browse-category"].includes(this.routerName);
    },
    showControlsMyTickers() {
      return ["user-tickers"].includes(this.routerName);
    },
  },
  methods: {
    getPermissions,
  },
};
</script>
