import LeftSidebar from "@/components/LeftSidebar.vue";
import TickersIndexView from "@/views/_platform/tickers/IndexView.vue";
import TickersOverView from "@/views/_platform/tickers/partials/user-playlists/IndexView.vue";
import TickersUserTickersOverView from "@/views/_platform/tickers/partials/user-tickers/IndexView.vue";
import TickersBrowseView from "@/views/_platform/tickers/partials/browse/IndexView.vue";
import TickersBrowseCategoryView from "@/views/_platform/tickers/partials/browse/CategoryView.vue";
import TickersDetailView from "@/views/_platform/tickers/partials/user-playlists/DetailView.vue";
import TickersCreateView from "@/views/_platform/tickers/partials/user-playlists/CreateView.vue";
import TickersUserTickersCreateView from "@/views/_platform/tickers/partials/user-tickers/CreateView.vue";
import TickersUserTickersEditView from "@/views/_platform/tickers/partials/user-tickers/EditView.vue";

export const tickersRoutes = [
  // Tickers
  {
    path: "/tickers",
    name: "tickers-index",
    components: {
      LeftSidebar,
      default: TickersIndexView,
    },
    children: [
      {
        path: "/tickers",
        name: "tickers",
        components: {
          Tickers: TickersOverView,
        },
        meta: {
          permission: "search tickers",
          supportPageID: "027",
        },
      },
      {
        path: "/tickers/user-tickers",
        name: "user-tickers",
        components: {
          Tickers: TickersUserTickersOverView,
        },
        meta: {
          permission: "search tickers",
          supportPageID: "028",
        },
      },
      {
        path: "/tickers/browse",
        name: "tickers-browse",
        components: {
          Tickers: TickersBrowseView,
        },
        meta: {
          permission: "search tickers",
          supportPageID: "029",
        },
      },
      {
        name: "tickers-browse-category",
        path: "/tickers/browse/category/:id",
        components: {
          Tickers: TickersBrowseCategoryView,
        },
        meta: {
          permission: "search tickers",
          supportPageID: "029",
        },
      },
    ],
  },
  {
    name: "tickers-detail",
    path: "/tickers/playlist/:playlist_id/detail",
    components: {
      LeftSidebar,
      default: TickersDetailView,
    },
    meta: {
      permission: "search tickers",
      supportPageID: "051",
    },
  },
  // {
  //   name: "tickers-browse-detail",
  //   path: "/tickers/browse/:category_id-:playlist_id/detail",
  //   components: {
  //     LeftSidebar,
  //     default: TickersBrowseDetailView,
  //   },
  //   meta: {
  //     permission: "search tickers",
  //   },
  // },
  {
    name: "tickers-playlist-create",
    path: "/tickers/playlist/create",
    components: {
      LeftSidebar,
      default: TickersCreateView,
    },
    meta: {
      permission: "create playlists",
      supportPageID: "048",
    },
  },
  // Create ticker inside my tickers
  {
    name: "tickers-user-tickers-create",
    path: "/tickers/user-tickers/create",
    components: {
      LeftSidebar,
      default: TickersUserTickersCreateView,
    },
    meta: {
      permission: "create tickers",
      supportPageID: "049",
    },
  },
  {
    name: "tickers-user-tickers-edit",
    path: "/tickers/user-tickers/:ticker_id/edit",
    components: {
      LeftSidebar,
      default: TickersUserTickersEditView,
    },
    meta: {
      permission: "edit tickers",
      supportPageID: "059",
    },
  },
];
