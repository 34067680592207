<template>
  <div class="surface-card surface-card--more h-full" @click="onClick()">
    <div class="more-inner">
      <vue-feather type="chevron-right" :size="60" class="icon" />
    </div>
  </div>
</template>
<script>
import VueFeather from "vue-feather";

export default {
  emits: ["onShowMoreClick"],
  components: {
    VueFeather,
  },
  methods: {
    onClick() {
      this.$emit("onShowMoreClick");
    },
  },
};
</script>
<style lang="scss" scoped>
.surface-card--more {
  padding: spacing(5);
  cursor: pointer;
  transition: all 0.15s cubic-bezier(1, 0.5, 0.8, 1);
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;

  .more-inner {
    opacity: 0.1;
    border: 1px solid #fff;
    height: 100%;
    width: 100%;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:not(.no-hover):hover {
    transform: scale(1.1);
    box-shadow: 0 10px 30px 0 rgba(#000, 0.2);
  }
}
</style>
