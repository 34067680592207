<template>
  <div>
    <div v-if="!loading">
      <TableSlides :slides="slides" />
    </div>
  </div>
</template>

<script>
import TableSlides from "@/components/_platform/table/slides/TableSlides.vue";

import { getPermissions, setBreadcrumbs } from "@/helpers";
import { useSlidesStore } from "@/stores";
import { storeToRefs } from "pinia";
import { useRoute } from "vue-router";
import { startStatusTimer } from "@/helpers/utils/statusTimer";

export default {
  setup() {
    const slidesStore = useSlidesStore();
    slidesStore.getMySlides();

    setBreadcrumbs("slides");

    const { slides, loading } = storeToRefs(slidesStore);

    const { name } = useRoute();

    startStatusTimer(() => {
      slidesStore.getMySlides(true, name);
    }, 5000);

    return {
      slides,
      loading,
    };
  },
  components: {
    TableSlides,
  },
  methods: {
    getPermissions,
  },
};
</script>
