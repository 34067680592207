<template>
  <TablePendingSystems :systems="systems" v-show="!loading" />
</template>

<script>
import TablePendingSystems from "@/components/_platform/table/systems/TablePendingSystems.vue";

import { getPermissions, setBreadcrumbs } from "@/helpers";
import { useSystemsStore } from "@/stores";
import { storeToRefs } from "pinia";

export default {
  setup() {
    setBreadcrumbs();
    const systemsStore = useSystemsStore();

    // Pending Systems
    systemsStore.getPendingSystems();

    const { systems_pending: systems, loading } = storeToRefs(systemsStore);

    return {
      loading,
      systems,
    };
  },
  components: {
    TablePendingSystems,
  },
  methods: {
    getPermissions,
  },
};
</script>
