<template>
  <button
    v-if="content"
    @mouseenter="mouseEnter"
    @mouseleave="mouseLeave"
    @mousemove="mouseEnter"
    @click="onClick"
    class="tooltip"
    ref="tooltip"
  >
    <span class="tooltip-wrapper">
      <slot v-if="$slots.default" />
      <vue-feather v-else type="info" size="18" />
      <Transition name="tooltip">
        <span
          v-if="visible"
          class="tooltip-content"
          :style="{ top: position.top + 'px', left: position.left + 'px' }"
          v-html="content"
        />
      </Transition>
    </span>
  </button>
  <slot v-else />
</template>

<script>
import VueFeather from "vue-feather";
import { ref, toRefs } from "vue";
import { useDetectOutsideClick } from "@/helpers";

export default {
  components: { VueFeather },
  setup(props) {
    const { type } = toRefs(props);
    const tooltip = ref();
    const visible = ref(false);
    const position = ref({
      top: 0,
      left: 0,
    });
    if (type.value === "click") {
      useDetectOutsideClick(tooltip, () => {
        visible.value = false;
      });
    }

    return {
      tooltip,
      visible,
      position,
    };
  },
  props: {
    content: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "mouse",
    },
  },
  methods: {
    onClick(event) {
      if (this.type !== "click") {
        return;
      }
      return !this.visible ? this.showTooltip(event) : this.hideTooltip(event);
    },
    mouseEnter(event) {
      if (this.type !== "mouse") {
        return;
      }
      this.showTooltip(event);
    },
    mouseLeave(event) {
      if (this.type !== "mouse") {
        return;
      }
      this.hideTooltip(event);
    },
    showTooltip(event) {
      if (this.content !== "") {
        this.visible = true;
        this.setPosition(event);
      }
    },
    hideTooltip() {
      this.visible = false;
    },
    setPosition(event) {
      let container = {
        top: 0,
        left: 0,
      };

      const dialogEl = document.querySelector(".p-dialog");
      if (dialogEl) {
        container = dialogEl.getBoundingClientRect();
      }

      this.position = {
        top: event.clientY + 10 - container.top,
        left: event.clientX + 10 - container.left,
      };
    },
  },
};
</script>

<style lang="scss" scoped>
/* we will explain what these classes do next! */
.tooltip-enter-active,
.tooltip-leave-active {
  transition: opacity 0.5s ease;
}

.tooltip-enter-from,
.tooltip-leave-to {
  opacity: 0;
}

.tooltip {
  border: none;
  padding: 0;
  margin: 0;
  background: transparent;
  pointer-events: auto;
}

.tooltip-wrapper {
  pointer-events: none;
}

.tooltip-content {
  position: fixed;
  background: var(--tooltip-ground);
  padding: var(--tooltip-padding);
  color: var(--tooltip-color);
  border-radius: var(--tooltip-border-radius);
  border-width: var(--tooltip-border-width);
  border-style: var(--tooltip-border-style);
  border-color: var(--tooltip-border-color);
  box-shadow: var(--tooltip-box-shadow);
  max-width: 500px;
  line-height: 1;
  z-index: 100;
  display: inline-block;
  text-align: left;
}

.vue-feather {
  color: var(--link-color);
  text-decoration: none;
  cursor: pointer;
  &:hover {
    color: var(--link-hover-color);
  }
  &:focus {
    color: var(--link-focus-color);
  }
}
</style>
