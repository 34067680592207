import { useAppStore } from "@/stores/app.store";
import { useNavStore } from "@/stores";

export function getAppImage(pic) {
  if (!pic) return;
  const { themeName, companyName, appName } = useAppStore().getAppConfig;
  const brand = themeName ?? "default";
  return `/images/themes/${brand}/${pic}`;
}

export function getAppCompany() {
  const { companyName } = useAppStore().getAppConfig;
  return companyName ?? null;
}

export function getAppTitle() {
  const { appName } = useAppStore().getAppConfig;
  const isAdmin = useNavStore().isAdminPage;
  if (isAdmin) {
    return "Admin";
  }
  return appName ?? null;
}
