<template>
  <div class="bar mt-4" v-if="progress > 0">
    <ProgressUI :progress="progress" style="height: 25px">
      <span class="amount">{{ progress }}%</span>
    </ProgressUI>
  </div>
</template>
<script>
import ProgressUI from "@/components/ProgressUI.vue";

export default {
  props: {
    progress: {
      type: Number,
      default: 0,
    },
  },
  components: {
    ProgressUI,
  },
};
</script>
<style scoped lang="scss">
.progress {
  color: var(--text-color);
  width: 45px;
}
.amount {
  padding: 4px;
  position: absolute;
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  line-height: 1;
}
</style>
