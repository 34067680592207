import LeftSidebar from "@/components/LeftSidebar.vue";
import DashboardView from "@/views/_admin/DashboardView.vue";

export const dashboardRoutes = [
  {
    name: "admin-dashboard",
    path: "/admin/dashboard",
    components: {
      LeftSidebar,
      default: DashboardView,
    },
  },
];
