<template>
  <p class="text mt-0">
    <slot />
  </p>
</template>
<style lang="scss" scoped>
.text {
  color: var(--text-color);
  opacity: 0.4;
}
</style>
