<template>
  <RouterLink
    class="btn-back"
    :class="[`btn-back--${variant}`, `btn-back--size-${size}`, { 'btn-back--focus': focus }]"
  >
    <ButtonUI :variant="variant" size="md" :feather="feather" class="p-button-icon-only p-button-rounded" />
    <span class="ui-txt" v-if="title && size === 'md'" v-html="title" />
    <h1 class="ui-txt" v-if="title && size === 'lg'" v-html="title" />
  </RouterLink>
</template>
<script>
import ButtonUI from "@/components/ButtonUI.vue";

export default {
  components: {
    ButtonUI,
  },
  props: {
    title: String,
    variant: {
      type: String,
      default: "default",
    },
    size: {
      type: String,
      default: "md",
    },
    feather: {
      type: String,
      default: "chevron-left",
    },
    focus: {
      type: Boolean,
      default: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.btn-back {
  background: transparent;
  color: var(--text-color);
  outline: none;
  border: none;
  position: relative;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  padding: 0;
  text-decoration: none;

  .p-button-icon-only {
    + .ui-txt {
      margin-left: spacing(3);
    }
  }

  .ui-txt {
    font-size: font-size("2xl");
    font-family: var(--font-family-headings);
    font-weight: 700;
    transition: 0.2s all;
  }

  h1.ui-txt {
    font-family: var(--font-family-headings);
    font-weight: 700;
    font-size: font-size("4xl");
    margin: 0;
  }

  &--size-md {
    &.btn-back--focus {
      &:active {
        transform: scale(1.05);
      }
    }
  }

  &:hover {
    span.ui-txt {
      color: var(--link-hover-color);
    }
  }
}
</style>
