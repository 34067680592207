<template>
  <div class="view-body">
    <DialogDuplicatePlaylist
      v-if="showDuplicateConfirm"
      :visible="showDuplicateConfirm"
      :playlist_id="playlist_id"
      :category_id="category_id"
      component="music"
      type="bcm"
      @onCancel="onDuplicateCancel"
    />
    <div class="content-wrapper">
      <div v-if="!loading">
        <div class="position-relative">
          <BackUI variant="primary" to="#" class="mb-7" @click.prevent="goBack()" />
          <NotificationMessage type="404" v-if="notFound" />
          <template v-else>
            <PlaylistHeader
              :image="form.image"
              :title="form.title"
              :text="form.description"
              :visibility="form.visibility?.value"
              class="mb-6"
            >
              <template #details>
                <!-- TODO: PERMISSIONS -->
                <ButtonUI
                  class="duplicate-playlist"
                  variant="text"
                  color="text-palette-1"
                  size="lg"
                  feather="copy"
                  :label="$t('button.duplicate_playlist')"
                  @click="showDuplicateConfirm = true"
                />

                <div class="flex mt-3 gap-2">
                  <BadgeUI
                    v-if="form.item_count > 0"
                    :title="getCountTitle({ count: form.item_count, type: 'music' })"
                    color="primary"
                  />

                  <BadgeUI
                    v-if="form.item_count"
                    :title="`${form.total_playing_time} ${$t('label.total_time').toLowerCase()} `"
                    color="primary"
                  />
                </div>
              </template>
            </PlaylistHeader>
            <TabNav :items="tabNavItems" variant="tab">
              <template #controls>
                <SearchUI
                  v-model:filterData="filterData"
                  :types="['all', 'audio', 'video', 'karaoke']"
                  :replaceTextType="[{ all: $t('label.all_media') }]"
                />
              </template>

              <template #panels>
                <div class="tab-panel">
                  <TableTracks :tracks="tracks" :filterData="filterData" :control-permissions="['add to playlist']" />
                </div>
              </template>
            </TabNav>
          </template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TabNav from "@/components/nav/TabNav.vue";
import PlaylistHeader from "@/components/_platform/playlist/PlaylistHeader.vue";
import BackUI from "@/components/BackUI.vue";
import TableTracks from "@/components/_platform/table/playlists/TableTracks.vue";

import { useRoute } from "vue-router";
import { useNavStore, useMusicStore } from "@/stores";
import { storeToRefs } from "pinia";
import NotificationMessage from "@/components/NotificationMessage.vue";
import { onUnmounted, ref, watchEffect } from "vue";
import {
  filterByTerm,
  getCountTitle,
  getPermissions,
  i18n,
  isNotEmpty,
  router,
  setBreadcrumbs,
  uniqueID,
} from "@/helpers";
import DialogDuplicatePlaylist from "@/components/_platform/dialog/DialogDuplicatePlaylist.vue";
import ButtonUI from "@/components/ButtonUI.vue";
import BadgeUI from "@/components/BadgeUI.vue";
import SearchUI from "@/components/SearchUI.vue";
import { filterTracks } from "@/helpers/filters/_platform/filterTracks";

export default {
  setup() {
    setBreadcrumbs();
    const route = useRoute();
    const { playlist_id, category_id, hash } = route.params;

    const filterData = ref({});
    const tracks = ref([]);

    const musicStore = useMusicStore();
    musicStore.getPlaylistsDetail({ playlist_id, category_id });
    musicStore.getCategories();

    const {
      playlists_detail: form,
      visibility_options: visibilityOptions,
      visibility_restricted_group_options: visibilityRestrictedOptions,
      categories,
      loading,
    } = storeToRefs(musicStore);

    const tabNavItems = [
      {
        title: i18n.global.t("label.tracks"),
        feather: "music",
        permissions: "show playlists",
      },
    ];

    watchEffect(() => {
      tracks.value = filterTracks({ tracks: form.value.tracks, filterData: filterData.value });
    });

    onUnmounted(() => {
      musicStore.resetPlaylistsDetail();
    });

    return {
      tracks,
      filterData,
      tabNavItems,
      playlist_id,
      category_id,
      form,
      visibilityOptions,
      visibilityRestrictedOptions,
      hash,
      categories,
      loading,
    };
  },
  data() {
    return {
      showDuplicateConfirm: false,
    };
  },
  components: {
    SearchUI,
    BadgeUI,
    ButtonUI,
    DialogDuplicatePlaylist,
    TabNav,
    NotificationMessage,
    PlaylistHeader,
    // BadgeUI,
    TableTracks,
    BackUI,
  },
  methods: {
    getCountTitle,
    async goBack() {
      router.back();
    },
    getPermissions,
    isNotEmpty(e) {
      return isNotEmpty(e);
    },
    // Duplicate
    onDuplicateCancel() {
      this.showDuplicateConfirm = false;
    },
    convertTime(time) {
      const timeComponents = time.split(":");
      const hours = timeComponents[0];
      const minutes = timeComponents[1];
      return `${hours}:${minutes}`;
    },
  },
  computed: {
    notFound() {
      return this.form?.status && this.form.status == 404;
    },
  },
  watch: {
    form(playlist) {
      if (playlist?.title) {
        let category = playlist.categories;
        if (category.length) {
          category = category[0];
          useNavStore().setBreadcrumbLink(
            {
              name: "music-browse-category",
              title: category.name,
              params: {
                id: category.id,
              },
            },
            1
          );
        } else {
          useNavStore().setBreadcrumbLink(
            {
              name: "music-user-category-other",
              title: i18n.global.t("route.playlists_user_category_other"),
            },
            1
          );
        }
        useNavStore().setBreadcrumbTitle(playlist.title, 2);
      }
    },
  },
};
</script>
<style lang="scss" scoped>
.position-relative {
  position: relative;
  .duplicate-playlist {
    position: absolute;
    right: 0;
    top: 0;
  }
}
</style>
