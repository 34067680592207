<template>
  <form @submit.prevent="onSubmit">
    <FormRow v-if="showCustomers()">
      <FormSelect
        id="customers"
        :size="size"
        :class="{ 'is-invalid': errors.data?.customer }"
        v-model="profile.customer"
        :options="orderStringByKey(getCustomers)"
        optionLabel="name"
        :placeholder="$t('placeholder.select_a_customer')"
      />
    </FormRow>
    <FormRow v-if="showRegions()">
      <FormSelect
        id="region"
        :size="size"
        :class="{ 'is-invalid': errors.data?.region }"
        v-model="profile.region"
        :options="orderStringByKey(profile.customer.regions)"
        optionLabel="name"
        :placeholder="$t('placeholder.select_a_region')"
      />
    </FormRow>

    <ButtonUI
      type="submit"
      :size="size"
      :class="{ 'w-full justify-content-center': size === 'xl' }"
      :label="$t('button.continue')"
      :disabled="profile?.region?.id ? false : true"
    />
  </form>
</template>
<script>
import { useAuthStore } from "@/stores";

import FormRow from "@/components/form/FormRow.vue";
import FormSelect from "@/components/form/FormSelect.vue";
import ButtonUI from "@/components/ButtonUI.vue";
import { router, orderStringByKey } from "@/helpers";
import { storeToRefs } from "pinia";

export default {
  setup() {
    const authStore = useAuthStore();

    if (!authStore.customerHasRegions()) {
      router.push({ name: "dashboard" });
    }

    const { getCustomers } = storeToRefs(authStore);
    return { getCustomers };
  },

  components: {
    FormRow,
    FormSelect,
    ButtonUI,
  },
  data() {
    return {
      tempCustomer: null,
      errors: [],
      profile: {
        customer: [],
      },
    };
  },
  props: {
    size: {
      type: String,
      default: "xl",
    },
  },
  methods: {
    showCustomers() {
      const customers = this.getCustomers;
      return customers.length > 1;
    },
    showRegions() {
      const customers = this.getCustomers;
      if (customers.length <= 1) {
        // show regions if 1 or no customers
        this.profile.customer = this.getCustomers[0]; // select first customer
        return true;
      } else if (customers.length > 1 && this.profile.customer.id) {
        // show regions if 2 or more customers AND if user selected customer
        return true;
      }
      return false;
    },
    orderStringByKey(arr) {
      return orderStringByKey(arr, "name");
    },
    async onSubmit() {
      const authStore = useAuthStore();

      const profile = {
        customer: {
          id: this.profile.customer.id,
          name: this.profile.customer.name,
        },
        region: {
          id: this.profile.region.id,
          name: this.profile.region.name,
        },
        code: this.profile.customer.code,
        permissions: this.profile.customer.permissions,
      };

      return authStore.setCustomerProfile({ scenario: "change", profile });
    },
  },
  watch: {
    profile: {
      handler(value) {
        if (this.profile.customer.regions.length == 1) {
          this.profile.region = value.customer.regions[0];
        }
        if (value.customer.id != this.tempCustomer) {
          this.profile.region = {};
        }
        this.tempCustomer = value.customer.id;
      },
      deep: true,
    },
  },
};
</script>
