<template>
  <TableGroups :groups="groups" v-if="getPermissions('show groups')" v-show="!loadingGroups" />
</template>

<script>
import TableGroups from "@/components/_platform/table/systems/TableGroups.vue";

import { getPermissions, setBreadcrumbs } from "@/helpers";
import { useGroupsStore } from "@/stores";
import { storeToRefs } from "pinia";

export default {
  setup() {
    setBreadcrumbs();
    const groupsStore = useGroupsStore();

    // Groups
    groupsStore.getGroups();
    const { groups, loading: loadingGroups } = storeToRefs(groupsStore);

    return {
      groups,
      loadingGroups,
    };
  },
  components: {
    TableGroups,
  },
  methods: {
    getPermissions,
  },
};
</script>
