import { useMetaStore } from "@/stores";

export function convertRoleIDsToNames(ids) {
  let objects = {};
  const roles = useMetaStore().getAdminRoles;
  if (ids) {
    const getName = (id) => {
      return `is_${roles.find((role) => role.id === id)?.name}`;
    };
    roles.forEach((role) => {
      const { id } = role;
      objects[getName(id)] = ids.includes(id);
    });
  }
  return objects;
}
export function convertRoleNamesToIDs(data) {
  let ids = [];
  const roles = useMetaStore().getAdminRoles;

  const getID = (name) => {
    return roles.find((role) => role.name === name)?.id;
  };

  roles.forEach((role) => {
    const { name } = role;
    if (`is_${name}` in data && data[`is_${name}`]) {
      ids.push(getID(name));
    }
  });

  return ids;
}
