<template>
  <PCheckbox :class="[color ? `p-checkbox-${color}` : '']" />
</template>
<script>
import Checkbox from "primevue/checkbox";

export default {
  components: {
    PCheckbox: Checkbox,
  },
  props: {
    color: {
      type: String,
      default: "primary",
    },
  },
};
</script>
