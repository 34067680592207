<template>
  <div>
    <TabNav
      :items="[
        {
          title: $t('label.music'),
          feather: 'music',
          route: 'settings-playlist-groups-music',
          permissions: 'show music categories',
        },
        {
          title: $t('label.slides'),
          feather: 'monitor',
          route: 'settings-playlist-groups-slides',
          permissions: 'show slides categories',
        },
        {
          title: $t('label.frames'),
          feather: 'table',
          route: 'settings-playlist-groups-frames',
          permissions: 'show frames categories',
        },
      ]"
      :stickyControls="true"
      :subTabNav="true"
    >
      <template #controls>
        <ButtonUI
          :label="$t('button.add_playlist_group')"
          color="primary"
          feather="plus"
          :to="{ name: 'settings-playlist-group-music-create', query: { ...this.$route.query } }"
          v-if="getPermissions('create music categories') && this.$route.name === 'settings-playlist-groups-music'"
        />
        <ButtonUI
          :label="$t('button.add_playlist_group')"
          color="primary"
          feather="plus"
          :to="{ name: 'settings-playlist-group-slides-create', query: { ...this.$route.query } }"
          v-if="getPermissions('create slides categories') && this.$route.name === 'settings-playlist-groups-slides'"
        />
        <ButtonUI
          :label="$t('button.add_playlist_group')"
          color="primary"
          feather="plus"
          :to="{ name: 'settings-playlist-group-frames-create', query: { ...this.$route.query } }"
          v-if="getPermissions('create frames categories') && this.$route.name === 'settings-playlist-groups-frames'"
        />
      </template>
      <template #panels>
        <RouterView name="Categories" v-slot="{ Component }">
          <Transition name="page-fade" mode="out-in">
            <component :is="Component" />
          </Transition>
        </RouterView>
      </template>
    </TabNav>
  </div>
</template>

<script>
import TabNav from "@/components/nav/TabNav.vue";
import ButtonUI from "@/components/ButtonUI.vue";
import { getPermissions } from "@/helpers";
export default {
  components: {
    TabNav,
    ButtonUI,
  },
  methods: {
    getPermissions,
  },
};
</script>
