<template>
  <SurfaceCard :title="$t('route.admin_users')" type="dashboard" class="h-full">
    <template #body>
      <DefaultList v-if="!loading" :list="users" type="admin-users" />
    </template>
    <template #footer>
      <ButtonUI
        v-if="users.length > 0"
        size="sm"
        :to="{ name: 'admin-users' }"
        :label="$t('button.view_all_admin_users')"
      />
    </template>
  </SurfaceCard>
</template>
<script>
import SurfaceCard from "@/components/SurfaceCard.vue";
import DefaultList from "@/components/_admin/dashboard/list/DefaultList.vue";
import ButtonUI from "@/components/ButtonUI.vue";

import { useAdminUsersStore } from "@/stores";

import { storeToRefs } from "pinia";
import { getPermissions } from "@/helpers";

export default {
  setup() {
    const usersStore = useAdminUsersStore();
    usersStore.getUsers({ limit: 5 });

    const { users, loading } = storeToRefs(usersStore);
    return {
      users,
      loading,
    };
  },
  components: {
    SurfaceCard,
    DefaultList,
    ButtonUI,
  },
  methods: {
    getPermissions,
  },
};
</script>
