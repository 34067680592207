import { router } from "@/helpers";
import { useNavStore as navStore } from "@/stores";

export function redirectToPreviousPage(name = "#", hash = "", query = {}) {
  const returnUrl = navStore().getReturnUrl;
  const returnName = navStore().getReturnName;
  const fromUrl = returnUrl?.from;
  const fromName = returnName?.from;

  // validate fromName and name
  if (name === fromName && fromUrl !== "/") {
    return router.push(fromUrl);
  }
  return router.push({ name: name, hash: hash, query: query });
}
