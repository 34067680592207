<template>
  <div class="view-body">
    <div class="content-wrapper">
      <PageTitle :title="$t('route.playlist_group_create')" class="mb-7" :backUI="true" />
      <TabNav
        :items="[
          {
            title: $t('label.settings'),
            feather: 'settings',
          },
        ]"
        variant="tab"
      >
        <template #controls>
          <ButtonUI @click="onSubmit" size="lg" :label="$t('button.save_settings')" />
        </template>
        <template #panels>
          <form @submit.prevent="onSubmit">
            <div class="grid">
              <div class="col-7">
                <SurfaceCard :title="$t('label.settings')" class="h-full">
                  <template #body>
                    <LimitWidth>
                      <NotificationMessage :error="error" />
                      <FormRow>
                        <FormLabel for="first_name" required>{{ $t("label.name") }}:</FormLabel>
                        <FormText
                          id="name"
                          type="text"
                          size="lg"
                          :class="{ 'is-invalid': error.data?.name }"
                          v-model="form.name"
                        />
                        <FormHelp type="invalid" :data="error.data" field="name" />
                      </FormRow>
                      <div class="grid">
                        <div class="col-6">
                          <FormRow>
                            <FormLabel for="upload">{{ $t("label.image") }}:</FormLabel>
                            <UploadUI :image="form.image_path" @uploadChange="uploadChange" @error="uploadError" />
                          </FormRow>
                        </div>
                      </div>
                    </LimitWidth>
                  </template>
                </SurfaceCard>
              </div>
            </div>
          </form>
        </template>
      </TabNav>
    </div>
  </div>
</template>

<script>
import TabNav from "@/components/nav/TabNav.vue";
import PageTitle from "@/components/PageTitle.vue";
import SurfaceCard from "@/components/SurfaceCard.vue";
import FormRow from "@/components/form/FormRow.vue";
import FormLabel from "@/components/form/FormLabel.vue";
import FormHelp from "@/components/form/FormHelp.vue";
import FormText from "@/components/form/FormText.vue";
import ButtonUI from "@/components/ButtonUI.vue";
import LimitWidth from "@/components/LimitWidth.vue";
import NotificationMessage from "@/components/NotificationMessage.vue";
import UploadUI from "@/components/UploadUI.vue";

import { useFramesStore, useAuthStore } from "@/stores";

import { getPermissions, setBreadcrumbs } from "@/helpers";

export default {
  setup() {
    setBreadcrumbs();
    const authStore = useAuthStore();
    authStore.validateMe();
  },
  components: {
    TabNav,
    PageTitle,
    SurfaceCard,
    FormRow,
    FormLabel,
    FormText,
    FormHelp,
    UploadUI,
    ButtonUI,
    LimitWidth,
    NotificationMessage,
  },
  data() {
    return {
      error: {},
      form: {},
    };
  },
  methods: {
    async onSubmit() {
      const framesStore = useFramesStore();
      return framesStore.createCategory(this.form).catch((error) => {
        this.error = error;
      });
    },
    uploadChange(image) {
      this.form.image_path = image;
    },
    uploadError(error) {
      this.error = {};
      if (error.length > 0) {
        this.error = { message: error[0], data: { upload: [error] } };
      }
    },
    getPermissions,
  },
};
</script>

<style lang="scss" scoped></style>
