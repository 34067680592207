import { defineStore } from "pinia";

import { useAuthStore as authStore } from "@/stores/_platform/auth.store";
import { useNotificationStore, useNotificationStore as notificationStore } from "@/stores/notification.store";
import { customError, redirectToPreviousPage, selectProps, router } from "@/helpers";
import { useNavStore } from "@/stores/nav.store";
import { useAppStore } from "@/stores/app.store";
import axiosInstance from "@/helpers/axios";

export const useGroupsStore = defineStore({
  id: "groups",
  state: () => ({
    groups: [],
    groups_detail: {},
    groups_autocomplete_playlists: [],
    groups_playlists: [],
    groups_playlists_detail: {},
    groups_state_sync: [],
    loading: false,
    loading_autocomplete: false,
  }),
  getters: {},
  actions: {
    async createGroup(formData) {
      let { name, description, location, admins, systems, is_active } = formData;

      // rewrite object to array with ids
      admins = admins.map(({ id }) => id);
      systems = systems.map(({ id }) => id);

      try {
        useNavStore().setAppSpinner(true);
        const group = await axiosInstance.post(`/groups`, {
          name,
          description,
          location,
          admins,
          systems,
          is_active,
        });

        notificationStore().saveAlert(group.data.message);
        await redirectToPreviousPage("systems-groups");
      } catch (error) {
        if ([401, 403].includes(error.response?.status)) {
          authStore().logout(error.response.status);
        } else {
          throw customError(error);
        }
      } finally {
        useNavStore().setAppSpinner(false);
      }
    },
    async updateGroup(formData, nextRouteName = null) {
      let { id: group_id, name, description, location, admins, systems, is_active } = formData;

      // rewrite object to array with ids
      admins = admins.map(({ id }) => id);
      systems = systems.map(({ id }) => id);
      useNavStore().setAppSpinner(true);
      try {
        const group = await axiosInstance.put(`/groups/${group_id}`, {
          name,
          description,
          location,
          is_active,
          admins,
          systems,
        });

        notificationStore().saveAlert(group.data.message);
        if (nextRouteName != null) {
          return router.push({ name: nextRouteName });
        }
        await redirectToPreviousPage("systems-groups");
      } catch (error) {
        if ([401, 403].includes(error.response?.status)) {
          authStore().logout(error.response.status);
        } else {
          throw customError(error);
        }
      } finally {
        useNavStore().setAppSpinner(false);
      }
    },
    async deleteGroup(group_id) {
      useNavStore().setAppSpinner(true);
      try {
        const group = await axiosInstance.delete(`/groups/${group_id}`);

        notificationStore().saveAlert(group.data.message);
        await redirectToPreviousPage("systems-groups");
      } catch (error) {
        if ([401, 403].includes(error.response?.status)) {
          authStore().logout(error.response.status);
        } else {
          throw customError(error);
        }
      } finally {
        useNavStore().setAppSpinner(false);
      }
    },
    async getGroupDetails(group_id) {
      useNavStore().setAppSpinner(true);
      try {
        const group = await axiosInstance.get(`/groups/${group_id}`);

        // update pinia state
        this.groups_detail = group.data.data;
        this.groups_detail.page_name = group.data.data.name;
      } catch (error) {
        this.groups_detail = customError(error);
        if ([401, 403].includes(error.response?.status)) {
          authStore().logout(error.response.status);
        } else {
          notificationStore().saveMessage(error);
        }
      } finally {
        useNavStore().setAppSpinner(false);
      }
    },
    resetGroups() {
      this.groups = [];
    },
    resetGroupDetails() {
      this.groups_detail = {};
    },
    // Sync states
    getSyncGroupStateIndex(group_id) {
      if (!group_id) return -1;
      return this.groups_state_sync.findIndex((item) => item.id === group_id);
    },
    getSyncGroupState(group_id) {
      if (!group_id) return false;
      return this.groups_state_sync.find((item) => item.id === group_id);
    },
    addGroupSync(group_id) {
      if (!this.getSyncGroupState(group_id)) {
        this.groups_state_sync.push({
          id: group_id,
        });
      }
    },
    removeGroupSync(group_id) {
      const removeObjIndex = this.getSyncGroupStateIndex(group_id);
      this.groups_state_sync.splice(removeObjIndex, 1);
    },
    async syncGroup(group_id) {
      this.addGroupSync(group_id);
      try {
        const sync_system = await axiosInstance.post(`/groups/${group_id}/sync`, {});
        useNotificationStore().saveAlert(sync_system.data.message);
      } catch (error) {
        if ([401, 403].includes(error.response?.status)) {
          await authStore().logout(error.response.status);
        } else {
          throw customError(error);
        }
      } finally {
        setTimeout(() => {
          this.removeGroupSync(group_id);
        }, 1000);
      }
    },
    // -------------------------
    // Groups overview
    // -------------------------
    async getGroups(fields = null) {
      this.loading = true;
      useNavStore().setAppSpinner(true);
      try {
        const groups = await axiosInstance.get(`/groups`);

        // update pinia state
        let groups_data = groups.data.data;

        // select specific props
        if (fields) {
          groups_data = groups_data.map(selectProps(...fields));
        }

        this.groups = groups_data;
      } catch (error) {
        if ([401, 403].includes(error.response?.status)) {
          authStore().logout(error.response.status);
        } else {
          throw customError(error);
        }
      } finally {
        this.loading = false;
        useNavStore().setAppSpinner(false);
      }
    },
    async getGroupPlaylists(data) {
      const { group_id, type, search_term, category_id, browse } = data;

      if (!group_id) {
        return;
      }

      this.loading = true;
      useNavStore().setAppSpinner(true);

      let endpoint = `/groups/${group_id}/playlists`;
      let config = {};

      if (type) {
        if (search_term) {
          endpoint = `/groups/${group_id}/${type}/my-playlists-search`;
          if (browse) {
            endpoint = `/${type}/search/playlists`;
          }
          config = { params: { q: search_term } };
        } else {
          endpoint = `/groups/${group_id}/${type}/my-playlists`;
          if (browse) {
            endpoint = `/${type}/browse`;
            if (category_id) {
              endpoint = `/${type}/browse/${category_id}`;
            }
          } else if (category_id) {
            endpoint = `/groups/${group_id}/${type}/my-playlists/category/${category_id}`;
          }
        }
      }

      try {
        const playlists = await axiosInstance.get(endpoint, config);

        // update pinia state
        this.groups_playlists = playlists.data.data;
      } catch (error) {
        this.groups_playlists = [];
        if ([401, 403].includes(error.response?.status)) {
          authStore().logout(error.response.status);
        } else {
          throw customError(error);
        }
      } finally {
        this.loading = false;
        useNavStore().setAppSpinner(false);
      }
    },
    async getGroupAutoCompletePlaylists(group_id) {
      this.loading_autocomplete = true;
      useNavStore().setAppSpinner(true);
      try {
        const playlists = await axiosInstance.get(`/groups/${group_id}/playlists`);

        // update pinia state
        this.groups_autocomplete_playlists = playlists.data.data;
      } catch (error) {
        if ([401, 403].includes(error.response?.status)) {
          authStore().logout(error.response.status);
        } else {
          throw customError(error);
        }
      } finally {
        this.loading_autocomplete = false;
        useNavStore().setAppSpinner(false);
      }
    },
  },
});
