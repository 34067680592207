import { defineStore } from "pinia";
import { useToast } from "vue-toast-notification";
import { customError } from "@/helpers";

export const useNotificationStore = defineStore({
  id: "notification",
  state: () => ({
    alerts: JSON.parse(localStorage.getItem("alerts")) == null ? [] : JSON.parse(localStorage.getItem("alerts")),
    message: {},
  }),
  actions: {
    saveMessage(error) {
      this.message = customError(error);
    },
    resetMessage() {
      this.message = {};
    },
    showAlerts(alerts) {
      const _this = this;
      if (alerts) {
        alerts.forEach((alert) => {
          if (!alert.active) {
            _this.updateStateAlert(alert.id);
            const $notification = useToast({
              position: alert?.options?.position ? alert.options.position : "top-right",
              duration: alert?.options?.duration ? alert.options.duration : 4000,
            });
            $notification.open({
              message: alert.message,
              onDismiss: () => {
                _this.deleteAlert(alert.id);
              },
            });
          } else {
            _this.updateStateAlert(alert.id);
          }
        });
      }
    },
    saveAlert(message, options = {}) {
      const alerts = this.alerts;
      alerts.push({
        id: Date.now(),
        message: message,
        active: false,
        options,
      });

      this.alerts = alerts;
      localStorage.setItem("alerts", JSON.stringify(alerts));
    },
    deleteAlert(id) {
      const alerts = this.alerts;
      const objIndex = alerts.findIndex((alert) => alert.id === id);
      alerts.splice(objIndex, 1);
      this.alerts = alerts;
      localStorage.setItem("alerts", JSON.stringify(alerts));
    },
    updateStateAlert(id) {
      const alerts = this.alerts;

      const objIndex = alerts.findIndex((alert) => alert.id === id);
      alerts[objIndex].active = true;

      this.alerts = alerts;
      localStorage.setItem("alerts", JSON.stringify(alerts));
    },
    resetAlerts() {
      const alerts = this.alerts;
      alerts.forEach((alert, index) => {
        if (alert.active) {
          alerts.splice(index, 1);
        }
      });
      this.alerts = alerts;
      localStorage.setItem("alerts", JSON.stringify(alerts));
    },
  },
});
