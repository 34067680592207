<template>
  <TableSystems :systems="systems" v-show="!loading" />
</template>

<script>
import TableSystems from "@/components/_platform/table/systems/TableSystems.vue";

import { getPermissions, setBreadcrumbs } from "@/helpers";
import { useSystemsStore } from "@/stores";
import { storeToRefs } from "pinia";

export default {
  setup() {
    setBreadcrumbs();
    const systemsStore = useSystemsStore();

    // Systems
    systemsStore.getSystems();
    const { systems, loading } = storeToRefs(systemsStore);

    return {
      systems,
      loading,
    };
  },
  components: {
    TableSystems,
  },
  methods: {
    getPermissions,
  },
};
</script>
