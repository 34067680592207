import RegisterView from "@/views/_platform/register/RegisterView.vue";
import VerifyView from "@/views/_platform/register/VerifyView.vue";

export const registerRoutes = [
  // Register
  {
    name: "register",
    path: "/register",
    component: RegisterView,
    meta: {
      permission: "public",
      supportPageID: "002",
    },
  },
  // Verify register
  {
    name: "register-verify",
    path: "/register/verify",
    component: VerifyView,
    meta: {
      permission: "public",
    },
  },
];
