const getCellWidth = (table = null, cell) => {
  const cellName = "40%";
  const cellControls = "30%";

  let width = 0;
  // groups
  if (table === "groups") {
    switch (cell) {
      case "systems":
        return "30%";
    }
  }
  // systems
  if (table === "systems") {
    switch (cell) {
      case "group":
        return "15%";
    }
  }
  // tracks
  if (table === "tracks") {
    switch (cell) {
      case "time":
        return "15%";
      case "media":
        return "15%";
    }
  }
  // users
  if (table === "users") {
    switch (cell) {
      case "email":
      case "roles":
        return "15%";
    }
  }

  // slides, frames, tickers
  if (table === "slides" || table === "frames") {
    switch (cell) {
      case "image":
        return "15%";
      case "name":
        return "50%";
    }
  }

  // tickers
  if (table === "tickers") {
    switch (cell) {
      case "name":
        return "50%";
    }
  }

  // default
  if (width === 0) {
    switch (cell) {
      case "name":
        return cellName;
      case "controls":
        return cellControls;
    }
  }

  return;
};

export function getTableCellWidth(table, cell) {
  const width = getCellWidth(table, cell);
  if (width) {
    return { width: width };
  }
  return;
}
