<template>
  <div class="view-body">
    <div class="content-wrapper">
      <PageTitle :title="$t('route.admin_dashboard')" class="mb-7" />
      <div class="grid">
        <div class="col-12 md:col-6" v-if="getAdminPermissions('show admin users')">
          <DashboardAdminUsers />
        </div>
        <div class="col-12 md:col-6" v-if="getAdminPermissions('impersonate users')">
          <DashboardImpersonate />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { useAdminAuthStore } from "@/stores";

import PageTitle from "@/components/PageTitle.vue";
import { getAdminPermissions, setBreadcrumbs } from "@/helpers";
import DashboardAdminUsers from "@/components/_admin/dashboard/DashboardAdminUsers.vue";
import DashboardImpersonate from "@/components/_admin/dashboard/DashboardImpersonate.vue";

export default {
  setup() {
    setBreadcrumbs();
    useAdminAuthStore().validateMe();
  },
  components: {
    DashboardAdminUsers,
    DashboardImpersonate,
    PageTitle,
  },
  methods: {
    getAdminPermissions,
  },
};
</script>
