<template>
  <div>
    <div v-if="!loading">
      <template v-if="list.length > 0">
        <FormRow type="checkbox" v-for="(role, index) in list" :key="index">
          <FormCheckbox
            :inputId="role.id.toString()"
            name="roles"
            :value="role.id"
            color="palette-1"
            v-model="inputValue"
            :disabled="role?.disabled ? role.disabled : false"
          />
          <FormLabel :for="role.id">{{ $t(`roles.${role.name}`) }}</FormLabel>
        </FormRow>
      </template>
      <TextUI v-else>{{ $t("message.no_data_found") }}</TextUI>
    </div>
  </div>
</template>

<script>
import FormRow from "@/components/form/FormRow.vue";
import FormLabel from "@/components/form/FormLabel.vue";
import FormCheckbox from "@/components/form/FormCheckbox.vue";
import TextUI from "@/components/TextUI.vue";
import { storeToRefs } from "pinia";
import { useMetaStore } from "@/stores/meta.store";
import { computed, toRefs } from "vue";

export default {
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const metaStore = useMetaStore();
    metaStore.getMeta();

    let { roles, admin_roles, loading } = storeToRefs(metaStore);
    const { modelValue, type, protectedRoles } = toRefs(props);

    const inputValue = computed({
      get() {
        return modelValue.value;
      },
      set(value) {
        modelValue.value = value;
        emit("update:modelValue", value);
      },
    });

    const list = computed(() => {
      const list = type.value === "admin" ? admin_roles.value : roles.value;
      return list.map((role) => {
        // disabled checkbox if it is protected roles
        role.disabled = protectedRoles.value.includes(role.id);
        return role;
      });
    });

    return {
      inputValue,
      loading,
      list,
    };
  },
  props: {
    type: {
      type: String,
      default: "default",
    },
    protectedRoles: {
      type: Array,
      default: () => {
        return [];
      },
    },
    modelValue: {},
  },
  data() {
    return {};
  },
  components: {
    FormRow,
    FormLabel,
    FormCheckbox,
    TextUI,
  },
  methods: {},
};
</script>

<style scoped></style>
