<template>
  <div class="view-body">
    <div class="content-wrapper">
      <div v-if="!loading">
        <NotificationMessage type="404" v-if="notFound" />
        <template v-else>
          <PageTitle :title="$t('route.frames_edit')" class="mb-7" :backUI="true" />
          <TabNav
            :items="[
              {
                title: $t('label.settings'),
                feather: 'settings',
              },
            ]"
            variant="tab"
          >
            <template #controls>
              <ButtonUI
                :underline="true"
                variant="text"
                color="text-palette-1"
                size="lg"
                :label="$t('button.delete_frame')"
                @click="deleteFrame()"
                v-if="getPermissions('delete frames')"
              />
              <ButtonUI @click="onSubmit" size="lg" :label="$t('button.save_settings')" :disabled="triggerUpload" />
            </template>
            <template #panels>
              <form @submit.prevent="onSubmit">
                <NotificationMessage :error="error" />
                <div class="grid">
                  <div class="col-7 xl:col-6">
                    <SurfaceCard :title="$t('label.image')">
                      <template #body>
                        <FormRow>
                          <FormLabel for="upload" required>{{ $t("label.image") }}:</FormLabel>
                          <UploadUI
                            entity="frames"
                            :image="uploadImage"
                            :imageProps="uploadImageProperties"
                            :setCropper="true"
                            :setChunks="true"
                            :validateAspectRatio="true"
                            :id="form.id"
                            :trigger="triggerUpload"
                            @update:trigger="triggerUpload = $event"
                            @removeFile="removeFile = $event"
                            @uploadChange="uploadChange"
                            @uploadIsCompleted="uploadIsCompleted"
                            @props="uploadProps"
                            @onError="uploadError = $event"
                          />
                          <FormHelp type="invalid" :data="error.data" field="properties" />
                          <NoteAlert v-if="ratioError" :text="ratioError" />
                        </FormRow>
                      </template>
                    </SurfaceCard>
                  </div>
                  <div class="col-5 xl:col-6">
                    <SurfaceCard :title="$t('label.settings')" class="mb-5">
                      <template #body>
                        <FormRow>
                          <FormLabel for="name" required>{{ $t("label.name") }}:</FormLabel>
                          <FormText
                            id="name"
                            type="text"
                            size="lg"
                            :class="{ 'is-invalid': error.data?.name }"
                            v-model="form.name"
                          />
                          <FormHelp type="invalid" :data="error.data" field="name" />
                        </FormRow>
                        <FormRow class="mb-0">
                          <FormLabel for="description">{{ $t("label.description") }}:</FormLabel>
                          <FormTextarea
                            id="description"
                            type="text"
                            size="lg"
                            :class="{ 'is-invalid': error.data?.description }"
                            v-model="form.description"
                          />
                          <FormHelp type="invalid" :data="error.data" field="description" />
                        </FormRow>
                      </template>
                    </SurfaceCard>

                    <SurfaceCard :title="$t('label.user_playlists')" class="mb-5">
                      <template #body>
                        <ManageList
                          v-model:selected="form.playlists"
                          :add="playlistOptions"
                          :confirmTitle="$t('label.disconnect_user_playlist')"
                          :placeholder="$t('placeholder.enter_name')"
                          :messageNoData="$t('message.no_connected_playlists')"
                          :triggerSubmit="triggerSubmit"
                          @isReady="optionsAreReady = $event"
                        >
                          <template #footer>
                            <ButtonUI
                              :label="$t('button.create_playlist')"
                              color="secondary"
                              :to="{ name: 'frames-playlist-create' }"
                              v-if="
                                playlistOptions.length === 0 &&
                                form?.playlists &&
                                form.playlists.length === 0 &&
                                getPermissions('create playlists')
                              "
                            />
                            <FormHelp type="invalid" :data="error.data" field="playlists" :multipleFields="true" />
                          </template>
                        </ManageList>
                      </template>
                    </SurfaceCard>
                  </div>
                </div>
              </form>
              <DialogConfirm
                :visible="showConfirm"
                :title="$t('button.delete_frame')"
                :message="
                  form?.playlist_count
                    ? $t('message.associated_playlist_frame', { playlist_count: form.playlist_count })
                    : $t('message.confirmation_proceed')
                "
                @onConfirm="onDeleteConfirm"
                @onCancel="onDeleteCancel"
              />
            </template>
          </TabNav>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/components/PageTitle.vue";
import SurfaceCard from "@/components/SurfaceCard.vue";
import FormRow from "@/components/form/FormRow.vue";
import FormLabel from "@/components/form/FormLabel.vue";
import FormText from "@/components/form/FormText.vue";
import FormTextarea from "@/components/form/FormTextarea.vue";
import FormHelp from "@/components/form/FormHelp.vue";
import ButtonUI from "@/components/ButtonUI.vue";
import UploadUI from "@/components/UploadUI.vue";
import DialogConfirm from "@/components/dialog/DialogConfirm.vue";
import NotificationMessage from "@/components/NotificationMessage.vue";
import { useNotificationStore as notificationStore, useFramesStore } from "@/stores";
import { redirectToPreviousPage, setBreadcrumbs, getPermissions, router, isNotEmpty } from "@/helpers";
import TabNav from "@/components/nav/TabNav.vue";
import { storeToRefs } from "pinia";
import { useRoute } from "vue-router";
import NoteAlert from "@/components/NoteAlert.vue";
import ManageList from "@/components/ManageList.vue";

export default {
  setup() {
    setBreadcrumbs();

    const route = useRoute();
    const { frame_id } = route.params;

    const framesStore = useFramesStore();
    framesStore.getMyFramesDetail(frame_id);
    framesStore.getAvailablePlaylistsByFrame(frame_id);

    const {
      frames_detail: form,
      temp_created_frame: tempCreatedFrameData,
      playlists_available: playlistOptions,
      loading,
    } = storeToRefs(framesStore);

    return {
      form,
      loading,
      frame_id,
      tempCreatedFrameData,
      playlistOptions,
    };
  },
  components: {
    ManageList,
    NoteAlert,
    TabNav,
    PageTitle,
    SurfaceCard,
    NotificationMessage,
    FormRow,
    FormLabel,
    FormText,
    FormTextarea,
    FormHelp,
    ButtonUI,
    UploadUI,
    DialogConfirm,
  },
  data() {
    return {
      showConfirm: false,
      file: null,
      removeFile: false,
      triggerUpload: false,
      uploadChangedValidation: false,
      error: {},
      uploadError: {},
      triggerSubmit: false,
      optionsAreReady: false,
      selectedPlaylist: [],
    };
  },
  watch: {
    tempCreatedFrameData: {
      handler(frame) {
        if (frame?.data?.id) {
          this.form.id = frame.data.id;
          if (this.form.image && this.uploadChangedValidation) {
            this.triggerUpload = true;
          } else {
            this.uploadIsCompleted(true);
          }
        }
      },
    },
    validatedSubmit(submit) {
      if (submit) {
        this.error = {};
        this.form.delete_media = this.removeFile;
        return useFramesStore()
          .updateMyFrames(this.form)
          .catch((error) => {
            this.error = error;
            this.triggerSubmit = false;
            this.resetManageList();
          });
      }
    },
  },
  computed: {
    notFound() {
      return this.form?.status && this.form.status == 404;
    },
    uploadImage() {
      if (this.form.image?.url) {
        return this.form.image;
      }
      return null;
    },
    uploadImageProperties() {
      if (this.form.properties) {
        return this.form.properties;
      }
      return null;
    },
    ratioError() {
      if ("ratio" in this.uploadError) {
        return this.uploadError.ratio ? false : this.$t("message.note_wrong_image_16_9_ratio");
      }
      return this.$t("message.note_image_16_9_ratio");
    },
    validatedSubmit() {
      const { triggerSubmit, optionsAreReady } = this;
      return triggerSubmit && optionsAreReady;
    },
  },
  methods: {
    uploadIsCompleted(success) {
      if (success) {
        if (this.tempCreatedFrameData?.message) {
          notificationStore().saveAlert(this.tempCreatedFrameData.message);
        }
        redirectToPreviousPage("user-frames");
      }
    },
    uploadChange(image) {
      this.form.image = image;
      this.uploadChangedValidation = !!image;
    },
    uploadProps(props) {
      this.form.properties = isNotEmpty(props) ? props : {};
    },
    deleteFrame() {
      this.showConfirm = true;
    },
    onDeleteConfirm() {
      this.showConfirm = false;
      const frameStore = useFramesStore();
      return frameStore.deleteMyFrame(this.frame_id).catch((error) => {
        this.error = error;
      });
    },
    onDeleteCancel() {
      this.showConfirm = false;
    },
    resetManageList() {
      this.optionsAreReady = false;
    },
    async onSubmit() {
      this.triggerSubmit = true;
      this.resetManageList();
    },
    getPermissions,
  },
};
</script>
