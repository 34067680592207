<template>
  <div>
    <div>
      <template v-if="providers.length > 0">
        <FormSelect
          id="provider"
          size="lg"
          v-model="inputValue"
          :options="providers"
          optionLabel="name"
          :placeholder="$t('placeholder.select_a_provider')"
        />
      </template>
      <TextUI v-else>{{ $t("message.no_data_found") }}</TextUI>
    </div>
  </div>
</template>

<script>
import FormSelect from "@/components/form/FormSelect.vue";
import TextUI from "@/components/TextUI.vue";
import { providers } from "@/helpers";
import { computed, toRefs } from "vue";

export default {
  emits: ["update:modelValue"],
  setup(props, { emit }) {
    const { modelValue } = toRefs(props);

    const inputValue = computed({
      get() {
        return modelValue.value;
      },
      set(value) {
        modelValue.value = value;
        emit("update:modelValue", value);
      },
    });

    return {
      inputValue,
      providers,
    };
  },
  props: {
    type: {
      type: String,
      default: "list",
    },
    modelValue: {},
  },
  components: {
    FormSelect,
    TextUI,
  },
};
</script>

<style scoped></style>
