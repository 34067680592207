export function setShowMoreRow(rows, index, value) {
  const itemObj = rows.find((item) => item.index === index);
  if (!itemObj) {
    rows.push({
      index,
      value,
    });
  } else {
    itemObj.value = value;
  }
}
export function setShowMoreState(rows, index) {
  const itemObj = rows.find((item) => item.index === index);
  if (itemObj) {
    return itemObj.value;
  }
  return false;
}
