export function findRouteMatch(routes, name, route_match = []) {
  let match;
  routes.find((route) => {
    if (route_match.length > 0) {
      route_match.map((i) => {
        if (route.name === name && name === i) {
          match = route;
        }
      });
    } else {
      if (route.name === name) {
        match = route;
      }
    }
    if (!match && route?.children) {
      match = findRouteMatch(route.children, name, route_match);
    }
  });

  return match;
}
