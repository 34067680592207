<template>
  <div>
    <NotificationMessage :error="error" />
    <div v-if="!isNotEmpty(error)">
      <div class="mb-8">
        <ButtonUI
          :color="$route.name == 'music-search' ? 'primary' : 'light'"
          size="md"
          label="All"
          :to="{ name: 'music-search' }"
          variant="rounded"
          class="mr-4"
        />
        <ButtonUI
          :color="$route.name == 'music-search-playlists' ? 'primary' : 'light'"
          size="md"
          label="Playlists"
          :to="{ name: 'music-search-playlists' }"
          variant="rounded"
          class="mr-4"
        />
        <ButtonUI
          :color="$route.name == 'music-search-tracks' ? 'primary' : 'light'"
          size="md"
          label="Tracks"
          :to="{ name: 'music-search-tracks' }"
          variant="rounded"
          class="mr-4"
        />
      </div>

      <div v-if="!loading">
        <template
          v-if="
            (search_results?.playlists && search_results.playlists.length > 0) ||
            (search_results?.tracks && search_results.tracks.length > 0)
          "
        >
          <div class="mb-10" v-if="showPlaylists">
            <div class="grid align-items-end mb-5">
              <div class="col-6">
                <h2 class="mb-0">
                  <span v-html="$t('label.playlists')" />
                  <span v-if="search_results?.total_playlists > 0"> ({{ search_results.total_playlists }})</span>
                </h2>
              </div>

              <div class="col-6 text-right" v-if="showMorePlaylists && $route.name != 'music-search-playlists'">
                <RouterLink :to="{ name: 'music-search-playlists' }" class="show-more">{{
                  $t("button.show_more")
                }}</RouterLink>
              </div>
            </div>

            <PlayLists
              v-if="search_results?.playlists && search_results.playlists.length > 0"
              :list="search_results.playlists"
              :limit-by-viewport="$route.name != 'music-search-playlists'"
              @onShowMoreClick="onShowMoreClickPlaylists()"
              @showMore="showMorePlaylists = $event"
            />
            <NotificationMessage type="default" v-else>{{ $t("message.no_results_found") }}</NotificationMessage>
          </div>

          <div v-if="showTracks">
            <div class="grid align-items-end mb-5">
              <div class="col-6">
                <h2 class="mb-0">
                  <span v-html="$t('label.tracks')" />
                  <span v-if="search_results?.total_tracks > 0"> ({{ search_results.total_tracks }})</span>
                </h2>
              </div>

              <div
                class="col-6 text-right"
                v-if="search_results?.total_tracks > limit && $route.name != 'music-search-tracks'"
              >
                <RouterLink :to="{ name: 'music-search-tracks' }" class="show-more">{{
                  $t("button.show_more")
                }}</RouterLink>
              </div>
            </div>

            <TableTracks
              :tracks="search_results.tracks"
              :control-permissions="['add to playlist']"
              v-if="search_results?.tracks && search_results.tracks.length > 0"
            />
            <NotificationMessage type="default" v-else>{{ $t("message.no_results_found") }}</NotificationMessage>
          </div>
        </template>
        <NotificationMessage type="default" v-else>{{ $t("message.no_results_found") }}</NotificationMessage>
      </div>
    </div>
  </div>
</template>

<script>
import NotificationMessage from "@/components/NotificationMessage.vue";
import TableTracks from "@/components/_platform/table/playlists/TableTracks.vue";
import PlayLists from "@/components/_platform/playlist/PlayLists.vue";
import ButtonUI from "@/components/ButtonUI.vue";

import { getPermissions, isNotEmpty, router, setBreadcrumbs } from "@/helpers";
import { useMusicStore } from "@/stores";
import { storeToRefs } from "pinia";

export default {
  setup() {
    const musicStore = useMusicStore();
    const { search_results } = storeToRefs(musicStore);

    setBreadcrumbs("music");

    return {
      search_results,
    };
  },
  data() {
    return {
      error: {},
      loading: true,
      limit: 15,
      showMorePlaylists: false,
    };
  },
  components: {
    NotificationMessage,
    TableTracks,
    PlayLists,
    ButtonUI,
  },
  computed: {
    term() {
      const { term } = this.$route.params;
      return term;
    },
    filter() {
      return this.$route?.name;
    },
    showAll() {
      return this.filter == "music-search";
    },
    showPlaylists() {
      return this.showAll || this.filter == "music-search-playlists";
    },
    showTracks() {
      return this.showAll || this.filter == "music-search-tracks";
    },
  },
  methods: {
    onShowMoreClickPlaylists() {
      router.push({
        name: "music-search-playlists",
      });
    },
    async search(value) {
      const musicStore = useMusicStore();
      this.loading = true;
      this.error = {};
      try {
        await musicStore.search(value, this.filter);
      } catch (error) {
        this.error = error;
      } finally {
        this.loading = false;
      }
    },
    getPermissions,
    isNotEmpty,
  },
  mounted() {
    this.search(this.term);
  },
  watch: {
    term(value) {
      this.search(value);
    },
    filter() {
      this.search(this.term);
    },
  },
};
</script>
<style lang="scss" scoped>
.col-card {
  @media (min-width: 1400px) {
    width: 20%;
  }

  @include media-breakpoint-up(xxxl) {
    width: 14.28%;
  }
}
</style>
