<template>
  <div class="grid">
    <div class="col-7">
      <form @submit.prevent="onSubmit">
        <SurfaceCard :title="$t('label.settings')">
          <template #body>
            <div class="card-body">
              <LimitWidth>
                <NotificationMessage :error="error" />
                <FormRow>
                  <FormLabel for="first_name" required>{{ $t("label.first_name") }}:</FormLabel>
                  <FormText
                    id="first_name"
                    type="text"
                    size="lg"
                    :class="{ 'is-invalid': error.data?.first_name }"
                    v-model="form.first_name"
                  />
                  <FormHelp type="invalid" :data="error.data" field="first_name" />
                </FormRow>
                <FormRow>
                  <FormLabel for="last_name" required>{{ $t("label.last_name") }}:</FormLabel>
                  <FormText
                    id="last_name"
                    type="text"
                    size="lg"
                    :class="{ 'is-invalid': error.data?.last_name }"
                    v-model="form.last_name"
                  />
                  <FormHelp type="invalid" :data="error.data" field="last_name" />
                </FormRow>
                <FormRow>
                  <FormLabel for="email" required>{{ $t("label.email") }}:</FormLabel>
                  <FormText
                    id="email"
                    type="email"
                    size="lg"
                    :class="{ 'is-invalid': error.data?.email }"
                    v-model="form.email"
                  />
                  <FormHelp type="invalid" :data="error.data" field="email" />
                </FormRow>

                <FormRow>
                  <FormLabel for="language">{{ $t("label.language") }}:</FormLabel>
                  <TextUI>English</TextUI>
                  <FormHelp type="invalid" :data="error.data" field="language" />
                </FormRow>
              </LimitWidth>
            </div>
          </template>
          <template #footer>
            <div class="card-footer">
              <ButtonUI type="submit" size="lg" :label="$t('button.save_settings')" />
            </div>
          </template>
        </SurfaceCard>
      </form>
    </div>
  </div>
</template>
<script>
import SurfaceCard from "@/components/SurfaceCard.vue";
import FormRow from "@/components/form/FormRow.vue";
import FormHelp from "@/components/form/FormHelp.vue";
import FormLabel from "@/components/form/FormLabel.vue";
import FormText from "@/components/form/FormText.vue";
import TextUI from "@/components/TextUI.vue";
import ButtonUI from "@/components/ButtonUI.vue";
import LimitWidth from "@/components/LimitWidth.vue";
import NotificationMessage from "@/components/NotificationMessage.vue";

import { useAuthStore } from "@/stores";
import { getPermissions, setBreadcrumbs } from "@/helpers";

export default {
  setup() {
    setBreadcrumbs();
    const authStore = useAuthStore();
    authStore.validateMe();

    const form = { ...authStore.getUser };

    return { form };
  },
  components: {
    SurfaceCard,
    FormRow,
    FormLabel,
    FormText,
    FormHelp,
    ButtonUI,
    TextUI,
    LimitWidth,
    NotificationMessage,
  },
  data() {
    return {
      error: {},
      state: {},
      page_name: null,
      timeout: false,
      showConfirm: false,
    };
  },
  methods: {
    async onSubmit() {
      this.error = [];
      const authStore = useAuthStore();

      if (this.timeout) {
        clearTimeout(this.timeout);
      }
      this.timeout = setTimeout(() => {
        return authStore.updateUserProfile(this.form).catch((error) => {
          this.error = error;
        });
      }, 500);
    },
    getPermissions,
  },
  computed: {
    notFound() {
      return this.form?.status && this.form.status == 404;
    },
  },
};
</script>

<style lang="scss" scoped></style>
