export function arrayHasChanged(arr1, arr2) {
  // Create a map for quick lookup of ids from the second array
  const idMap = new Map(arr2.map((item) => [item, item]));

  // Check for changes in the first array
  for (const item of arr1) {
    if (!idMap.has(item)) {
      // If id is not found in the second array, it means it has been removed
      return true;
    }
  }

  // Check for changes in the second array
  const idMap1 = new Map(arr1.map((item) => [item, item]));
  for (const item of arr2) {
    if (!idMap1.has(item)) {
      // If id is not found in the first array, it means it has been added
      return true;
    }
  }

  return false;
}
