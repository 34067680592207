import { getRouteTranslation, i18n } from "@/helpers";
import { reactive } from "vue";
import { useAppStore, useNavStore } from "@/stores";

export function getPageTitle(key) {
  let metaTitle;
  let { appName } = useAppStore().getAppConfig;
  const isAdmin = useNavStore().isAdminPage;

  const appTitle = appName;

  if (Array.isArray(key)) {
    const newKey = reactive([]);
    Object.assign(newKey, key);

    metaTitle = newKey
      .reverse()
      .map((item) => {
        return item?.name ? getRouteTranslation(item.name) : item?.title ? item.title : null;
      })
      .join(" - ");
  } else {
    metaTitle = getRouteTranslation(key);
  }

  let newTitle = appTitle;
  if (isAdmin) {
    newTitle = `${i18n.global.t("label.admin")} ${newTitle}`;
  }

  if (metaTitle) {
    newTitle = `${metaTitle} - ${newTitle}`;
  }

  return newTitle;
}
