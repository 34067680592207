<template>
  <div :id="`category_${id}`" class="surface-card surface-card--category h-full" @click="onClick(id)">
    <div class="card-header">
      <div class="card-image mb-4">
        <div v-if="error || !image" class="no-image"></div>
        <div v-else-if="image" class="image">
          <Transition name="image">
            <img v-lazy="{ src: lazyOptions.src, lifecycle: lazyOptions.lifecycle, delay: 400 }" :alt="title" />
          </Transition>
          <Transition name="image">
            <PSkeleton width="100%" height="100%" class="skeleton" v-show="!loaded" />
          </Transition>
        </div>
        <div v-else class="no-image"></div>
      </div>
    </div>
    <div class="card-body">
      <div class="body-header">
        <h3 v-html="title" class="card-title mb-0" />
      </div>
    </div>
  </div>
</template>
<script>
import Skeleton from "primevue/skeleton";
import { toRefs, ref, reactive } from "vue";

export default {
  setup(props) {
    const { image } = toRefs(props);
    const src = ref(image.value);
    let loaded = ref(false);
    let error = ref(false);

    const lazyOptions = reactive({
      src: src,
      delay: 400,
      lifecycle: {
        loading: () => {
          error.value = false;
        },
        loaded: () => {
          loaded.value = true;
        },
        error: () => {
          loaded.value = true;
          error.value = true;
        },
      },
    });

    return {
      lazyOptions,
      loaded,
      error,
    };
  },
  props: {
    type: {
      type: String,
    },
    id: {
      type: [String, Number],
    },
    title: {
      type: String,
    },
    image: {
      type: String,
    },
  },
  emits: ["onClick"],
  components: {
    PSkeleton: Skeleton,
  },
  methods: {
    onClick(id) {
      this.$emit("onClick", id);
    },
  },
};
</script>
<style lang="scss" scoped>
.image-enter-active {
  transition: all 0.15s ease-out;
}

.image-leave-active {
  transition: all 0.15s cubic-bezier(1, 0.5, 0.8, 1);
}

.image-enter-from,
.image-leave-to {
  opacity: 0;
}

.surface-card--category {
  padding: spacing(5);
  cursor: pointer;
  transition: all 0.15s cubic-bezier(1, 0.5, 0.8, 1);

  .card-header {
    position: relative;
  }

  .card-image {
    padding-bottom: 100%;
    height: 0;
    position: relative;
    overflow: hidden;
    border-radius: 3px;
    border: var(--surface-card-image-border);

    .card-label {
      position: absolute;
      right: -10px;
      top: -10px;
      width: 34px;
      height: 34px;
      background: var(--surface-card);
      border-radius: 0 0 0 6px;
      color: currentColor;
      z-index: 1;

      .lock-inner {
        width: 24px;
        height: 24px;
        margin-top: 10px;
        padding: 1px 0 0 6px;
      }
    }

    .no-image {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: var(--no-image-ground--surface);
      z-index: 0;
      &:before {
        content: "";
        background: var(--input-playlist-category-ground-icon) no-repeat 50% 50%;
        background-size: 100%;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 54px;
        height: 54px;
        transform: translateX(-50%) translateY(-50%);
        z-index: 1;
        transition: all 0.15s ease-in-out;
        -webkit-mask-image: -webkit-gradient(
          linear,
          left top,
          left bottom,
          from(rgba(0, 0, 0, 1)),
          to(rgba(0, 0, 0, 0))
        );
      }
    }

    .image {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 0;
      .skeleton,
      img {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        z-index: 0;
        border-radius: 0;
      }

      .skeleton {
        z-index: 1;
      }

      img {
        z-index: 0;
        background: var(--surface-card);
      }
    }
  }

  .card-title {
    font-size: font-size("xl");
    word-break: break-word;
  }

  &:hover {
    transform: scale(1.1);
    box-shadow: 0 10px 30px 0 rgba(#000, 0.2);
  }
}
</style>
