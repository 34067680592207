<template>
  <div id="view" class="login-view">
    <BackUI feather="x" variant="primary" :to="{ name: 'login' }" />

    <SurfaceCard type="login">
      <PageTitle :title="$t('route.register_verify')" class="mb-5 text-3xl" />

      <form @submit.prevent="onSubmit">
        <NotificationMessage :error="error" />
        <FormRow>
          <FormLabel for="verify-password" required>{{ $t("label.password") }}:</FormLabel>
          <FormText
            inputId="verify-password"
            :inputProps="{
              autocomplete: 'off',
            }"
            type="password"
            size="lg"
            :class="{ 'is-invalid': error.data?.password }"
            v-model="form.password"
          />
        </FormRow>

        <FormRow>
          <FormLabel for="confirm-verify-password" required>{{ $t("label.confirm_password") }}:</FormLabel>
          <FormText
            inputId="confirm-verify-password"
            :inputProps="{
              autocomplete: 'off',
            }"
            type="password"
            size="lg"
            :class="{ 'is-invalid': error.data?.password_confirmation }"
            v-model="form.password_confirmation"
          />
          <FormHelp type="invalid" :data="error.data" field="password" />
        </FormRow>

        <div class="field mb-0">
          <ButtonUI
            type="submit"
            size="xl"
            class="w-full justify-content-center"
            :label="$t('button.verify_account')"
            :disabled="isSubmitting"
          />
        </div>
      </form>
    </SurfaceCard>
  </div>
</template>

<script>
import { useAuthStore } from "@/stores";

import PageTitle from "@/components/PageTitle.vue";
import FormRow from "@/components/form/FormRow.vue";
import FormText from "@/components/form/FormText.vue";
import FormHelp from "@/components/form/FormHelp.vue";
import ButtonUI from "@/components/ButtonUI.vue";
import BackUI from "@/components/BackUI.vue";
import NotificationMessage from "@/components/NotificationMessage.vue";
import FormLabel from "@/components/form/FormLabel.vue";
import SurfaceCard from "@/components/SurfaceCard.vue";
import { router } from "@/helpers";

export default {
  setup() {
    const authStore = useAuthStore();
    if (authStore.loggedIn) {
      router.push({ name: "dashboard" });
    }
  },
  components: {
    PageTitle,
    FormRow,
    ButtonUI,
    BackUI,
    FormText,
    FormHelp,
    NotificationMessage,
    FormLabel,
    SurfaceCard,
  },
  data() {
    return {
      error: {},
      form: {
        password: null,
        password_confirmation: null,
      },
      isSubmitting: false,
    };
  },
  methods: {
    async onSubmit() {
      const authStore = useAuthStore();
      this.isSubmitting = true;

      try {
        await authStore.verify(this.form, this.$route);
      } catch (error) {
        this.error = error;
      } finally {
        this.isSubmitting = false;
      }
    },
  },
};
</script>
