import { useAppStore } from "@/stores";
import { EncryptStorage } from "encrypt-storage";

export function storageEncryption() {
  const { isEncrypt, mode, providerID, hostname } = useAppStore().getAppConfig;

  /**
   * secret key should be stored in a safe place. This is only for a demo purpose.
   */
  const secretkey = `mode=${mode}&provider=${providerID}&host=${hostname}`;

  const encryptStorage = new EncryptStorage(secretkey, {
    doNotParseValues: true,
  });

  function isString(variable) {
    return typeof variable === "string";
  }

  function isValidJson(jsonString) {
    try {
      JSON.parse(jsonString);
      return true; // JSON is valid
    } catch (e) {
      return false; // JSON is not valid
    }
  }

  function manipulateLocalStorage() {
    Storage.prototype.setEncryptedItem = (key, value) => {
      if (value) {
        const stringify = JSON.stringify(value);
        isEncrypt ? encryptStorage.setItem(key, stringify) : localStorage.setItem(key, stringify);
      }
    };

    Storage.prototype.getDecryptedItem = (key) => {
      const value = localStorage.getItem(key);
      if (value) {
        if (isEncrypt && isString(value)) {
          if (isValidJson(value)) {
            return localStorage.removeItem(key);
          }
          return JSON.parse(encryptStorage.getItem(key));
        } else if (isValidJson(value)) {
          return JSON.parse(value);
        }
        return localStorage.removeItem(key);
      }
      // return null;
    };
  }
  /**
   * First call this function to add our custom functions to the Storage interface
   *
   */
  manipulateLocalStorage();
  /**
   * you can use the setEncryptedItem and getDecryptedItem functions
   * to encrypt and decrypt the data
   * */
}
