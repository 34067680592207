<template>
  <ToolTip :content="tooltip">
    <span
      class="badge"
      :class="[`badge-${size}`, variant ? `badge-${variant}` : '', color ? `badge-${color}` : '', className]"
    >
      <span>
        <span class="badge-icon" v-if="feather">
          <vue-feather :type="feather" size="18" />
        </span>
        <span class="badge-label" v-html="title" v-if="title" />
      </span>
    </span>
  </ToolTip>
</template>
<script>
import VueFeather from "vue-feather";
import ToolTip from "@/components/ToolTip.vue";

export default {
  components: {
    VueFeather,
    ToolTip,
  },
  props: {
    title: {
      type: String,
    },
    tooltip: {
      type: String,
    },
    variant: {
      type: String,
    },
    color: {
      type: String,
      default: "default",
    },
    feather: {
      type: String,
    },
    size: {
      type: String,
      default: "md",
    },
    class: {
      type: String,
    },
  },
  computed: {
    className() {
      return this.class;
    },
  },
  methods: {},
};
</script>
