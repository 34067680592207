import { defineStore } from "pinia";

import { useAuthStore as authStore } from "@/stores/_platform/auth.store";
import { customError, redirectToPreviousPage, getMinutesBetweenDates, router } from "@/helpers";
import { useNotificationStore } from "@/stores/notification.store";
import { useNavStore } from "@/stores/nav.store";
import { useAppStore } from "@/stores/app.store";
import axiosInstance from "@/helpers/axios";

export const useSystemsStore = defineStore({
  id: "systems",
  state: () => ({
    systems: [],
    systems_detail: {},
    systems_autocomplete_playlists: [],
    systems_playlists: [],
    systems_playlists_detail: {},
    systems_state_list:
      JSON.parse(localStorage.getItem("systems_state_list")) == null
        ? []
        : JSON.parse(localStorage.getItem("systems_state_list")),
    systems_state_visible: [],
    systems_state_sync: [],
    systems_pending: [],
    loading: false,
    loading_autocomplete: false,
  }),
  getters: {
    systemsPendingTotal(state) {
      return state.systems_pending.length;
    },
  },
  actions: {
    // -------------------------
    // Status overview
    // -------------------------
    syncStatus() {
      const _this = this;
      const nowDate = new Date().getTime();

      let timer = 0;
      let index = 0;
      this.systems_state_visible.map((visible_system) => {
        const system = _this.systems_state_list.find((system) => system.system_id == visible_system.system_id);
        if (getMinutesBetweenDates(system.last_seen, nowDate, system) >= 15) {
          if (index < 5) {
            setTimeout(() => {
              // console.log(`/systems/${system.system_id}/status`);
              axiosInstance
                .get(`/systems/${system.system_id}/status`)
                .then((response) => {
                  _this.updateSystemStatus(system.system_id, response.data.data.status, nowDate);
                })
                .catch((error) => {
                  _this.updateSystemStatus(system.system_id, "warning", nowDate);
                  throw error;
                });
            }, timer);
            timer = timer + 300;
            index++;
          }
        }
      });
    },
    resetSystems() {
      this.systems = [];
      this.systems_pending = [];
      this.resetVisibleSystems();
    },
    // Visible states
    getStatus(id) {
      const fallBackState = "loading";
      const objSystemState = this.systems_state_list.find((state) => state.system_id === id);

      if (objSystemState && objSystemState?.status) {
        return objSystemState.status;
      } else {
        // save date in the past
        const yesterdayDate = new Date(new Date().setDate(new Date().getDate() - 1)).getTime();
        this.saveSystemStatus(id, fallBackState, yesterdayDate);
        return fallBackState;
      }
    },
    updateSystemStatus(system_id, status, last_seen) {
      const systemIndex = this.systems_state_list.findIndex((system) => system.system_id == system_id);
      this.systems_state_list[systemIndex].status = status;
      this.systems_state_list[systemIndex].last_seen = last_seen;
      localStorage.setItem("systems_state_list", JSON.stringify(this.systems_state_list));
    },
    saveSystemStatus(system_id, status, last_seen) {
      this.systems_state_list.push({ system_id, status, last_seen });
      localStorage.setItem("systems_state_list", JSON.stringify(this.systems_state_list));
    },
    resetVisibleSystems() {
      this.systems_state_visible = [];
    },
    resetPendingSystems() {
      this.systems_pending = [];
    },
    saveVisibleSystem(system_id) {
      const system = this.systems_state_visible.find((system) => system.system_id == system_id);
      if (!system) {
        this.systems_state_visible.push({ system_id });
      }
    },
    // Sync states
    getSyncSystemStateIndex(system_id) {
      if (!system_id) return -1;
      return this.systems_state_sync.findIndex((item) => item.id === system_id);
    },
    getSyncSystemState(system_id) {
      if (!system_id) return false;
      return this.systems_state_sync.find((item) => item.id === system_id);
    },
    addSystemSync(system_id) {
      if (!this.getSyncSystemState(system_id)) {
        this.systems_state_sync.push({
          id: system_id,
        });
      }
    },
    removeSystemSync(system_id) {
      const removeObjIndex = this.getSyncSystemStateIndex(system_id);
      this.systems_state_sync.splice(removeObjIndex, 1);
    },
    async syncSystem(system_id) {
      this.addSystemSync(system_id);
      try {
        const sync_system = await axiosInstance.post(`/systems/${system_id}/sync`, {});
        useNotificationStore().saveAlert(sync_system.data.message);
      } catch (error) {
        if ([401, 403].includes(error.response?.status)) {
          authStore().logout(error.response.status);
        } else {
          throw customError(error);
        }
      } finally {
        setTimeout(() => {
          this.removeSystemSync(system_id);
        }, 1000);
      }
    },
    // -------------------------
    // Systems overview
    // -------------------------
    async getSystems(group_id = null) {
      this.loading = true;
      useNavStore().setAppSpinner(true);
      try {
        const systems = await axiosInstance.get(`/${group_id == null ? `systems` : `groups/${group_id}/systems`}`);

        // update pinia state
        this.systems = systems.data.data;
      } catch (error) {
        if ([401, 403].includes(error.response?.status)) {
          authStore().logout(error.response.status);
        } else {
          throw customError(error);
        }
      } finally {
        this.loading = false;
        useNavStore().setAppSpinner(false);
      }
    },
    async getGroupSystems() {
      this.loading = true;
      useNavStore().setAppSpinner(true);
      try {
        const systems = await axiosInstance.get(`/groups/systems`);

        // update pinia state
        this.systems = systems.data.data;
      } catch (error) {
        if ([401, 403].includes(error.response?.status)) {
          authStore().logout(error.response.status);
        } else {
          throw customError(error);
        }
      } finally {
        this.loading = false;
        useNavStore().setAppSpinner(false);
      }
    },
    async getPendingSystems(limit = 0) {
      this.loading = true;
      useNavStore().setAppSpinner(true);
      try {
        const params = limit > 0 ? { limit: limit } : {};

        const systems = await axiosInstance.get(`/systems/pending`, {
          params: params,
        });

        // update pinia state
        this.systems_pending = systems.data.data.length > 0 ? systems.data.data : [];
      } catch (error) {
        if ([401, 403].includes(error.response?.status)) {
          authStore().logout(error.response.status);
        } else {
          throw customError(error);
        }
      } finally {
        this.loading = false;
        useNavStore().setAppSpinner(false);
      }
    },
    async getSystemsDetail(system_id) {
      try {
        const system = await axiosInstance.get(`/systems/${system_id}`);

        this.systems_detail = system.data.data;
        this.systems_detail.page_name = this.systems_detail.name;

        // replace group value by id
        // this.systems_detail.group = system.data.data?.group?.id;
      } catch (error) {
        this.systems_detail = customError(error);
        if ([401, 403].includes(error.response?.status)) {
          authStore().logout(error.response.status);
        } else {
          useNotificationStore().saveMessage(error);
        }
      }
    },
    resetSystemsDetail() {
      this.systems_detail = {};
    },
    async updateSystem(formData, nextRouteName = null) {
      let { id, name, description, admins, group } = formData;

      // rewrite object to array with ids
      admins = admins.map(({ id }) => id);

      try {
        const system = await axiosInstance.put(`/systems/${id}`, {
          name,
          description,
          group_id: group.id,
          admins,
        });
        this.systems_detail.page_name = name;

        useNotificationStore().saveAlert(system.data.message);
        if (nextRouteName != null) {
          return router.push({ name: nextRouteName });
        }
        await redirectToPreviousPage("systems");
      } catch (error) {
        if ([401, 403].includes(error.response?.status)) {
          authStore().logout(error.response.status);
        } else {
          throw customError(error);
        }
      }
    },
    async deleteSystem(system_id) {
      try {
        const system = await axiosInstance.delete(`/systems/${system_id}`);

        useNotificationStore().saveAlert(system.data.message);
        await redirectToPreviousPage("systems");
      } catch (error) {
        if ([401, 403].includes(error.response?.status)) {
          authStore().logout(error.response.status);
        } else {
          throw customError(error);
        }
      }
    },
    async approveOrRejectSystem(system_id, system_case) {
      try {
        const system = await axiosInstance.post(`/systems/${system_id}/${system_case}`, null);

        useNotificationStore().saveAlert(system.data.message);
        await redirectToPreviousPage("systems-pending");
      } catch (error) {
        if ([401, 403].includes(error.response?.status)) {
          authStore().logout(error.response.status);
        } else {
          throw customError(error);
        }
      }
    },
    async getSystemAutoCompletePlaylists(system_id) {
      if (!system_id) {
        return;
      }

      this.loading_autocomplete = true;
      useNavStore().setAppSpinner(true);

      try {
        const playlists = await axiosInstance.get(`/systems/${system_id}/playlists`);

        // update pinia state
        this.systems_autocomplete_playlists = playlists.data.data;
      } catch (error) {
        if ([401, 403].includes(error.response?.status)) {
          authStore().logout(error.response.status);
        } else {
          throw customError(error);
        }
      } finally {
        this.loading_autocomplete = false;
        useNavStore().setAppSpinner(false);
      }
    },

    async getSystemPlaylists(data) {
      const { system_id, type, search_term, category_id, browse } = data;

      if (!system_id) {
        return;
      }

      this.loading = true;
      useNavStore().setAppSpinner(true);

      let endpoint = `/systems/${system_id}/playlists`;
      let config = {};

      if (type) {
        if (search_term) {
          endpoint = `/systems/${system_id}/${type}/my-playlists-search`;
          if (browse) {
            endpoint = `/${type}/search/playlists`;
          }
          config = { params: { q: search_term } };
        } else {
          endpoint = `/systems/${system_id}/${type}/my-playlists`;
          if (browse) {
            endpoint = `/${type}/browse`;
            if (category_id) {
              endpoint = `/${type}/browse/${category_id}`;
            }
          } else if (category_id) {
            endpoint = `/systems/${system_id}/${type}/my-playlists/category/${category_id}`;
          }
        }
      }

      try {
        const playlists = await axiosInstance.get(endpoint, config);

        // update pinia state
        this.systems_playlists = playlists.data.data;
      } catch (error) {
        this.systems_playlists = [];
        if ([401, 403].includes(error.response?.status)) {
          authStore().logout(error.response.status);
        } else {
          throw customError(error);
        }
      } finally {
        this.loading = false;
        useNavStore().setAppSpinner(false);
      }
    },
  },
});
