<template>
  <div>
    <BackUI variant="primary" to="#" :title="categories_detail.name" class="mb-7" @click.prevent="goBack()" />
    <div v-if="!loading">
      <NotificationMessage type="default" v-if="notFound">{{ $t("404.text") }}</NotificationMessage>
      <SlideList :list="categories_detail.slides" type="bcm" />
    </div>
  </div>
</template>

<script>
import BackUI from "@/components/BackUI.vue";
import NotificationMessage from "@/components/NotificationMessage.vue";
import SlideList from "@/components/_platform/slides/SlideList.vue";

import { getPermissions, router, setBreadcrumbs } from "@/helpers";
import { useNavStore, useSlidesStore } from "@/stores";
import { storeToRefs } from "pinia";
import { useRoute } from "vue-router";

export default {
  setup() {
    const route = useRoute();
    const { id } = route.params;

    const slideStore = useSlidesStore();
    slideStore.getSlidesByCategory(id);

    const { categories_detail, loading } = storeToRefs(slideStore);

    return {
      categories_detail,
      loading,
    };
  },
  emits: ["notFound"],
  components: {
    BackUI,
    SlideList,
    NotificationMessage,
  },
  methods: {
    getPermissions,
    goBack() {
      router.push({ name: "slides-browse" });
    },
  },
  computed: {
    notFound() {
      const notFound = this.categories_detail?.status && this.categories_detail.status == 404;
      this.$emit("notFound", notFound);
      return notFound;
    },
  },
  watch: {
    categories_detail: {
      handler(category) {
        if (category?.name) {
          const navStore = useNavStore();
          navStore.setBreadcrumb("slides-user-category");
          navStore.setBreadcrumbTitle(category.name, 2);
        }
      },
      immediate: true,
    },
  },
};
</script>
