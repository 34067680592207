<template>
  <small :class="[`form-help form-help--${type}`]" v-if="showError">
    <div v-if="message" v-html="message" />
    <slot v-else />
  </small>
</template>
<script>
export default {
  props: {
    type: {
      type: String,
      default: "help",
    },
    data: {
      type: Object,
      default() {
        return {};
      },
    },
    field: {
      type: String,
    },
    multipleFields: {
      type: Boolean,
      default: false,
    },
    label: {
      type: String,
    },
  },
  methods: {
    hasSlot(name = "default") {
      return !!this.$slots[name] || !!this.$slots[name];
    },
  },
  computed: {
    showError() {
      if (this.message || this.hasSlot()) {
        return true;
      }
      return false;
    },
    message() {
      if (this.field) {
        let message;
        if (this.multipleFields) {
          let errorMessage = "";
          Object.entries(this.data).map((data) => {
            const key = data[0];
            message = Array.isArray(data[1]) ? data[1][0] : data[1];
            if (key.includes(this.field)) {
              errorMessage += `${message}<br />`;
            }
          });
          return errorMessage;
        } else if (this.data && this.field && this.data[this.field]) {
          const data = this.data[this.field];
          message = Array.isArray(data) ? data[0] : data;
        }
        return message;
      }
      return this.label;
    },
  },
};
</script>
