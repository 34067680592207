<template>
  <div>
    <div v-if="!loading">
      <TableTickers :tickers="tickers" />
    </div>
  </div>
</template>

<script>
import TableTickers from "@/components/_platform/table/tickers/TableTickers.vue";

import { getPermissions } from "@/helpers";
import { useNavStore, useTickersStore } from "@/stores";
import { storeToRefs } from "pinia";

export default {
  setup() {
    const tickersStore = useTickersStore();
    tickersStore.getMyTickers();

    const navStore = useNavStore();
    navStore.setBreadcrumb("tickers");

    const { tickers, loading } = storeToRefs(tickersStore);

    return {
      tickers,
      loading,
    };
  },
  components: {
    TableTickers,
  },
  methods: {
    getPermissions,
  },
};
</script>
