import LeftSidebar from "@/components/LeftSidebar.vue";
import SlidesIndexView from "@/views/_platform/slides/IndexView.vue";
import SlidesOverView from "@/views/_platform/slides/partials/user-playlists/IndexView.vue";
import SlidesUserSlidesOverView from "@/views/_platform/slides/partials/user-slides/IndexView.vue";
import SlidesBrowseView from "@/views/_platform/slides/partials/browse/IndexView.vue";
import SlidesBrowseCategoryView from "@/views/_platform/slides/partials/browse/CategoryView.vue";
import SlidesCategoryView from "@/views/_platform/slides/partials/user-playlists/CategoryView.vue";
import SlidesPlaylistDetailView from "@/views/_platform/slides/partials/user-playlists/DetailView.vue";
import SlidesPlaylistCreateView from "@/views/_platform/slides/partials/user-playlists/CreateView.vue";
import SlidesUserSlidesCreateView from "@/views/_platform/slides/partials/user-slides/CreateView.vue";
import SlidesUserSlidesEditView from "@/views/_platform/slides/partials/user-slides/EditView.vue";

export const slidesRoutes = [
  // Slides
  // TODO: Correct permissions
  {
    path: "/slides",
    name: "slides-index",
    components: {
      LeftSidebar,
      default: SlidesIndexView,
    },
    children: [
      {
        path: "/slides",
        name: "slides",
        components: {
          Slides: SlidesOverView,
        },
        meta: {
          permission: "search slides",
          supportPageID: "021",
        },
      },
      {
        path: "/slides/user-slides",
        name: "user-slides",
        components: {
          Slides: SlidesUserSlidesOverView,
        },
        meta: {
          permission: "search slides",
          supportPageID: "022",
        },
      },
      {
        path: "/slides/browse",
        name: "slides-browse",
        components: {
          Slides: SlidesBrowseView,
        },
        meta: {
          permission: "search slides",
          supportPageID: "023",
        },
      },
      // {
      //   path: "/slides/search/:term",
      //   name: "slides-search",
      //   components: {
      //     Slides: SlidesSearchView,
      //   },
      //   meta: {
      //     permission: "search slides",
      //   },
      // },
      {
        name: "slides-browse-category",
        path: "/slides/browse/category/:id",
        components: {
          Slides: SlidesBrowseCategoryView,
        },
        meta: {
          permission: "search slides",
          supportPageID: "023",
        },
      },
      {
        name: "slides-user-category",
        path: "/slides/category/:id",
        components: {
          Slides: SlidesCategoryView,
        },
        meta: {
          permission: "search slides",
          supportPageID: "022",
        },
      },
      {
        name: "slides-user-category-other",
        path: "/slides/other",
        components: {
          Slides: SlidesCategoryView,
        },
        meta: {
          permission: "search slides",
          supportPageID: "022",
        },
      },
    ],
  },
  {
    name: "slides-detail",
    path: "/slides/playlist/:playlist_id/detail",
    components: {
      LeftSidebar,
      default: SlidesPlaylistDetailView,
    },
    meta: {
      permission: "search slides",
      supportPageID: "040",
    },
  },
  {
    name: "slides-playlist-create",
    path: "/slides/playlist/create",
    components: {
      LeftSidebar,
      default: SlidesPlaylistCreateView,
    },
    meta: {
      permission: "create playlists",
      supportPageID: "038",
    },
  },
  // Create slide inside my slides
  {
    name: "slides-user-slides-create",
    path: "/slides/user-slides/create",
    components: {
      LeftSidebar,
      default: SlidesUserSlidesCreateView,
    },
    meta: {
      permission: "create slides",
      supportPageID: "039",
    },
  },
  {
    name: "slides-user-slides-edit",
    path: "/slides/user-slides/:slide_id/edit",
    components: {
      LeftSidebar,
      default: SlidesUserSlidesEditView,
    },
    meta: {
      permission: "edit slides",
      supportPageID: "057",
    },
  },
];
