import { defineStore } from "pinia";

import { useAuthStore as authStore } from "@/stores/_platform/auth.store";
import { customError, i18n } from "@/helpers";
import axiosInstance from "@/helpers/axios";

export const useMetaStore = defineStore({
  id: "meta",
  state: () => ({
    roles: [],
    admin_roles: [
      {
        id: 6,
        name: "provider_admin",
      },
      {
        id: 5,
        name: "platform_admin",
      },
    ],
    loading: false,
  }),
  getters: {
    getAdminRoles(state) {
      return state.admin_roles;
    },
  },
  actions: {
    async getMeta() {
      this.loading = true;
      try {
        const meta = await axiosInstance.get(`/meta`);

        // update pinia state
        this.roles = meta.data.data.roles;
        this.loading = false;
      } catch (error) {
        this.loading = false;
        if ([401, 403].includes(error.response?.status)) {
          authStore().logout(error.response.status);
        } else {
          throw customError(error);
        }
      }
    },
  },
});
