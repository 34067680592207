<template>
  <SurfaceCard :title="$t('route.admin_impersonate')" type="dashboard" class="h-full">
    <template #body>
      <DefaultList v-if="!loading" :list="customers" type="impersonate-users" />
    </template>
    <template #footer>
      <div class="flex gap-4 justify-content-between">
        <ButtonUI
          v-if="customers.length > 0"
          size="sm"
          :to="{ name: 'admin-impersonate' }"
          :label="$t('button.view_all_impersonate_users')"
        />
        <OpenImpersonateLogin />
      </div>
    </template>
  </SurfaceCard>
</template>
<script>
import SurfaceCard from "@/components/SurfaceCard.vue";
import DefaultList from "@/components/_admin/dashboard/list/DefaultList.vue";
import ButtonUI from "@/components/ButtonUI.vue";
import OpenImpersonateLogin from "@/components/_admin/OpenImpersonateLogin.vue";

import { useAdminImpersonateStore } from "@/stores";

import { storeToRefs } from "pinia";
import { getPermissions } from "@/helpers";

export default {
  setup() {
    const impersonateStore = useAdminImpersonateStore();
    impersonateStore.getCustomers({ limit: 5 });

    const { customers, loading } = storeToRefs(impersonateStore);
    return {
      customers,
      loading,
    };
  },
  components: {
    OpenImpersonateLogin,
    SurfaceCard,
    DefaultList,
    ButtonUI,
  },
  methods: {
    getPermissions,
  },
};
</script>
