<template>
  <div id="track-header" class="flex">
    <div class="col-image flex-shrink-0">
      <div class="image">
        <ImageUI variant="image" :src="image ?? null" :alt="title ?? null" class="w-full" />
      </div>
    </div>
    <div class="col-details pl-5 flex-grow-1 flex-shrink-1">
      <div class="flex align-items-center mb-1">
        <PageTitle :title="title" class="mb-0 mr-2" />
        <BadgeUI :title="$t('label.private')" feather="lock" v-if="visibility == 'private'" size="sm" />
        <BadgeUI
          :title="$t('label.public_restricted')"
          feather="lock"
          v-else-if="visibility == 'public_restricted'"
          size="sm"
        />
      </div>
      <p v-if="text" v-html="text" class="mt-0 mb-4" />
      <slot name="details" />
    </div>
  </div>
</template>
<script>
import PageTitle from "@/components/PageTitle.vue";
import BadgeUI from "@/components/BadgeUI.vue";
import ImageUI from "@/components/ImageUI.vue";

export default {
  data() {
    return {
      loading: false,
      timeout: false,
    };
  },
  components: {
    PageTitle,
    BadgeUI,
    ImageUI,
  },
  props: {
    image: {},
    title: {},
    text: {},
    visibility: {
      type: String,
      default: "public",
    },
  },
  watch: {
    image: {
      handler(file) {
        if (file) {
          const vm = this;
          vm.loading = true;

          let img = new Image();
          img.onload = (event) => {
            if (vm.timeout) {
              clearTimeout(vm.timeout);
            }
            vm.timeout = setTimeout(() => {
              vm.loading = false;
            }, 200);
          };
          img.src = file;
        }
      },
      immediate: true,
    },
  },
};
</script>
<style lang="scss" scoped>
.col-image {
  max-width: 125px;
  width: 100%;
}
</style>
