<template>
  <TableSlidesGroups :groups="categories" v-show="!loading" />
</template>

<script>
import TableSlidesGroups from "@/components/_platform/table/settings/TableSlidesGroups.vue";

import { getPermissions, setBreadcrumbs } from "@/helpers";
import { useSlidesStore } from "@/stores";
import { storeToRefs } from "pinia";

export default {
  setup() {
    setBreadcrumbs();
    const slidesStore = useSlidesStore();

    // Groups
    slidesStore.getCategories();
    const { categories, loading } = storeToRefs(slidesStore);

    return {
      categories,
      loading,
    };
  },
  components: {
    TableSlidesGroups,
  },
  methods: {
    getPermissions,
  },
};
</script>
