<template>
  <div class="view-body">
    <div class="content-wrapper">
      <NotificationMessage type="404" v-if="notFound" />
      <template v-else>
        <PageTitle :title="title" :backUI="true" />
        <TabNav
          :items="[
            {
              title: $t('label.settings'),
              feather: 'settings',
            },
          ]"
          variant="tab"
        >
          <template #controls>
            <div>
              <ButtonUI
                :underline="true"
                variant="text"
                color="text-palette-1"
                size="lg"
                :label="$t('button.delete_user')"
                @click="deleteUser()"
                v-if="getPermissions('delete users')"
              />
              <ButtonUI @click="onSubmit" size="lg" :label="$t('button.save_settings')" />
            </div>
          </template>

          <template #panels>
            <div class="grid">
              <div class="col-7">
                <SurfaceCard :title="$t('label.settings')">
                  <form @submit.prevent="onSubmit">
                    <div class="card-body">
                      <LimitWidth>
                        <NotificationMessage :error="error" />
                        <FormRow>
                          <FormLabel for="first_name" required>{{ $t("label.first_name") }}:</FormLabel>
                          <FormText
                            id="first_name"
                            type="text"
                            size="lg"
                            :class="{ 'is-invalid': error.data?.first_name }"
                            v-model="form.first_name"
                          />
                          <FormHelp type="invalid" :data="error.data" field="first_name" />
                        </FormRow>
                        <FormRow>
                          <FormLabel for="last_name" required>{{ $t("label.last_name") }}:</FormLabel>
                          <FormText
                            id="last_name"
                            type="text"
                            size="lg"
                            :class="{ 'is-invalid': error.data?.last_name }"
                            v-model="form.last_name"
                          />
                          <FormHelp type="invalid" :data="error.data" field="last_name" />
                        </FormRow>
                        <FormRow>
                          <FormLabel for="email" required>{{ $t("label.email") }}:</FormLabel>
                          <FormText
                            id="email"
                            type="email"
                            size="lg"
                            :class="{ 'is-invalid': error.data?.email }"
                            v-model="form.email"
                          />
                          <FormHelp type="invalid" :data="error.data" field="email" />
                        </FormRow>
                        <FormRow>
                          <FormLabel for="description">{{ $t("label.description") }}:</FormLabel>
                          <FormTextarea
                            id="description"
                            type="text"
                            size="lg"
                            :class="{ 'is-invalid': error.data?.description }"
                            v-model="form.description"
                          />
                          <FormHelp type="invalid" :data="error.data" field="description" />
                        </FormRow>
                        <FormRow class="mt-5">
                          <FormLabel class="mb-3" required>{{ $t("label.roles") }}:</FormLabel>
                          <ListRoles v-model="form.roles" />
                          <NoteAlert v-if="userEditingOwnRoles" :text="$t('message.note_user_editing_own_roles')" />
                          <FormHelp type="invalid" :data="error.data" field="roles" />
                        </FormRow>
                      </LimitWidth>
                    </div>
                  </form>
                  <DialogConfirmDeleteUser :visible="showConfirm" @onConfirm="onConfirm" @onCancel="onCancel" />
                </SurfaceCard>
              </div>
            </div>
          </template>
        </TabNav>
      </template>
    </div>
  </div>
</template>

<script>
import TabNav from "@/components/nav/TabNav.vue";
import PageTitle from "@/components/PageTitle.vue";
import SurfaceCard from "@/components/SurfaceCard.vue";
import FormRow from "@/components/form/FormRow.vue";
import FormHelp from "@/components/form/FormHelp.vue";
import FormLabel from "@/components/form/FormLabel.vue";
import FormText from "@/components/form/FormText.vue";
import FormTextarea from "@/components/form/FormTextarea.vue";
import ButtonUI from "@/components/ButtonUI.vue";
import LimitWidth from "@/components/LimitWidth.vue";
import DialogConfirmDeleteUser from "@/components/dialog/DialogConfirmDeleteUser.vue";

import { useRoute } from "vue-router";
import { useAuthStore, useUsersStore } from "@/stores";
import { storeToRefs } from "pinia";
import ListRoles from "@/components/ListRoles.vue";
import NotificationMessage from "@/components/NotificationMessage.vue";
import { onUnmounted, ref, watch } from "vue";
import { arrayHasChanged, getPermissions, isNotEmpty, setBreadcrumbs } from "@/helpers";
import NoteAlert from "@/components/NoteAlert.vue";

export default {
  setup() {
    setBreadcrumbs();
    const route = useRoute();
    const { user_id } = route.params;

    const authStore = useAuthStore();
    authStore.validateMe();

    const usersStore = useUsersStore();
    usersStore.getUserDetails(user_id);

    const { users_detail } = storeToRefs(usersStore);
    const form = users_detail;
    let tempForm = ref({});

    watch(form, (value) => {
      if (isNotEmpty(value)) {
        tempForm.value = JSON.parse(JSON.stringify(value));
      }
    });

    onUnmounted(() => {
      usersStore.resetUserDetails();
    });

    return { user_id, tempForm, form };
  },
  components: {
    NoteAlert,
    TabNav,
    PageTitle,
    SurfaceCard,
    FormRow,
    FormLabel,
    FormText,
    FormTextarea,
    FormHelp,
    ButtonUI,
    LimitWidth,
    ListRoles,
    // ManageAccess,
    DialogConfirmDeleteUser,
    NotificationMessage,
  },
  data() {
    return {
      error: {},
      showConfirm: false,
      state: {},
    };
  },
  methods: {
    async onSubmit() {
      const usersStore = useUsersStore();
      return usersStore.updateUser({ tempForm: this.tempForm, form: this.form }).catch((error) => {
        this.error = error;
      });
    },
    deleteUser() {
      this.showConfirm = true;
    },
    onConfirm() {
      this.showConfirm = false;
    },
    onCancel() {
      this.showConfirm = false;
    },
    getPermissions,
  },
  computed: {
    title() {
      if (this.form?.page_name) {
        return this.form.page_name;
      }
      return "&nbsp;";
    },
    notFound() {
      return this.form?.status && this.form.status == 404;
    },
    userEditingOwnRoles() {
      if (this.form?.roles && this.tempForm?.roles && this.user_id) {
        const authUser = { ...useAuthStore().getUser };
        return arrayHasChanged(this.form?.roles, this.tempForm?.roles) && authUser?.id && authUser.id === this.user_id;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
