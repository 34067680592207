import { defineStore } from "pinia";
import { router, customError, redirectToPreviousPage } from "@/helpers";
import * as Sentry from "@sentry/browser";
import { useGtm } from "@gtm-support/vue-gtm";

import { useAuthStore, useNavStore, useNotificationStore as notificationStore } from "@/stores";
import { useAppStore } from "@/stores/app.store";
import axiosInstance from "@/helpers/axios";

export const useAdminAuthStore = defineStore({
  id: "admin_auth",
  state: () => ({
    // initialize state from local storage to enable user to stay logged in
    auth: localStorage.getDecryptedItem("admin_auth"),
    verification: localStorage.getDecryptedItem("admin_auth_verification"),
  }),
  getters: {
    loggedIn() {
      return !!this.auth;
    },
    getUser(state) {
      if (state.auth?.user) {
        return state.auth.user;
      }
      return {};
    },
    getVerification(state) {
      if (
        state.verification &&
        "hash" in state.verification &&
        "user" in state.verification &&
        "remember_me" in state.verification
      ) {
        return state.verification;
      }
      return {};
    },
    getToken(state) {
      return state.auth?.authorisation?.type && state.auth?.authorisation?.token
        ? `${state.auth.authorisation.type} ${state.auth.authorisation.token}`
        : null;
    },
    getExpirationDate(state) {
      return state.auth?.authorisation?.expiration ? parseInt(state.auth.authorisation.expiration) : null;
    },
  },
  actions: {
    resetVerification() {
      this.setVerification();
    },

    setVerification(data) {
      if (!data) {
        this.verification = null;
        localStorage.removeItem("admin_auth_verification");
        return;
      }

      this.verification = data;

      // store user details and jwt in local storage to keep user logged in between page refreshes
      localStorage.setEncryptedItem("admin_auth_verification", data);
    },

    async login(formData) {
      const { email, password, remember_me } = formData;

      const { providerID: provider } = useAppStore().getAppConfig;
      useNavStore().setAppSpinner(true);
      try {
        const login = await axiosInstance.post(`/admin/auth/login`, { email, password, remember_me, provider });
        const data = login.data.data;
        data.remember_me = remember_me;

        this.setVerification(data);
      } catch (error) {
        throw customError(error);
      } finally {
        useNavStore().setAppSpinner(false);
      }
    },
    async auth2fa(formData) {
      const { code } = formData;
      const { hash, user, remember_me } = this.verification;

      useNavStore().setAppSpinner(true);
      try {
        const authentication = await axiosInstance.post(`/admin/auth/2fa/mail`, { user, hash, code, remember_me });
        const data = authentication.data.data;

        const auth = {
          user: {
            id: data.user.id,
            email: data.user.email,
            first_name: data.user.first_name,
            last_name: data.user.last_name,
            roles: data.user.roles,
            permissions: data.user.permissions,
            provider: data.user.provider,
          },
          authorisation: data.authorisation,
        };

        // update pinia state
        this.auth = auth;

        // store user details and jwt in local storage to keep user logged in between page refreshes
        localStorage.setEncryptedItem("admin_auth", auth);

        Sentry.setUser({ id: auth.user.id });

        this.resetVerification();

        const gtm = useGtm();
        gtm.trackEvent({
          event: "admin-login",
          user_id: auth.user.id,
        });
        await router.push({ name: "admin-dashboard" });
      } catch (error) {
        throw customError(error);
      } finally {
        useNavStore().setAppSpinner(false);
      }
    },
    async verify(formData, route) {
      const { password, password_confirmation } = formData;
      useNavStore().setAppSpinner(true);
      try {
        const user = await axiosInstance.post(
          `/auth/verify/${route.query.id}`,
          { password, password_confirmation },
          {
            params: {
              expires: route.query.expires,
              hash: route.query.hash,
              signature: route.query.signature,
            }, //Add mail as a param
          }
        );

        notificationStore().saveAlert(user.data.message);
        await redirectToPreviousPage("admin-login");
      } catch (error) {
        throw customError(error);
      } finally {
        useNavStore().setAppSpinner(false);
      }
    },
    async resetUserPasswordRequest(email) {
      useNavStore().setAppSpinner(true);
      try {
        const user = await axiosInstance.post(`/admin/users/forgot-password/email`, { email });

        notificationStore().saveAlert(user.data.message);
        await redirectToPreviousPage("admin-login");
      } catch (error) {
        throw customError(error);
      } finally {
        useNavStore().setAppSpinner(false);
      }
    },
    async resetUserPasswordVerification(formData) {
      const { email, password, password_confirmation, token } = formData;
      useNavStore().setAppSpinner(true);
      try {
        const user = await axiosInstance.post(`/admin/users/forgot-password/reset`, {
          email,
          password,
          password_confirmation,
          token,
        });
        notificationStore().saveAlert(user.data.message);
        await redirectToPreviousPage("admin-login");
      } catch (error) {
        throw customError(error);
      } finally {
        useNavStore().setAppSpinner(false);
      }
    },
    async validateMe() {
      try {
        await axiosInstance.get(`/admin/users/me`);
      } catch (error) {
        if ([401, 403].includes(error.response?.status)) {
          await this.logout(error.response.status);
        } else {
          throw customError(error);
        }
      }
    },
    async refreshPermissions() {
      try {
        const userMe = await axiosInstance.get(`/admin/users/me`);
        const permissions = userMe.data.data?.permissions;
        if (permissions) {
          this.auth.user.permissions = permissions;
          localStorage.setEncryptedItem("admin_auth", this.auth);
        }
      } catch (error) {
        if ([401, 403].includes(error.response?.status)) {
          await this.logout(error.response.status);
        } else {
          throw customError(error);
        }
      }
    },
    resetAuth() {
      this.auth = null;
      localStorage.removeItem("admin_auth");
      Sentry.setUser(null);
      useAuthStore().resetImpersonate();
    },
    adminHasPermissions(permissions) {
      const user = this.getUser;
      if (user) {
        const user_permissions = user?.permissions;

        if (user_permissions) {
          const include_permissions = Array.isArray(permissions) ? permissions : permissions.split(","); // check if permissions is an array
          return include_permissions.some((i) => user_permissions.includes(i));
        }
      }
      return false;
    },
    async logout(status = null) {
      if (this.loggedIn) {
        try {
          await axiosInstance.post(`/auth/logout`);
        } catch (error) {
          throw customError(error);
        } finally {
          await this.resetAuth();
          await router.push({ name: "admin-login" });
        }
      }
      await router.push({ name: "admin-login" });
    },
  },
});
