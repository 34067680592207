<template>
  <div class="view-body">
    <div class="content-wrapper">
      <NotificationMessage type="404" v-if="notFound" />
      <template v-else>
        <PageTitle :title="$t('route.systems_approve')" :backUI="true" />
        <TabNav
          :items="[
            {
              title: $t('label.details'),
              feather: 'box',
            },
          ]"
          variant="tab"
        >
          <template #panels>
            <div class="grid">
              <div class="col-7">
                <SurfaceCard :title="$t('label.details')">
                  <div class="card-body">
                    <LimitWidth>
                      <NotificationMessage :error="error" type="warn" />
                      <FormRow v-if="form?.name">
                        <FormLabel :label="false">{{ $t("label.name") }}:</FormLabel>
                        <TextUI>
                          {{ form.name }}
                        </TextUI>
                      </FormRow>
                      <FormRow v-if="form?.description">
                        <FormLabel :label="false">{{ $t("label.description") }}:</FormLabel>
                        <TextUI>
                          {{ form.description }}
                        </TextUI>
                      </FormRow>

                      <FormRow v-if="form?.group?.name">
                        <FormLabel :label="false">{{ $t("label.group") }}:</FormLabel>
                        <TextUI>
                          {{ form.group.name }}
                        </TextUI>
                      </FormRow>

                      <div class="grid">
                        <div class="col-6" v-if="form?.app_id">
                          <FormRow>
                            <FormLabel :label="false">{{ $t("label.app_id") }}:</FormLabel>
                            <TextUI>
                              {{ form.app_id }}
                            </TextUI>
                          </FormRow>
                        </div>
                        <div class="col-6" v-if="form?.serial_number">
                          <FormRow>
                            <FormLabel :label="false">{{ $t("label.serial_number") }}:</FormLabel>
                            <TextUI>{{ form.serial_number }}</TextUI>
                          </FormRow>
                        </div>
                      </div>
                    </LimitWidth>
                  </div>
                  <div v-if="getPermissions('admin pending systems')" class="card-footer">
                    <ButtonUI
                      size="lg"
                      color="success"
                      :label="$t('button.approve')"
                      @click="approveSystem()"
                      class="mr-2"
                    />
                    <ButtonUI size="lg" color="danger" :label="$t('button.reject')" @click="rejectSystem()" />
                  </div>
                </SurfaceCard>
              </div>
            </div>
          </template>
        </TabNav>
      </template>
    </div>
  </div>
</template>

<script>
import TopBar from "@/components/TopBar.vue";
import TabNav from "@/components/nav/TabNav.vue";
import PageTitle from "@/components/PageTitle.vue";
import SurfaceCard from "@/components/SurfaceCard.vue";
import FormRow from "@/components/form/FormRow.vue";
import FormLabel from "@/components/form/FormLabel.vue";
import ButtonUI from "@/components/ButtonUI.vue";
import TextUI from "@/components/TextUI.vue";
import LimitWidth from "@/components/LimitWidth.vue";
import NotificationMessage from "@/components/NotificationMessage.vue";

import { useAuthStore, useSystemsStore } from "@/stores";

import { useRoute } from "vue-router";
import { storeToRefs } from "pinia";
import { onUnmounted } from "vue";
import { getPermissions, isNotEmpty, setBreadcrumbs } from "@/helpers";

export default {
  setup() {
    setBreadcrumbs();
    const route = useRoute();
    const { system_id } = route.params;

    const authStore = useAuthStore();
    authStore.validateMe();

    const systemsStore = useSystemsStore();
    systemsStore.getSystemsDetail(system_id);

    const { systems_detail } = storeToRefs(systemsStore);
    const form = systems_detail;

    const pageVariant = route.name;

    onUnmounted(() => {
      systemsStore.resetSystemsDetail();
    });

    return { system_id, form, pageVariant };
  },
  components: {
    TabNav,
    PageTitle,
    SurfaceCard,
    FormRow,
    FormLabel,
    ButtonUI,
    TextUI,
    LimitWidth,
    NotificationMessage,
  },
  data() {
    return {
      error: {},
      errorApprove: [],
      showConfirm: false,
      state: {},
    };
  },
  methods: {
    approveSystem() {
      const systemStore = useSystemsStore();
      return systemStore.approveOrRejectSystem(this.system_id, "approve").catch((error) => {
        this.errorApprove = error;
      });
    },
    rejectSystem() {
      const systemStore = useSystemsStore();
      return systemStore.approveOrRejectSystem(this.system_id, "reject").catch((error) => {
        this.errorApprove = error;
      });
    },
    getPermissions,
  },
  computed: {
    notFound() {
      return this.form?.status && this.form.status == 404;
    },
  },
};
</script>
