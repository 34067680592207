<template>
  <div class="manage-list">
    <div class="list-header" v-if="$slots.header">
      <slot name="header" />
    </div>
    <div class="list">
      <table v-if="tempList.length > 0">
        <tr v-for="(item, index) in tempList" :key="index">
          <td class="access-badge" v-if="badge">
            <template v-if="badge === 'credentials'">
              <UserAvatar :name="item.name" />
            </template>
            <BadgeUI :title="badge" color="primary" v-else />
          </td>
          <td class="access-title">
            <span class="access-title">{{ item.name }}</span>
          </td>
          <td class="access-control">
            <ButtonUI
              color="light"
              size="sm"
              feather="trash-2"
              class="p-button-icon-only p-button-rounded"
              @click="openDialog(item.id)"
            />
          </td>
        </tr>
      </table>
      <TextUI class="mb-5" v-else-if="messageNoData != false">
        <span v-if="messageNoData != true">{{ messageNoData }}</span>
        <span v-else>{{ $t("message.no_data_found") }}</span>
      </TextUI>
    </div>
    <div class="list-controls" v-if="addList.length > 0 && !addLimit">
      <FormRow class="flex flex-nowrap">
        <AutoComplete
          v-model="selectedAutocompleteList"
          loadingIcon="pi pi-spinner"
          :suggestions="orderStringByKey(filteredAutocompleteList)"
          @change="changeList($event)"
          @complete="searchList($event)"
          :dropdown="true"
          optionLabel="name"
          :placeholder="placeholder"
          class="flex-grow-1 flex-shrink-1"
          inputClass="w-full"
          dropdownClass="p-button-light"
        >
          <template #item="slotProps">
            <div class="country-item">
              <div>{{ slotProps.item.name }}</div>
            </div>
          </template>
        </AutoComplete>
        <ButtonUI
          v-if="showAddButton"
          feather="plus"
          class="flex-none ml-2"
          :label="$t('button.add')"
          @click="addItem"
          :disabled="!addState"
        />
      </FormRow>
      <Transition name="fade">
        <FormHelp type="invalid" v-if="showNoResultsError" class="mt-0">
          {{ $t("message.no_results_found") }}
        </FormHelp>
      </Transition>
    </div>
    <div class="footer" v-if="$slots.footer">
      <slot name="footer" />
    </div>
    <DialogConfirm :visible="showConfirm" :title="confirmTitle" @onConfirm="onConfirm" @onCancel="onCancel" />
  </div>
</template>
<style lang="scss" scoped>
.manage-list {
  display: flex;
  flex-direction: column;
  height: 100%;

  .list {
    display: flex;
    flex: 1;
    flex-direction: column;
  }

  .list-controls {
  }
}

table {
  margin-bottom: 1rem;
  width: 100%;
  border-spacing: 0;

  td {
    padding-right: 0.5rem;
    padding-bottom: 0.5rem;

    &.access-badge {
      width: 1px;
      white-space: nowrap;
    }

    &.access-title {
      color: var(--text-color);
      opacity: 1;
    }

    &.access-control {
      text-align: right;
    }

    &:last-child {
      padding-right: 0;
    }
  }
}

.fade-enter-active {
  transition: all 0.15s ease-out;
}

.fade-leave-active {
  transition: all 0.15s cubic-bezier(1, 0.5, 0.8, 1);
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
<script>
import BadgeUI from "@/components/BadgeUI.vue";
import ButtonUI from "@/components/ButtonUI.vue";
import FormRow from "@/components/form/FormRow.vue";
import FormHelp from "@/components/form/FormHelp.vue";
import TextUI from "@/components/TextUI.vue";
import UserAvatar from "@/components/UserAvatar.vue";
import AutoComplete from "primevue/autocomplete";
import { isNotEmpty, orderStringByKey } from "@/helpers";
import DialogConfirm from "@/components/dialog/DialogConfirm.vue";

export default {
  components: {
    BadgeUI,
    ButtonUI,
    FormRow,
    FormHelp,
    AutoComplete,
    TextUI,
    DialogConfirm,
    UserAvatar,
  },
  data() {
    return {
      triggerReadyDelay: false,
      showNoResultsError: false,
      selectedAutocompleteList: null,
      filteredAutocompleteList: null,
      addState: false,
      showConfirm: false,
      removeTempID: null,
      addList: [],
      tempList: [],
    };
  },
  props: {
    selected: {
      type: Array,
      default() {
        return [];
      },
    },
    add: {
      type: Array,
      default() {
        return [];
      },
    },
    badge: {
      type: String,
    },
    placeholder: {
      type: String,
    },
    messageNoData: {
      type: [String, Boolean],
      default: true,
    },
    showConfirmDialog: {
      type: Boolean,
      default: true,
    },
    showAddButton: {
      type: Boolean,
      default: true,
    },
    confirmTitle: {
      type: String,
    },
    triggerSubmit: {
      type: Boolean,
      default: false,
    },
    limit: {
      type: Number,
      default: 0,
    },
  },
  emits: ["isReady", "change"],
  methods: {
    orderStringByKey(arr) {
      return orderStringByKey(arr, "name");
    },
    openDialog(id) {
      if (!this.showConfirmDialog) {
        return this.removeItem(id);
      }
      this.removeTempID = id;
      this.showConfirm = true;
    },
    onConfirm() {
      this.showConfirm = false;
      if (this.removeTempID) {
        this.removeItem(this.removeTempID);
      }
    },
    onCancel() {
      this.showConfirm = false;
    },
    changeList(event) {
      this.showNoResultsError = false;
      this.addState = event.value?.id ? true : false;
      if (event.originalEvent.key === "Enter") {
        this.addItem();
      }
    },
    searchList(event) {
      this.filteredAutocompleteList = this.addList.filter((item) =>
        item.name.toLowerCase().includes(event.query.toLowerCase())
      );
    },
    addItem() {
      if (this.isNotEmpty(this.selectedAutocompleteList) && this.selectedAutocompleteList?.id) {
        this.triggerReadyDelay = true;
        this.$emit("isReady", false);

        this.tempList.push(this.selectedAutocompleteList);
        const itemID = this.selectedAutocompleteList.id;

        // remove item in add list
        const itemObjIndex = this.addList.findIndex((item) => item.id === itemID);
        this.addList.splice(itemObjIndex, 1);

        // reset autocomplete list
        this.selectedAutocompleteList = null;
      } else {
        this.$emit("isReady", true);
        this.triggerReadyDelay = false;
      }
      this.addState = false;
      this.$emit("change", true);
    },

    removeItem(id) {
      // add to add list
      const addObj = this.tempList.find((item) => item.id === id);
      this.addList.push(addObj);

      // remove from merged list
      const removeObjIndex = this.tempList.findIndex((item) => item.id === id);
      this.tempList.splice(removeObjIndex, 1);
      this.$emit("change", true);
    },
    isNotEmpty(e) {
      return isNotEmpty(e);
    },
  },
  computed: {
    addLimit() {
      if (this.limit > 0) {
        return this.tempList.length >= this.limit;
      }
      return false;
    },
    list() {
      const { add, selected } = this;
      return {
        selected,
        add,
      };
    },
  },
  watch: {
    selectedAutocompleteList: {
      handler(value) {
        if (!this.showAddButton && value?.id) {
          this.addItem();
        }
      },
    },
    filteredAutocompleteList: {
      handler(value) {
        this.showNoResultsError = value.length == 0;
      },
    },
    list: {
      handler(value) {
        if (value) {
          this.addList = value?.add ?? [];
          const tempList = value?.selected ?? [];
          tempList.map(({ id }) => {
            // remove selected item in add list
            const itemObjIndex = this.addList.findIndex((item) => item.id === id);
            if (itemObjIndex != -1) {
              this.addList.splice(itemObjIndex, 1);
            }
          });
          this.tempList = tempList;
        }
      },
      immediate: true,
    },
    triggerSubmit: {
      handler(trigger) {
        if (trigger) {
          this.addItem();
        }
      },
    },
    triggerReadyDelay: {
      handler(value) {
        const _this = this;
        if (value) {
          setTimeout(() => {
            _this.$emit("isReady", true);
            _this.triggerReadyDelay = false;
          }, 500);
        }
      },
    },
  },
  mounted() {
    this.$emit("isReady", true);
  },
};
</script>
