import { defineStore } from "pinia";
import { useRoute } from "vue-router";
import { useAppStore } from "@/stores/app.store";

import { platformBreadcrumbs } from "@/helpers/breadcrumb/platform.breadcrumb";
import { adminBreadcrumbs } from "@/helpers/breadcrumb/admin.breadcrumb";
import { useAuthStore } from "@/stores/_platform/auth.store";

export const useNavStore = defineStore({
  id: "nav",
  state: () => ({
    sidebarIsOpen: false,
    topBarIsVisible: false,
    spinnerIsVisible: false,
    progressBarValue: 0,
    breadcrumbs: [],
    returnOptions: {},
    returnTab: null,
    adminPageActive: false,
  }),
  getters: {
    isAdminPage(state) {
      return state.adminPageActive;
    },
    isNoAdminPageAndImpersonateIsActive() {
      return !this.isAdminPage && useAuthStore().impersonateIsActive;
    },
    getReturnOptions(state) {
      return state.returnOptions;
    },
    getReturnName(state) {
      return {
        from: state.returnOptions?.from?.name,
        to: state.returnOptions?.to?.name,
      };
    },
    getReturnUrl(state) {
      return {
        from: state.returnOptions?.from?.fullPath,
        to: state.returnOptions?.to?.fullPath,
      };
    },
    getReturnTab(state) {
      return state.returnTab;
    },
    getAppSpinner(state) {
      return state.spinnerIsVisible;
    },
    getProgressBarValue(state) {
      return state.progressBarValue;
    },
    getTopBarState(state) {
      return state.topBarIsVisible;
    },
  },
  actions: {
    setAdminPage(state) {
      this.adminPageActive = state;
    },
    sidebarClose() {
      if (this.sidebarIsOpen) {
        this.sidebarIsOpen = false;
      }
    },
    sidebarOpen() {
      this.sidebarIsOpen = true;
    },
    setBreadcrumb(page) {
      if (!page) {
        const route = useRoute();
        page = route?.name;
      }

      const { isDev } = useAppStore().getAppConfig;
      if (isDev) {
        console.log(`Breadcrumb Page: ${page}`);
      }

      const isAdmin = useNavStore().isAdminPage;
      this.breadcrumbs = isAdmin ? adminBreadcrumbs(page) : platformBreadcrumbs(page);
    },
    setBreadcrumbTitle(title, index = 0) {
      if (typeof this.breadcrumbs[index] === "undefined") {
        this.breadcrumbs.push({ title });
      } else {
        this.breadcrumbs.splice(index, 1, {
          title,
        });
      }
    },
    setBreadcrumbLink(route, index = 0) {
      if (typeof this.breadcrumbs[index] === "undefined") {
        this.breadcrumbs.push(route);
      } else {
        this.breadcrumbs.splice(index, 1, route);
      }
    },
    resetBreadcrumb() {
      this.breadcrumbs = [];
    },
    setAppSpinner(state) {
      this.spinnerIsVisible = state;
    },
    setProgressBarValue(value) {
      this.progressBarValue = value;
    },
    setTopNavState(state) {
      this.topBarIsVisible = state;
    },
  },
});
