<template>
  <ul class="flex gap-5 m-0 p-0 align-items-center list-none help">
    <li>
      <a :href="supportUrl" target="_blank" class="inline-flex gap-1 align-items-center">
        <vue-feather type="help-circle" size="16" class="ui-icon" />
        <span class="ui-txt">
          {{ $t("button.help") }}
        </span>
      </a>
    </li>
    <li v-if="showSuperAdminLink">
      <RouterLink :to="{ name: 'admin-login' }" class="inline-flex gap-1 align-items-center">
        <vue-feather type="key" size="16" class="ui-icon" />
        <span class="ui-txt">
          {{ $t("label.provider_admin") }}
        </span>
      </RouterLink>
    </li>
  </ul>
</template>
<script>
import VueFeather from "vue-feather";
import { useAppStore } from "@/stores/app.store";
export default {
  props: {
    showSuperAdminLink: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    VueFeather,
  },
  computed: {
    supportUrl() {
      return useAppStore().supportUrl;
    },
  },
};
</script>
