import VerifyView from "@/views/_admin/register/VerifyView.vue";

export const registerRoutes = [
  // Verify register
  {
    name: "admin-register-verify",
    path: "/admin/register/verify",
    component: VerifyView,
    meta: {
      permission: "public",
    },
  },
];
