<template>
  <div :class="rowClass">
    <slot />
  </div>
</template>
<script>
export default {
  props: {
    type: {
      type: String,
      default: "field",
    },
  },
  computed: {
    rowClass() {
      return this.type === "checkbox" ? "field-row--checkbox" : "field-row";
    },
  },
};
</script>
