export function getScrollBarWidth(elementClass = null) {
  const barEl = elementClass ? document.querySelector(elementClass) : document.body;

  if (barEl) {
    let el = document.createElement("div");
    el.style.cssText = "overflow:scroll; visibility:hidden; position:absolute;";

    barEl.appendChild(el);
    let width = el.offsetWidth - el.clientWidth;
    el.remove();
    barEl.style.setProperty("--scrollbar-width", `${width}px`);

    return width;
  }
  return 0;
}
