<template>
  <div class="view-body">
    <div class="content-wrapper">
      <PageTitle :title="$t('route.users_create')" class="mb-7" :backUI="true" />
      <TabNav
        :items="[
          {
            title: $t('label.settings'),
            feather: 'settings',
          },
        ]"
        variant="tab"
      >
        <template #controls>
          <ButtonUI @click="onSubmit" size="lg" :label="$t('button.save_settings')" />
        </template>
        <template #panels>
          <div class="grid">
            <div class="col-7">
              <SurfaceCard :title="$t('label.settings')">
                <form @submit.prevent="onSubmit">
                  <div class="card-body">
                    <LimitWidth>
                      <NotificationMessage :error="error" />
                      <FormRow>
                        <FormLabel for="first_name" required>{{ $t("label.first_name") }}:</FormLabel>
                        <FormText
                          id="first_name"
                          type="text"
                          size="lg"
                          :class="{ 'is-invalid': error.data?.first_name }"
                          v-model="form.first_name"
                        />
                        <FormHelp type="invalid" :data="error.data" field="first_name" />
                      </FormRow>
                      <FormRow>
                        <FormLabel for="last_name" required>{{ $t("label.last_name") }}:</FormLabel>
                        <FormText
                          id="last_name"
                          type="text"
                          size="lg"
                          :class="{ 'is-invalid': error.data?.last_name }"
                          v-model="form.last_name"
                        />
                        <FormHelp type="invalid" :data="error.data" field="last_name" />
                      </FormRow>
                      <FormRow>
                        <FormLabel for="email" required>{{ $t("label.email") }}:</FormLabel>
                        <FormText
                          id="email"
                          type="email"
                          size="lg"
                          :class="{ 'is-invalid': error.data?.email }"
                          v-model="form.email"
                        />
                        <FormHelp type="invalid" :data="error.data" field="email" />
                      </FormRow>

                      <FormRow class="mt-5">
                        <FormLabel class="mb-3" required>{{ $t("label.roles") }}:</FormLabel>
                        <ListRoles v-model="form.roles" type="admin" />
                        <FormHelp type="invalid" :data="error.data" field="role" />
                      </FormRow>

                      <FormRow v-if="showProvider" class="mt-5">
                        <FormLabel class="mb-3" required>{{ $t("label.provider_admin") }}:</FormLabel>
                        <ListProviders v-model="form.provider" />
                        <FormHelp type="invalid" :data="error.data" field="provider" />
                      </FormRow>
                    </LimitWidth>
                  </div>
                </form>
              </SurfaceCard>
            </div>
          </div>
        </template>
      </TabNav>
    </div>
  </div>
</template>

<script>
import PageTitle from "@/components/PageTitle.vue";
import SurfaceCard from "@/components/SurfaceCard.vue";
import FormRow from "@/components/form/FormRow.vue";
import FormLabel from "@/components/form/FormLabel.vue";
import FormText from "@/components/form/FormText.vue";
import FormHelp from "@/components/form/FormHelp.vue";
import ButtonUI from "@/components/ButtonUI.vue";
import LimitWidth from "@/components/LimitWidth.vue";
import ListRoles from "@/components/ListRoles.vue";
import NotificationMessage from "@/components/NotificationMessage.vue";
import { useAdminAuthStore, useAdminUsersStore } from "@/stores";
import { convertRoleIDsToNames, setBreadcrumbs } from "@/helpers";
import TabNav from "@/components/nav/TabNav.vue";
import ListProviders from "@/components/ListProviders.vue";

export default {
  setup() {
    setBreadcrumbs();
    const authStore = useAdminAuthStore();
    authStore.validateMe();
  },
  components: {
    ListProviders,
    TabNav,
    PageTitle,
    SurfaceCard,
    NotificationMessage,
    FormRow,
    FormLabel,
    FormText,
    FormHelp,
    ButtonUI,
    LimitWidth,
    ListRoles,
  },
  data() {
    return {
      error: {},
      form: {
        first_name: null,
        last_name: null,
        email: null,
        provider: "",
        roles: [],
      },
    };
  },
  methods: {
    async onSubmit() {
      const usersStore = useAdminUsersStore();
      return usersStore.createUser(this.form).catch((error) => {
        this.error = error;
      });
    },
  },
  computed: {
    showProvider() {
      if (this.form?.roles) {
        const roles = convertRoleIDsToNames(this.form.roles);
        return roles?.is_provider_admin;
      }
      return false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
