import { loginRoutes } from "@/helpers/router/_admin/login";
import { dashboardRoutes } from "@/helpers/router/_admin/dashboard";
import { usersRoutes } from "@/helpers/router/_admin/users";
import { impersonateRoutes } from "@/helpers/router/_admin/impersonate";
import { forgotPasswordRoutes } from "@/helpers/router/_admin/forgotPassword";
import { registerRoutes } from "@/helpers/router/_admin/register";

export const adminRoutes = [
  ...loginRoutes,
  ...forgotPasswordRoutes,
  ...dashboardRoutes,
  ...usersRoutes,
  ...impersonateRoutes,
  ...registerRoutes,
];
