import { getPageTitle } from "@/helpers";
import { useAppStore } from "@/stores";
export function setBrowserTitle(key, title = null) {
  let metaTitle = getPageTitle(key);
  const { isDev } = useAppStore().getAppConfig;

  if (title) {
    metaTitle = `${title} - ${metaTitle}`;
  }

  if (isDev) {
    console.log(`MetaTitle Key: ${key}`);
  }

  if (metaTitle) {
    document.title = `${metaTitle}`;
  }
}
