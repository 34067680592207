<template>
  <div class="profile-dropdown" ref="profileNav" :class="[{ 'is-collapse': showDropdown }]">
    <button ref="dropdownBtn" @click="toggleDropdown" class="dropdown-btn" :style="`min-width: ${minWidth}px`">
      <div class="hidden md:block">
        <div class="name">{{ fullName }}</div>
        <div class="customer-name">{{ customerName }}</div>
      </div>
      <UserAvatar :firstName="firstName" :lastName="lastName" />
    </button>
    <Transition name="fade">
      <div v-show="showDropdown" ref="dropdownToggle" class="dropdown-toggle" :style="`min-width: ${minWidth}px`">
        <ul class="dropdown-nav">
          <template v-if="isAdmin">
            <li role="presentation">
              <RouterLink :to="{ name: 'login' }" @click="closeDropdown" class="dropdown-item">
                <vue-feather type="globe" size="16" class="ui-icon" />
                <span class="ui-txt">
                  {{ impersonateIsActive ? $t("label.open_impersonate") : $t("button.open_platform") }}
                </span>
              </RouterLink>
            </li>
          </template>

          <template v-else>
            <li role="presentation">
              <RouterLink :to="{ name: 'settings-profile' }" @click="closeDropdown" class="dropdown-item">
                <vue-feather type="user" size="16" class="ui-icon" />
                <span class="ui-txt">
                  {{ $t("button.profile") }}
                </span>
              </RouterLink>
            </li>
            <li role="presentation">
              <div class="dropdown-category" @click="toggleCustomer()">
                <vue-feather type="globe" size="16" class="ui-icon" />
                <span
                  class="ui-txt"
                  v-html="customerHasRegions ? $t('label.customer') : $t('button.change_customer')"
                />
              </div>
              <ul v-if="showCustomer">
                <li role="presentation" v-if="customerHasRegions">
                  <RouterLink
                    :to="{ name: 'login-region', query: { returnUrl: $route.fullPath } }"
                    class="dropdown-item"
                  >
                    <span class="ui-txt">
                      {{ $t("button.change_customer") }}
                    </span>
                  </RouterLink>
                </li>
                <li role="presentation">
                  <RouterLink
                    :to="{ name: 'customer-create', query: { returnUrl: $route.fullPath } }"
                    @click="closeDropdown"
                    class="dropdown-item"
                  >
                    <span class="ui-txt">
                      {{ $t("button.create_customer") }}
                    </span>
                  </RouterLink>
                </li>
              </ul>
            </li>
          </template>

          <li role="presentation" class="border">
            <a :href="supportUrl" class="dropdown-item" target="_blank">
              <vue-feather type="help-circle" size="16" class="ui-icon" />
              <span class="ui-txt">
                {{ $t("button.help") }}
              </span>
            </a>
          </li>
          <li role="presentation" v-if="isImpersonate">
            <RouterLink :to="{ name: 'admin-dashboard' }" @click="closeDropdown" class="dropdown-item">
              <vue-feather type="key" size="16" class="ui-icon" />
              <span class="ui-txt" v-html="$t('label.provider_admin')" />
            </RouterLink>
          </li>
          <li role="presentation">
            <a href="#" @click.prevent="logout()" class="dropdown-item">
              <vue-feather type="log-out" size="16" class="ui-icon" />
              <span class="ui-txt">
                {{ isImpersonate ? $t("button.logout_impersonate") : $t("button.logout") }}
              </span>
            </a>
          </li>
        </ul>
      </div>
    </Transition>
  </div>
</template>
<script>
import { computed, ref, watch } from "vue";
import { useAuthStore, useNavStore, useAdminAuthStore, useAppStore, useAdminImpersonateStore } from "@/stores";
import VueFeather from "vue-feather";
import UserAvatar from "@/components/UserAvatar.vue";
import { router, useDetectOutsideClick } from "@/helpers";

export default {
  setup() {
    const isAdmin = computed(() => useNavStore().isAdminPage);
    const isImpersonate = computed(() => useNavStore().isNoAdminPageAndImpersonateIsActive);
    const impersonateIsActive = computed(() => useAuthStore().impersonateIsActive);
    const isUser = computed(() => {
      return isImpersonate.value || !isAdmin.value;
    });

    const fullName = ref(null);
    const firstName = ref(null);
    const lastName = ref(null);
    const customerName = ref(null);
    const customerHasRegions = ref(false);

    watch(
      isUser,
      (user) => {
        const authStore = user ? useAuthStore() : useAdminAuthStore();
        const authUser = authStore.getUser;
        fullName.value = `${authUser?.first_name} ${authUser?.last_name}`;
        firstName.value = authUser?.first_name;
        lastName.value = authUser?.last_name;

        if (user) {
          const customerProfile = authStore.getCustomerProfile;
          customerName.value = customerProfile?.customer?.name;
          customerHasRegions.value = authStore.customerHasRegions();
        }
      },
      {
        immediate: true,
      }
    );

    const profileNav = ref();
    const showDropdown = ref(false);
    useDetectOutsideClick(profileNav, () => {
      showDropdown.value = false;
    });

    const supportUrl = computed(() => useAppStore().supportUrl);

    return {
      fullName,
      firstName,
      lastName,
      showDropdown,
      profileNav,
      customerName,
      customerHasRegions,
      isAdmin,
      isImpersonate,
      impersonateIsActive,
      isUser,
      supportUrl,
    };
  },
  components: {
    VueFeather,
    UserAvatar,
  },
  data() {
    return {
      minWidth: 230,
      showCustomer: false,
    };
  },
  methods: {
    toggleDropdown() {
      this.showDropdown = !this.showDropdown;
      this.closeCustomer();
    },
    toggleCustomer() {
      if (this.customerHasRegions) {
        this.showCustomer = !this.showCustomer;
      } else {
        router.push({ name: "customer-create", query: { returnUrl: this.$route.fullPath } });
      }
    },
    closeCustomer() {
      this.showCustomer = false;
    },
    closeDropdown() {
      this.showDropdown = false;
      this.closeCustomer();
    },
    logout() {
      if (this.isImpersonate) {
        useAdminImpersonateStore().logoutImpersonate();
      } else {
        const authStore = this.isAdmin ? useAdminAuthStore() : useAuthStore();
        authStore.logout();
      }

      this.closeDropdown();
    },
  },
  mounted() {
    setTimeout(() => {
      const btnWidth = this.$refs.dropdownBtn.clientWidth;
      const dropdownWidth = this.$refs.dropdownToggle.clientWidth;
      this.minWidth = btnWidth > dropdownWidth ? btnWidth : dropdownWidth;
    }, 100);
  },
};
</script>
<style lang="scss" scoped>
.profile-dropdown {
  display: inline-block;
  position: relative;

  &:before {
    background: var(--app-ground);
    position: fixed;
    height: 100vh;
    width: 100vw;
    left: 0;
    top: 0;
    opacity: 0;
    content: "";
    transition: 0.2s all;
    pointer-events: none;
    z-index: 900;
  }

  .dropdown-btn {
    color: var(--profile-btn-color);
    background: transparent;
    border: none;
    outline: none;
    font-size: font-size("base");
    font-weight: 500;
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    border-radius: 5px;
    padding: 0.5rem 0.5rem 0.5rem 1rem;
    cursor: pointer;
    transition: 0.2s all;
    position: relative;
    z-index: 1000;

    > div {
      margin-right: spacing(3);
      text-align: right;
    }

    .customer-name {
      font-size: font-size("xs");
      opacity: 0.4;
    }
  }

  .dropdown-toggle {
    position: absolute;
    right: 0;
    top: 100%;
    z-index: 1000;
    border-radius: 5px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    box-shadow: 0 0 15px 0 rgba(#000, 0.15);
    background: var(--profile-dropdown-ground);
    text-align: left;
    font-size: font-size("base");

    ul {
      list-style: none;
    }

    ul.dropdown-nav {
      margin: 0;
      padding: spacing(2) 0;
      > li {
        padding: 0;

        ul {
          margin: spacing(1) 0 spacing(6) 0;
          padding: 0;
        }

        &.border:not(:first-child) {
          border-top: solid 1px var(--profile-dropdown-border);
          margin-top: spacing(2);
          padding-top: spacing(2);
        }

        .dropdown-category,
        .dropdown-item {
          color: var(--profile-dropdown-color);
          text-decoration: none;
          padding: spacing(2) spacing(6);
          display: block;
          cursor: pointer;
          display: flex;
          align-items: center;
          white-space: nowrap;

          .ui-icon {
            margin-right: spacing(2);
          }
        }

        a.dropdown-item {
          &:focus,
          &:hover {
            color: var(--profile-dropdown-color-hover);
          }
        }

        ul {
          .dropdown-item {
            padding: spacing(1) spacing(6);
          }
        }
      }
    }
  }

  &.is-collapse {
    &:before {
      opacity: 0.5;
      pointer-events: none;
    }

    .dropdown-btn {
      background: var(--profile-btn-active-ground);
      color: var(--profile-btn-active-color);
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;

      .customer-name {
        opacity: 0.8;
      }
    }
  }
}
</style>
