export function formHasChanged(options) {
  let { validatedField, tempForm, form } = options;

  if (!tempForm) {
    return console.log("No tempForm fields!");
  }

  if (!form) {
    return console.log("No form fields!");
  }

  if (!validatedField) {
    return console.log("No validatedField!");
  }

  const match = validatedField.map((validate) => {
    const field = validate.field;
    const type = validate.type;
    const key = validate?.key ? validate.key : null;
    // console.log({
    //   field: field,
    //   tempForm: tempForm[field],
    //   form: form[field],
    // });
    if (tempForm[field] && form[field]) {
      if (type === "String") {
        if (typeof tempForm[field] !== "string") {
          return console.log(`Temp form field [${field}] is not a String`);
        } else if (typeof form[field] !== "string") {
          return console.log(`Form field [${field}] is not a String`);
        }
        return tempForm[field] !== form[field];
      } else if (type === "Object") {
        if (!key) {
          return console.log("No key exists!");
        }
        // console.log(tempForm[field][key] + "!==" + form[field][key]);
        return tempForm[field][key] !== form[field][key];
      } else if (type === "Array") {
        const matchForward = form[field].some((validate) => {
          const match = tempForm[field].find((item) => item[key] === validate[key]);
          // console.log({
          //   type: "forward",
          //   field,
          //   match,
          //   validated: match ? false : true,
          // });
          return match ? false : true;
        });

        const matchBackward = tempForm[field].some((validate) => {
          const match = form[field].find((item) => item[key] === validate[key]);
          // console.log({
          //   type: "backward",
          //   field,
          //   match,
          //   validated: match ? false : true,
          // });
          return match ? false : true;
        });

        return matchForward || matchBackward ? true : false;
      }
    }
    return true;
  });

  return match.includes(true);
}
