<template>
  <template v-if="hasError">
    <template v-if="(errorData?.status == 404 && type != 'warn') || (!isNotEmpty(errorData) && type === '404')">
      <PageTitle :title="$t('404.title')" />
      <IntroText :text="$t('404.text')" class="mb-0" />
    </template>
    <PMessage
      v-else-if="isNotEmpty(errorData) || hasSlot()"
      :severity="type"
      class="p-message mt-0 mb-5"
      :key="Date.now()"
      :closable="false"
    >
      <template v-if="isNotEmpty(errorData)">
        {{ renderError(errorData) }}
      </template>
      <template v-else>
        <slot />
      </template>
    </PMessage>
  </template>
</template>
<style lang="scss">
.p-message {
  .p-message-icon {
    display: none;
  }

  &.p-message-warn {
    border-radius: 3px;

    .p-message-wrapper {
      padding: 1rem;
    }
  }

  &.p-message-404,
  &.p-message-default {
    .p-message-wrapper {
      padding: 0;
    }
  }
}
</style>
<script>
import { useNotificationStore } from "@/stores";
import Message from "primevue/message";
import { isNotEmpty } from "@/helpers";

import PageTitle from "@/components/PageTitle.vue";
import IntroText from "@/components/IntroText.vue";

export default {
  components: {
    PMessage: Message,
    PageTitle,
    IntroText,
  },
  props: {
    error: {
      type: [Object],
    },
    type: {
      type: String,
      default: "warn",
    },
  },
  computed: {
    errorData() {
      const notificationStore = useNotificationStore();
      const message = notificationStore.$state.message;

      let errorData = {};

      if (isNotEmpty(message)) {
        errorData = message;
      } else if (this.error && isNotEmpty(this.error)) {
        errorData = this.error;
      }

      return errorData;
    },
    hasError() {
      return isNotEmpty(this.errorData) || this.hasSlot() || this.type == 404;
    },
  },
  methods: {
    isNotEmpty(e) {
      return isNotEmpty(e);
    },
    hasSlot(name = "default") {
      return !!this.$slots[name] || !!this.$slots[name];
    },
    renderError(error) {
      if (error?.message && error.message.startsWith("message.")) {
        return this.$t(error.message);
      } else if (error?.status == 422) {
        return this.$t("message.422");
      } else if (error?.message) {
        return error.message;
      }
      return error;
    },
  },
  watch: {
    hasError(visible) {
      if (visible) {
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      }
    },
  },
};
</script>
