<template>
  <span class="tab-title">
    <span class="tab-icon" v-if="feather">
      <vue-feather :type="feather" size="18" />
    </span>
    <span class="tab-label" v-html="title" />
  </span>
</template>
<script>
import VueFeather from "vue-feather";

export default {
  props: {
    feather: {
      type: String,
    },
    title: {
      type: String,
    },
  },
  components: {
    VueFeather,
  },
};
</script>
<style lang="scss" scoped>
.tab-title {
  display: flex;
  align-content: center;
  .tab-icon {
    margin-right: spacing(2);
  }
}
</style>
