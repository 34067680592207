<template>
  <div class="grid">
    <div v-for="(item, index) in list" :key="index" class="col-3 col-card">
      <CategoryCard :id="item.id" :image="item.thumbnail_small" :title="item.name" @onClick="goToCategory(item)" />
    </div>
  </div>
</template>
<script>
import CategoryCard from "@/components/_platform/tickers/CategoryCard.vue";
import { router } from "@/helpers";

export default {
  props: {
    list: {
      type: Array,
    },
  },
  components: {
    CategoryCard,
  },
  methods: {
    goToCategory(item) {
      router.push({
        name: "tickers-browse-category",
        params: { id: item.id },
        query: { ...this.$route.query },
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.col-card {
  @media (min-width: 1400px) {
    width: 20%;
  }

  @include media-breakpoint-up(xxxl) {
    width: 14.28%;
  }
}
</style>
