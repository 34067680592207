<template>
  <div>
    <div v-if="!loading">
      <template v-if="groups.length > 0">
        <template v-if="type === 'list'">
          <FormRow type="checkbox" v-for="(group, index) in groups" :key="index">
            <FormCheckbox
              :inputId="group.id"
              name="roles"
              :value="group.id"
              color="palette-1"
              v-model="inputValue"
              @click="onClick"
            />
            <FormLabel :for="group.id">{{ group.name }}</FormLabel>
          </FormRow>
        </template>
        <FormSelect
          v-else
          id="groups"
          size="lg"
          v-model="inputValue"
          :options="groups"
          optionLabel="name"
          :placeholder="$t('placeholder.select_a_group')"
          @click="onClick"
        />
      </template>
      <TextUI v-else>{{ $t("message.no_data_found") }}</TextUI>
    </div>
  </div>
</template>

<script>
import FormRow from "@/components/form/FormRow.vue";
import FormLabel from "@/components/form/FormLabel.vue";
import FormCheckbox from "@/components/form/FormCheckbox.vue";
import FormSelect from "@/components/form/FormSelect.vue";
import { storeToRefs } from "pinia";
import { useGroupsStore } from "@/stores/_platform/groups.store";
import TextUI from "@/components/TextUI.vue";
import { isNotEmpty } from "@/helpers";
import { computed, toRefs } from "vue";

export default {
  emits: ["change", "update:modelValue"],
  setup(props, { emit }) {
    const groupsStore = useGroupsStore();
    groupsStore.getGroups(["id", "name"]);

    const { groups, loading } = storeToRefs(groupsStore);

    const { modelValue } = toRefs(props);

    const inputValue = computed({
      get() {
        return modelValue.value;
      },
      set(value) {
        modelValue.value = value;
        emit("update:modelValue", value);
      },
    });

    return {
      inputValue,
      loading,
      groups,
    };
  },
  props: {
    type: {
      type: String,
      default: "list",
    },
    modelValue: {},
  },
  data() {
    return {
      hasClicked: false,
    };
  },

  components: {
    FormRow,
    FormLabel,
    FormCheckbox,
    FormSelect,
    TextUI,
  },
  methods: {
    onClick() {
      this.hasClicked = true;
    },
  },
  watch: {
    state: {
      handler(value) {
        if (this.hasClicked) {
          this.$emit("change", true);
          this.hasClicked = false;
        }
      },
      deep: true,
    },
  },
};
</script>

<style scoped></style>
