export const platformBreadcrumbs = (page) => {
  let breadcrumbs = [];

  switch (page) {
    case "dashboard":
      breadcrumbs = [
        {
          name: "dashboard",
        },
      ];
      break;
    case "systems":
    case "systems-pending":
    case "systems-groups":
      breadcrumbs = [
        {
          name: "systems",
        },
      ];
      break;
    case "systems-detail-schedule":
      breadcrumbs = [
        {
          name: "systems",
        },
        {
          name: "systems-schedule",
          link: false,
        },
      ];
      break;
    case "systems-detail-edit":
      breadcrumbs = [
        {
          name: "systems",
        },
        {
          name: "settings",
          link: false,
        },
      ];
      break;
    case "systems-approve":
      breadcrumbs = [
        {
          name: "systems",
        },
        {
          name: "systems-pending",
        },
        {
          name: "systems-approve",
        },
      ];
      break;
    case "groups-create":
      breadcrumbs = [
        {
          name: "systems",
        },
        {
          name: "systems-groups",
        },
        {
          name: "groups-create",
        },
      ];
      break;
    case "groups-detail-schedule":
      breadcrumbs = [
        {
          name: "systems",
        },
        {
          name: "systems-groups",
        },
        {
          name: "schedule",
          link: false,
        },
      ];
      break;
    case "groups-detail-edit":
      breadcrumbs = [
        {
          name: "systems",
        },
        {
          name: "systems-groups",
        },
        {
          name: "settings",
          link: false,
        },
      ];
      break;
    case "users":
      breadcrumbs = [
        {
          name: "users",
        },
      ];
      break;
    case "users-create":
      breadcrumbs = [
        {
          name: "users",
        },
        {
          name: "users-create",
        },
      ];
      break;
    case "users-edit":
      breadcrumbs = [
        {
          name: "users",
        },
        {
          name: "settings",
          link: false,
        },
      ];
      break;
    case "settings-playlist-groups-music":
    case "settings-playlist-groups-slides":
    case "settings-playlist-groups-frames":
    case "settings-playlist-groups-tickers":
      breadcrumbs = [
        {
          name: "settings",
        },
      ];
      break;
    case "settings-playlist-group-music-create":
      breadcrumbs = [
        {
          name: "settings",
        },
        {
          name: "settings-playlist-groups-music",
        },
        {
          name: "playlist-group-create",
          link: false,
        },
      ];
      break;
    case "settings-playlist-group-music-edit":
      breadcrumbs = [
        {
          name: "settings",
        },
        {
          name: "settings-playlist-groups-music",
        },
        {
          name: "playlist-group-edit",
          link: false,
        },
      ];
      break;
    case "settings-playlist-group-slides-create":
      breadcrumbs = [
        {
          name: "settings",
        },
        {
          name: "settings-playlist-groups-slides",
        },
        {
          name: "playlist-group-create",
          link: false,
        },
      ];
      break;
    case "settings-playlist-group-slides-edit":
      breadcrumbs = [
        {
          name: "settings",
        },
        {
          name: "settings-playlist-groups-slides",
        },
        {
          name: "playlist-group-edit",
          link: false,
        },
      ];
      break;
    case "settings-playlist-group-frames-create":
      breadcrumbs = [
        {
          name: "settings",
        },
        {
          name: "settings-playlist-groups-frames",
        },
        {
          name: "playlist-group-create",
          link: false,
        },
      ];
      break;
    case "settings-playlist-group-frames-edit":
      breadcrumbs = [
        {
          name: "settings",
        },
        {
          name: "settings-playlist-groups-frames",
        },
        {
          name: "playlist-group-edit",
          link: false,
        },
      ];
      break;
    case "settings-profile":
      breadcrumbs = [
        {
          name: "settings",
        },
        {
          name: "profile",
        },
      ];
      break;
    case "settings-profile-account":
      breadcrumbs = [
        {
          name: "settings",
        },
      ];
      break;
    case "settings-profile-password":
      breadcrumbs = [
        {
          name: "settings",
        },
      ];
      break;
    case "music":
    case "music-user-category":
    case "music-user-category-other":
    case "music-browse":
    case "music-search":
    case "music-search-tracks":
    case "music-search-playlists":
    case "music-detail":
    case "music-browse-detail":
      breadcrumbs = [
        {
          name: "music",
        },
      ];
      break;

    case "music-create":
      breadcrumbs = [
        {
          name: "music",
        },
        {
          name: "music-create",
        },
      ];
      break;
    case "slides":
    case "user-slides":
    case "slides-user-category":
    case "slides-user-category-other":
    case "slides-browse":
    case "slides-search":
    case "slides-search-tracks":
    case "slides-search-playlists":
    case "slides-detail":
    case "slides-browse-detail":
      breadcrumbs = [
        {
          name: "slides",
        },
      ];
      break;

    case "slides-playlist-create":
      breadcrumbs = [
        {
          name: "slides",
        },
        {
          name: "slides-playlist-create",
        },
      ];
      break;
    case "slides-user-slides-edit":
      breadcrumbs = [
        {
          name: "slides",
        },
        {
          name: "user-slides",
        },
        {
          name: "slide-edit",
        },
      ];
      break;
    case "slides-user-slides-create":
      breadcrumbs = [
        {
          name: "slides",
        },
        {
          name: "user-slides",
        },
        {
          name: "slide-create",
        },
      ];
      break;
    case "frames":
    case "user-frames":
    case "frames-user-category":
    case "frames-user-category-other":
    case "frames-browse":
    case "frames-search":
    case "frames-search-tracks":
    case "frames-search-playlists":
    case "frames-detail":
    case "frames-browse-detail":
      breadcrumbs = [
        {
          name: "frames",
        },
      ];
      break;
    case "frames-playlist-create":
      breadcrumbs = [
        {
          name: "frames",
        },
        {
          name: "frames-playlist-create",
        },
      ];
      break;
    case "frames-user-frames-create":
      breadcrumbs = [
        {
          name: "frames",
        },
        {
          name: "user-frames",
        },
        {
          name: "frames-create",
        },
      ];
      break;
    case "frames-user-frames-edit":
      breadcrumbs = [
        {
          name: "frames",
        },
        {
          name: "user-frames",
        },
        {
          name: "frames-edit",
        },
      ];
      break;
    case "tickers-user-tickers-edit":
      breadcrumbs = [
        {
          name: "tickers",
        },
        {
          name: "user-tickers",
        },
        {
          name: "tickers-edit",
        },
      ];
      break;
    case "tickers-user-tickers-create":
      breadcrumbs = [
        {
          name: "tickers",
        },
        {
          name: "user-tickers",
        },
        {
          name: "tickers-create",
        },
      ];
      break;
    case "tickers":
    case "tickers-user-category":
    case "tickers-user-category-other":
    case "tickers-browse":
    case "tickers-detail":
    case "tickers-browse-detail":
      breadcrumbs = [
        {
          name: "tickers",
        },
      ];
      break;
    case "tickers-playlist-create":
      breadcrumbs = [
        {
          name: "tickers",
        },
        {
          name: "tickers-playlist-create",
        },
      ];
      break;
  }
  return breadcrumbs;
};
