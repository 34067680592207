<template>
  <div class="view-body">
    <div class="content-wrapper">
      <div v-if="!loading">
        <BackUI variant="primary" to="#" class="mb-7" @click.prevent="goBack()" />
        <NotificationMessage type="404" v-if="notFound" />
        <template v-else>
          <PlaylistHeader
            :image="form.image"
            :title="form.title"
            :text="form.description"
            :visibility="form.visibility?.value"
            class="mb-6"
          />
          <TabNav :items="tabNavItems" variant="tab">
            <template #panels>
              <div class="tab-panel">
                <TableTracks :tracks="form.tracks" :control-permissions="['add to playlist']" />
              </div>
            </template>
          </TabNav>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import TabNav from "@/components/nav/TabNav.vue";
import PlaylistHeader from "@/components/_platform/playlist/PlaylistHeader.vue";
import BackUI from "@/components/BackUI.vue";
import TableTracks from "@/components/_platform/table/playlists/TableTracks.vue";

import { useRoute } from "vue-router";
import { useNavStore, useFramesStore } from "@/stores";
import { storeToRefs } from "pinia";
import NotificationMessage from "@/components/NotificationMessage.vue";
import { onUnmounted } from "vue";
import { getPermissions, i18n, isNotEmpty, router, setBreadcrumbs } from "@/helpers";

export default {
  setup() {
    setBreadcrumbs();
    const route = useRoute();
    const { playlist_id, category_id, hash } = route.params;

    const framesStore = useFramesStore();
    framesStore.getPlaylistsDetail({ playlist_id, category_id });
    framesStore.getCategories();

    const {
      playlists_detail,
      visibility_options: visibilityOptions,
      visibility_restricted_group_options: visibilityRestrictedOptions,
      categories,
      loading,
    } = storeToRefs(framesStore);
    const form = playlists_detail;

    onUnmounted(() => {
      framesStore.resetPlaylistsDetail();
    });

    const tabNavItems = [
      {
        title: i18n.global.t("label.tracks"),
        feather: "music",
        permissions: "show playlists",
      },
    ];

    return {
      tabNavItems,
      playlist_id,
      form,
      visibilityOptions,
      visibilityRestrictedOptions,
      hash,
      categories,
      loading,
    };
  },
  components: {
    TabNav,
    NotificationMessage,
    PlaylistHeader,
    // BadgeUI,
    TableTracks,
    BackUI,
  },
  methods: {
    async goBack() {
      router.back();
    },
    getPermissions,
    isNotEmpty(e) {
      return isNotEmpty(e);
    },
  },
  computed: {
    notFound() {
      return this.form?.status && this.form.status == 404;
    },
  },
  watch: {
    form(playlist) {
      if (playlist?.title) {
        let category = playlist.categories;
        if (category.length) {
          category = category[0];
          useNavStore().setBreadcrumbLink(
            {
              name: "frames-browse-category",
              title: category.name,
              params: {
                id: category.id,
              },
            },
            1
          );
        } else {
          useNavStore().setBreadcrumbLink(
            {
              name: "frames-user-category-other",
              title: i18n.global.t("route.playlists_my_category_other"),
            },
            1
          );
        }
        useNavStore().setBreadcrumbTitle(playlist.title, 2);
      }
    },
  },
};
</script>
