import { useNavStore as navStore } from "@/stores";
import { router } from "@/helpers";

const goBackIfSameDomain = (options) => {
  const { name, hash, query } = options;

  const referrer = document.referrer;

  if (referrer) {
    const referrerURL = new URL(referrer);
    const currentURL = new URL(window.location.href);

    if (referrerURL.origin === currentURL.origin) {
      router.back();
    } else {
      router.push({ name: name, hash: hash, query: query });
    }
  } else {
    // No referrer, meaning the current page was accessed directly
    router.push({ name: name, hash: hash, query: query });
  }
};

export function redirectToBackPage({ name, hash, query }) {
  goBackIfSameDomain({ name, hash, query });
}
