import { defineStore } from "pinia";

import { useAuthStore as authStore } from "@/stores/_platform/auth.store";
import {
  customError,
  appConfig,
  i18n,
  redirectToPreviousPage,
  isBase64,
  orderStringByKey,
  renameKeys,
} from "@/helpers";
import { useNotificationStore as notificationStore } from "@/stores/notification.store";
import { useRoute } from "vue-router";
import { useNavStore } from "@/stores/nav.store";
import { useAppStore } from "@/stores/app.store";
import axiosInstance from "@/helpers/axios";

export const useSlidesStore = defineStore({
  id: "slides",
  state: () => ({
    playlists: [],
    playlists_available: [],
    playlists_detail: {},
    categories: [],
    categories_detail: {},
    visibility_options: [
      {
        feather: "globe",
        value: "public",
        name: i18n.global.t("label.public_all_groups"),
      },
      {
        feather: "lock",
        value: "public_restricted",
        name: i18n.global.t("label.public_restricted"),
      },
      {
        feather: "lock",
        value: "private",
        name: i18n.global.t("label.private_only_for_me"),
      },
    ],
    media_type_options: [
      {
        value: "image",
        name: i18n.global.t("label.image"),
      },
      {
        value: "video",
        name: i18n.global.t("label.video"),
      },
    ],
    slides: [],
    slides_detail: {},
    temp_created_slide: {},
    loading: false,
    loading_available_playlists: false,
  }),
  getters: {},
  actions: {
    getStoreMediaType(find) {
      return this.media_type_options.find((option) => option.value === find);
    },
    async getCategories() {
      const { name } = useRoute();
      this.loading = true;
      useNavStore().setAppSpinner(true);

      let endpoint;
      switch (name) {
        case "slides-browse":
          endpoint = `slides/browse`;
          break;
        default:
          endpoint = `settings/slides/playlist-categories`;
      }

      if (!endpoint) {
        return;
      }

      try {
        const categories = await axiosInstance.get(`/${endpoint}`);

        // update pinia state
        this.categories = orderStringByKey(categories.data.data, "name");
      } catch (error) {
        if ([401, 403].includes(error.response?.status)) {
          authStore().logout(error.response.status);
        } else {
          this.categories = [];
          throw customError(error);
        }
      } finally {
        this.loading = false;
        useNavStore().setAppSpinner(false);
      }
    },
    async getCategoryDetails(category_id) {
      useNavStore().setAppSpinner(true);
      try {
        const category = await axiosInstance.get(`/settings/slides/playlist-categories/${category_id}`);

        // update pinia state
        this.categories_detail = category.data.data;
        this.categories_detail.page_name = category.data.data.name;
      } catch (error) {
        this.categories_detail = customError(error);
        if ([401, 403].includes(error.response?.status)) {
          authStore().logout(error.response.status);
        } else {
          notificationStore().saveMessage(error);
        }
      } finally {
        useNavStore().setAppSpinner(false);
      }
    },
    async createCategory(formData) {
      let { id, name, image_path } = formData;
      useNavStore().setAppSpinner(true);

      let newData = {
        id,
        name,
      };

      if (image_path && isBase64(image_path)) {
        // merge new image with data
        newData = {
          ...newData,
          image: image_path,
        };
      }

      try {
        const category = await axiosInstance.post(`/settings/slides/playlist-categories`, newData);

        notificationStore().saveAlert(category.data.message);
        await redirectToPreviousPage("settings-playlist-groups-slides");
      } catch (error) {
        if ([401, 403].includes(error.response?.status)) {
          authStore().logout(error.response.status);
        } else {
          throw customError(error);
        }
      } finally {
        useNavStore().setAppSpinner(false);
      }
    },
    async updateCategory(formData) {
      let { id, name, image_path } = formData;
      useNavStore().setAppSpinner(true);

      let newData = {
        id,
        name,
      };

      if (image_path && isBase64(image_path)) {
        // merge new image with data
        newData = {
          ...newData,
          image: image_path,
        };
      }

      try {
        const category = await axiosInstance.put(`/settings/slides/playlist-categories/${id}`, newData);

        notificationStore().saveAlert(category.data.message);
        await redirectToPreviousPage("settings-playlist-groups-slides");
      } catch (error) {
        if ([401, 403].includes(error.response?.status)) {
          authStore().logout(error.response.status);
        } else {
          throw customError(error);
        }
      } finally {
        useNavStore().setAppSpinner(false);
      }
    },
    async deleteCategory(category_id) {
      useNavStore().setAppSpinner(true);
      try {
        const category = await axiosInstance.delete(`/settings/slides/playlist-categories/${category_id}`);

        notificationStore().saveAlert(category.data.message);
        await redirectToPreviousPage("settings-playlist-groups-slides");
      } catch (error) {
        if ([401, 403].includes(error.response?.status)) {
          authStore().logout(error.response.status);
        } else {
          throw customError(error);
        }
      } finally {
        useNavStore().setAppSpinner(false);
      }
    },
    resetCategories() {
      this.categories = [];
    },
    resetCategoryDetails() {
      this.categories_detail = {};
    },
    resetPlaylistsDetail() {
      this.playlists_detail = {};
    },
    resetPlaylists() {
      this.playlists = [];
    },
    async getPlaylists() {
      this.loading = true;
      useNavStore().setAppSpinner(true);

      try {
        const playlists = await axiosInstance.get(`/slides/my-playlists`);

        // update pinia state
        this.playlists = playlists.data.data;
      } catch (error) {
        if ([401, 403].includes(error.response?.status)) {
          authStore().logout(error.response.status);
        }
        this.playlists = customError(error);
        throw this.playlists;
      } finally {
        this.loading = false;
        useNavStore().setAppSpinner(false);
      }
    },
    async getSlidesByCategory(category_id) {
      this.loading = true;
      useNavStore().setAppSpinner(true);

      try {
        const categories = await axiosInstance.get(`/slides/browse/${category_id}`);

        // update pinia state
        this.categories_detail = categories.data.data;
      } catch (error) {
        this.categories_detail = customError(error);
        if ([401, 403].includes(error.response?.status)) {
          authStore().logout(error.response.status);
        }
      } finally {
        this.loading = false;
        useNavStore().setAppSpinner(false);
      }
    },
    async getPlaylistsByCategory(category_id) {
      const { name } = useRoute();

      let endpoint;
      switch (name) {
        case "slides-browse-category":
          endpoint = `browse/${category_id}`;
          break;
        case "slides-user-category":
          endpoint = `my-playlists/category/${category_id}`;
          break;
        case "slides-user-category-other":
          endpoint = `my-playlists/other`;
          break;
      }

      if (!endpoint) {
        return;
      }

      this.loading = true;
      useNavStore().setAppSpinner(true);

      try {
        const playlists = await axiosInstance.get(`/slides/${endpoint}`);

        // update pinia state
        this.playlists = playlists.data.data;
      } catch (error) {
        if ([401, 403].includes(error.response?.status)) {
          authStore().logout(error.response.status);
        }
        this.playlists = customError(error);
        throw this.playlists;
      } finally {
        this.loading = false;
        useNavStore().setAppSpinner(false);
      }
    },
    // -------------------------
    // Playlists detail
    // -------------------------
    async getPlaylistsDetail(data) {
      const { playlist_id, category_id } = data;

      let endpoint;
      if (playlist_id) {
        endpoint = `my-playlists/${playlist_id}`;
        if (category_id) {
          endpoint = `browse/${category_id}/playlist/${playlist_id}`;
        }
      }

      if (!endpoint) {
        this.playlists_detail.status = "404";
        return;
      }

      this.loading = true;
      useNavStore().setAppSpinner(true);

      try {
        const playlist = await axiosInstance.get(`/slides/${endpoint}`);

        // update pinia state
        this.playlists_detail = playlist.data.data;

        // rename old image_path key to image
        if (this.playlists_detail?.image_path) {
          delete Object.assign(this.playlists_detail, { image: this.playlists_detail.image_path })["image_path"];
        }

        const visibility = this.visibility_options.find((item) => item.value === this.playlists_detail.visibility);
        this.playlists_detail.visibility = visibility;
      } catch (error) {
        this.playlists_detail = customError(error);
        if ([401, 403].includes(error.response?.status)) {
          authStore().logout(error.response.status);
        }
      } finally {
        this.loading = false;
        useNavStore().setAppSpinner(false);
      }
    },

    async updatePlaylist(formData) {
      let { id, title, description, categories, visibility, groups, image } = formData;
      useNavStore().setAppSpinner(true);
      let newData = {
        id,
        title,
        description,
        categories: categories.map(({ id }) => id), // rewrite object to array with ids
        visibility: "",
        groups: [],
      };

      if ((image && isBase64(image)) || image === null) {
        // merge new image with data
        newData = {
          ...newData,
          image: image,
        };
      }

      if (visibility?.value) {
        // merge visibility with data
        newData = {
          ...newData,
          visibility: visibility.value, // rewrite visibility with only value
        };

        if (visibility.value === "public_restricted") {
          newData = {
            ...newData,
            groups: groups.map(({ id }) => id), // rewrite object to array with ids
          };
        }
      }

      try {
        const playlist = await axiosInstance.put(`/slides/my-playlists/${id}`, newData);

        // reset visibility restricted groups
        if (visibility?.value && visibility.value != "public_restricted") {
          this.playlists_detail.groups = [];
        }

        notificationStore().saveAlert(playlist.data.message);
      } catch (error) {
        if ([401, 403].includes(error.response?.status)) {
          authStore().logout(error.response.status);
        } else {
          throw customError(error);
        }
      } finally {
        useNavStore().setAppSpinner(false);
      }
    },
    async deletePlaylist(playlist_id) {
      useNavStore().setAppSpinner(true);
      try {
        const playlist = await axiosInstance.delete(`/slides/my-playlists/${playlist_id}`);

        notificationStore().saveAlert(playlist.data.message);
        await redirectToPreviousPage("slides");
      } catch (error) {
        if ([401, 403].includes(error.response?.status)) {
          authStore().logout(error.response.status);
        } else {
          throw customError(error);
        }
      } finally {
        useNavStore().setAppSpinner(false);
      }
    },
    async createPlaylist(formData) {
      let { title, description, categories, visibility, groups, image } = formData;
      useNavStore().setAppSpinner(true);
      let newData = {
        title,
        description,
        categories: categories.map(({ id }) => id), // rewrite object to array with ids
        visibility: "",
        groups: [],
      };

      if ((image && isBase64(image)) || image === null) {
        // merge new image with data
        newData = {
          ...newData,
          image: image,
        };
      }

      if (visibility?.value) {
        // merge visibility with data
        newData = {
          ...newData,
          visibility: visibility.value, // rewrite visibility with only value
        };

        if (visibility.value === "public_restricted") {
          newData = {
            ...newData,
            groups: groups.map(({ id }) => id), // rewrite object to array with ids
          };
        }
      }

      try {
        const user = await axiosInstance.post(`/slides/my-playlists`, newData);

        notificationStore().saveAlert(user.data.message);
        await redirectToPreviousPage("slides");
      } catch (error) {
        if ([401, 403].includes(error.response?.status)) {
          authStore().logout(error.response.status);
        } else {
          throw customError(error);
        }
      } finally {
        useNavStore().setAppSpinner(false);
      }
    },
    async duplicatePlaylist(formData) {
      let { title, playlist_id } = formData;
      useNavStore().setAppSpinner(true);

      try {
        const playlist = await axiosInstance.post(`/slides/my-playlists/${playlist_id}/duplicate`, { title });

        notificationStore().saveAlert(playlist.data.message);
        await redirectToPreviousPage("slides");
      } catch (error) {
        if ([401, 403].includes(error.response?.status)) {
          authStore().logout(error.response.status);
        } else {
          throw customError(error);
        }
      } finally {
        useNavStore().setAppSpinner(false);
      }
    },
    // -------------------------
    // Playlists overview
    // -------------------------
    async getAvailablePlaylistsBySlide(slide_id = null) {
      this.loading_available_playlists = true;
      useNavStore().setAppSpinner(true);

      const endpoint = slide_id ? `slides/my-playlists/slide/${slide_id}/available` : `slides/my-slides/playlists`;

      try {
        const playlists = await axiosInstance.get(`/${endpoint}`);

        // update pinia state
        this.playlists_available = playlists.data.data;
        this.playlists_available = this.playlists_available.map((obj) => renameKeys(obj, { title: "name" })); // rename title to name
      } catch (error) {
        if ([401, 403].includes(error.response?.status)) {
          authStore().logout(error.response.status);
        } else {
          throw customError(error);
        }
      } finally {
        this.loading_available_playlists = false;
        useNavStore().setAppSpinner(false);
      }
    },
    async addSlideToPlaylist(data) {
      useNavStore().setAppSpinner(true);

      try {
        await axiosInstance.post(`/slides/my-playlists/add-slide`, data);
        notificationStore().saveAlert(i18n.global.t("message.slide_added_to_playlist"));
      } catch (error) {
        if ([401, 403].includes(error.response?.status)) {
          authStore().logout(error.response.status);
        } else {
          throw customError(error);
        }
      } finally {
        useNavStore().setAppSpinner(false);
      }
    },
    async removeSlideFromPlaylist(data) {
      const { playlist_id, slide_id } = data;
      useNavStore().setAppSpinner(true);

      try {
        await axiosInstance.delete(`/slides/my-playlists/${playlist_id}/slides/${slide_id}`);

        // remove slide in item_count
        if (this.playlists_detail?.item_count) {
          this.playlists_detail.item_count = this.playlists_detail.item_count - 1;
        }

        // remove slides from list
        const slides = this.playlists_detail.slides.filter((item) => item.id !== slide_id);
        this.playlists_detail.slides = slides;

        notificationStore().saveAlert(i18n.global.t("message.slide_removed_from_playlist"));
      } catch (error) {
        if ([401, 403].includes(error.response?.status)) {
          authStore().logout(error.response.status);
        } else {
          throw customError(error);
        }
      } finally {
        useNavStore().setAppSpinner(false);
      }
    },
    async getMySlides(intervalIsRunning = false, routeName) {
      let name;
      if (!routeName) {
        name = useRoute().name;
      } else {
        name = routeName;
      }

      let endpoint;
      switch (name) {
        case "user-slides":
          endpoint = `my-slides`;
          break;
        case "slides-browse":
          endpoint = `browse`;
          break;
      }

      if (!endpoint) {
        return;
      }

      if (!intervalIsRunning) {
        this.loading = true;
        useNavStore().setAppSpinner(true);
      }

      try {
        const slides = await axiosInstance.get(`/slides/${endpoint}`);

        // update pinia state
        this.slides = slides.data.data;
      } catch (error) {
        if ([401, 403].includes(error.response?.status)) {
          authStore().logout(error.response.status);
        } else {
          throw customError(error);
        }
      } finally {
        this.loading = false;
        useNavStore().setAppSpinner(false);
      }
    },
    async updateSlidesOrder(playlist_id, slides) {
      if (!playlist_id && !slides) {
        return;
      }
      await axiosInstance.post(`/slides/my-playlists/${playlist_id}/slides/reorder`, {
        slides,
      });
    },
    resetCreatedTempSlide() {
      this.temp_created_slide = {};
    },
    async createMySlides(formData) {
      formData = { ...formData };

      useNavStore().setAppSpinner(true);

      this.resetCreatedTempSlide();

      formData.media_type = formData.media_type?.value ? formData.media_type.value : formData.media_type;
      formData.playlists = formData.playlists.map(({ id }) => id);

      try {
        const slides = await axiosInstance.post(`/slides/my-slides`, formData);

        this.temp_created_slide = slides.data;
      } catch (error) {
        if ([401, 403].includes(error.response?.status)) {
          authStore().logout(error.response.status);
        } else {
          throw customError(error);
        }
      } finally {
        useNavStore().setAppSpinner(false);
      }
    },
    async updateMySlides(formData) {
      formData = { ...formData };

      let { id } = formData;

      useNavStore().setAppSpinner(true);

      this.resetCreatedTempSlide();

      formData.media_type = formData.media_type?.value ? formData.media_type.value : formData.media_type;
      formData.playlists = formData.playlists.map(({ id }) => id);

      try {
        const slides = await axiosInstance.put(`/slides/my-slides/${id}`, formData);

        this.temp_created_slide = slides.data;
      } catch (error) {
        if ([401, 403].includes(error.response?.status)) {
          authStore().logout(error.response.status);
        } else {
          throw customError(error);
        }
      } finally {
        useNavStore().setAppSpinner(false);
      }
    },
    async deleteMySlide(slide_id) {
      useNavStore().setAppSpinner(true);
      try {
        const slides = await axiosInstance.delete(`/slides/my-slides/${slide_id}`);

        notificationStore().saveAlert(slides.data.message);
        await redirectToPreviousPage("user-slides");
      } catch (error) {
        if ([401, 403].includes(error.response?.status)) {
          authStore().logout(error.response.status);
        } else {
          throw customError(error);
        }
      } finally {
        useNavStore().setAppSpinner(false);
      }
    },
    // -------------------------
    // Slides detail
    // -------------------------
    async getMySlidesDetail(slide_id) {
      this.loading = true;
      useNavStore().setAppSpinner(true);

      try {
        const slides = await axiosInstance.get(`/slides/my-slides/${slide_id}`);

        // update pinia state
        this.slides_detail = slides.data.data;
        this.slides_detail.media_type = this.getStoreMediaType(this.slides_detail.media_type);

        // rename old image_path key to image
        if (this.playlists_detail?.image_path) {
          delete Object.assign(this.playlists_detail, { image: this.playlists_detail.image_path })["image_path"];
        }
      } catch (error) {
        this.slides_detail = customError(error);
        if ([401, 403].includes(error.response?.status)) {
          authStore().logout(error.response.status);
        }
      } finally {
        this.loading = false;
        useNavStore().setAppSpinner(false);
      }
    },
  },
});
