<template>
  <div class="grid">
    <div v-for="(item, index) in list" :key="index" class="col-3 col-card">
      <SlideCard :id="item.id" :image="item.thumb_small" :title="item.title" :type="type" ratio="16-9" />
    </div>
  </div>
</template>
<script>
import SlideCard from "@/components/_platform/slides/SlideCard.vue";

export default {
  props: {
    list: {
      type: Array,
    },
    type: {
      type: String,
    },
  },
  components: { SlideCard },
};
</script>
<style lang="scss" scoped>
.col-card {
  @media (min-width: 1400px) {
    width: 20%;
  }

  @include media-breakpoint-up(xxxl) {
    width: 14.28%;
  }
}
</style>
