import LeftSidebar from "@/components/LeftSidebar.vue";
import ImpersonateIndexView from "@/views/_admin/impersonate/IndexView.vue";
import ImpersonateDetailView from "@/views/_admin/impersonate/DetailView.vue";

export const impersonateRoutes = [
  // Impersonate users
  {
    name: "admin-impersonate",
    path: "/admin/impersonate",
    components: {
      LeftSidebar,
      default: ImpersonateIndexView,
    },
    meta: {
      permission: "impersonate users",
    },
  },
  {
    name: "admin-impersonate-detail",
    path: "/admin/impersonate/:customer_id",
    components: {
      LeftSidebar,
      default: ImpersonateDetailView,
    },
    meta: {
      permission: "impersonate users",
    },
  },
];
