<template>
  <DialogImage
    :visible="previewDialogType === 'image'"
    :cancelTextLink="true"
    :data="previewData"
    @onCancel="onCancelPreview"
  />
  <DialogVideo
    :visible="previewDialogType === 'video'"
    :cancelTextLink="true"
    :data="previewData"
    @onCancel="onCancelPreview"
  />
  <div class="upload-ui">
    <div class="area">
      <div class="upload" v-if="!isNotEmpty(newFile)" @click="chooseFile" :class="`is-${mediaType}`">
        <div class="controls">
          <button type="button" class="btn" v-show="!disableControls">
            <vue-feather type="upload" size="16" />
          </button>
        </div>
      </div>
      <div v-else>
        <div class="upload" :class="`is-${mediaType}`">
          <Transition name="image">
            <PSkeleton width="100%" height="100%" class="skeleton" v-if="loading" @click="chooseFile" />
            <div class="cropper-container" @click.self="previewFile" v-else-if="setCropper">
              <extended-cropper
                ref="cropper"
                class="cropper"
                :canvas="false"
                :src="newFile.url"
                :default-size="defaultCropSize"
                :default-position="defaultCropPosition"
                :stencil-props="{
                  aspectRatio: 16 / 9,
                }"
                @change="onCropperChange"
                @error="onCropperError"
              />
            </div>
            <span v-else-if="mediaType === 'video'" class="filename" v-html="newFile.name" @click="previewFile" />
            <img :src="newFile.url" @click="previewFile" v-else />
          </Transition>
        </div>
        <div class="controls" v-show="!disableControls">
          <button type="button" class="btn" @click="chooseFile">
            <vue-feather type="upload" size="16" />
          </button>
          <button type="button" @click="previewFile" class="btn btn-preview-file" v-if="showPreviewButton">
            <vue-feather type="zoom-in" size="16" />
          </button>
          <button type="button" @click="removeFile" class="btn btn-remove-file">
            <vue-feather type="trash-2" size="16" />
          </button>
        </div>
      </div>
    </div>
    <input
      type="file"
      @change="uploadFile($event)"
      :accept="mediaType === 'video' ? '.mp4' : '.png, .jpeg, .jpg'"
      ref="file"
      class="file"
    />
  </div>
</template>

<script>
import axios from "axios";
import VueFeather from "vue-feather";
import Skeleton from "primevue/skeleton";
import { Cropper } from "vue-advanced-cropper";
import "vue-advanced-cropper/dist/style.css";

import { getApiHeaders, isAspectRatio, isNotEmpty, validateFileSize } from "@/helpers";
import { useNavStore, useNotificationStore as notificationStore, useSlidesStore, useAppStore } from "@/stores";
import DialogVideo from "@/components/dialog/DialogVideo.vue";
import DialogImage from "@/components/dialog/DialogImage.vue";

// https://codesandbox.io/s/vue-advanced-cropper-zoom-limit-workaround-j3m81?file=/src/App.vue:569-1097

const ExtendedCropper = {
  extends: Cropper,
  methods: {
    onManipulateImage(event, params = {}) {
      if (event && event.scale) {
        if (event.scale.factor < 1) {
          // disable zoom
          event.scale.factor = 1;
        }
      }
      Cropper.methods.onManipulateImage.call(this, event, params);
    },
  },
};

export default {
  data() {
    return {
      loading: false,
      timeout: false,
      newFile: {},
      newImageProps: null,
      chunks: [],
      uploaded: 0,
      disableControls: false,
      defaultCropSize: null,
      defaultCropPosition: null,
      previewDialogType: null,
      previewData: {},
    };
  },
  emits: ["update:trigger", "progress", "uploadChange", "uploadIsCompleted", "removeFile", "props", "onError"],
  props: {
    id: {
      type: String,
    },
    limit: {
      type: Number,
      default: 0,
    },
    image: {
      type: [Object, String],
    },
    video: {
      type: [Object, String],
    },
    imageProps: {},
    validateAspectRatio: {
      type: Boolean,
      default: false,
    },
    setCropper: {
      type: Boolean,
      default: false,
    },
    setChunks: {
      type: Boolean,
      default: false,
    },
    setPreviewButton: {
      type: Boolean,
      default: true,
    },
    mediaType: {
      type: String,
      default: "frame",
      validator(value) {
        return ["frame", "image", "video"].includes(value);
      },
    },
    entity: {
      type: String,
      default: "slides",
      validator(value) {
        return ["slides", "frames"].includes(value);
      },
    },
    trigger: {
      type: Boolean,
      default: false,
    },
  },
  watch: {
    mediaType: {
      handler() {
        this.removeFile();
      },
      deep: true,
    },
    image: {
      handler(value) {
        if (value) {
          this.newFile = value?.url ? value : { url: value };
          this.checkAspectRatioImage({ width: value?.width, height: value?.height });
        }
      },
      immediate: true,
    },
    video: {
      handler(value) {
        if (value) {
          this.newFile = value?.url ? value : { url: value };
        }
      },
      immediate: true,
    },
    imageProps: {
      handler(value) {
        if (value?.cutout) {
          this.defaultCropPosition = {
            left: value.cutout.x1,
            top: value.cutout.y1,
          };
          this.defaultCropSize = {
            width: value.cutout.x2 - value.cutout.x1,
            height: value.cutout.y2 - value.cutout.y1,
          };
        }
      },
      immediate: true,
    },
    validateFile: {
      handler(trigger) {
        if (trigger) {
          this.startUpload();
        }
      },
      deep: true,
    },
    progress: {
      handler(progress) {
        this.$emit("progress", progress);
        useNavStore().setProgressBarValue(progress);
        if (progress > 0) {
          this.disableControls = true;
          useNavStore().setAppSpinner(true);
        }
      },
    },
    newImageProps(props) {
      const properties = props
        ? {
            cutout: {
              x1: props.left,
              y1: props.top,
              x2: props.left + props.width,
              y2: props.top + props.height,
            },
          }
        : null;
      this.$emit("props", properties);
    },
  },
  methods: {
    isNotEmpty,
    chooseFile() {
      if (!this.trigger) {
        this.$refs.file.value = "";
        this.$refs.file.click();
      }
    },
    resetInputImage() {
      this.disableControls = false;
      this.uploaded = 0;
      this.chunks = [];
      this.$refs.file.value = "";
      this.$emit("onError", {});
      this.$emit("update:trigger", false);
      useNavStore().setAppSpinner(false);
    },
    removeFile() {
      this.loading = false;
      this.uploaded = 0;
      this.newFile = {};
      this.newImageProps = null;
      this.chunks = [];
      this.$refs.file.value = "";
      this.$emit("onError", {});
      this.$emit("update:trigger", false);
      this.$emit("removeFile", true);
      this.$emit("uploadChange", null);
      useNavStore().setAppSpinner(false);
    },
    previewFile() {
      if (this.newFile) {
        this.previewDialogType = this.mediaType;
        if (this.previewDialogType !== "video") {
          this.previewDialogType = "image";
        }
        this.previewData = this.newFile;
      } else {
        this.chooseFile();
      }
      return false;
    },
    onCancelPreview() {
      this.previewDialogType = null;
      this.previewData = {};
    },
    uploadFile(event) {
      /// Reference to the DOM input element
      const file = event.target.files[0];
      // Ensure that you have a file before attempting to read it
      if (file) {
        this.loading = true;
        useNavStore().setAppSpinner(true);

        if (this.limit > 0 && validateFileSize(file.size, this.limit)) {
          notificationStore().saveMessage({
            message: this.$t("message.upload_limit", {
              limit: this.limit,
            }),
          });
          this.removeFile();
          return;
        }

        // 1. Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
        if (this.newFile.url) {
          URL.revokeObjectURL(this.newFile.url);
        }

        // 2. Create the blob link to the file to optimize performance:
        const blob = URL.createObjectURL(file);

        // 3. Update the image. The type will be derived from the extension and it can lead to an incorrect result:
        this.newFile = {
          url: blob,
          type: file.type,
          name: file.name,
        };

        if (this.mediaType === "video") {
          this.hideLoader();
        } else {
          // image or frame

          const image = new Image();
          // Hide loader
          image.onload = () => {
            this.checkAspectRatioImage({ width: image.width, height: image.height });
            this.hideLoader();
          };
          image.onerror = () => {
            this.hideLoader();
          };

          image.src = this.newFile.url;
        }

        if (this.setChunks) {
          this.$emit("uploadChange", this.newFile);
          this.createChunks(file);
        } else {
          const reader = new FileReader();
          reader.onload = (event) => {
            this.$emit("uploadChange", event.target.result);
          };
          reader.readAsDataURL(file);
        }
      }
    },
    hideLoader() {
      const _this = this;
      if (_this.timeout) {
        clearTimeout(_this.timeout);
      }
      _this.timeout = setTimeout(() => {
        _this.loading = false;
        useNavStore().setAppSpinner(false);
      }, 500);
    },
    createChunks(file) {
      if (!file) {
        return;
      }
      let mb = 2;
      let size = mb * 1024 * 1024,
        chunks = Math.ceil(file.size / size);

      for (let i = 0; i < chunks; i++) {
        this.chunks.push(file.slice(i * size, Math.min(i * size + size, file.size), file.type));
      }
    },
    startUpload() {
      const { apiUrl } = useAppStore().getAppConfig;

      if (this.formData.getAll("file").length) {
        axios({
          method: "POST",
          data: this.formData,
          url: `${apiUrl}/files/${this.mediaType}/${this.entity}/${this.id}`,
          headers: { ...getApiHeaders(), ...{ "Content-Type": "application/octet-stream" } },
          onUploadProgress: (event) => {
            this.uploaded += event.loaded;
          },
        })
          .then((response) => {
            this.chunks.shift();
            if (response.data.finished) {
              this.$emit("uploadIsCompleted", true);
              this.resetInputImage();
            }
          })
          .catch((error) => {
            this.$emit("uploadIsCompleted", false);
            notificationStore().saveMessage(error);
            this.resetInputImage();
          });
      }
    },
    onCropperChange({ coordinates }) {
      this.newImageProps = coordinates;
    },
    onCropperError() {
      this.newFile = {};
      this.loading = false;
      notificationStore().saveMessage({
        message: this.$t("message.upload_error"),
      });
    },
    checkAspectRatioImage(dimensions) {
      const { width, height } = dimensions;
      if (width > 0 && height > 0 && this.validateAspectRatio) {
        this.$emit("onError", {
          ratio: isAspectRatio(width, height),
        });
      }
    },
  },
  computed: {
    showPreviewButton() {
      return this.newFile && this.setPreviewButton;
    },
    mediaTypeName() {
      return useSlidesStore().getStoreMediaType(this.mediaType)?.name;
    },
    validateFile() {
      return this.trigger && this.id && this.chunks;
    },
    progress() {
      const file = this.$refs.file?.files[0];

      if (this.uploaded && file?.size) {
        const progress = Math.floor((this.uploaded * 100) / file.size);
        return progress >= 100 ? 100 : progress;
      }
      return 0;
    },
    formData() {
      let formData = new FormData();
      const file = this.$refs.file.files[0];

      if (file && this.chunks) {
        formData.set("is_last", this.chunks.length === 1);
        formData.set("file", this.chunks[0], `${file?.name}.part`);
      }

      return formData;
    },
  },
  unmounted() {
    // Revoke the object URL, to allow the garbage collector to destroy the uploaded before file
    if (this.newFile.url) {
      URL.revokeObjectURL(this.newFile.url);
    }
  },
  components: {
    DialogImage,
    DialogVideo,
    VueFeather,
    PSkeleton: Skeleton,
    ExtendedCropper,
  },
};
</script>
<style lang="scss" scoped>
.image-enter-active {
  transition: all 0.15s ease-out;
}

.image-leave-active {
  transition: all 0.15s cubic-bezier(1, 0.5, 0.8, 1);
}

.image-enter-from,
.image-leave-to {
  opacity: 0;
}

.upload-ui {
  background: var(--input-upload-ui-ground);
  border: var(--input-upload-ui-border);
  padding-bottom: 100%;
  border-radius: 6px;
  height: 0;
  position: relative;
  overflow: hidden;

  .file {
    position: absolute;
    left: -999999px;
  }

  .upload {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    z-index: 0;
    cursor: pointer;

    &:before {
      content: "";
      background: var(--input-upload-ui-image-ground-icon) no-repeat 50% 50%;
      background-size: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      width: 30px;
      height: 30px;
      opacity: 0.1;
      transform: translateX(-50%) translateY(-50%);
      z-index: 1;
      transition: all 0.15s ease-in-out;
      pointer-events: none;
    }

    &.is-video {
      &:before {
        background-image: var(--input-upload-ui-video-ground-icon);
      }
    }

    .cropper-container,
    .skeleton {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 3;
    }

    .cropper-container {
      z-index: 2;
    }

    .cropper {
      top: 50%;
      transform: translateY(-50%);
    }

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      z-index: 4;
    }

    &:hover {
      &:before {
        opacity: 0.05;
      }
    }
  }

  .btn {
    height: 33px;
    width: 33px;
    outline: none;
    border: 0;
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all 0.2s;
    cursor: pointer;
    background: var(--btn-control-ground);
    color: #fff;
    margin-left: 4px;
    .vue-feather {
      pointer-events: none;
    }

    &:hover {
      background: var(--btn-control-ground-hover);
      color: #fff;
    }
  }

  .controls {
    position: absolute;
    bottom: 10px;
    right: 10px;
    z-index: 5;
    display: flex;
  }

  .filename {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    padding: 10px;
    word-break: break-all;
  }
}
</style>
