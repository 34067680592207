<template>
  <div>
    <div v-if="!loading">
      <TableFrames :frames="frames" />
    </div>
  </div>
</template>

<script>
import TableFrames from "@/components/_platform/table/frames/TableFrames.vue";

import { getPermissions, setBreadcrumbs } from "@/helpers";
import { useFramesStore } from "@/stores";
import { storeToRefs } from "pinia";
import { startStatusTimer } from "@/helpers/utils/statusTimer";
import { useRoute } from "vue-router";

export default {
  setup() {
    const framesStore = useFramesStore();
    framesStore.getMyFrames();

    setBreadcrumbs("frames");

    const { frames, loading } = storeToRefs(framesStore);

    const { name } = useRoute();

    startStatusTimer(() => {
      framesStore.getMyFrames(true, name);
    }, 5000);

    return {
      frames,
      loading,
    };
  },
  components: {
    TableFrames,
  },
  methods: {
    getPermissions,
  },
};
</script>
