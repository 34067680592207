<template>
  <div class="view-body">
    <div class="content-wrapper">
      <PageTitle :title="$t('route.slide_create')" class="mb-7" :backUI="true" />
      <TabNav
        :items="[
          {
            title: $t('label.settings'),
            feather: 'settings',
          },
        ]"
        variant="tab"
      >
        <template #controls>
          <ButtonUI @click="onSubmit" size="lg" :label="$t('button.save_settings')" :disabled="triggerUpload" />
        </template>
        <template #panels>
          <form @submit.prevent="onSubmit">
            <div class="grid">
              <div class="col-7">
                <SurfaceCard :title="$t('label.settings')">
                  <template #body>
                    <NotificationMessage :error="error" />
                    <FormRow>
                      <FormLabel for="name">{{ $t("label.media_type") }}:</FormLabel>
                      <FormSelect
                        id="media_type"
                        size="lg"
                        v-model="form.media_type"
                        :options="mediaTypeOptions"
                        optionLabel="name"
                        :placeholder="$t('placeholder.select_a_media_type')"
                      />
                      <FormHelp type="invalid" :data="error.data" field="media_type" />
                    </FormRow>

                    <hr />

                    <div class="flex">
                      <div class="col-upload flex-shrink-0">
                        <FormRow>
                          <FormLabel for="upload" required>{{ uploadTitle }}:</FormLabel>
                          <UploadUI
                            entity="slides"
                            :setChunks="true"
                            :mediaType="form.media_type?.value ?? form.media_type.value"
                            :image="form.image"
                            :id="form.id"
                            :limit="limit"
                            :trigger="triggerUpload"
                            @update:trigger="triggerUpload = $event"
                            @uploadChange="uploadChange"
                            @uploadIsCompleted="uploadIsCompleted"
                            @progress="uploadProgress = $event"
                          />
                          <FormHelp type="invalid" :data="error.data" field="upload" />
                        </FormRow>
                      </div>
                      <div class="col-fields pl-5 flex flex-column flex-grow-1 flex-shrink-1">
                        <FormRow>
                          <FormLabel for="name" required>{{ $t("label.name") }}:</FormLabel>
                          <FormText
                            id="name"
                            type="text"
                            size="lg"
                            :class="{ 'is-invalid': error.data?.name }"
                            v-model="form.name"
                          />
                          <FormHelp type="invalid" :data="error.data" field="name" />
                        </FormRow>
                        <FormRow class="mt-auto">
                          <FormLabel for="description">{{ $t("label.description") }}:</FormLabel>
                          <FormTextarea
                            id="description"
                            type="text"
                            size="lg"
                            :class="{ 'is-invalid': error.data?.description }"
                            v-model="form.description"
                          />
                          <FormHelp type="invalid" :data="error.data" field="description" />
                        </FormRow>
                      </div>
                    </div>
                    <ProgressBar :progress="uploadProgress" />
                  </template>
                </SurfaceCard>
              </div>
              <div class="col-5">
                <SurfaceCard :title="$t('label.user_playlists')" class="mb-5">
                  <ManageList
                    v-model:selected="form.playlists"
                    :add="playlistOptions"
                    :confirmTitle="$t('label.disconnect_user_playlist')"
                    :placeholder="$t('placeholder.enter_name')"
                    :messageNoData="$t('message.no_connected_playlists')"
                    :triggerSubmit="triggerSubmit"
                    @isReady="optionsAreReady = $event"
                  >
                    <template #footer>
                      <ButtonUI
                        :label="$t('button.create_playlist')"
                        color="secondary"
                        :to="{ name: 'slides-playlist-create' }"
                        v-if="
                          playlistOptions.length === 0 &&
                          form?.playlists &&
                          form.playlists.length === 0 &&
                          getPermissions('create playlists')
                        "
                      />
                      <FormHelp type="invalid" :data="error.data" field="playlists" :multipleFields="true" />
                    </template>
                  </ManageList>
                </SurfaceCard>
              </div>
            </div>
          </form>
        </template>
      </TabNav>
    </div>
  </div>
</template>

<script>
import ProgressBar from "@/components/ProgressBar.vue";
import PageTitle from "@/components/PageTitle.vue";
import SurfaceCard from "@/components/SurfaceCard.vue";
import FormRow from "@/components/form/FormRow.vue";
import FormLabel from "@/components/form/FormLabel.vue";
import FormText from "@/components/form/FormText.vue";
import FormSelect from "@/components/form/FormSelect.vue";
import FormTextarea from "@/components/form/FormTextarea.vue";
import FormHelp from "@/components/form/FormHelp.vue";
import ButtonUI from "@/components/ButtonUI.vue";
import UploadUI from "@/components/UploadUI.vue";
import NotificationMessage from "@/components/NotificationMessage.vue";
import {
  useAppStore,
  useAuthStore,
  useNotificationStore as notificationStore,
  useSlidesStore,
  useSystemsStore,
} from "@/stores";
import { getPermissions, i18n, redirectToPreviousPage, setBreadcrumbs } from "@/helpers";
import TabNav from "@/components/nav/TabNav.vue";
import { storeToRefs } from "pinia";
import { ref } from "vue";
import ManageList from "@/components/ManageList.vue";

export default {
  setup() {
    setBreadcrumbs();
    const authStore = useAuthStore();
    authStore.validateMe();

    const slidesStore = useSlidesStore();
    slidesStore.getAvailablePlaylistsBySlide();

    const {
      media_type_options: mediaTypeOptions,
      temp_created_slide: tempCreatedSlideData,
      playlists_available: playlistOptions,
    } = storeToRefs(slidesStore);

    const defaultMediaType = slidesStore.getStoreMediaType("image");

    const form = ref({
      id: null,
      name: "",
      description: "",
      media_type: defaultMediaType,
      playlists: [],
    });

    return {
      playlistOptions,
      mediaTypeOptions,
      tempCreatedSlideData,
      form,
    };
  },
  components: {
    ManageList,
    TabNav,
    PageTitle,
    SurfaceCard,
    NotificationMessage,
    FormRow,
    FormLabel,
    FormText,
    FormSelect,
    FormTextarea,
    FormHelp,
    ButtonUI,
    UploadUI,
    ProgressBar,
  },
  data() {
    return {
      triggerUpload: false,
      uploadChangedValidation: false,
      hasFile: false,
      error: {},
      uploadProgress: 0,
      triggerSubmit: false,
      optionsAreReady: false,
      selectedPlaylist: [],
    };
  },
  watch: {
    tempCreatedSlideData: {
      handler(slide) {
        if (slide?.data?.id) {
          this.form.id = slide.data.id;
          if (this.form.image && this.uploadChangedValidation) {
            this.triggerUpload = true;
          } else {
            this.uploadIsCompleted(true);
          }
        }
      },
    },
    validatedSubmit(submit) {
      if (submit) {
        this.error = {};

        // validation - no image or video exists
        if (!this.hasFile) {
          this.error = {
            status: 422,
            data: { upload: [i18n.global.t("message.field_required", { field: this.uploadTitle.toLowerCase() })] },
          };
          this.triggerSubmit = false;
          this.resetManageList();
          return false;
        }

        return useSlidesStore()
          .createMySlides(this.form)
          .catch((error) => {
            this.error = error;
            this.triggerSubmit = false;
            this.resetManageList();
          });
      }
    },
  },
  computed: {
    uploadTitle() {
      return useSlidesStore().getStoreMediaType(this.form.media_type?.value)?.name ?? "";
    },
    limit() {
      const { uploadLimit } = useAppStore().getAppConfig;
      const type = this.form.media_type?.value ?? "image";
      return uploadLimit[type];
    },
    validatedSubmit() {
      const { triggerSubmit, optionsAreReady } = this;
      return triggerSubmit && optionsAreReady;
    },
  },
  methods: {
    uploadIsCompleted(success) {
      if (success) {
        if (this.tempCreatedSlideData?.message) {
          notificationStore().saveAlert(this.tempCreatedSlideData.message);
        }
        redirectToPreviousPage("user-slides");
      }
    },
    uploadChange(image) {
      this.hasFile = !!image;
      this.form.image = image;
      this.uploadChangedValidation = !!image;
    },
    resetManageList() {
      this.optionsAreReady = false;
    },
    async onSubmit() {
      this.triggerSubmit = true;
      this.resetManageList();
    },
    getPermissions,
  },
};
</script>
